import React from 'react';
import LoginForm from '@/components/login/LoginForm';  

const Login: React.FC = () => {
  return (
      <LoginForm />
  );
};

export default Login;
