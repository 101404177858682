import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import './SideBar.css';
import { navItems } from '@/assets/data/navItem';

interface SideBarProps {
  onPageChange: (link: string, icon: JSX.Element, childLink?: string) => void;
  isOpen: boolean;  // Add isOpen prop to manage sidebar visibility
  onToggleSidebar: () => void;
}

const SideBar: React.FC<SideBarProps> = ({ onPageChange, isOpen, onToggleSidebar }) => {
  const sidebarRef = useRef<HTMLElement>(null); // Create a reference for the sidebar
  const navigate = useNavigate();
  const location = useLocation(); // Get the current path

  // Handle collapsing the sidebar when clicking outside of it
  const handleClickOutside = (event: MouseEvent) => {
    // also check if the  user click in the sidebar or not/ if the user click in the sidebar , keep it open, if not close it
    if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node ) && document.body.classList.contains('toggle-sidebar')) {
      document.body.classList.add('toggle-sidebar');  
    } else if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node )) {
      onToggleSidebar();  // Close the sidebar
    }
  };

  useEffect(() => {
    const rootElement = document.getElementById('main');
  
    // Check if rootElement exists before adding event listener
    if (rootElement) {
      rootElement.addEventListener('mousedown', handleClickOutside);
    }
  
    return () => {
      // Clean up: remove event listener if rootElement exists
      if (rootElement) {
        rootElement.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, []);
  

   useEffect(() => {
    if (isOpen) {
      document.body.classList.add('toggle-sidebar');  // Open the sidebar
    } else {
      document.body.classList.remove('toggle-sidebar');  // Close the sidebar
    }
  }, [isOpen]);

  const handleNavigation = (link: string, icon: JSX.Element, childLink?: string) => {
    onPageChange(link, icon, childLink); // Update the dashboard state if necessary
    navigate(link); // Navigate to the selected page
  };

  return (
    <aside id="sidebar" className="sidebar" ref={sidebarRef}> {/* Attach the reference */}
      <ul className="sidebar-nav" id="sidebar-nav">
        {navItems.map((item, index) => (
          <li className="nav-item" key={index}>
            {!item.children ? (
              <NavLink
                to={item.link || '#'}
                className={({ isActive }) =>
                  `nav-link ${isActive ? 'active' : 'collapsed'}`
                }
                end={item.link === '/admin'} // Only for the homepage
                onClick={() => handleNavigation(item.link || '#', item.icon, item.title)}
              >
                {item.icon} {/* Render the icon JSX */}
                <span>{item.title}</span>
              </NavLink>
            ) : (
              <>
                <a
                  className={`nav-link ${item.children.some(child => location.pathname === child.link) ? 'active' : 'collapsed'}`}
                  data-bs-target={`#nav-${index}`}
                  data-bs-toggle="collapse"
                >
                  {item.icon}
                  <span>{item.title}</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul
                  id={`nav-${index}`}
                  className={`nav-content collapse ${
                    item.children.some(child => location.pathname === child.link) ? 'show' : ''
                  }`}
                  data-bs-parent="#sidebar-nav"
                >
                  {item.children.map((child, childIndex) => (
                    <li key={childIndex}>
                      <NavLink
                        to={child.link || '#'}
                        className={({ isActive }) => (isActive ? 'active' : '')}
                        onClick={() => handleNavigation(child.link || '#', item.icon, child.title)}
                      >
                        {child.icon}
                        <span>{child.title}</span>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </li>
        ))}
      </ul>
    </aside>
  );
};

export default SideBar;
