import * as React from 'react';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { Button, List, ListItem, ListItemText, Checkbox, Divider, Typography, Pagination, Box } from '@mui/material';

interface DialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (inspectionData: any) => void;
  notPassedData: any[];
}

interface SelectedItem {
  inspectionItemId: number;
  vehicleId?: number;
  reportedById?: number;
  description?: string; 
}

export const NotPassedToIssueDialog: React.FC<DialogProps> = ({ open, onClose, onSubmit, notPassedData }) => {
  const [selectedItem, setSelectedItem] = React.useState<SelectedItem | null>(null); // Define state type
  const [page, setPage] = React.useState(1);
  const itemsPerPage = 5; // Number of items per page

  // Flatten the list of failed inspection items
  const failedItems = React.useMemo(() => 
    notPassedData.flatMap(inspection =>
      inspection.inspectionItems
        .filter((item: any) => item.status === 'Fail')
        .map((item: any) => ({
          inspectionItemId: item.inspectionItemId,
          vehicleId: inspection.vehicleId,
          reportedById: inspection.inspectionById,
          description: item.comment,
          inspectionNumber: inspection.inspectionNumber,
          vehicleName: inspection.vehicle?.name || 'N/A',
          inspectionByUsername: inspection.inspectionBy?.username || 'N/A',
          inspectionFormName: inspection.inspectionForm?.name || 'N/A',
          itemName: item.inspectionItem?.name || 'Không có tên nhiệm vụ',
          itemDescription: item.inspectionItem?.description || 'Không có mô tả'
        }))
    ), 
  [notPassedData]);

  const paginatedItems = failedItems.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  const handleToggle = (item: SelectedItem) => {
    setSelectedItem((prevSelected: SelectedItem | null) =>
      prevSelected?.inspectionItemId === item.inspectionItemId ? null : item
    );
  };

  const handleSubmit = () => {
    if (selectedItem) {
      onSubmit(selectedItem); // Pass the selected item to IssueDialog
      setSelectedItem(null);
    } else {
        alert ('Hãy chọn ít nhất 1 mục');
    }
  };

 return (
    <CustomDialog open={open} title="Thêm thẻ vấn đề từ mục ko đạt" onClose={onClose} onSubmit={handleSubmit} submitLabel="Thêm">
      {failedItems.length > 0 ? (
        <>
          <List>
            {paginatedItems.map((item) => (
              <React.Fragment key={item.inspectionItemId}>
                <ListItem>
                  <Checkbox
                    checked={selectedItem?.inspectionItemId === item.inspectionItemId}
                    onChange={() => handleToggle(item)}
                  />
                  <ListItemText
                    primary={
                      <Typography component="span" variant="body1">
                        <strong>Mã số kiểm tra:</strong> {item.inspectionNumber}, <strong>Phương tiện:</strong> {item.vehicleName}, 
                        <strong> Kiểm tra bởi:</strong> {item.inspectionByUsername}, <strong>Đơn kiểm tra:</strong> {item.inspectionFormName}, 
                        <strong> Hạng mục:</strong> {item.itemName}
                      </Typography>
                    }
                    secondary={item.itemDescription}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>

          {/* Pagination Controls */}
          <Box display="flex" justifyContent="center" mt={2}>
            <Pagination
              count={Math.ceil(failedItems.length / itemsPerPage)}
              page={page}
              onChange={(_, value) => setPage(value)}
              color="primary"
            />
          </Box>
        </>
      ) : (
        <Typography variant="body1" color="textSecondary" align="center" sx={{ mt: 2 }}>
          Chưa có vấn đề
        </Typography>
      )}
    </CustomDialog>
  );
};
