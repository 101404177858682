import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import Header from '@/components/dashboard/header/Header';
import SideBar from '@/components/dashboard/sidebar/SideBar';
import Main from '@/components/dashboard/mainpage/Main';
import { Outlet } from 'react-router-dom';
import { navItems } from '@/assets/data/navItem';


const Dashboard: React.FC = () => {
  const location = useLocation(); // Get the current URL
  const [activePage, setActivePage] = useState<string | undefined>();
  const [activeChildPage, setActiveChildPage] = useState<string | undefined>(undefined);
  const [activeIcon, setActiveIcon] = useState<JSX.Element | undefined>(undefined);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);  // Manage the sidebar open/close state

  // Function to find the active page based on the current URL
  useEffect(() => {
    const currentPath = location.pathname;

    // Find the active nav item based on the current URL
    const activeNavItem = navItems.find((item) => {
      if (item.link === currentPath) {
        return true;
      }
      if (item.children) {
        return item.children.some((child) => child.link === currentPath);
      }
      return false;
    });

    // Set the active page, icon, and child page based on the matched nav item
    if (activeNavItem) {
      setActivePage(activeNavItem.link || '');
      setActiveIcon(activeNavItem.icon);
      setActiveChildPage(activeNavItem.title);
    }
  }, [location]);

  // Function to handle page changes from the sidebar
  const handlePageChange = (link: string, icon: JSX.Element, childLink?: string) => {
    setActivePage(link);
    setActiveIcon(icon);
    setActiveChildPage(childLink);
  };

  const toggleSidebar = () => {
    // check if the body have toggle-sidebar class or not. if yes mean it open, if not mean it closed
    if (document.body.classList.contains('toggle-sidebar')) {
      setIsSidebarOpen(false);
      document.body.classList.remove('toggle-sidebar');  // Close the sidebar
    } else {
      setIsSidebarOpen(true);
      document.body.classList.add('toggle-sidebar');  // Open the sidebar
    }
  };

  useEffect(() => {
    // Handle screen resize event to automatically toggle the sidebar on desktop vs mobile
    const handleResize = () => {
      if (window.innerWidth >= 1200) {
        setIsSidebarOpen(false);
        document.body.classList.remove('toggle-sidebar');  // Ensure it's open on desktop
      } else {
        setIsSidebarOpen(false);
        document.body.classList.add('toggle-sidebar');  // Close on smaller screens
      }
    };

    window.addEventListener('resize', handleResize);

    // Set the initial state based on screen size
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
      <Header onToggleSidebar={toggleSidebar} /> {/* Pass toggleSidebar function to Header */}
      <SideBar onPageChange={handlePageChange} isOpen={isSidebarOpen}  onToggleSidebar={toggleSidebar} />
      <Main activePage={activePage || ''} activeIcon={activeIcon} activeChildPage={activeChildPage || ''}>
        <Outlet />
      </Main>
    </>
  );
};

export default Dashboard;
