import React, { useState } from 'react';
import {
  Typography,
  Stack,
  Pagination,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
} from '@mui/material';
import { AddCircleOutline, DeleteOutline } from '@mui/icons-material';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields } from '@/components/dashboard/formField/FormFields';
import { ServiceSchedule, ServiceTasks } from '@/components/subPlace/subEntriesTypeMaintainance';
import { timeIntervalUnit } from '@/hooks/mapping';

interface ServiceScheduleProps {
  schedules: ServiceSchedule[];
  availableTasks: ServiceTasks[];
  onSchedulesChange: (updatedSchedules: ServiceSchedule[]) => void;
  primaryMeterType?: string;  
  secondaryMeterType?: string;
}

export const ServiceScheduleComponent: React.FC<ServiceScheduleProps> = ({
  schedules,
  availableTasks,
  onSchedulesChange,
  primaryMeterType,
  secondaryMeterType,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState<ServiceSchedule | null>(null);

  const itemsPerPage = 6;

  const handleOpenDialog = (schedule?: ServiceSchedule) => {
       // Check if the primaryMeterInterval value is set before opening the dialog
    if (!primaryMeterType || !primaryMeterType.length) {
        alert('Vui lòng chọn giá trị đo chính trước khi thêm lịch.');
        return;
    }
    setSelectedSchedule(
      schedule || {
        id: `0`, // Assign a temporary ID
        serviceProgramId: 0, // Default or placeholder value
        serviceTaskIds: [],
        primaryMeterInterval: undefined,
        secondaryMeterInterval: undefined,
        timeInterval: undefined,
        timeIntervalUnit: 'days', // Default unit 
        createdAt: new Date(),
        updateAt: new Date(),
      }
    );
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedSchedule(null);
  };

  const handleSaveSchedule = () => {
    console.log('Selected Schedule before update:', selectedSchedule);
  
    if (selectedSchedule) {
      // Validation logic
      const errors: string[] = [];
  
      // Check if all required fields are filled
      if (!selectedSchedule.primaryMeterInterval || selectedSchedule.primaryMeterInterval <= 0) {
        errors.push('Dữ liệu đo chính phải lớn hơn 0.');
      }
      if (secondaryMeterType && (!selectedSchedule.secondaryMeterInterval || selectedSchedule.secondaryMeterInterval <= 0)) {
        errors.push('Dữ liệu đo phụ phải lớn hơn 0.');
      }
      if (!selectedSchedule.timeInterval || selectedSchedule.timeInterval <= 0) {
        errors.push('Khoảng thời gian phải lớn hơn 0.');
      }
      if (!selectedSchedule.timeIntervalUnit) {
        errors.push('Đơn vị thời gian là bắt buộc.');
      }
      if (!selectedSchedule.serviceTaskIds || selectedSchedule.serviceTaskIds.length === 0) {
        errors.push('Phải chọn ít nhất một công việc dịch vụ.');
      }
  
      // If there are validation errors, display them and return
      if (errors.length > 0) {
        alert(`Vui lòng sửa các lỗi sau:\n- ${errors.join('\n- ')}`);
        return;
      }
  
      // Sanitize the schedule
      const sanitizedSchedule = {
        ...selectedSchedule,
        serviceTaskIds: selectedSchedule.serviceTaskIds.map(Number), // Ensure task IDs are numbers
      };
  
      console.log('Sanitized Schedule:', sanitizedSchedule);
  
      // Determine if the schedule exists in the array
      const scheduleIndex = schedules.findIndex(
        (schedule) => schedule.id === sanitizedSchedule.id
      );
  
      let updatedSchedules;
      if (scheduleIndex > -1) {
        // Update existing schedule
        updatedSchedules = schedules.map((schedule, index) =>
          index === scheduleIndex ? sanitizedSchedule : schedule
        );
      } else {
        // Add new schedule
        updatedSchedules = [
          ...schedules,
          {
            ...sanitizedSchedule,
            id: `${Date.now()}`, // Assign a unique ID
            createdAt: new Date(),
            updateAt: new Date(),
          },
        ];
      }
  
      // Update the schedules state
      onSchedulesChange(updatedSchedules);
    }
  
    handleCloseDialog();
  };

  const paginatedSchedules = schedules.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
  
    if (selectedSchedule) {
      setSelectedSchedule({
        ...selectedSchedule,
        [name]:
          name === "serviceTaskIds" && e.target instanceof HTMLSelectElement
            ? Array.from(e.target.selectedOptions, (option) => Number(option.value))
            : ["timeInterval", "primaryMeterInterval", "secondaryMeterInterval"].includes(name)
            ? Number(value)
            : value,
      });
    }
  };

  const handleSpecificFieldChange = (fieldName: string, fieldValue: any) => {
    if (selectedSchedule) {
      setSelectedSchedule({
        ...selectedSchedule,
        [fieldName]: fieldValue,
      });
    }
  };

  const handleDeleteSchedule = (id: string) => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa lịch này?');
    if (confirmDelete) {
      const updatedSchedules = schedules.filter((schedule) => schedule.id !== id);
      onSchedulesChange(updatedSchedules);
    }
  };
  
  const formFields = [
    {
      name: "primaryMeterInterval",
      label: `Dữ liệu đo chính (${timeIntervalUnit(primaryMeterType || '')})`, // Dynamically apply the unit
      type: "number",
      value: selectedSchedule?.primaryMeterInterval || "",
      handleChange: handleFieldChange,
      required: true,
    },
    ...(secondaryMeterType === 'hour' || secondaryMeterType === 'kilometer' || secondaryMeterType === 'mile' ? [{
      name: "secondaryMeterInterval",
      label: `Dự liệu đo phụ (${timeIntervalUnit(secondaryMeterType)})`, // Dynamically apply the unit
      type: "number",
      value: selectedSchedule?.secondaryMeterInterval || "",
      handleChange: handleFieldChange,
      required: true,
    }] : []), // Conditionally add this field based on secondaryMeterType=
    {
        name: "serviceTaskIds",
        label: "Chọn công việc ",
        type: "select",
        value: selectedSchedule?.serviceTaskIds.map(String) || [], // Ensure strings for select
        handleChange: handleFieldChange, // Pass the same handler
        options: availableTasks.map((task) => ({
          value: task.id.toString(), // Ensure the value is a string
          label: task.name,
        })),
        multiple: true,
        required: true,
      },
  ];

  return (
    <div className="service-schedule proof mt-2">
      <Stack spacing={2}>
        {/* <Typography variant="h6">Lịch nhắc</Typography> */}
        {paginatedSchedules.map((schedule, index) => (
          <Stack
            key={schedule.id}
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            className="schedule-item"
          >
            <Typography>
              <strong>Mỗi:</strong> {schedule.timeInterval || 'N/A'}  {timeIntervalUnit(schedule.timeIntervalUnit || '')}
            </Typography>
            <Typography>
              <strong>Các Việc:</strong>{' '}
              {schedule.serviceTaskIds.length > 0 ? (
                schedule.serviceTaskIds.map((id, index) => (
                  <React.Fragment key={id}>
                    {/* Add a <br /> before the first item */}
                    {index === 0 && <br />}
                    + {availableTasks.find((task) => Number(task.id) === id)?.name}
                    <br />
                  </React.Fragment>
                ))
              ) : (
                'No tasks'
              )}
            </Typography>


            <div className='button-group'> 
              <Button className='me-1' variant="outlined" onClick={() => handleOpenDialog(schedule)}>
                Sửa
              </Button>
              <Button variant="outlined" color="error"  onClick={() => handleDeleteSchedule(schedule.id)}>
              Xóa
              </Button>
            </div>
          </Stack>
        ))}
        <Button startIcon={<AddCircleOutline />} onClick={() => handleOpenDialog()}>
          Thêm lịch
        </Button>
        <Pagination
          count={Math.ceil(schedules.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Stack>

      {/* Dialog for Adding/Editing a Schedule */}
      {openDialog && selectedSchedule && (
        <CustomDialog
          open={openDialog}
          title={selectedSchedule.id ? 'Chỉnh sửa' : 'Thêm'}
          onClose={handleCloseDialog}
          onSubmit={handleSaveSchedule}
          dialogWidth="600px"
          dialogMaxWidth="80%"
        >
          <FormFields fields={formFields} />

          <Typography variant="body1" sx={{ mb: 1, mt: 2 }}>
                  <strong>Thời gian nhắc (bắt đầu từ lúc thêm gói này vào)</strong> *
            </Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <TextField
              label="Khoảng thời gian (số)"
              type="number"
              value={selectedSchedule?.timeInterval || ''}
              onChange={(e) =>
                handleSpecificFieldChange('timeInterval', Number(e.target.value))
              }
            />
          <FormControl fullWidth>
            <Select
              value={selectedSchedule?.timeIntervalUnit || ''}
              onChange={(e) =>
                handleSpecificFieldChange('timeIntervalUnit', e.target.value)
              }
              displayEmpty // Enables displaying the placeholder when the value is empty
            >
              <MenuItem value="" disabled >
                Đơn vị
              </MenuItem>
              <MenuItem value="day">Ngày</MenuItem>
              <MenuItem value="week">Tuần</MenuItem>
              <MenuItem value="month">Tháng</MenuItem>
              <MenuItem value="year">Năm</MenuItem>
            </Select>
          </FormControl>
          </Stack>

        </CustomDialog>
      )}
    </div>
  );
};
