import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Stack, IconButton } from '@mui/material';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields } from '@/components/dashboard/formField/FormFields';
import { InspectionForm, InspectionPrograms, InspectionSchedule } from './InspectionIssuesSetup';
import { InspectionScheduleComponent } from '@/components/subPlace/InspectionIssuesScheduleComponenet';

interface InspectionIssuesProgramDialogProps {
    open: boolean;
    data?: any;
    onClose: () => void;
    onSubmit: (data: Partial<InspectionPrograms>, existingServiceSchedule: InspectionSchedule[]) => void; // Pass existing schedules
    availableInspectionForms?: InspectionForm[]; 
};

export const InspectionIssuesProgramDialog: React.FC<InspectionIssuesProgramDialogProps> = ({ open, data, onClose, onSubmit, availableInspectionForms: avaiableInspectionFormsProp }) => { 
    const [programData, setProgramData] = useState<Partial<InspectionPrograms>>(data || {});
    const [errors, setErrors] = useState<{ [key: string]: string }>({});    
    const [forms, setForms] = useState<InspectionForm[]>([]);
    const [inspectionSchedule, setInspectionSchedule] = useState<InspectionSchedule[]>(data?.inspectionSchedules || []);
    const [existingInspectionSchedule, setExistingInspectionSchedule] = useState<InspectionSchedule[]>([]);
    const [avaiableInspectionForms, setAvailableInspectionForms] = useState<InspectionForm[]>(avaiableInspectionFormsProp || []);   

    useEffect(() => {
        if (data) {
            setProgramData(data);
            fetchInspectionSchedule(data.id); // Fetch existing service schedule if `data` is provided
        } else {
            setProgramData({});
            setInspectionSchedule([]);  
        }

    }, [data]);

    async function fetchInspectionSchedule(programId?: string) {
        if (programId) {
            const response = await fetch(`/api/inspection-programs/${programId}/inspection-schedules`);
            const inspectionScheduleData = await response.json();

            // Convert `id` to string if necessary
            const formattedData = inspectionScheduleData.map((schedule: any) => ({
                ...schedule,
                id: String(schedule.id), // Ensure `id` is a string
            }));

            setExistingInspectionSchedule(formattedData); // Store existing schedules

            setInspectionSchedule(formattedData); 
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
      
        setProgramData((prev) => ({
          ...prev,
          [name]: type === 'checkbox' ? checked : value, // Handle checkbox and other input types
        }));
    };

    const handleInspectionScheduleChange = (updatedSchedule: InspectionSchedule[]) => { 
        setInspectionSchedule(updatedSchedule); 
    };

    const handleSubmit = () => {
        const newErrors: { [key: string]: string } = {};

        if (!programData.name) {
          newErrors.name = 'Tên gói không được để trống';
        } 

        if (!programData.description) {
          newErrors.description = 'Mô tả không được để trống';
        }

        if (!programData.primaryMeterType) {
          newErrors.primaryMeterType = 'Khoảng đo chính không được để trống';
        }

        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
          alert(Object.values(newErrors).join('\n'));
          return;
        }

        setErrors({});  

        const updatedData: Partial<InspectionPrograms> = {
          ...programData,
          inspectionSchedules: inspectionSchedule,  
        };

        onSubmit(updatedData, existingInspectionSchedule);
    };


    const formFields = [
        {
          name: 'name',
          label: 'Tên gói',
          type: 'text',
          value: programData.name || '',
          handleChange: handleChange,
          required: true,
        },
        {
          name: 'description',
          label: 'Mô Tả',
          type: 'text',
          value: programData.description || '',
          handleChange: handleChange,
          required: true,
        },
        {
          name: 'primaryMeterType',
          label: 'Khoảng đo chính',
          type: 'select',
          value: programData.primaryMeterType || '',
          handleChange: handleChange,
          required: true,
          options: [
            { value: 'hour', label: 'Giờ' },
            { value: 'kilometer', label: 'Kilometer' },
            { value: 'mile', label: 'Dặm' },
          ],
        },
        {
          name: 'secondaryMeter_flg',
          label: 'Có khoảng đo phụ không?',
          type: 'checkbox',
          value: programData.secondaryMeter_flg || false,
          handleChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            const isChecked = e.target.checked;
      
            setProgramData((prev) => ({
              ...prev,
              secondaryMeter_flg: isChecked,
              secondaryMeterType: isChecked ? prev.secondaryMeterType : '', // Clear secondaryMeterType if unchecked
            }));
          },
        },
        ...(programData.secondaryMeter_flg
          ? [
              {
                name: 'secondaryMeterType',
                label: 'Khoảng đo phụ',
                type: 'select',
                value: programData.secondaryMeterType || '',
                handleChange: handleChange,
                required: false,
                options: [
                  { value: 'hour', label: 'Giờ' },
                  { value: 'kilometer', label: 'Kilometer' },
                  { value: 'mile', label: 'Dặm' },
                ],
              },
            ]
          : []), // Conditionally add the secondaryMeterType field
    ];


    return (
        <CustomDialog 
            open={open}
            title="Thêm/Chỉnh sửa gói kiểm tra"
            onClose={onClose}
            onSubmit={handleSubmit}
            submitLabel='Lưu'
            dialogWidth='1600px'
            dialogMaxWidth='95%'
        >
            <div className='row'>
                <div className='col-md-6'>
                    <FormFields fields={formFields} />
                </div>

                <div className='col-md-6'>
                    <Typography variant='h6' gutterBottom>Chọn mẫu kiểm tra</Typography>
                    <InspectionScheduleComponent
                        schedules={inspectionSchedule}
                        onInspectionScheduleChange={handleInspectionScheduleChange} 
                        availableInspectionForms={avaiableInspectionForms}
                        primaryMeterType={programData.primaryMeterType}
                        secondaryMeterType={programData.secondaryMeterType}
                    />

                </div>
            </div>   
        </CustomDialog>
    );


};