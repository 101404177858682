import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { Download as DownloadIcon } from '@phosphor-icons/react';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { Upload as UploadIcon } from '@phosphor-icons/react';
import { GenericFilters } from '@/components/dashboard/filter/genericFilter';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1'; 
import axios from 'axios';
import { timeIntervalUnit, statusOrder, reverseStatusOrder, statusMap } from '@/hooks/mapping';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SpeedIcon from '@mui/icons-material/Speed';
import { formatDate } from '@/hooks/format-date';
import { InspectionReminderDialog } from './InspectionReminderDialog';
import { Dayjs } from 'dayjs';
import { getContrastTextColor } from '@/hooks/contrast-text-color';

interface Vehicle {
    vehicleNumber: string;
    name: string;
    primaryMeterType: string;
    primaryMeter: number;
    secondaryMeterType: string; 
    secondaryMeter: number; 
}

interface InspetionForm {
    id: string;
    name: string;
    description: string;
}

interface inspectionsLatest {
  inspectionDate: Date | string;  
  primaryMeter: number;
}

export interface InspectionReminder {
  id: string;
  vehicleId: number;
  vehicle: Vehicle;
  inspectionFormId: number;
  inspectionForm: InspetionForm;
  primaryMeterInterval: number;   
  timeInterval: number;
  timeIntervalUnit: string;
  primaryMeterThreshold: number;  
  timeIntervalThreshold: number;  
  timeIntervalThresholdUnit: string;
  manualNextDueDate_flg: boolean;
  nextDuePrimaryMeter: number;    
  nextDueDate: Date | string;  
  createdDate: Date;
  updateAt: Date;    
  customFields: Record<string, string>;
  calculatedNextDueDate: Date | string; 
  calculatedNextDuePrimaryMeter: number;  
  status: string
  // latest inspection down here

  latestInspections: inspectionsLatest;
}

function applyPagination(rows: InspectionReminder[], page: number, rowsPerPage: number): InspectionReminder[] {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export default function InspectionReminderPage(): React.JSX.Element { 
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5); // Adjust number of rows per page as necessary
  const [data, setData] = React.useState<InspectionReminder[]>([]); // MaintainanceServiceEntry data state
  const [filteredData, setFilteredData] = React.useState<InspectionReminder[]>([]); // Filtered data state
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedInspectionReminder, setSelectedInspectionReminder] = React.useState<InspectionReminder | null>(null);


  const fetchData = async () => {
    try {
      const response = await axios.get('/api/inspection-reminders');
      const sortedData = response.data.sort((a: InspectionReminder, b: InspectionReminder) => a.id.toString().localeCompare(b.id.toString())); 

      setData(sortedData);
      setFilteredData(sortedData);
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    fetchData();
  }, []);

    const handleFilterChange = (column: string, searchTerm: string | boolean | Dayjs | null) => {
      if (column === 'statusOrder') {
        const sorted = [...filteredData].sort((a, b) => {
          const orderMap = searchTerm === 'asc' ? statusOrder : reverseStatusOrder;
          const statusA = a.status as keyof typeof orderMap; // Type assertion
          const statusB = b.status as keyof typeof orderMap; // Type assertion
    
          // Compare status order first
          const statusComparison = orderMap[statusA] - orderMap[statusB];
          if (statusComparison !== 0) {
            return statusComparison;
          }
    
          // If statuses are equal, compare primaryMeter relative to primaryMeterThreshold
          const thresholdA = a.primaryMeterThreshold ?? 0;
          const thresholdB = b.primaryMeterThreshold ?? 0;
    
          const distanceA = Math.abs((a.vehicle.primaryMeter ?? 0) - thresholdA);
          const distanceB = Math.abs((b.vehicle.primaryMeter ?? 0) - thresholdB);
    
          return searchTerm === 'asc' ? distanceA - distanceB : distanceB - distanceA;
        });
    
        setFilteredData(sorted);
        return;
      }
    
      const filtered = data.filter((insepction) => {
        let value;
    
        switch (column) {
          case 'vehicleNumber':
            value = insepction.vehicle.vehicleNumber;
            break;
          case 'inspectionForm':
            value = insepction.inspectionForm.name;
            break;
          case 'status':
            value = insepction.status;
            break;
        }
    
        if (searchTerm === null || searchTerm === '') {
          return true;
        }
    
        if (typeof value === 'string' && typeof searchTerm === 'string') {
          return value.toLowerCase().includes(searchTerm.toLowerCase());
        }
    
        if (typeof value === 'boolean' && typeof searchTerm === 'boolean') {
          return value === searchTerm;
        }
    
        return false;
      });
      setFilteredData(filtered);
    };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  }

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  }

  const handleOpenDialog = () => {
    setSelectedInspectionReminder(null); 
    setOpenDialog(true);
  }

  const handleEditInspectionReminder = (id: string) => {
    const inspectionReminderToEdit = data.find((inspectionReminder) => inspectionReminder.id === id); 
    if (inspectionReminderToEdit) {
      setSelectedInspectionReminder(inspectionReminderToEdit);
      setOpenDialog(true);
    } 
  }

  const handleDeleteServiceReminder = async (id: string) => {
    const confirmed = window.confirm('Bạn có chắc muốn xóa thẻ này?');
    if (confirmed) {
      try {
        await axios.delete(`/api/inspection-reminders/${id}`);
        alert('Xóa thành công');  
        setData((prevData) => prevData.filter((inspectionReminder) => inspectionReminder.id !== id));
        setFilteredData((prevData) => prevData.filter((inspectionReminder) => inspectionReminder.id !== id)); 
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleDeleteInspectionReminderSelected = async (selectedIds: string[]) => {
    const confirmed = window.confirm('Bạn có chắc muốn xóa những thẻ đã chọn?'); 
    if (confirmed) {
      try {
        await axios.delete(`/api/inspection-reminders/${JSON.stringify(selectedIds)}`);
        alert('Xóa thành công');
        setData((prevData) => prevData.filter((inspectionReminder) => !selectedIds.includes(inspectionReminder.id)));
        setFilteredData((prevData) => prevData.filter((inspectionReminder) => !selectedIds.includes(inspectionReminder.id)));
      } catch (error) {
        console.log('Error deleting service reminders:', error);
      }
    }
  };

  const handleAddOrEditInspetionReminder = async (inspectionReminderData: Partial<InspectionReminder>) => {  
    try {

      const formData = new FormData();

      Object.keys(inspectionReminderData).forEach((key) => {
        const value = inspectionReminderData[key as keyof InspectionReminder];
        if (value !== undefined && value !== null) { // Check for undefined or null explicitly
          formData.append(key, value.toString());
        }
      });

      if (inspectionReminderData.customFields) {
        formData.set('customFields', JSON.stringify(inspectionReminderData.customFields)); 
      }

      if (selectedInspectionReminder) {
        const response = await axios.put(`/api/inspection-reminders/${selectedInspectionReminder.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
          },
        });

        const updateInspectionReminder = response.data;  

        setData((prevData) => 
          prevData.map((inspectionReminder) => (inspectionReminder.id === updateInspectionReminder.id ? updateInspectionReminder : inspectionReminder))
        )
        setFilteredData((prevData) => 
          prevData.map((inspectionReminder) => (inspectionReminder.id === updateInspectionReminder.id ? updateInspectionReminder : inspectionReminder))
        )
      } else {
        const response = await axios.post('/api/inspection-reminders', formData, { 

          headers: {
            'Content-Type': 'multipart/form-data', 
          },
        });
        const newInspectionReminder = response.data;
        setData((prevData) => [newInspectionReminder, ...prevData]);
        setFilteredData((prevData) => [newInspectionReminder, ...prevData]); 
      }
      fetchData();
      setOpenDialog(false);

    } catch (error) {
      alert('Có lỗi xảy ra khi thêm hoặc sửa thẻ'); 
      console.error('Lỗi thêm/cập nhập phiếu nhắc nhở');
    }

  };

  const filterColumns = [
    { value: 'vehicleNumber', label: "Mã số xe" },
    { value: 'inspectionForm', label: "Tên mẫu kiểm tra" },
    { 
      value: 'status', 
      label: "Trạng thái đến hạn", 
      options: [
        { label: "Chưa đến hạn", value: "NotDue" },
        { label: "Sắp đến hạn", value: "DueSoon" },
        { label: "Quá hạn", value: "Overdue" }
      ],
      type: 'dropdown' as const,
    },
    {
      value: 'statusOrder',
      label: "Sắp xếp trạng thái",
      options: [
        { label: "Chưa đến hạn -> Quá hạn", value: "asc" },
        { label: "Quá hạn -> Chưa đến hạn", value: "desc" },
      ],
      type: 'dropdown' as const,
    },
  ];

  type StatusKey = keyof typeof statusMap; // Type for keys of statusMap
  
  const transformedInspectionReminder = filteredData.map((reminder) => {
    return {
      ...reminder,
      vehicleNumber: reminder.vehicle?.vehicleNumber || 'N/A',
      serviceTaskName: reminder.inspectionForm?.name || 'N/A',
    };
  });

  const columns: TableColumn<InspectionReminder>[] = [ 
    { label: 'Mã số xe', field: (row) => row.vehicle?.vehicleNumber || 'Chưa có' },
    { label: 'Tên mẫu kiểm tra', field: (row) => row.inspectionForm?.name || 'Chưa có' },
    {
      label: 'Nhắc vào mỗi (theo số ODO chính)',
      field: (row) =>
        row.primaryMeterInterval && row.vehicle?.primaryMeterType
          ? `${row.primaryMeterInterval} ${row.vehicle.primaryMeterType}`
          : 'Chưa có',
    },
    {
      label: 'Nhắc vào mỗi (theo thời gian)',
      field: (row) =>
        row.timeInterval && row.timeIntervalUnit
          ? `${row.timeInterval} ${timeIntervalUnit(row.timeIntervalUnit)}`
          : 'Chưa có',
    },
    {
      label: 'Số ODO chính hiện tại',
      field: (row) =>
        row.vehicle.primaryMeter && row.vehicle?.primaryMeterType
          ? `${row.vehicle.primaryMeter} ${row.vehicle.primaryMeterType}`
          : 'Chưa có',
    },
    {
      label: 'Trạng thái',
      field: (row) => {
        const status = (row.status as StatusKey) || 'NotDue'; // Explicitly cast 'status' to StatusKey
        const statusInfo = statusMap[status];
        const textColor = getContrastTextColor(statusInfo.color); // Get text color based on background color 

        return (
          <Chip
            label={statusInfo.label}
            style={{
              backgroundColor: statusInfo.color,
              color: textColor,
              fontWeight: 'bold',
              borderRadius: '5px',
              padding: '5px 10px',
            }}
          />
        );
      },
    },

    // Lịch sử gần đây
    { 
      label: 'Lần kiểm tra gần nhất',
      field: (row) => {
        const { latestInspections } = row;
          if (latestInspections) {
            const completedDate = new Date(latestInspections.inspectionDate).toLocaleDateString('vi-VN'); // Format the date
            const primaryMeter = latestInspections.primaryMeter
              ? `${latestInspections.primaryMeter} ${row.vehicle.primaryMeterType}`
              : 'N/A';
              return (
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CalendarMonthIcon fontSize="small" style={{ marginRight: '5px' }} />
                    <span>Ngày hoàn thành: {completedDate}</span>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SpeedIcon fontSize="small" style={{ marginRight: '5px' }} />
                    <span>Số ODO: {primaryMeter}</span>
                  </div>
                </div>
              );
          }
          return 'Không có lịch sử gần đây';
      }
    },
    {
      label: 'Ngày đến hạn sắp tới',
      field: (row) => {
        if (row.calculatedNextDueDate || row.calculatedNextDuePrimaryMeter) {
          const dueDate = row.calculatedNextDueDate
            ? formatDate(row.calculatedNextDueDate)
            : 'N/A';
          const duePrimaryMeter = row.calculatedNextDuePrimaryMeter
            ? `${row.calculatedNextDuePrimaryMeter} ${row.vehicle.primaryMeterType}`
            : 'N/A';
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CalendarMonthIcon fontSize="small" style={{ marginRight: '5px' }} />
                <span>{dueDate}</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SpeedIcon fontSize="small" style={{ marginRight: '5px' }} />
                <span>{duePrimaryMeter}</span>
              </div>
            </div>
          );
        }
        return 'Chưa có';
      },
    },
    {
      label: 'Ngày/Khoảng đo đến hạn thủ công',
      field: (row) => {
        const hasNextDueDate = row.manualNextDueDate_flg && row.nextDueDate;
        const hasNextDuePrimaryMeter = row.manualNextDueDate_flg && row.nextDuePrimaryMeter;
    
        const manualDueDate = hasNextDueDate ? formatDate(row.nextDueDate) : null;
        const manualDuePrimaryMeter = hasNextDuePrimaryMeter
          ? `${row.nextDuePrimaryMeter} ${row.vehicle?.primaryMeterType || ''}`
          : null;
    
        if (!manualDueDate && !manualDuePrimaryMeter) {
          return 'Không có dữ liệu';
        }
    
        return (
          <div>
            {manualDueDate && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CalendarMonthIcon fontSize="small" style={{ marginRight: '5px' }} />
                <span>{manualDueDate}</span>
              </div>
            )}
            {manualDuePrimaryMeter && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SpeedIcon fontSize="small" style={{ marginRight: '5px' }} />
                <span>{manualDuePrimaryMeter}</span>
              </div>
            )}
          </div>
        );
      },
    },
  ];


  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h4">Nhắc nhở kiểm tra</Typography>
        </Stack>
        <div>
          <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={handleOpenDialog}>
            Thêm
          </Button>
        </div>
      </Stack>
      <GenericFilters 
      columns={filterColumns} onFilterChange={handleFilterChange} placeholder="Tìm thẻ nhắc nhở"
      />
      <GenericTable
        count={filteredData.length}
        page={page}
        rows={applyPagination(transformedInspectionReminder, page, rowsPerPage)} // Use transformed data
        columns={columns}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onEdit={handleEditInspectionReminder  }
        onDelete={handleDeleteServiceReminder }
        onDeleteSelected={handleDeleteInspectionReminderSelected  }
      />

      <InspectionReminderDialog open={openDialog}  onClose={() => setOpenDialog(false) } onSubmit={handleAddOrEditInspetionReminder} inspectionReminderData={selectedInspectionReminder ?? undefined} 
        refreshData={fetchData}   />
    

    </Stack>
  );

};
  