import * as React from 'react';
import {Typography } from '@mui/material';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { TireInpspection } from './TireInspections'; 
import { formatDate } from '@/hooks/format-date';
import { CustomSelectWithAdd } from '@/components/dashboard/customSelectWithAdd/customSelectWithAdd';
import { UseFetchAccount } from '@/hooks/fetch-relationship-field';

interface TireInspectionDialogProps {   
  open: boolean;
  onClose: () => void;    
  onSubmit: (tireInspectionData: Partial<TireInpspection>) => void;   
  tireInspectionData?: Partial<TireInpspection>;
  treadDepthUnit: string;
  vehicle? : {
    id: number;
    name: string;
    vehicleNumber: string;
    primaryMeterType: string;
    primaryMeter: number;
    secondaryMeter: number;
    secondaryMeterType: string;
  }
  refreshData: () => void;
}

export const TireInspectionDialog: React.FC<TireInspectionDialogProps> = ({open, onClose, onSubmit, tireInspectionData, treadDepthUnit, vehicle, refreshData}) => {
  const isEditMode = !!tireInspectionData; // add another flag if chagne the edit mode mecha
  const [formData, setFormData] = React.useState<Partial<TireInpspection>>(tireInspectionData || {}); 
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});  

  const { assignees } = UseFetchAccount();  

  const [inspectionById, setInspectionById] = React.useState<number | null>(null);  


  const handleSubmit = async () => {  
    if (validateForm()){

      onSubmit({
        ...formData,
        inspectionById: inspectionById ?? undefined,  
      });
    }
  };

  const validateForm = (): boolean => { 
    const newErrors: { [key: string]: string } = {}; 

    //require the date
    if (!formData.date) {
      newErrors.date = 'Vui lòng chọn ngày.';
    }

    if (!formData.currentTreadDepth) {
      newErrors.currentTreadDepth = 'Vui lòng nhập độ sâu gai lốp hiện tại.';
    } 

    // if there is primaryMeter value or secondaryMeter value, check if it should be equal or greater than the one in the vehicle data
    if (vehicle?.primaryMeterType && formData.primaryMeter) {
      if (formData.primaryMeter < vehicle.primaryMeter) {
        newErrors.primaryMeter = 'Số đo chính không thể nhỏ hơn số đo chính của xe.';
      }
    }

    if (vehicle?.secondaryMeterType && formData.secondaryMeter) {
      if (formData.secondaryMeter < vehicle.secondaryMeter) {
        newErrors.secondaryMeter = 'Số đo phụ không thể nhỏ hơn số đo phụ của xe.';
      }
    } 

    setErrors(newErrors);
    // If there are errors, show them in an alert
    if (Object.keys(newErrors).length > 0) {
      const errorMessages = Object.values(newErrors)
        .map((message) => `+ ${message}`) // Add "+ " before each error message
        .join('\n'); // Combine all error messages

      alert(`Các mục bị lỗi:\n${errorMessages}`); // Show alert with errors
    }

    return Object.keys(newErrors).length === 0;;
  }

  React.useEffect(() => {
    if (open) {
      if (isEditMode && tireInspectionData) {
        setFormData({...tireInspectionData, 
          date: formatDate(tireInspectionData?.date),
        });
      
      } else {
        setFormData({
        }); 
      }
    }
  }, [open, isEditMode]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {  
    const { name, type, value, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));  
  };

  const formFields: FormField[] = [
    {
      label: "Ngày *",
      name: "date",
      type: "date",
      value: formData.date || "",
      handleChange: handleChange,
      error: errors.date,
    },
    ...(vehicle?.primaryMeterType
      ? [
          {
            label: "Số đo chính ",
            name: "primaryMeter",
            type: "number",
            value: formData.primaryMeter || "",
            handleChange: handleChange,
            error: errors.primaryMeter,
          },
        ]
      : []),
    ...(vehicle?.secondaryMeterType
      ? [
          {
            label: "Số đo phụ",
            name: "secondaryMeter",
            type: "number",
            value: formData.secondaryMeter || "",
            handleChange: handleChange,
            error: errors.secondaryMeter,
          },
        ]
      : []),
    {
      label: "Độ sâu gai lốp hiện tại ",
      name: "currentTreadDepth",
      type: "number",
      value: formData.currentTreadDepth || "",
      handleChange: handleChange,
      error: errors.currentTreadDepth,
      required: true,
    },
  ];

  return (
    <CustomDialog open={open} title={isEditMode ? "Chỉnh sửa thông tin thẻ kiểm tra" : "Thêm thẻ kiểm tra"} onClose={onClose}  onSubmit={handleSubmit} submitLabel="Lưu" dialogWidth="850px" dialogMaxWidth="95%" > 
   
      {formFields.map((field, index) => (
      <div key={index}>
        <FormFields fields={[field]} />
        {/* Display helper text for the meter fields */}
        {field.name === "primaryMeter" && vehicle?.primaryMeterType && (
          <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
            Odo chính của xe: {vehicle.primaryMeter} {vehicle.primaryMeterType}
          </Typography>
        )}
        {field.name === "secondaryMeter" && vehicle?.secondaryMeterType && (
          <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
            Odo phụ của xe: {vehicle.secondaryMeter} {vehicle.secondaryMeterType || "N/A"}
          </Typography>
        )}

        {field.name === "currentTreadDepth" && (
          <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
            Đơn vị đo: {treadDepthUnit}
          </Typography>
        )}
      </div>
    ))}

      <CustomSelectWithAdd 
        label="Người kiểm tra"
        items={assignees}
        value={inspectionById || 0}
        onChange={setInspectionById}
      />

    </CustomDialog>
  )
};