import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';  
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { GenericFilters } from '@/components/dashboard/filter/genericFilter';
import { TableColumn } from '@/components/dashboard/table/columnType1'; 
import axios from 'axios';
import { formatDate } from '@/hooks/format-date';
import dayjs, { Dayjs } from 'dayjs';

export interface InventoryTransactionProps { 
  inventoryLocationId: string;
}

export enum InventoryTransactionsType {
  ServiceEntry = "ServiceEntry",
  ManualAdjustment = "ManualAdjustment",
  PurchaseOrderReception = "PurchaseOrderReception",
}

export interface InventoryTransaction {
  id: string;
  transactionType: InventoryTransactionsType;
  quantity: number;
  createdAt: string;
  updateAt: string;
  purchaseOrderNumber: string | null;
  serviceEntryNumber: string | null;
}


function applyPagination(rows: InventoryTransaction[], page: number, rowsPerPage: number): InventoryTransaction[] {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export default function InventoryTransactionComponent({inventoryLocationId}: InventoryTransactionProps ): React.JSX.Element {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [data, setData] = React.useState<InventoryTransaction[]>([]);
  const [filteredData, setFilteredData] = React.useState<InventoryTransaction[]>([]);  
  const [openDialog, setOpenDialog] = React.useState(false);  
  const [selectedInventoryTransaction, setSelectedInventoryTransaction] = React.useState<InventoryTransaction | null>(null); 
  
  const fetchData = async () => {
    try {
      const response = await axios.get(`/api/invnetory-transactions/${inventoryLocationId}`);
      const sortedData = response.data.sort((a: InventoryTransaction, b: InventoryTransaction) => 
        dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf()
      );
            setFilteredData(sortedData);  
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => { 
    setRowsPerPage(newRowsPerPage); 
    setPage(0);
  };

  const handleOpenDialog = () => {
    setSelectedInventoryTransaction(null); // Reset selected inspection when adding a new one
    setOpenDialog(true);
  }

  const handleEditInventoryTransaction = (id: string) => {
    const inventoryTransactionToEdit = data.find((inventoryTransaction) => inventoryTransaction.id === id); 
    if (inventoryTransactionToEdit) {
      setSelectedInventoryTransaction(inventoryTransactionToEdit);
      setOpenDialog(true);
    } 
  };

  const handleDeleteInventoryTransaction = async (id: string) => {  
    const confirmed = window.confirm('Bạn có chắc muốn xóa thẻ này?');
    if (confirmed) {
      try {
        await axios.delete(`/api/inventory-transactions/${id}`);
        setData(data.filter((inventoryTransaction) => inventoryTransaction.id !== id));
        setFilteredData(filteredData.filter((inventoryTransaction) => inventoryTransaction.id !== id));
      } catch (error) {
        console.error(error);
      }
    }
  }

  const handleDeleteInventoryTransactionSelected = async (selectedIds: string[]) => {
    const confirmed = window.confirm('Bạn có chắc muốn xóa các thẻ này?');
    if (confirmed) {
      try {
        await axios.delete(`/api/inventory-transactions/${JSON.stringify(selectedIds)}`);
        setData(data.filter((inventoryTransaction) => !selectedIds.includes(inventoryTransaction.id)));
        setFilteredData(filteredData.filter((inventoryTransaction) => !selectedIds.includes(inventoryTransaction.id)));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleAddOrEditInventoryTransaction = (inventoryTransactionData: Partial<InventoryTransaction>) => {  
    try {
      if (selectedInventoryTransaction) {
        setData((prevData) => prevData.map((inventoryTransaction) => (inventoryTransaction.id === inventoryTransactionData.id ? inventoryTransactionData as InventoryTransaction : inventoryTransaction)));
        setFilteredData((prevData) => prevData.map((inventoryTransaction) => (inventoryTransaction.id === inventoryTransactionData.id ? inventoryTransactionData as InventoryTransaction : inventoryTransaction))); 
      } else {
        setData((prevData) => [...prevData, inventoryTransactionData as InventoryTransaction]);
        setFilteredData((prevData) => [...prevData, inventoryTransactionData as InventoryTransaction]);
      }
      fetchData();
      setOpenDialog(false);
    } catch (error) {
      alert('Có lỗi xảy ra, vui lòng thử lại sau!');  
      console.error("Error adding or editing inventory transaction:", error);
    }
  };

  const transactionTypeMap: Record<InventoryTransactionsType, string> = {
    ServiceEntry: "Thẻ nhiệm vụ",
    ManualAdjustment: "Chỉnh tay",
    PurchaseOrderReception: "Từ đơn mua",
  };
  
  const columns: TableColumn<InventoryTransaction>[] = [
    { label: "Loại", field: (row) => transactionTypeMap[row.transactionType as InventoryTransactionsType] || "Không xác định" },
    { label: "Số lượng", field: (row) => row.quantity },
    // mã số nhiệm vụ
    // mã số đơn hàng
    { label: "Mã số nhiệm vụ", field: (row) => row.serviceEntryNumber || "" },
    { label: "Mã số đơn hàng", field: (row) => row.purchaseOrderNumber || "" },
    { label: "Ngày tạo", field: (row) => formatDate(row.createdAt) },
    { label: "Ngày cập nhật", field: (row) => formatDate(row.updateAt) }, // Changed from `updateAt`
  ];
  

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
            <Typography variant="h5">Lịch sử số lượng</Typography> 
        </Stack>
        <div>
        
        </div>
      </Stack>

      <GenericTable 
        count={filteredData.length}
        page={page}
        rows={applyPagination(filteredData, page, rowsPerPage)} // Use transformed data
        columns={columns}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Stack>
  )
}