import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './pages/login/Login';
import Dashboard from './pages/dashboard/Dashboard';
import UsersPage from '@/components/user/User';
import SuppliersPage from '@/components/supplier/Supplier';
import CustomersPage from '@/components/customer/Customer';
import VehiclesPage from '@/components/vehicle/Vehicle';
import VehicleAssignmentPage from '@/components/vehicle/vehicleAssign';
import MaintainanceWorkOrdersPage from '@/components/maintainance/MaintainanceWorkOrder';
import MaintainanceServiceEntrysPage from '@/components/maintainance/MaintainanceServiceEntries';
import MaintainanceSetupPage from '@/components/maintainance/MaintainanceSetup';
import InspectionsPage from '@/components/inspection/Inspections';
import IssuesPage from '@/components/inspection/Issues';  
import InspectionsIssuesSetupPage from '@/components/inspection/InspectionIssuesSetup';
import ReminderPage from '@/components/reminder/ReminderPage';
import RenewalPage from './components/reminder/RenewalPage';
import  InspectionReminderPage from '@/components/reminder/InspectionReminderPage';
import FuelEntryPage from '@/components/fuel/FuelEntryPage';
import FuelTankPage from '@/components/fuel/FuelTank';
import TiresPage from '@/components/tire/TiresPage';  
import PartsPage from '@/components/inventory/PartsPage';
import InventoryPage from '@/components/inventory/InventoryPage';
import PurchaseOrderPage from '@/components/inventory/PurchaseOrderPage';
import InventorySetupPage from '@/components/inventory/InventorySetup';

import ProtectedRoute from '@/components/login/ProtectedRoute';

import 'bootstrap-icons/font/bootstrap-icons.css';
import 'remixicon/fonts/remixicon.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { viVN } from '@mui/material/locale';
import { vi } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


const theme = createTheme({}, viVN);


const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={vi}
      >
      <Routes>
        <Route path="/" element={<Login />} />


        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        >
          {/* Nested Protected Routes */}
          <Route path="employees" element={<UsersPage />} />
          <Route path="suppliers" element={<SuppliersPage />} />
          <Route path="customers" element={<CustomersPage />} />
          <Route path="vehicles" element={<VehiclesPage />} />
          <Route path="vehicles-schedules" element={<VehicleAssignmentPage />} />
          <Route path="maintainance-work-orders" element={<MaintainanceWorkOrdersPage />} />
          <Route path="maintainance-service-entries" element={<MaintainanceServiceEntrysPage />} />
          <Route path="maintenance-management" element={<MaintainanceSetupPage />} />
          <Route path="inspections" element={<InspectionsPage />} />
          <Route path="issues" element={<IssuesPage />} /> 
          <Route path="repair-reminders" element={<ReminderPage />}/>
          <Route path="renewal-reminders" element={<RenewalPage />}/>  
          <Route path="inspection-reminders" element={<InspectionReminderPage />}/>
          <Route path="inspections-issues-management" element={<InspectionsIssuesSetupPage />} />
          <Route path="fuel-bills" element={<FuelEntryPage />} /> 
          <Route path="fuel-tank-management" element={<FuelTankPage />} />  
          <Route path="tires" element={<TiresPage />} /> 
          <Route path="part" element={<PartsPage />} /> 
          <Route path="inventory" element={<InventoryPage />} />
          <Route path='purchase-order' element={<PurchaseOrderPage />} /> 

          <Route path='inventory-management' element={<InventorySetupPage />} />

        </Route> 
      </Routes>

      </LocalizationProvider>
    </ThemeProvider>  );
};

export default App;
