import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { CustomSelectWithAdd , CustomSelectWithAddProps } from '@/components/dashboard/customSelectWithAdd/customSelectWithAdd';
import { ServiceEntriesComponent } from '@/components/subPlace/ServiceEntriesComponents'; // Import the component


const FormThings = ({
    formFields,
    status,
    statusId,
    setStatusId,
    vehicles,
    vehicleId,
    inChargeId,
    setInChargeId,
    setVehicleId,
    technicians,
    suppliers,
    inChargeType,
  }: {
    formFields: FormField[];
    status: any[];
    statusId: number | null;
    setStatusId: (id: number) => void;
    vehicles: any[];   
    vehicleId: number | null;
    setVehicleId: (id: number) => void; 
    technicians: any[];
    suppliers: any[];
    inChargeId: number | null;
    setInChargeId: (id: number) => void;    
    inChargeType: string | undefined; // Add inChargeType prop to determine type
   
  }) => {
    return (
      <form>
        {formFields.map((field, index) => (
          <div key={index}>
            {/* Render form fields */}
            <FormFields fields={[field]} />

            {index === 0 && (
              <CustomSelectWithAdd
                label='Tình trạng'
                items={status}
                value={statusId || 0}
                onChange={setStatusId}
                manageItem='/dashboard/maintenance-management'
                required={true}
              />
            )}

            {index === 5 && (
              <CustomSelectWithAdd
                label="Phương tiện"
                items={vehicles}
                value={vehicleId || 0}
                onChange={setVehicleId} 
                manageItem='/dashboard/vehicles'
                required={true}
                />
            )}

            {index === 6 && (
              <CustomSelectWithAdd
                label="Người phụ trách"
                items={inChargeType === 'TECHNICIAN' ? technicians : suppliers} // Dynamically switch items
                value={inChargeId || 0}
                onChange={setInChargeId}
                manageItem={inChargeType === 'TECHNICIAN' ? '/dashboard/employees' : '/dashboard/suppliers'} // Dynamically change manage link
                required={true}
              />
            )}     
          </div>
        ))}
        
      </form>
    );
};
  
  export default FormThings;