import * as React from 'react';
import { Typography, Tabs, Tab, Box } from '@mui/material';
import { TabPanel } from '@/components/dashboard/tabPanel/tabPanel'; // Import the tab panel component
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { InventoryLocation } from './InventoryPage'; 
import axios from 'axios';
import { FieldManagementDialog, DialogField } from '@/components/dashboard/fieldManagement/FieldManagement';
import  useCustomFieldsUpdate from '@/hooks/field-managementSubmit'; // Import the hook
import { UseFetchPart, UseFetchWarehouse, UseFetchAisle, UseFetchRow, UseFetchBin } from '@/hooks/fetch-relationship-field'; 
import { formatDate } from '@/hooks/format-date';
import { CustomSelectWithAdd } from '@/components/dashboard/customSelectWithAdd/customSelectWithAdd';
import InventoryTransactionComponent from './InventoryTransaction';

interface InventoryLocationDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (fuelEntryData: Partial<InventoryLocation>) => void;
  inventoryLocationData?: Partial<InventoryLocation>;
  refreshData: () => void;  
}

export const InventoryDialog: React.FC<InventoryLocationDialogProps> = ({ open, onClose, onSubmit, inventoryLocationData, refreshData }) => {
  let isEditMode = !!inventoryLocationData;
  const [fields, setFields] = React.useState<DialogField[]>([]);  
  const [formData, setFormData] = React.useState<Partial<InventoryLocation>>(inventoryLocationData ||{});
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [codeNumberLoading, setCodeNumberLoading] = React.useState(false);  

  const [isFieldManagementOpen, setFieldManagementOpen] = React.useState(false);
  const [customFieldsData, setCustomFieldsData] = React.useState<Record<string, any>>({});
  
  const { parts } = UseFetchPart();
  const { warehouses, addWarehouse, editWarehouse, deleteWarehouse } = UseFetchWarehouse();
  const { aisles, addAisle, editAisle, deleteAisle } = UseFetchAisle(); 
  const { rows, addRow, editRow, deleteRow } = UseFetchRow();
  const { bins, addBin, editBin, deleteBin } = UseFetchBin();

  const [partId, setPartId] = React.useState<number | null>(null);  
  const [warehouseId, setWarehouseId] = React.useState<number | null>(null);
  const [aisleId, setAisleId] = React.useState<number | null>(null);
  const [rowId, setRowId] = React.useState<number | null>(null);
  const [binId, setBinId] = React.useState<number | null>(null);

  const fetchNextCodeNumber = async () => {
    try {
      setCodeNumberLoading(true);
      const response = await axios.get('/api/inventory-locations/next-code-number');
      const nextCodeNumber = response.data.nextCodeNumber;  
      setFormData({ ...formData, codeNumber: nextCodeNumber }); 
    } catch (error) {
      console.error('Failed to fetch next vehicle number:', error);
    } finally {
      setCodeNumberLoading(false);
    }
  }

  React.useEffect(() => {
    fetchFields();
  }, []);

  const fetchFields = async () => {
    try {
      const response = await axios.get('/api/inventory-locations/dialog-fields');  
      setFields(response.data);
    } catch (error) {
      console.error('Failed to fetch fields:', error);
    } 
  }

  
  const addFieldToDatabase = async (field: DialogField): Promise<DialogField> => {  
    try {
      const response = await axios.post('/api/inventory-locations/dialog-fields', field);
      return response.data;
    } catch (error) {
      console.error('Failed to add dialog field:', error);
      return field;
    }
  }

  const updateFieldInDatabase = async (field: DialogField): Promise<DialogField> => {
    try {
      const response = await axios.put(`/api/inventory-locations/dialog-fields/${field.id}`, field);
      return response.data;
    } catch (error) {
      console.error('Error updating field:', error);
      throw error;
    }
  };

  const deleteFieldFromDatabase = async (id: number): Promise<void> => {
    try {
      await axios.delete(`/api/inventory-locations/dialog-fields/${id}`);
    } catch (error) {
      console.error('Error deleting field:', error);
      throw error;
    }
  };

  const { handleCustomFieldsUpdate } = useCustomFieldsUpdate(); // Use the custom

  const handleFieldManagementSubmit = (updatedFields: DialogField[]) => {
    handleCustomFieldsUpdate(
      fields,                         
      formData.customFields || {},     
      inventoryLocationData?.customFields,      
      updatedFields,                   
      setCustomFieldsData,             
      setFormData,                     
      setFields,                       
      setFieldManagementOpen           
    );

    refreshData();  // <-- Trigger refresh after field management is done
  };
  
  const handleSubmit = async () => {
    if (validateForm()) {

      // require fuel entry number / vehicleId / date   
      const customFields: Record<string, any> = {}; 

      fields.forEach((field) => {
        if (field.canDelete) {
          // Merge custom fields with form data
          customFields[field.name] = formData[field.name as keyof typeof formData] || customFieldsData[field.name];
        }
      }); 

      onSubmit({ ...formData, customFields, partId: partId ?? undefined, warehouseId: warehouseId ?? undefined, aisleId: aisleId ?? undefined, rowId : rowId ?? undefined, binId: binId ?? undefined });

      // clear the data
      setFormData({});
      setCustomFieldsData({});
      setPartId(null);
      setWarehouseId(null); 
      setAisleId(null);
      setRowId(null);
      setBinId(null);
      
    }
  };

  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};

    fields.forEach(field => {
      const fieldValue = formData[field.name as keyof InventoryLocation];

      if (field.required && (!fieldValue || typeof fieldValue === 'string' && !fieldValue.trim())) {
        newErrors[field.name] = `${field.label} cần có giá trị`;
      }
    });

    // require warehouseId
    if (!warehouseId) {
      newErrors['warehouseId'] = 'Kho cần được chọn';
    }
          
    setErrors(newErrors);
    // If there are errors, show them in an alert
    if (Object.keys(newErrors).length > 0) {
      const errorMessages = Object.values(newErrors)
        .map((message) => `+ ${message}`) // Add "+ " before each error message
        .join('\n'); // Combine all error messages

      alert(`Các mục bị lỗi:\n${errorMessages}`); // Show alert with errors
    }

    return Object.keys(newErrors).length === 0;
  } 

  React.useEffect(() => {
    if (open) {
      if (isEditMode && inventoryLocationData) {
        setFormData({

          ...inventoryLocationData,
          quantityLastVerify: formatDate(inventoryLocationData.quantityLastVerify),
        });

        if (inventoryLocationData.customFields) {
          try {
            const parsedCustomFields = typeof inventoryLocationData.customFields === 'string' ? JSON.parse(inventoryLocationData.customFields) : inventoryLocationData.customFields;
            setCustomFieldsData(parsedCustomFields); 
          } catch (error) {
            console.log('Failed to parse custom fields:', error); 
          }
        }

        setPartId(inventoryLocationData.partId || null);
        setWarehouseId(inventoryLocationData.warehouseId || null);
        setAisleId(inventoryLocationData.aisleId || null);
        setRowId(inventoryLocationData.rowId || null);  
        setBinId(inventoryLocationData.binId || null);
      } else {
        setFormData({});
        setCustomFieldsData({});  
        setPartId(null);
        setWarehouseId(null);
        setAisleId(null);
        setRowId(null);
        setBinId(null);

        if (!isEditMode) {
          fetchNextCodeNumber();
        }
      }
    }
  }, [open, isEditMode]);

  const handleCustomFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Check if the input is a checkbox or a regular text input
    setCustomFieldsData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,  // If checkbox, store as boolean; else, store the value
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {  
    const { name, type, value, checked } = e.target;
    // Update the state
    const updatedFormData = {
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    };

    setFormData(updatedFormData);
  };

  const formFields: FormField[] = fields.map(field => {
      if (field.name === "codeNumber") {
        return {
          label: 'Mã số thẻ trong kho',
          name: 'codeNumber', 
          type: 'text', 
          value: formData.codeNumber || '',
          rqeuired: true,
          errors: errors['codeNumber'],
        };
      } else if ((field.name === 'reOrderPoint' || field.name === 'reOrderQuantity' || field.name === 'leadTimeDays') && !formData.reminderOnLowStockFlg) {
        return null;
      } else {
        return {
          label: field.label,
          name: field.name,
          type: field.type,
          value: formData[field.name as keyof typeof formData] || '',
          required: field.required,
          handleChange: field.canDelete ? handleCustomFieldChange : handleChange,
          errors: errors[field.name],
        };

      }

  }).filter(Boolean) as FormField[]; // Filter out null values and cast to FormField[]
  
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue); 
  };

  return (
      <CustomDialog open={open} title="Thêm/Chỉnh sửa thông tin linh kiện trong kho" onClose={onClose} manageFields={() => setFieldManagementOpen(true)}
       onSubmit={handleSubmit} submitLabel="Lưu" dialogWidth='1200px' dialogMaxWidth='90%'
      >

        <div className="row">
          <div className={ isEditMode ? "col-md-6" : "col-md-12"}>  
            {formFields.map((field, index) => (
              <div key={index}>
                <FormFields fields={[field]} />

                { index === 0 && (
                  <CustomSelectWithAdd
                    label='Linh kiện'
                    items={parts}
                    value={partId || 0}
                    onChange={setPartId}
                    manageItem="/dashboard/part"
                  />
                )}
                { index === 0 && (  
                  <FormFields fields={[{ 
                    label: 'Số Lượng hàng', 
                    name: 'availableQuantity', 
                    type: 'number', 
                    value: formData.availableQuantity || '',
                    handleChange: handleChange, 
                    error: errors['availableQuantity'] }]}   
                  />
                )}

                { index === 1 && (
                  <CustomSelectWithAdd
                    label='Kho *'
                    items={warehouses}
                    value={warehouseId || 0}
                    onChange={setWarehouseId}
                    addItem={addWarehouse}
                    editItem={editWarehouse}  
                    deleteItem={deleteWarehouse}  
                  />
                )}

                { index === 1 && (
                  <CustomSelectWithAdd
                    label='Lối đi'
                    items={aisles}
                    value={aisleId || 0}
                    onChange={setAisleId}
                    addItem={addAisle}
                    editItem={editAisle}
                    deleteItem={deleteAisle}
                  />
                )}

                { index === 1 && (
                  <CustomSelectWithAdd
                    label='Hàng'
                    items={rows}
                    value={rowId || 0}
                    onChange={setRowId}
                    addItem={addRow}
                    editItem={editRow}  
                    deleteItem={deleteRow}
                  />
                )}

                { index === 1 && (
                  <CustomSelectWithAdd
                    label='Kệ'
                    items={bins}
                    value={binId || 0}
                    onChange={setBinId}
                    addItem={addBin}
                    editItem={editBin}
                    deleteItem={deleteBin}
                  />
                )}
                  
              </div>
            ))} 
          </div>

          { isEditMode && formData.id && (
            <div className="col-md-6">
              <Typography variant="h5" gutterBottom>Các dữ liệu liên quan</Typography>

              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={activeTab} onChange={handleTabChange} aria-label="Inventory related data" variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile  // MUI v5+ only
                  >
                  <Tab label="Lịch sử số lượng" />
                </Tabs>
              </Box>

              <TabPanel value={activeTab} index={0}>  
                { formData.id ? (
                  <InventoryTransactionComponent inventoryLocationId={formData.id} />  
                ) : (
                  <Typography>Loading...</Typography> // Prevents rendering with an incorrect ID
                )}
              </TabPanel>
            </div>
          )}
        </div>

  
        <FieldManagementDialog
          open={isFieldManagementOpen}
          onClose={() => setFieldManagementOpen(false)}
          fields={fields}
          onFieldSubmit={handleFieldManagementSubmit}
          addFieldToDatabase={addFieldToDatabase}
          updateFieldInDatabase={updateFieldInDatabase}
          deleteFieldFromDatabase={deleteFieldFromDatabase}
        />
      </CustomDialog>
  );
};
