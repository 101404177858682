import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { Download as DownloadIcon } from '@phosphor-icons/react';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { Upload as UploadIcon } from '@phosphor-icons/react';
import { GenericFilters } from '@/components/dashboard/filter/genericFilter';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1'; 
import axios from 'axios';
import { ReminderDialog } from './ReminderDialog';
import { timeIntervalUnit, statusOrder, reverseStatusOrder } from '@/hooks/mapping';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SpeedIcon from '@mui/icons-material/Speed';
import { formatDate } from '@/hooks/format-date';
import { statusMap } from '@/hooks/mapping';
import dayjs, { Dayjs } from 'dayjs';
import { getContrastTextColor } from '@/hooks/contrast-text-color';

interface Vehicle {
    vehicleNumber: string;
    name: string;
    primaryMeterType: string;
    primaryMeter: number;
    secondaryMeterType: string; 
    secondaryMeter: number; 
}

interface ServiceTask {
  id: string;
  name: string;
  description: string;  
}

interface serviceEntryLatest {
  completedDate: Date | string;
  primaryMeter: number;
}

export interface ServiceReminder {
    id: string;
    vehicleId: number;
    vehicle: Vehicle;
    serviceTaskId: number;
    serviceTask: ServiceTask;   
    primaryMeterInterval: number;   
    timeInterval: number;
    timeIntervalUnit: string;
    primaryMeterThreshold: number;  
    timeIntervalThreshold: number;  
    timeIntervalThresholdUnit: string;
    manualNextDueDate_flg: boolean;
    nextDuePrimaryMeter: number;    
    nextDueDate: Date | string;  
    createdDate: Date;
    updateAt: Date;    
    customFields: Record<string, string>;
    calculatedNextDueDate: Date | string; 
    calculatedNextDuePrimaryMeter: number;  
    status: string
    latestServiceEntry: serviceEntryLatest;
  }

function applyPagination(rows: ServiceReminder[], page: number, rowsPerPage: number): ServiceReminder[] {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}
  

export default function ReminderPage(): React.JSX.Element { 
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5); // Adjust number of rows per page as necessary
    const [data, setData] = React.useState<ServiceReminder[]>([]); // MaintainanceServiceEntry data state
    const [filteredData, setFilteredData] = React.useState<ServiceReminder[]>([]); // Filtered data state
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedServiceReminder, setSelectedServiceReminder] = React.useState<ServiceReminder | null>(null);

    const fetchData = async () => {
      try {
        const response = await axios.get('/api/repair-reminders');
        const sortedData = response.data.sort((a: ServiceReminder, b: ServiceReminder) => a.id.toString().localeCompare(b.id.toString()));
        setData(sortedData);
        setFilteredData(sortedData);
      } catch (error) {
        console.log('Error fetching data: ', error);
      }
    };

    React.useEffect(() => {
      fetchData();
    }, []);

    // update later in here
    const handleFilterChange = (column: string, searchTerm: string | boolean | Dayjs | null) => {
      if (column === 'statusOrder') {
        const sorted = [...filteredData].sort((a, b) => {
          const orderMap = searchTerm === 'asc' ? statusOrder : reverseStatusOrder;
          const statusA = a.status as keyof typeof orderMap; // Type assertion
          const statusB = b.status as keyof typeof orderMap; // Type assertion
    
          // Compare status order first
          const statusComparison = orderMap[statusA] - orderMap[statusB];
          if (statusComparison !== 0) {
            return statusComparison;
          }
    
          // If statuses are equal, compare primaryMeter relative to primaryMeterThreshold
          const thresholdA = a.primaryMeterThreshold ?? 0;
          const thresholdB = b.primaryMeterThreshold ?? 0;
    
          const distanceA = Math.abs((a.vehicle.primaryMeter ?? 0) - thresholdA);
          const distanceB = Math.abs((b.vehicle.primaryMeter ?? 0) - thresholdB);
    
          return searchTerm === 'asc' ? distanceA - distanceB : distanceB - distanceA;
        });
    
        setFilteredData(sorted);
        return;
      }
    
      const filtered = data.filter((reminder) => {
        let value;
    
        switch (column) {
          case 'vehicleNumber':
            value = reminder.vehicle.vehicleNumber;
            break;
          case 'serviceTask':
            value = reminder.serviceTask.name;
            break;
          case 'status':
            value = reminder.status;
            break;
        }
    
        if (searchTerm === null || searchTerm === '') {
          return true;
        }
    
        if (typeof value === 'string' && typeof searchTerm === 'string') {
          return value.toLowerCase().includes(searchTerm.toLowerCase());
        }
    
        if (typeof value === 'boolean' && typeof searchTerm === 'boolean') {
          return value === searchTerm;
        }
    
        return false;
      });
      setFilteredData(filtered);
    };
    
    const handlePageChange = (newPage: number) => {
      setPage(newPage);
    };
    
    const handleRowsPerPageChange = (newRowsPerPage: number) => {
      setRowsPerPage(newRowsPerPage);
      setPage(0);
    };
    
    const handleOpenDialog = () => {
      setSelectedServiceReminder(null); // Reset selected maintainanceServiceEntry when adding a new one
      setOpenDialog(true);
    };

    const handleEditServiceReminder = (id: string) => {
      const serviceReminderToEdit = data.find((serviceReminder) => serviceReminder.id === id);
      if (serviceReminderToEdit) {
        setSelectedServiceReminder(serviceReminderToEdit);
        setOpenDialog(true);
      } 
    }

    const handleDeleteServiceReminder = async (id: string) => {
      const confirmed = window.confirm('Bạn có chắc là xóa thẻ này?');  
      if (confirmed) {
        try {
          await axios.delete(`/api/repair-reminders/${id}`);
          alert('Xóa thành công');
          setData((prevData) => prevData.filter((serviceReminder) => serviceReminder.id !== id));
          setFilteredData((prevData) => prevData.filter((serviceReminder) => serviceReminder.id !== id));
        } catch (error) {
          console.log('Error deleting service reminders:', error);
        }
      }
    };

    const handleDeleteServiceReminderSelected = async (selectedIds: string[]) => {
      const confirmed = window.confirm('Bạn có chắc muốn xóa những thẻ đã chọn?'); 
      if (confirmed) {
        try {
          await axios.delete(`/api/repair-reminders/${JSON.stringify(selectedIds)}`);
          alert('Xóa thành công');
          setData((prevData) => prevData.filter((serviceReminder) => !selectedIds.includes(serviceReminder.id)));
          setFilteredData((prevData) => prevData.filter((serviceReminder) => !selectedIds.includes(serviceReminder.id)));
        } catch (error) {
          console.log('Error deleting service reminders:', error);
        }
      }
    };

    const handleAddOrEditServiceReminder = async (serviceReminderData: Partial<ServiceReminder>) => {       
      try {
        const formData = new FormData();  

        Object.keys(serviceReminderData).forEach((key) => {
          const value = serviceReminderData[key as keyof ServiceReminder];
          if (value !== undefined && value !== null) { // Check for undefined or null explicitly
            formData.append(key, value.toString());
          }
        });
        
        if (serviceReminderData.customFields) {
          formData.set('customFields', JSON.stringify(serviceReminderData.customFields)); 
        }

        if (selectedServiceReminder ) {
          const response = await axios.put(`/api/repair-reminders/${selectedServiceReminder.id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
            },
          });

          const updateServiceReminder = response.data;  

          setData((prevData) => 
            prevData.map((serviceReminder) => (serviceReminder.id === updateServiceReminder.id ? updateServiceReminder : serviceReminder))
          )
          setFilteredData((prevData) => 
            prevData.map((serviceReminder) => (serviceReminder.id === updateServiceReminder.id ? updateServiceReminder : serviceReminder))
          )
        } else {
          const response = await axios.post('/api/service-reminders', formData, { 

            headers: {
              'Content-Type': 'multipart/form-data', 
            },
          });
          const newServiceReminder = response.data;
          setData((prevData) => [newServiceReminder, ...prevData]);
          setFilteredData((prevData) => [newServiceReminder, ...prevData]); 
        }
        fetchData();
        setOpenDialog(false);
      } catch (error) {
        alert('Lỗi thêm cập nhập phiếu nhắc nhở. Hãy kiểm tra các ô lại');  
        console.error('Lỗi thêm/cập nhập phiếu nhắc nhở');
      }
    };

    const filterColumns = [
      { value: 'vehicleNumber', label: "Mã số xe" },
      { value: 'serviceTask', label: "Tên công việc" },
      { 
        value: 'status', 
        label: "Trạng thái đến hạn", 
        options: [
          { label: "Chưa đến hạn", value: "NotDue" },
          { label: "Sắp đến hạn", value: "DueSoon" },
          { label: "Đã đến hạn", value: "Due" },  
          { label: "Quá hạn", value: "Overdue" }
        ],
        type: 'dropdown' as const,
      },
      {
        value: 'statusOrder',
        label: "Sắp xếp trạng thái",
        options: [
          { label: "Chưa đến hạn -> Quá hạn", value: "asc" },
          { label: "Quá hạn -> Chưa đến hạn", value: "desc" },
        ],
        type: 'dropdown' as const,
      },
    ];


    type StatusKey = keyof typeof statusMap; // Type for keys of statusMap
    
    const transformedReminder = filteredData.map((reminder) => {
      return {
        ...reminder,
        vehicleNumber: reminder.vehicle?.vehicleNumber || 'N/A',
        serviceTaskName: reminder.serviceTask?.name || 'N/A',
      };
    });
    
    const columns: TableColumn<ServiceReminder>[] = [
      { label: 'Mã số xe', field: (row) => row.vehicle?.vehicleNumber || 'Chưa có' },
      { label: 'Tên công việc', field: (row) => row.serviceTask?.name || 'Chưa có' },
      {
        label: 'Nhắc vào mỗi (theo số ODO chính)',
        field: (row) =>
          row.primaryMeterInterval && row.vehicle?.primaryMeterType
            ? `${row.primaryMeterInterval} ${row.vehicle.primaryMeterType}`
            : 'Chưa có',
      },
      {
        label: 'Nhắc vào mỗi (theo thời gian)',
        field: (row) =>
          row.timeInterval && row.timeIntervalUnit
            ? `${row.timeInterval} ${timeIntervalUnit(row.timeIntervalUnit)}`
            : 'Chưa có',
      },
      {
        label: 'Số ODO chính hiện tại',
        field: (row) =>
          row.vehicle.primaryMeter && row.vehicle?.primaryMeterType
            ? `${row.vehicle.primaryMeter} ${row.vehicle.primaryMeterType}`
            : 'Chưa có',
      },
      {
        label: 'Trạng thái',
        field: (row) => {
          const status = (row.status as StatusKey) || 'NotDue'; // Explicitly cast 'status' to StatusKey
          const statusInfo = statusMap[status];
          const textColor = getContrastTextColor(statusInfo.color); // Get text color based on background color

          return (
            <Chip
              label={statusInfo.label}
              style={{
                backgroundColor: statusInfo.color,
                color: textColor,
                fontWeight: 'bold',
                borderRadius: '5px',
                padding: '5px 10px',
              }}
            />
          );
        },
      },
      {
        label: 'Lịch sử dịch vụ gần nhất',
        field: (row) => {
          const { latestServiceEntry } = row;
          if (latestServiceEntry) {
            const completedDate = new Date(latestServiceEntry.completedDate).toLocaleDateString('vi-VN'); // Format the date
            const primaryMeter = latestServiceEntry.primaryMeter
              ? `${latestServiceEntry.primaryMeter} ${row.vehicle.primaryMeterType}`
              : 'N/A';
              return (
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CalendarMonthIcon fontSize="small" style={{ marginRight: '5px' }} />
                    <span>Ngày hoàn thành: {completedDate}</span>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SpeedIcon fontSize="small" style={{ marginRight: '5px' }} />
                    <span>Số ODO: {primaryMeter}</span>
                  </div>
                </div>
              );
          }
          return 'Không có lịch sử gần đây';
        },
      },
      {
        label: 'Ngày đến hạn sắp tới',
        field: (row) => {
          if (row.calculatedNextDueDate || row.calculatedNextDuePrimaryMeter) {
            const dueDate = row.calculatedNextDueDate
              ? formatDate(row.calculatedNextDueDate)
              : 'N/A';
            const duePrimaryMeter = row.calculatedNextDuePrimaryMeter
              ? `${row.calculatedNextDuePrimaryMeter} ${row.vehicle.primaryMeterType}`
              : 'N/A';
            return (
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CalendarMonthIcon fontSize="small" style={{ marginRight: '5px' }} />
                  <span>{dueDate}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <SpeedIcon fontSize="small" style={{ marginRight: '5px' }} />
                  <span>{duePrimaryMeter}</span>
                </div>
              </div>
            );
          }
          return 'Chưa có';
        },
      },
      {
        label: 'Ngày/Khoảng đo đến hạn thủ công',
        field: (row) => {
          const hasNextDueDate = row.manualNextDueDate_flg && row.nextDueDate;
          const hasNextDuePrimaryMeter = row.manualNextDueDate_flg && row.nextDuePrimaryMeter;
      
          const manualDueDate = hasNextDueDate ? formatDate(row.nextDueDate) : null;
          const manualDuePrimaryMeter = hasNextDuePrimaryMeter
            ? `${row.nextDuePrimaryMeter} ${row.vehicle?.primaryMeterType || ''}`
            : null;
      
          if (!manualDueDate && !manualDuePrimaryMeter) {
            return 'Không có dữ liệu';
          }
      
          return (
            <div>
              {manualDueDate && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CalendarMonthIcon fontSize="small" style={{ marginRight: '5px' }} />
                  <span>{manualDueDate}</span>
                </div>
              )}
              {manualDuePrimaryMeter && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <SpeedIcon fontSize="small" style={{ marginRight: '5px' }} />
                  <span>{manualDuePrimaryMeter}</span>
                </div>
              )}
            </div>
          );
        },
      },
      

    ];
    
    return (
      <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h4">Nhắc nhở sửa chữa</Typography>
        </Stack>
        <div>
          <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={handleOpenDialog}>
            Thêm
          </Button>
        </div>
      </Stack>
      <GenericFilters 
      columns={filterColumns} onFilterChange={handleFilterChange} placeholder="Tìm thẻ nhắc nhở"
      />
      <GenericTable
        count={filteredData.length}
        page={page}
        rows={applyPagination(transformedReminder, page, rowsPerPage)} // Use transformed data
        columns={columns}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onEdit={handleEditServiceReminder}
        onDelete={handleDeleteServiceReminder }
        onDeleteSelected={handleDeleteServiceReminderSelected}
      />
      {/* dialog */}
      <ReminderDialog open={openDialog} onClose={() => setOpenDialog(false) } onSubmit={handleAddOrEditServiceReminder} serviceReminderData={selectedServiceReminder ?? undefined} 
        refreshData={fetchData} 
      />
      </Stack>
    );
};
