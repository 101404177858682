import axios from 'axios';
import { useEffect } from 'react';  


// handle login
export const HandleLogin = async (email: string, password: string) => {
  try {
    const response = await axios.post('/api/login', { email, password });
    const { token } = response.data;

    localStorage.setItem('token', token);

    return { success: true, token };
  } catch (error) {
    console.error('Login error:', error);
    return { success: false, message: 'Invalid email or password' }; // default message
  }
};

// handle signout 
export const HandleSignout = () => {
  localStorage.removeItem('token');

  // If no token is found, redirect to login using `window.location.href`
  useEffect(() => {
      // Trigger a full reload to apply styles properly on redirect
      window.location.href = '/';
  });
};

