import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Typography, Button } from '@mui/material';
import dayjs from 'dayjs';
import { TableColumn } from './columnType1';
import { useSelection } from '@/hooks/use-selection';

interface GenericTableProps<T> {
  count: number;
  page: number;
  rows: T[];
  columns: TableColumn<T>[];
  rowsPerPage?: number;
  onPageChange?: (newPage: number) => void;
  onRowsPerPageChange?: (newRowsPerPage: number) => void;
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  onDeleteSelected?: (ids: string[]) => void;
  onCustomAction?: (id: string) => void; // New optional prop for custom button
  customButtonLabel?: string; // Optional custom button label
  CustomIcon?: React.ReactNode;
  width?: number;
  height?: number;
  aspectRatio?: string;
  maxWidth?: string;
}

export function GenericTable<T extends { id: string }>({
  count,
  rows,
  columns,
  page,
  rowsPerPage = 5,
  onPageChange = () => {},
  onRowsPerPageChange = () => {},
  onEdit,
  onDelete,
  onDeleteSelected,
  onCustomAction, // Destructure the new prop
  customButtonLabel = 'Custom Action', // Default label for the custom button
  CustomIcon = null,
  width = 150,
  height = 150,
  aspectRatio = '1 / 1',
  maxWidth = '150px',
}: GenericTableProps<T>): React.JSX.Element {
  
  const rowIds = React.useMemo(() => {
    return rows.map((row) => row.id);
  }, [rows]);

  const { selected, selectAll, deselectAll, selectOne, deselectOne } = useSelection(rowIds);

  const selectedSome = (selected?.size ?? 0) > 0 && (selected?.size ?? 0) < rows.length;
  const selectedAll = rows.length > 0 && selected?.size === rows.length;

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      selectAll();
    } else {
      deselectAll();
    }
  };

  const handleDeleteSelected = () => {
    const selectedIds = Array.from(selected);
    onDeleteSelected?.(selectedIds);
    deselectAll();
  };

  const hasActions = onEdit || onDelete || onDeleteSelected || onCustomAction; // Determine if any action is available

  return (
    <Card>
      <Box sx={{ overflowX: 'auto' }}>
        <Table sx={{ minWidth: '800px', whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis', }}>
          <TableHead>
            <TableRow>
              { hasActions && (

                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selectedSome}
                    checked={selectedAll}
                    onChange={handleSelectAll}
                  />
                </TableCell>
              )}

              {hasActions && <TableCell>Hành động</TableCell>}

              {columns.map((column) => (
                <TableCell key={String(column.label)}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            { rows.map((row) => {
                const isSelected = selected?.has(row.id);

                return (
                  <TableRow hover key={row.id} selected={isSelected}>

                    { hasActions && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isSelected}
                          onChange={(event) => {
                            if (event.target.checked) {
                              selectOne(row.id);
                            } else {
                              deselectOne(row.id);
                            }
                          }}
                        />
                      </TableCell>
                    )}

                    {hasActions && (
                      <TableCell>
                        {onEdit && (
                          <Tooltip title="Chỉnh sửa/Chi tiết" arrow>
                            <IconButton aria-label="edit" onClick={() => onEdit(row.id)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        )}

                        {onDelete && (
                          <Tooltip title="Xóa thẻ" arrow>
                            <IconButton aria-label="delete" onClick={() => onDelete(row.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {onCustomAction && CustomIcon && (
                          <Tooltip title={customButtonLabel} arrow>
                            <IconButton
                              aria-label="custom-action"
                              onClick={() => onCustomAction(row.id)}
                            >
                              {CustomIcon}
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    )}

                    {columns.map((column) => {
                      let cellContent: React.ReactNode;

                      if (column.customRender) {
                        // Use customRender if it's defined
                        cellContent = column.customRender(row);
                      } else if (typeof column.field === 'function') {
                        // If the field is a function, use it to render the content
                        cellContent = column.field(row);
                      } else {
                        const value = row[column.field];
                        if (column.isImage) {
                          cellContent = value ? (
                            <div style={{ width: width, height: height, aspectRatio: aspectRatio }}>
                              <img
                                src={value as string}
                                alt="img"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                loading="lazy"
                              />
                            </div>
                          ) : (
                            <Typography variant="body2" color="textSecondary">
                              [Không có ảnh]
                            </Typography>
                          );
                        } else if (column.isDate && value) {
                          cellContent = dayjs(value as string | number | Date).format('MMM D, YYYY');
                        } else {
                          const strValue = value ? String(value) : '';
                          if (strValue.length > 100) {
                            // If it's over 100 chars, truncate and show tooltip
                            cellContent = (
                              <Tooltip title={strValue}
                                slotProps={{
                                  tooltip: {
                                    sx: {
                                      maxWidth: '500px', // Set the desired max width
                                      fontSize: '14px', // Optional: Customize font size
                                    },
                                  },
                              }} arrow>
                                <Box
                                  component="span"
                                  sx={{
                                    display: 'inline-block',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    cursor: 'pointer',
                                  }}
                                >
                                  {strValue.substring(0, 44) + '...'}
                                </Box>
                              </Tooltip>
                            );       
                          } else {
                            cellContent = strValue;
                          } 
                        }
                      }

                      return <TableCell key={String(column.label)}>{cellContent}</TableCell>;
                    })}
                    
                  
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </Box>
      <Divider />
      {selected?.size > 0 && (
        <Box sx={{ padding: 2 }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteSelected}
          >
            Xóa những mục đã chọn ({selected?.size})
          </Button>
        </Box>
      )}
      <TablePagination
        component="div"
        count={count}
        page={page}
        onPageChange={(event, newPage) => onPageChange(newPage)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(event) => onRowsPerPageChange(parseInt(event.target.value, 10))}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}
