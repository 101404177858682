import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { formatDate } from '@/hooks/format-date';


interface FormDataNote {
  resolved_flg: boolean;
  resolvedWithType: string;
  resolvedNote?: string;
  resolvedAt?: Date | string;
}

export const ResolvedNoteDisplay = ({
  formDataNote,
  handleChange,
  errors,
}: {
  formDataNote: FormDataNote;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors: { [key: string]: string };
}) => {

  
  if (formDataNote.resolved_flg && formDataNote.resolvedWithType === 'Note') {
    const formFields: FormField[] = [
      {
        name: 'resolvedNote',
        label: 'Ghi chú',
        type: 'text',
        value: formDataNote.resolvedNote || '',
        handleChange: handleChange,
        error: errors.resolvedNote,
        required: true,
      },
      {
        name: 'resolvedAt',
        label: 'Ngày giải quyết',
        type: 'date',
        value: formDataNote.resolvedAt || '',
        handleChange: handleChange,
        error: errors.resolvedAt,
        required: true,
      },
    ];

    return (
      <Box
        sx={{
          border: '1px solid #ddd',
          borderRadius: '8px',
          padding: '16px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          width: '100%',
          backgroundColor: '#f9f9f9',
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
        >
          Giải quyết bằng ghi chú
        </Typography>
        <Divider sx={{ marginBottom: '16px' }} />
        <FormFields fields={formFields} />
      </Box>
    );
  }
  return null; // Return nothing if the condition isn't met
};

export default ResolvedNoteDisplay;
