import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';  
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1'; 
import axios from 'axios';
import { formatDate } from '@/hooks/format-date';
import { TireInspectionDialog } from './TireInspectionsDialog';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface InspectionBy {
  username: string;
  firstName: string;
  lastName: string;
}


export interface TireInpspection {
  id: string;
  tireId: number;
  date: Date | string;
  primaryMeter: number;
  secondaryMeter: number;
  inspectionById: number;
  inspectionBy: InspectionBy;
  currentTreadDepth: number;
  createdAt: Date | string;
  updatedAt: Date | string;
}

interface TireDialogInspectionsProps {
  tireId: string;
  treadDepthUnit: string;
  vehicle?: {
    id: number;
    name: string;
    vehicleNumber: string;
    primaryMeterType: string;
    primaryMeter: number;
    secondaryMeter: number;
    secondaryMeterType: string;
  };
  warningWhenWornFlg?: boolean; 
  minimumTreadDepth?: number;
}   

function applyPagination(rows:  TireInpspection[], page: number, rowsPerPage: number): TireInpspection[] {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export default function TireInpspectionComponents({ tireId, treadDepthUnit, vehicle,  warningWhenWornFlg, minimumTreadDepth  }: TireDialogInspectionsProps): React.JSX.Element {

  // log the vehicle

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5); // Adjust number of rows per page as necessary
  const [data, setData] = React.useState<TireInpspection[]>([]); // Inspection data state
  const [filteredData, setFilteredData] = React.useState<TireInpspection[]>([]); // Filtered data state
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedTireInspection, setSelectedTireInspetion] = React.useState<TireInpspection | null>(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(`/api/tire/inspections/${tireId}`);
            
      const sortedData = response.data.sort((a: TireInpspection, b: TireInpspection) => {
          const dateA = new Date(a.date).toISOString(); // Convert to ISO string for consistent comparison
          const dateB = new Date(b.date).toISOString(); 
          return dateB.localeCompare(dateA);
      });

      setData(sortedData);
      setFilteredData(sortedData);
    } catch (error) {
      console.error('Error fetching data: ', error);  
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handlePageChange = (newPage: number) => {
    setPage(newPage); 
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => { 
    setRowsPerPage(newRowsPerPage); 
    setPage(0);   
  };

  const handleOpenDialog = () => {
    setSelectedTireInspetion(null); // Reset selected inspection when adding a new one
    setOpenDialog(true);
  };

  const handleEditTireInspection = (id: string) => {
    const tireInspectionToEdit = data.find((tireInspection) => tireInspection.id === id);  
    if (tireInspectionToEdit  ) {
      setSelectedTireInspetion(tireInspectionToEdit);
      setOpenDialog(true);
    } 
  };

  const handleDeleteTireInspection = async (id: string) => {
    const confirmed = window.confirm('Bạn có chắc muốn xóa thẻ này?');
    if (confirmed) {
      try {
        await axios.delete(`/api/tire/inspections/${JSON.stringify(id)}`);
        setData((prevData) => prevData.filter((tireInspection) => tireInspection.id !== id));
        setFilteredData((prevData) => prevData.filter((tireInspection) => tireInspection.id !== id));
      } catch (error) {
        console.error('Error deleting tire inspecion:', error);
      }
    }
  }

  const handleDeleteTireInspectionSelected = async (selectedIds: string[]) => {  
    const confirmed = window.confirm('Bạn có chắc muốn xóa các thẻ đã chọn?');
    if (confirmed) {
      try{
          await axios.delete(`/api/tire/inspections/${JSON.stringify(selectedIds)}`);
          alert('Xóa thành công');
          setData((prevData) => prevData.filter((tireInspection) => !selectedIds.includes(tireInspection.id)));
          setFilteredData((prevData) => prevData.filter((tireInspection) => !selectedIds.includes(tireInspection.id)));  
      } catch (error){
        console.log('Error deleting tire inspection:', error);
      }
    }
  };

const handleAddOrEditTireInspection = async (tireInspectionData: Partial<TireInpspection>) => {  
  try {
    const formData = new FormData();

    // Iterate over tireInspectionData keys
    Object.keys(tireInspectionData).forEach((key) => {
      const value = tireInspectionData[key as keyof TireInpspection];
      if (value !== null && value !== undefined) {
        formData.append(key, value instanceof Blob ? value : String(value)); // Convert non-Blob values to strings
      }
    });

    if (selectedTireInspection) {
      // Update existing inspection
      const response = await axios.put(`/api/tire/inspections/${selectedTireInspection.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const updatedTireInspection = response.data;

      // Update state with the edited inspection
      setData((prevData) =>
        prevData.map((tireInspection) =>
          tireInspection.id === updatedTireInspection.id ? updatedTireInspection : tireInspection
        )
      );
      setFilteredData((prevData) =>
        prevData.map((tireInspection) =>
          tireInspection.id === updatedTireInspection.id ? updatedTireInspection : tireInspection
        )
      );
    } else {
      // Create new inspection
      if (!tireId) {
        throw new Error("Tire ID is required for creating a new inspection.");
      }

      const response = await axios.post(`/api/tire/inspections/${tireId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const newTireInspection = response.data;

      // Add the new inspection to state
      setData((prevData) => [newTireInspection, ...prevData]);
      setFilteredData((prevData) => [newTireInspection, ...prevData]);
    }

    // Close the dialog
    setOpenDialog(false); 
  } catch (error) {
    alert('Có lỗi xảy ra, vui lòng thử lại sau');
    console.error('Error adding or editing tire inspection:', error);
  }
};


  const columns: TableColumn<TireInpspection>[] = [
    { label: 'Ngày', field: 'date', customRender: (row) => formatDate(row.date) },
    {
      label: 'Độ sâu gai hiện tại',
      field: 'currentTreadDepth',
      customRender: (row) => {
        const currentTreadDepth = row.currentTreadDepth;
        if (currentTreadDepth === null || currentTreadDepth === undefined) {
          return (
            <Typography color="textSecondary" sx={{ display: 'flex', alignItems: 'center', fontStyle: 'italic' }}>
              <HelpOutlineIcon sx={{ color: 'orange', mr: 1 }} />
              Chưa kiểm tra
            </Typography>
          );
        }

        if (minimumTreadDepth === null || minimumTreadDepth === undefined) {
          return (
            <Typography color="textSecondary" sx={{ display: 'flex', alignItems: 'center', fontStyle: 'italic' }}>
              <HelpOutlineIcon sx={{ color: 'orange', mr: 1 }} />
              Chưa đặt giới hạn
            </Typography>
          );
        }

        return (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: warningWhenWornFlg && currentTreadDepth < minimumTreadDepth ? 'red' : 'green',
            }}
          >
            {currentTreadDepth < minimumTreadDepth ? (
              <>
                <CancelIcon sx={{ color: 'red', mr: 1 }} />
                {currentTreadDepth} {treadDepthUnit}
              </>
            ) : (
              <>
                <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
                {currentTreadDepth} {treadDepthUnit}
              </>
            )}
          </Typography>
        );
      },
    },
    { label: 'Số đo chính lúc kiểm tra', field: 'primaryMeter' },
    { label: 'Số đo phụ lúc kiểm tra', field: 'secondaryMeter' },
    {
      label: 'Kiểm tra bởi',
      field: (row) =>
        `${row.inspectionBy.firstName} ${row.inspectionBy.lastName} (${row.inspectionBy.username})`,
    },
  ];

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedTireInspetion(null); 
  }

  return (
    <Stack spacing={2}>
    <Stack direction="row" spacing={3}>
      <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
        <Typography variant="h5">Lịch sử kiểm tra lốp xe</Typography>
      </Stack>
      <div>
        <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={handleOpenDialog}>
          Thêm
        </Button>
      </div>
    </Stack>      

    <GenericTable
      count={filteredData.length}
      page={page}
      rows={applyPagination(filteredData, page, rowsPerPage)} // Use transformed data
      columns={columns}
      rowsPerPage={rowsPerPage}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      onEdit={handleEditTireInspection}
      onDelete={handleDeleteTireInspection}
      onDeleteSelected={handleDeleteTireInspectionSelected} 
    />

    <TireInspectionDialog
      open={openDialog} onClose={handleDialogClose} onSubmit={handleAddOrEditTireInspection}  tireInspectionData={selectedTireInspection ?? undefined} treadDepthUnit={treadDepthUnit}
      refreshData={fetchData} vehicle={vehicle} 
    />


  </Stack>
  );
};

