import React, { useEffect, useState } from 'react';
import { Warehouse } from './InventorySetup'; // Import the component
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields } from '@/components/dashboard/formField/FormFields';
import axios from 'axios';
import { error } from 'console';


interface WarehouseDialogProps {
  open: boolean;
  onClose: () => void;    
  onSubmit: (data: any) => void;  
  data?: Partial<Warehouse>;  
}

export const WarehouseDialog: React.FC<WarehouseDialogProps> = ({ open, data, onClose, onSubmit }) => {
  const isEditMode = !!data;
  const [warehouseData, setWarehouseData] = React.useState<Partial<Warehouse>>(data || {});
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (data) {
      setWarehouseData(data);
    } else {
      setWarehouseData({});
    } 
  }, [open, data])


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => { 
    const { name, type, value, checked } = e.target;  

    setWarehouseData((prevData) => ({  
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,  

    }));
  };
  
  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};  
    const errorMessages: string[] = [];

    // validate the name
    if (!warehouseData.name || warehouseData.name.trim() === '') {
      newErrors.name = 'Vui lòng nhập tên kho hàng.'; 
    }

    setErrors(newErrors);

    if (errorMessages.length > 0) {
      const formattedErrorMessages = errorMessages.map((message, index) => `+ ${message}`); 
      alert(`Vui lòng kiểm tra lại các trường thông tin sau:\n${formattedErrorMessages.join('\n')}`); 
    }

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      const warehouseDataSubmit = {
        ...warehouseData  
      }

      const formDataSubmit = new FormData();
      Object.keys(warehouseDataSubmit).forEach(key => {
        const value = warehouseDataSubmit[key as keyof Warehouse];
        formDataSubmit.append(key, value as string | Blob);
      });

      let response;
      if (isEditMode) {
        response = await axios.put(`/api/warehouse/full/${warehouseData.id}`, formDataSubmit, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      } else {
        response = await axios.post('/api/warehouse/full', formDataSubmit, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      } 

      const updatedWarehouseData = response.data;
      onSubmit(updatedWarehouseData); 

      setWarehouseData({}); 
      setErrors({});
    } catch (error: any) {
      if (error.response) {
        const { status, data } = error.response;
  
        if (status === 409) {
          alert(`❌ Lỗi tên kho hàng đã tồn tại. Vui lòng chọn một tên khác.`);
        } else if (status === 400) {
          alert(`⚠️ Lỗi dữ liệu không hợp lệ: ${data.error}`);
        } else if (status === 404) {
          alert('❌ Không tìm thấy kho hàng.');
        } else {
          alert('❌ Có lỗi xảy ra khi thêm/chỉnh sửa kho hàng. Vui lòng thử lại sau.');
        }
      } else {
        alert('❌ Không thể kết nối đến máy chủ. Vui lòng kiểm tra mạng và thử lại.');
      }
    }

  }

  const formFields = [
    { name: 'name', label: 'Tên kho hàng', type: 'text', value: warehouseData.name, handleChange: handleChange, required: true, error: errors.name,  },
    { name: 'default_flg', label: 'Kho mặc định', value: warehouseData.default_flg, handleChange: handleChange, type: 'checkbox', error: errors.default_flg },
    { name: 'address', label: 'Địa chỉ', value: warehouseData.address, handleChange: handleChange,  type: 'text', error: errors.address },
    { name: 'address2', label: 'Địa chỉ 2', value: warehouseData.address2, handleChange: handleChange, type: 'text', error: errors.address2 },   
    { name: 'city', label: 'Thành phố', value: warehouseData.city, handleChange: handleChange, type: 'text', error: errors.city },   
  ]

  return (
     <>
      <CustomDialog open={open} title={ isEditMode ? 'Chỉnh sủa thông tin nhà kho' : 'Thêm thông tin nhà kho'} onClose={onClose} onSubmit={handleSubmit} dialogWidth='1200px' dialogMaxWidth='95%'>
      
            <FormFields fields={formFields} />
          
      </CustomDialog>
    </>
  )

};

