import * as React from 'react';
import { Typography } from '@mui/material'; 
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { Issues } from './Issues'; // Import your inspectiontype
import { ImageUpload } from '@/components/dashboard/imageUpload/ImageUpLoad';
import { CustomSelectWithAdd } from '@/components/dashboard/customSelectWithAdd/customSelectWithAdd';
import axios from 'axios';
import { DialogField } from '@/components/dashboard/fieldManagement/FieldManagement';
import useImageDownload from '@/hooks/image-download'; // Import the hook
import { UseFetchAccount, UseFetchVechicle, UseFetchPriorityStatus } from '@/hooks/fetch-relationship-field'; // Import the hooks
import { getDefaultDate, formatDate } from '@/hooks/format-date';
import { ResolveOptions } from '@/components/subPlace/IssueResolvedOptions'; // Import the component
import { ResolvedNoteDisplay } from '@/components/subPlace/IssueResolvedNoteDisplay'; // Import the component  
import { resolve } from 'path';
import dayjs from 'dayjs'; // Import dayjs to get the current date
import { useNavigate } from 'react-router-dom';
import { ResolvedServiceEntry } from '@/components/subPlace/IssueResolvedServiceEntry'; // Import the component 

interface ResolveFormData {
  note: string;
  resolvedDate: string;
}

interface IssueDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (inspectionData: Partial<Issues>, imageFile?: File, notPassed_flg?: Boolean) => void;
  issueData?: Partial<Issues>;
  refreshData: () => void; // <-- pass fetchData here
}

export const IssueDialog: React.FC<IssueDialogProps> = ({ open, onClose, onSubmit, issueData, refreshData }) => {  
  const isEditMode = !!issueData;

  const [formData, setFormData] = React.useState<Partial<Issues>>(issueData || {});
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const [imageFile, setImageFile] = React.useState<File | null>(null);
  const [passwordInput, setPasswordInput] = React.useState(''); 
  const [imagePreview, setImagePreview] = React.useState<string | null>(null);
  const [imageError, setImageError] = React.useState<string | null>(null);
  const [isPictureDeleted, setIsPictureDeleted] = React.useState(false); // Flag for picture deletion
  const { assignees } = UseFetchAccount();
  const { vehicles } = UseFetchVechicle();
  const { priorityStatus } = UseFetchPriorityStatus();  

  const [ reportedById, setReportedById ] = React.useState<number | null>(null);
  const [ vehicleId, setVehicleId ] = React.useState<number | null>(null); 
  const [ assignedToId, setAssignedToId ] = React.useState<number | null>(null);
  const [ priorityId, setPriorityId ] = React.useState<number | null>(null);  

  const [issueNumberLoading, setIssueNumberLoading] = React.useState(false);

  // the resolved part
  const [openResolveDialog, setOpenResolveDialog] = React.useState(false); // State for resolve dialog
  const [resolveFormData, setResolveFormData] = React.useState({
    note: '',
    resolvedDate: '',
  });
  const [errorsIssue, setErrorsIssue] = React.useState({}); // To store validation errors

  const navigate = useNavigate(); // Use the navigate function from react-router-dom


  // for the image
  const handleDeleteImage = async () => {
    if (formData.id) {
      try {
        setIsPictureDeleted(true);
        setImageFile(null);
        setImagePreview(null);
        setFormData({ ...formData, photoUrl: undefined });
      } catch (error) {
        console.error('Failed to delete image:', error);
      }
    }
  };

  const { handleDownloadImage } = useImageDownload(); // Use the custom hook

  React.useEffect(() => {
    if (open && !isEditMode) {
      fetchNextIssueNumber();
    } else if (open && isEditMode && issueData.notPassed_flg === true){
      fetchNextIssueNumber();
    }
  }, [open, issueData]);

  const fetchNextIssueNumber = async () => { 
    try {
        setIssueNumberLoading(true);
        const response = await axios.get('/api/issues/next-issue-number'); 
        const fetchNextIssueNumber = response.data.nextIssueNumber;
        setFormData({ ...formData, issueNumber: fetchNextIssueNumber });
        
    } catch (error) {
        console.error('Failed to fetch next inspection number:', error);
    }  finally { 
        setIssueNumberLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (validateForm()) {

      onSubmit({
        ...formData,
        reportedById: reportedById ?? undefined,
        vehicleId: vehicleId ?? undefined,
        assignedToId: assignedToId ?? undefined,
        priorityId: priorityId ?? undefined
      }, imageFile || undefined, issueData?.notPassed_flg || false ); 
    }
  }

  const validateForm = (): boolean => {  
    const newErrors: { [key: string]: string } = {};

    console.log('Validating form data:', formData);

    fields.forEach(field => {
      const fieldValue = formData[field.name as keyof Issues];
      // For other fields, handle as usual
      if (field.required && (!fieldValue || typeof fieldValue === 'string' && !fieldValue.trim())) {
        newErrors[field.name] = `${field.label} cần có giá trị`;
      }  
    });

    // validate to require vehicleId
    if (!vehicleId) {
      newErrors.vehicleId = 'Cần chọn phương tiện';
    }
    setErrors(newErrors);

    // show alert with the list of errors if there is
    if (Object.keys(newErrors).length > 0) {
      const errorMessages = Object.values(newErrors).join('\n');
      alert(`Có lỗi xảy ra:\n${errorMessages}`);
    }

    return Object.keys(newErrors).length === 0;
  }

  // function to clear the form data when the dialog is closed
  const closeDialog = () => {
    setFormData({});
    setReportedById(null);
    setVehicleId(null);
    setAssignedToId(null);
    setPriorityId(null);
    setImageFile(null);
    setImagePreview(null);      

    setErrors({});
    onClose();
  };


  React.useEffect(() => {
    if (open) {
      if (issueData) {
        setFormData({...issueData,
          reportedById: issueData.reportedById ?? undefined, 
          vehicleId: issueData.vehicleId ?? undefined,
          assignedToId: issueData.assignedToId ?? undefined,
          priorityId: issueData.priorityId ?? undefined,
          reportedAt: formatDate(issueData.reportedAt),
          dueDate: formatDate(issueData.dueDate),
          resolvedAt: formatDate(issueData.resolvedAt),
        });
        setReportedById(issueData.reportedById ?? null);
        setVehicleId(issueData.vehicleId ?? null);
        setAssignedToId(issueData.assignedToId ?? null);
        setPriorityId(issueData.priorityId ?? null);

        setIsPictureDeleted(false);
        if (issueData.photoUrl) {
          setImagePreview(issueData.photoUrl);  
        }
      } else {
        const currentDate = formatDate(dayjs().startOf('day').toISOString());
        console.log('Setting reportedAt date to:', currentDate);
        setFormData({})
        setFormData({
          reportedAt: currentDate, // Set default value for reportedAt
        });
        setReportedById(null);
        setVehicleId(null);
        setAssignedToId(null);
        setPriorityId(null);
        setImageFile(null);
        setImagePreview(null);
      }
    }
  }, [open, issueData]);

  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Handle checkbox separately to update the state with boolean values
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const fields = [
    { name: 'issueNumber', label: 'Mã số kiểm tra', type: 'text', required: true },
    { name: 'name', label: 'Tiêu đề', type: 'text', required: true },
    { name: 'description', label: 'Mô tả', type: 'text', required: true },
    { name: 'reportedAt', label: 'Ngày báo cáo', type: 'date', required: true },
    { name: 'dueDate', label: 'Ngày đến hạn', type: 'date', required: true },
  ];

  const formFields: FormField[] = fields.map((field) => {
    let defaultValue = '';
  
    if (field.name === 'reportedAt') {
      defaultValue = getDefaultDate(field.name);
      if (!formData.reportedAt) {
        setFormData((prevFormData) => ({ ...prevFormData, reportedAt: defaultValue })); // Set default value for reportedAt if it isn't already set
      }
    }
  
    if (field.name === 'issueNumber') {
      // Special handling for issueNumber
      return {
        label: 'Mã số kiểm tra',
        name: 'issueNumber',
        type: 'text',
        value: formData.issueNumber || '',
        required: true,
        error: errors['issueNumber'],
        handleChange: handleChange, // Include the handleChange if needed for this field
      };
    } else {
      return {
        label: field.label,
        name: field.name,
        type: field.type,
        value: formData[field.name as keyof Issues] || defaultValue, // Default today's date for reportedAt if it's not set
        required: field.required,
        handleChange: handleChange,
        error: errors[field.name],
      };
    }
  });
  
  // resolve part

  // for the resolve with 
  const handleServiceEntry = () => {
    if (issueData && !issueData.notPassed_flg) {
      // Pass the issue data to the MaintainanceServiceEntrysPage
      navigate('/dashboard/maintainance-service-entries', {
        state: {
          issueData,
          fromIsssueFlg: true,
        },
      });
    }
  };

  const handleNote = () => {
    handleResolveDialogOpen();
  };

  const handleResolveDialogOpen = () => {
    setOpenResolveDialog(true); // Open the resolve dialog
  };

  const handleResolveDialogClose = () => {
    setOpenResolveDialog(false); // Close the resolve dialog
  };

  const validateResolveForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};

    // Validation rules
    if (!resolveFormData.note || resolveFormData.note.trim() === '') {
      newErrors.note = 'Ghi chú cần có giá trị';
    }

    if (!resolveFormData.resolvedDate || resolveFormData.resolvedDate.trim() === '') {
      newErrors.resolvedDate = 'Ngày giải quyết cần có giá trị';
    }

    // Set the errors for display
    setErrorsIssue(newErrors);

    // If there are errors, show an alert with the list of errors
    if (Object.keys(newErrors).length > 0) {
      const errorMessages = Object.values(newErrors).join('\n'); // Combine errors into a single string
      alert(`Có lỗi xảy ra:\n${errorMessages}`); // Show alert with the errors
    }

    // Return whether the form is valid
    return Object.keys(newErrors).length === 0;
  };

  const handleResolveDialogSubmit = async () => {
    if (validateResolveForm()) {
      try {
        const response = await axios.post('/api/issues/resolved-note', {
          ...resolveFormData,
          issueId: formData.id,
        });

        if (response.status === 200) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            resolved_flg: true,
            resolvedWithType: 'Note',
            resolvedNote: resolveFormData.note,
            resolvedAt: resolveFormData.resolvedDate,
          }));
          alert('Thẻ vấn đề đã được giải quyết thành công');
        }
      } catch (error) {
        console.error('Error resolving issue:', error);
        if (axios.isAxiosError(error) && error.response?.data?.error) {
          alert(`Failed to resolve issue: ${error.response.data.error}`);
        } else {
          alert('An error occurred while resolving the issue.');
        }
      } finally {
        setOpenResolveDialog(false);
      }
    }
  };

  const handleResolveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setResolveFormData((prevState: ResolveFormData) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resolveFormFields = [
    {
      name: 'note',
      label: 'Ghi chú',
      type: 'text',
      value: resolveFormData.note,
      error: errors.note,
      required: true,
      handleChange: handleResolveChange,
    },
    {
      name: 'resolvedDate',
      label: 'Ngày giải quyết',
      type: 'date',
      value: resolveFormData.resolvedDate,
      error: errors.resolvedDate,
      required: true,
      handleChange: handleResolveChange,
    },
  ];

  const handleResolvedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // State for resolved service entries
  interface ServiceEntryData {
    serviceEntryNumber: string;
    serviceTask: { name: string };
    status: { name: string; color: string } | null;
    completedDate: string;
  }
  
  const [serviceEntryData, setServiceEntryData] = React.useState<ServiceEntryData | null>(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (formData.resolved_flg && formData.resolvedWithType === 'ServiceEntries' && formData.resolvedServiceEntryId) {
      fetchServiceEntryData(formData.resolvedServiceEntryId);
    }
  }, [formData.resolved_flg, formData.resolvedWithType, formData.resolvedServiceEntryId]);

  const fetchServiceEntryData = async (serviceEntryId: number) => {
    try {
      setLoading(true);
      const response = await axios.get(`/api/service-entries/${serviceEntryId}/issues`);
      setServiceEntryData(response.data);
    } catch (error) {
      console.error('Error fetching service entry data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDissociateServiceEntry = async () => {
    try {
      await axios.patch(`/api/issues/${formData.id}/dissociate-service-entry`);
      alert('Hủy liên kết thẻ nhiệm vụ thành công');
      setServiceEntryData(null);
      setFormData((prev) => ({
        ...prev,
        resolvedServiceEntryId: undefined,
        resolved_flg: false,
        resolvedWithType: undefined,
      }));
  
      // Refresh the data after successful dissociation
      refreshData();
    } catch (error) {
      console.error('Lỗi hủy liên với với thẻ nhiệm vụ:', error);
    }
  };
  
  const resolveContent = () => {
    if (formData.resolved_flg && formData.resolvedWithType === 'Note') {
      const formDataNote = {
        resolved_flg: formData.resolved_flg,
        resolvedWithType: formData.resolvedWithType,
        resolvedNote: formData.resolvedNote,
        resolvedAt: formData.resolvedAt,
      };

      return (
        <ResolvedNoteDisplay
          formDataNote={formDataNote}
          handleChange={handleResolvedChange}
          errors={errors}
        />
      );
    } else if (formData.resolved_flg && formData.resolvedWithType === 'ServiceEntries') {
      if (loading) {
        return <Typography>Loading service entry data...</Typography>;
      }

      if (serviceEntryData) {
        const formDataServiceEntry = {
          resolved_flg: formData.resolved_flg,
          resolvedWithType: formData.resolvedWithType,
          reference: serviceEntryData.serviceEntryNumber,
          serviceTask: serviceEntryData.serviceTask.name,
          status: serviceEntryData.status ? {
            name: serviceEntryData.status.name,
            color: serviceEntryData.status.color
          } : null,
          resolvedAt: formatDate(formData.resolvedAt),
        };

        return (
          <ResolvedServiceEntry
            formData={formDataServiceEntry}
           
            onDissociateServiceEntry={handleDissociateServiceEntry}
          />
        );
      }

      return null;
    } else {
      return (
        <ResolveOptions
          onServiceEntryClick={handleServiceEntry}
          onNoteClick={handleNote}
        />
      );
    }
  };

  return (
    <CustomDialog open={open} title='Thêm/Chỉnh sửa thẻ trục trặc' 
      onClose={closeDialog} onSubmit={handleSubmit} 
      submitLabel='Lưu'
      dialogWidth='1200px' dialogMaxWidth='75%'
    >

      <div className='row'>
        <div className={isEditMode && !issueData?.notPassed_flg ? 'col-md-6' : 'col-md-12'}>
          <FormFields fields={formFields} />
          <div className='mt-3'>
            <ImageUpload
              imageFile={imageFile}
              imagePreview={imagePreview}
              setImageFile={setImageFile}
              setImagePreview={setImagePreview}
              onDeleteImage={handleDeleteImage}
              onDownloadImage={() => handleDownloadImage(formData.photoUrl)}  // Pass a function without arguments
              setImageError={setImageError}
              imageError={imageError}
              label='Ảnh vấn đề'
            />
          </div>

          <CustomSelectWithAdd
            label="Tình trạng *"
            items={priorityStatus}
            value={priorityId || 0}
            onChange={(id) => {
              setPriorityId(id); // Update the selected status          
            }}
            manageItem="/dashboard/maintenance-management"
          />

          <CustomSelectWithAdd
            label='Người báo cáo *'
            items={assignees}
            value={reportedById || 0}
            onChange={setReportedById}
            manageItem='/dashboard/employees'
          />
          <CustomSelectWithAdd
            label='Phương tiện'
            items={vehicles}
            value={vehicleId || 0}
            onChange={setVehicleId}
            manageItem='/dashboard/vehicles'
          />
          <CustomSelectWithAdd
            label='Người được giao'
            items={assignees}
            value={assignedToId || 0}
            onChange={setAssignedToId}
            manageItem='/dashboard/employees'
          />
        </div>

     
        {isEditMode && !issueData?.notPassed_flg && (
          <div className="col-md-6">
            {resolveContent()}
          </div>
        )}

       
      </div>

      {/* Resolve Note Dialog */}
      <CustomDialog
        open={openResolveDialog}
        title="Giải quyết bằng ghi chú"
        onClose={handleResolveDialogClose}
        onSubmit={handleResolveDialogSubmit}
        submitLabel="Lưu"
        dialogWidth="800px"
        dialogMaxWidth="95%"
      >
        <FormFields fields={resolveFormFields} />
      </CustomDialog>
      

    </CustomDialog>
  );

}