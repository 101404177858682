
import { ToolbarProps } from 'react-big-calendar';
import { format } from 'date-fns';
import vi from 'date-fns/locale/vi';

export const customVi = {
  ...vi,
  // Fully extend the `localize` object, overriding only the `day` function
  localize: {
    ...vi.localize,
    day: (n: number) => ['C', '2', '3', '4', '5', '6', '7'][n],
    ordinalNumber: vi.localize?.ordinalNumber ?? ((n: number) => n), // Ensure `ordinalNumber` is defined
    era: vi.localize?.era ?? ((n: number) => n), // Ensure `era` is defined
    quarter: vi.localize?.quarter ?? ((n: number) => n), // Ensure `quarter` is defined
    month: vi.localize?.month ?? ((n: number) => n), // Ensure `month` is defined
    dayPeriod: vi.localize?.dayPeriod ?? ((n: number) => n), // Ensure `dayPeriod` is defined
  },
};

export const CustomToolbar: React.FC<ToolbarProps<{ id: string; title: string; start: Date; end: Date }>> = ({
  date,
  onNavigate,
  onView,
  view,
}) => {
  // Determine the date format based on the current view
  const dateFormat = view === 'day' ? 'dd MMMM yyyy' : 'MMMM yyyy';

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={() => onNavigate('TODAY')}>Hôm nay</button>
        <button type="button" onClick={() => onNavigate('PREV')}>Trước</button>
        <button type="button" onClick={() => onNavigate('NEXT')}>Tiếp</button>
      </span>
      <span className="rbc-toolbar-label">
        {/* Use date-fns format with conditional formatting */}
        {format(date, dateFormat, { locale: vi })}
      </span>
      <span className="rbc-btn-group">
        <button type="button" onClick={() => onView('month')}>Tháng</button>
        <button type="button" onClick={() => onView('week')}>Tuần</button>
        <button type="button" onClick={() => onView('day')}>Ngày</button>
      </span>
    </div>
  );
};



