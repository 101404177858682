//create the hooks isValidImage function

const isValidImage = (file: File): { valid: boolean, error?: string } => {
    const validTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/x-icon', 'image/svg+xml'];
    const maxSizeInMB = 4;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    if (!validTypes.includes(file.type)) {
        return {
            valid: false,
            error: 'File type not supported. Please upload an image file.'
        };
    }   

    if (file.size > maxSizeInBytes) {
        return {
            valid: false,
            error: `File is too large. Please upload an image less than ${maxSizeInMB}MB.`
        };
    }   

    return {
        valid: true
    };
}

export { isValidImage };