import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';  
import { Download as DownloadIcon } from '@phosphor-icons/react';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { Upload as UploadIcon } from '@phosphor-icons/react';
import { GenericFilters } from '@/components/dashboard/filter/genericFilter';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1'; 
import axios from 'axios';
import { InspectionDialog } from './inspectionDialog';
import { formatDate } from '@/hooks/format-date';
import { statusInspectionMap } from '@/hooks/mapping'; // <-- import statusInsepctionMap
import { useLocation, useNavigate } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { getContrastTextColor } from '@/hooks/contrast-text-color';

// **NOTE: in this code just prefered the inspection to the accoutns instead (tempalte used)

interface Vehicle {
  vehicleNumber: string;
  name: string;
  primaryMeterType: string;
  primaryMeter: number;
  secondaryMeterType: string; 
  secondaryMeter: number; 
}

interface InspectionForm {
  name: string;
}

interface InspectionBy {
  username: string;
  firstName: string;
  lastName: string;
}

export interface Inspection {
  id: string;
  inspectionNumber: string;
  vehicleId: number;
  vehicle: Vehicle;
  inspectionFormId: number;
  inspectionForm: InspectionForm;
  inspectionById: number;
  inspectionBy: InspectionBy;
  inspectionDate: Date | string;
  primaryMeter: number; 
  createdAt: Date;
  updateAt: Date;
  customFields: Record<string, string>;
  // inspeciton items status calculated
  status: string
  inspectionItemsStatus?: Partial<InspectionItemStatus>[];  // Optional array to handle partial data
}

export interface InspectionItemStatus {
  id: string;
  inspectionCategoryId: number;
  inspectionComment: string;
  inspectionDescription: string;
  inspectionItemId: number;
  inspectionItemName: string;
  inspectionRequireCommentOnFail: boolean;
  status: string;
}

function applyPagination(rows: Inspection[], page: number, rowsPerPage: number): Inspection[] {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export default function InspectionsPage(): React.JSX.Element {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5); // Adjust number of rows per page as necessary
  const [data, setData] = React.useState<Inspection[]>([]); // Inspection data state
  const [filteredData, setFilteredData] = React.useState<Inspection[]>([]); // Filtered data state
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedInspection, setSelectedInspection] = React.useState<Inspection | null>(null);

  const [inspectionNumberProps, setInspectionNumberProps] = React.useState('');

  const location = useLocation(); 

  React.useEffect(() => {
    const params = new URLSearchParams(location.search); // Use location from useLocation hook
    const inspectionNumber = params.get('inspectionNumber');
  
    if (inspectionNumber) {
      const inspection = data.find((inspection) => inspection.inspectionNumber === inspectionNumber);
      if (inspection) {
        setInspectionNumberProps(inspectionNumber);
        handleFilterChange('inspectionNumber', inspectionNumber);
      }
    }
  }, []); // Empty dependency array ensures this runs only once on mount
  
  const fetchData = async () => {
    try{
      const response = await axios.get('/api/inspections');
      const sortedData = response.data.sort((a: Inspection, b: Inspection) => {a.inspectionNumber.localeCompare(b.inspectionNumber)});
      setData(sortedData);
      setFilteredData(sortedData);
    } catch (error){
      console.error('Error fetching data:', error);
    }
  };

  React.useEffect(() => {   
    fetchData();
  }, []);

  const paginatedInspections = applyPagination(filteredData, page, rowsPerPage);
  
  const handleFilterChange = (column: string, searchTerm: string | boolean | Dayjs | null) => {
    if (!searchTerm) {
      // Reset to the original data if searchTerm is null or empty
      setFilteredData(data);
      return;
    }
  
    const filtered = data.filter((inspection) => {
      let value;
  
      // Map column name to the actual data field
      switch (column) {
        case 'inspectionNumber':
          value = inspection.inspectionNumber;
          break;
        case 'vehicleNumber':
          value = inspection.vehicle?.vehicleNumber; // Safely access nested property
          break;
        case 'inspectionFormName':
          value = inspection.inspectionForm.name; // Access inspection form name
          break;
        case 'inspectionByName':
          value = `${inspection.inspectionBy.firstName} ${inspection.inspectionBy.lastName}`; // Concatenate first and last names
          break;
        default:
          value = inspection[column as keyof Inspection]; // Access the column value dynamically
      }
  
      // Handle different value types: string or boolean
      if (typeof value === 'string' && typeof searchTerm === 'string') {
        return value.toLowerCase().includes(searchTerm.toLowerCase()); // Case-insensitive search for strings
      }
  
      if (typeof value === 'boolean' && typeof searchTerm === 'boolean') {
        return value === searchTerm; // Match boolean values exactly
      }
  
      return false;
    });
  
    setFilteredData(filtered);
  };
  
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => { 
    setRowsPerPage(newRowsPerPage); 
    setPage(0);
  };

  const handleOpenDialog = () => {
    setSelectedInspection(null); // Reset selected inspection when adding a new one
    setOpenDialog(true);
  };

  const handleEditInspection = (id: string) => {
    const inspectionToEdit = data.find((inspection) => inspection.id === id);
    if (inspectionToEdit) {
      setSelectedInspection(inspectionToEdit); // Populate the dialog with the selected inspection
      setOpenDialog(true); // Open dialog for editing
    }
  };

  const handleDeleteInspection = async (id: string) => {
    const confirmed = window.confirm('Bạn có chắc là xóa dữ liệu kiểm tra đã chọn?');
    if (confirmed) {
      try {
        await axios.delete(`/api/inspections/${JSON.stringify(id)}`);
        setData((prevData) => prevData.filter((inspection) => inspection.id !== id));
        setFilteredData((prevData) => prevData.filter((inspection) => inspection.id !== id));
      } catch (error) {
        console.error('Error deleting inspection:', error);
      }
    }
  };
  
  const handleDeleteInspectionSelected = async (selectedIds: string[]) => {
    const confirmed = window.confirm('Bạn có chắc là xóa những dữ liệu kiểm tra đã chọn?'); 
    if (confirmed) {
      try{
          await axios.delete(`/api/inspections/${JSON.stringify(selectedIds)}`);
          alert('Xóa thành công');
          setData((prevData) => prevData.filter((inspection) => !selectedIds.includes(inspection.id)));
          setFilteredData((prevData) => prevData.filter((inspection) => !selectedIds.includes(inspection.id)));  
      } catch (error){
        console.log('Error deleting inspection:', error);
      }
    }
  }

  const handleAddOrEditInspection = async (inspectionData: Partial<Inspection>) => {
    try {
      const formData = new FormData();
  
      // Add inspection data to the form data
      Object.keys(inspectionData).forEach((key) => {
        const value = inspectionData[key as keyof Inspection];
        if (value !== undefined && key !== 'inspectionItemsStatus') { // Skip inspectionItemsStatus for now
          formData.append(key, value as string | Blob);
        }
      });
  
      if (inspectionData.customFields) {
        formData.set('customFields', JSON.stringify(inspectionData.customFields));
      }
  
      // Handle inspectionItemsStatus separately
      if (inspectionData.inspectionItemsStatus) {
        formData.set('inspectionItemsStatus', JSON.stringify(inspectionData.inspectionItemsStatus));
      }
  
      // Define whether to add or edit the data
      if (selectedInspection) {
        const response = await axios.put(`/api/inspections/${inspectionData.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
          },
        });
        const updatedInspection = response.data;
  
        // Update the state with the updated inspection data
        setData((prevData) =>
          prevData.map((inspection) => (inspection.id === updatedInspection.id ? updatedInspection : inspection))
        );
        setFilteredData((prevData) =>
          prevData.map((inspection) => (inspection.id === updatedInspection.id ? updatedInspection : inspection))
        );
      } else {
        const response = await axios.post('/api/inspections', formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
          },
        });
        const newInspection = response.data;
  
        // Add the new inspection data to the state
        setData((prevData) => [newInspection, ...prevData]);
        setFilteredData((prevData) => [newInspection, ...prevData]);
      }
  
      // Close the dialog after successful submission
      setOpenDialog(false);
    } catch (error) {
      alert('Có lỗi xảy ra, vui lòng thử lại sau');
      console.error('Error adding/editing inspection:', error);
    }
  };
  
  //filter columns
  const filterColumns = [
    { label: 'Mã số kiểm tra', value: 'inspectionNumber' },
    { label: 'Mã số xe', value: 'vehicleNumber' },
    { label: 'Tên đơn kiểm tra', value: 'inspectionFormName' },
    { label: 'Người kiểm tra', value: 'inspectionByName' },
  ];

      type StatusKey = keyof typeof statusInspectionMap; // Type for keys of statusMap
  

  //table columns
  const columns: TableColumn<Inspection>[] = [
    { label: 'Mã số kiểm tra', field: 'inspectionNumber', },
    { label: 'Mã Số Xe', field: (row) => row.vehicle?.vehicleNumber || 'N/A' },
    { label: 'Tên Xe', field: (row) => row.vehicle?.name || 'N/A' },
    { label: 'Tên đơn kiểm tra', field: (row) => row.inspectionForm.name },
    { label: 'Người kiểm tra', field: (row) => `${row.inspectionBy.username} - ${row.inspectionBy.firstName} ${row.inspectionBy.lastName}` },
    { label: 'Ngày kiểm tra',  field: 'inspectionDate', customRender: (row) => formatDate(row.inspectionDate) },     
    { label: 'Odo chính lúc thực hiện', field: 'primaryMeter' },
    { label: 'Trạng thái', 
      field: (row) => {
        const status = (row.status as StatusKey) || 'NotDue'; // Explicitly cast 'status' to StatusKey
        const statusInfo = statusInspectionMap[status];

        const textColor = getContrastTextColor(statusInfo.color); // Get text color based on background color

        return (
          <Chip
            label={statusInfo.label}
            style={{
              backgroundColor: statusInfo.color,
              color: textColor,
              fontWeight: 'bold',
              borderRadius: '5px',
              padding: '5px 10px',
            }}
          />
        );
      },
    }
  ];

   return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h4">Kiểm tra</Typography>
        </Stack>
        <div>
          <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={handleOpenDialog}>
            Thêm
          </Button>
        </div>
      </Stack>
      <GenericFilters columns={filterColumns} onFilterChange={handleFilterChange} placeholder="Tìm" preFillColumn='inspectionNumber' preFillValue={inspectionNumberProps} />
      <GenericTable
        count={filteredData.length}
        page={page}
        rows={paginatedInspections}
        columns={columns}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onEdit={handleEditInspection}
        onDelete={handleDeleteInspection}
        onDeleteSelected={handleDeleteInspectionSelected}
      />
      {/* Add inspection dialog */}
      <InspectionDialog open={openDialog} onClose={() => setOpenDialog(false)} onSubmit={handleAddOrEditInspection} inspectionData={selectedInspection ?? undefined}   
      refreshData={fetchData} // <-- pass fetchData here
 />
    </Stack>
  );
}

// This ensures that this file is treated as a module and resolves the TS1208 error
export {};