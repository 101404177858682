import React from 'react';

const NavNotice: React.FC = () => {
    return (
        <li className="nav-item dropdown">
            <a className='nav-link nav-icon' href='#' data-bs-toggle="dropdown">
                <i className="bi bi-bell"></i>
                <span className="badge bg-danger badge-number">4</span>
            </a>

            <ul className='dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications'>
                <li className='dropdown-header'>
                    You have 4 new notifications
                    <a href='#'>
                        <span className="badge rounded-pill bg-primary p-2 ms-2">
                            View All
                        </span>
                    </a>
                </li>

                <li>
                    <hr className='dropdown-divider' />
                </li>

                <li className='notifications-item'>
                    <i className='bi bi-exclamation-circle text-warning'></i>
                    <div>
                        <h4>Thông báo 1</h4>
                        <p>Something somethin</p>
                        <p>30 phút trước</p>
                    </div>
                </li>

                <li>
                    <hr className='dropdown-divider' />
                </li>

                <li className='notifications-item'>
                    <i className='bi bi-check-circle text-success'></i>
                    <div>
                        <h4>Thông báo 2</h4>
                        <p>Something somethin</p>
                        <p>60 phút trước</p>
                    </div>
                </li>

                <li>
                    <hr className='dropdown-divider' />
                </li>

                <li className='notifications-item'>
                    <i className='bi bi-x-circle text-danger'></i>
                    <div>
                        <h4>Thông báo 3</h4>
                        <p>Something somethin</p>
                        <p>90 phút trước</p>
                    </div>
                </li>

                <li>
                    <hr className='dropdown-divider' />
                </li>


                <li className='notifications-item'>
                    <i className='bi bi-info-circle text-info'></i>
                    <div>
                        <h4>Thông báo 4</h4>
                        <p>Something somethin</p>
                        <p>90 phút trước</p>
                    </div>
                </li>

                <li>
                    <hr className='dropdown-divider' />
                </li>

                <li className='dropdown-footer'>
                    <a href='#'>Show all notifications</a>
                </li>

            </ul>

        </li>
    )

}

export default NavNotice;