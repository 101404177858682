import { Typography } from '@mui/material';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { CustomSelectWithAdd, CustomSelectWithAddProps } from '@/components/dashboard/customSelectWithAdd/customSelectWithAdd';

const FormThings = ({
  formFields, 
  vehicles,
  vehicleId,
  setVehicleId,
  drivers,
  driverId,
  setDriverId,
  fuelTypes,
  addFuelType,
  editFuelType,
  deleteFuelType,
  fuelTypeId,
  setFuelTypeId,  
  fuelFromType,
  fuelFromId,
  setFuelFromId,
  fuelSuppliers,
  fuelTanks,
} : {
  formFields: FormField[];
  vehicles: any[];
  vehicleId: number | null;
  setVehicleId: (id: number) => void;
  drivers: any[];
  driverId: number | null;
  setDriverId: (id: number) => void;
  fuelTypes: any[]; 
  fuelTypeId: number | null;
  setFuelTypeId: (id: number) => void;  
  addFuelType: (item: string) => void;
  editFuelType: (id: number, newName: string) => void;
  deleteFuelType: (id: number) => void;
  fuelFromType: string | undefined;
  fuelFromId: number | null;
  setFuelFromId: (id: number) => void;  
  fuelSuppliers: any[];
  fuelTanks: any[];
}) => {

  const selectedVehicle = vehicles.find((vehicle) => vehicle.id === vehicleId);

  return (
    <form>
       {formFields.map((field, index) => (
          <div key={index}>
            {/* Render form fields */}
            <FormFields fields={[field]} />

            {index === 0 && (
              <CustomSelectWithAdd
                label="Phương tiện *"
                items={vehicles}
                value={vehicleId || 0}
                onChange={setVehicleId}
                manageItem="/dashboard/vehicles"
              />
            )}

            {/* Helper text for the selected vehicle */}
            {index === 0 && selectedVehicle && (
              <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
                Odo chính của xe: {selectedVehicle.primaryMeter} {selectedVehicle.primaryMeterType}
              </Typography>
            )}

            {index === 1 && ( 
              <CustomSelectWithAdd
                label="Tài xế"
                items={drivers}
                value={driverId || 0}
                onChange={setDriverId}
                manageItem="/dashboard/employees"
              />
            )}

            { index === 2 && fuelFromType && fuelFromType !== 'None' && (
              <CustomSelectWithAdd
                label={fuelFromType === 'Vendor' ? 'Chọn nhà cung cấp nhiên liệu' : fuelFromType === 'FuelTank' ? 'Chọn bồn chứa xăng' : 'Nguồn nhiên liệu'} 
                items={fuelFromType === 'Vendor' ? fuelSuppliers : fuelFromType === 'FuelTank' ? fuelTanks : []} 
                value={fuelFromId || 0}
                onChange={setFuelFromId}     
                manageItem={fuelFromType === 'Vendor' ? '/dashboard/Suppliers' : ''} // Dynamically change manage link
              />
            )}

            {index === 2 && (
              <CustomSelectWithAdd
                label='Loại nhiên liệu'
                items={fuelTypes}
                value={fuelTypeId || 0}
                onChange={setFuelTypeId }
                addItem={addFuelType}
                editItem={editFuelType}
                deleteItem={deleteFuelType}
              />
            )}


            




            </div>
        ))}
    </form>

  );

}

export default FormThings;
