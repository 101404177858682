import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { Chip, Button } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Download as DownloadIcon } from '@phosphor-icons/react';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { Upload as UploadIcon } from '@phosphor-icons/react';
import { GenericFilters } from '@/components/dashboard/filter/genericFilter';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1';
import axios, { AxiosResponse } from 'axios';
import {DynamicDialog } from '@/components/subPlace/statusDialog';
import './maintainanceWorkOder.css'; // Import the CSS file  
import { ServiceTasksDialog } from './maintainanceSetupServiceTasksDialog';
import {  ServiceSchedule, ServiceTasks, ServicePrograms } from '@/components/subPlace/subEntriesTypeMaintainance';
import { ServiceProgramsDialog } from '@/components/maintainance/MaintainanceSetupServiceProgramDialog';
import { timeIntervalUnit }  from '@/hooks/mapping'; // Import the function
import { Refresh } from '@mui/icons-material';
import { functionMapping } from '@/hooks/mapping';
import dayjs, { Dayjs } from 'dayjs';

export interface WorkOrderStatus {
    id: string;
    name: string;
    description: string;
    color: string;
    function: string;
    createdAt: Date;
    updateAt: Date;
}

export interface ServiceEntriesStatus{
    id: string;
    name: string;
    description: string;
    color: string;
    function: string;
    createdAt: Date;    
    updateAt: Date;
}

export interface BillableServiceEntriesStatus{
    id: string;
    name: string;
    color: string;
    function: string;
    createdAt: Date;    
    updateAt: Date;
}

function applyPagination<T>(rows: T[], page: number, rowsPerPage: number): T[] {
    return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export default function MaintainanceSetupPage(): React.JSX.Element {
    const [serviceTasks, setServiceTasks] = React.useState<ServiceTasks[]>([]);
    const [servicePrograms, setServicePrograms] = React.useState<ServicePrograms[]>([]);
    const [workOrderStatus, setWorkOrderStatus] = React.useState<WorkOrderStatus[]>([]);
    const [serviceEntriesStatus, setServiceEntriesStatus] = React.useState<ServiceEntriesStatus[]>([]);
    const [billableServiceEntriesStatus, setBillableServiceEntriesStatus] = React.useState<BillableServiceEntriesStatus[]>([]);

    // Pagination and filtered data states for each table
    const [filteredData, setFilteredData] = React.useState({
        serviceTasks: [] as ServiceTasks[],
        servicePrograms: [] as ServicePrograms[],
        workOrderStatus: [] as WorkOrderStatus[],
        serviceEntriesStatus: [] as ServiceEntriesStatus[],
        billableServiceEntriesStatus: [] as BillableServiceEntriesStatus[]
    });

    type TableKey = "serviceTasks" | "servicePrograms" | "workOrderStatus" | "serviceEntriesStatus" | "billableServiceEntriesStatus";
    
    const paginationStatesInitial: Record<TableKey, { page: number; rowsPerPage: number }> = {
        serviceTasks: { page: 0, rowsPerPage: 5 },
        workOrderStatus: { page: 0, rowsPerPage: 5 },
        serviceEntriesStatus: { page: 0, rowsPerPage: 5 },
        billableServiceEntriesStatus: { page: 0, rowsPerPage: 5 },
        servicePrograms: { page: 0, rowsPerPage: 5 },
    };
    
    const [paginationStates, setPaginationStates] = React.useState(paginationStatesInitial);
    
    const tableLabels = [
        { key: 'serviceTasks', label: 'Công việc' },
        { key: 'workOrderStatus', label: 'Trạng thái việc cần làm' },
        { key: 'serviceEntriesStatus', label: 'Trạng thái nhiệm vụ' },
        { key: 'billableServiceEntriesStatus', label: 'Tình trạng thanh toán' },
        { key: 'servicePrograms', label: 'Gói công việc' },
    ];
    
    const [visibleTables, setVisibleTables] = React.useState({
        serviceTasks: true,
        workOrderStatus: true,
        serviceEntriesStatus: true,
        billableServiceEntriesStatus: true, 
        servicePrograms: true,
    });

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const [serviceTasksRes, serviceProgramsRes, workOrderStatusRes, serviceEntriesStatusRes, billableServiceEntriesStatusRes] = await Promise.all([
                    axios.get('/api/service-tasks'),
                    axios.get('/api/service-programs'),
                    axios.get('/api/work-order-statuses'),
                    axios.get('/api/service-entries-statuses'),
                    axios.get('/api/billable-statuses'),
                ]);

                setServiceTasks(serviceTasksRes.data);
                setFilteredData(prev => ({ ...prev, serviceTasks: serviceTasksRes.data }));

                setServicePrograms(serviceProgramsRes.data);
                setFilteredData(prev => ({ ...prev, servicePrograms: serviceProgramsRes.data }));

                setWorkOrderStatus(workOrderStatusRes.data);
                setFilteredData(prev => ({ ...prev, workOrderStatus: workOrderStatusRes.data }));

                setServiceEntriesStatus(serviceEntriesStatusRes.data);
                setFilteredData(prev => ({ ...prev, serviceEntriesStatus: serviceEntriesStatusRes.data }));

                setBillableServiceEntriesStatus(billableServiceEntriesStatusRes.data);
                setFilteredData(prev => ({ ...prev, billableServiceEntriesStatus: billableServiceEntriesStatusRes.data }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const handleFilterChange = (table: string, column: string, searchTerm: string | boolean | Dayjs | null) => {
        const tableConfig = tablesConfig.find((t) => t.key === table);
      
        if (!tableConfig) {
          console.warn(`Table configuration for "${table}" not found.`);
          return;
        }
      
        const { data, setFilteredData } = tableConfig;
      
        if (searchTerm === null || searchTerm === '') {
          // Reset to original data if searchTerm is null or empty
          setFilteredData(data);
          return;
        }
      
        const filtered = data.filter((row) => {
          const value = row[column as keyof typeof row];
          
          if (typeof value === 'string' && typeof searchTerm === 'string') {
            return value.toLowerCase().includes(searchTerm.toLowerCase());
          }
      
          if (typeof value === 'boolean' && typeof searchTerm === 'boolean') {
            return value === searchTerm; // Match boolean values exactly
          }
      
          return false; // Default to false if value type does not match the expected types
        });
      
        setFilteredData(filtered);
      };
      

    const handlePageChange = (tableKey: keyof typeof paginationStates, newPage: number) => {
        setPaginationStates(prev => ({
            ...prev,
            [tableKey]: { ...prev[tableKey], page: newPage }
        }));
    };

    const handleRowsPerPageChange = (tableKey: keyof typeof paginationStates, newRowsPerPage: number) => {
        setPaginationStates(prev => ({
            ...prev,
            [tableKey]: { page: 0, rowsPerPage: newRowsPerPage }
        }));
    };

    const handleToggleTable = (tableKey: keyof typeof visibleTables) => {
        setVisibleTables(prev => ({ ...prev, [tableKey]: !prev[tableKey] }));
    };

    const tablesConfig: Array<{
        key: TableKey;
        title: string;
        data: any[];
        filteredData: any[];
        setFilteredData: (data: any[]) => void;
        visible: boolean;
        filterColumns: { value: string; label: string }[];
        tableColumns: TableColumn<any>[];
    }> = [
        { 
            key: 'serviceTasks', 
        title: 'Công việc', 
        data: serviceTasks, 
        filteredData: filteredData.serviceTasks, 
        setFilteredData: (data: ServiceTasks[]) => setFilteredData(prev => ({ ...prev, serviceTasks: data })), 
        visible: visibleTables.serviceTasks,
        filterColumns: [
            { value: 'name', label: 'Tên' },
            { value: 'description', label: 'Mô tả' }
        ],
        tableColumns: [
            { label: 'Tên', field: 'name' },
            { label: 'Mô tả', field: 'description' },
        ] as TableColumn<ServiceTasks>[]
        },
        {
            key: 'workOrderStatus',
            title: 'Trạng thái việc cần làm ',
            data: workOrderStatus,  
            filteredData: filteredData.workOrderStatus,
            setFilteredData: (data: WorkOrderStatus[]) => setFilteredData(prev => ({ ...prev, workOrderStatus: data })),
            visible: visibleTables.workOrderStatus,
            filterColumns: [
                { value: 'name', label: 'Tên' },
                { value: 'description', label: 'Mô tả' }
            ],
            tableColumns: [
                { label: 'Tên', field: 'name' },
                { label: 'Mô tả', field: 'description' },
                {
                    label: 'Màu',
                    field: (row) => (
                        <Chip
                            style={{
                                backgroundColor: row.color || '#ccc', // Use the color from the row
                                color: '#fff',                         // White text for contrast
                                borderRadius: '5px',
                                padding: '5px 10px',
                                fontWeight: 'bold',
                            }}
                        />
                    ),
                },
                { 
                    label: 'Chức năng',
                    field: 'function',
                    customRender: (row) => functionMapping(row.function || ''),
                }

            ] as TableColumn<WorkOrderStatus>[]
        },
        {
            key: 'serviceEntriesStatus',
            title: 'Trạng thái nhiệm vụ',
            data: serviceEntriesStatus,
            filteredData: filteredData.serviceEntriesStatus,
            setFilteredData: (data: ServiceEntriesStatus[]) => setFilteredData(prev => ({ ...prev, serviceEntriesStatus: data })),
            visible: visibleTables.serviceEntriesStatus,
            filterColumns: [
                { value: 'name', label: 'Tên' },
                { value: 'description', label: 'Mô tả' }
            ],
            tableColumns: [
                { label: 'Tên', field: 'name' },
                { label: 'Mô tả', field: 'description' },
                {
                    label: 'Màu',
                    field: (row) => (
                        <Chip
                            style={{
                                backgroundColor: row.color || '#ccc', // Use the color from the row
                                color: '#fff',                         // White text for contrast
                                borderRadius: '5px',
                                padding: '5px 10px',
                                fontWeight: 'bold',
                            }}
                        />
                    ),
                },
                { 
                    label: 'Chức năng',
                    field: 'function',
                    customRender: (row) => functionMapping(row.function || ''),
                }

            ] as TableColumn<ServiceEntriesStatus>[]
        },
        {
            key: 'billableServiceEntriesStatus',
            title: 'Tình trạng thanh toán',
            data: billableServiceEntriesStatus,
            filteredData: filteredData.billableServiceEntriesStatus,
            setFilteredData: (data: BillableServiceEntriesStatus[]) => setFilteredData(prev => ({ ...prev, billableServiceEntriesStatus: data })),  
            visible: visibleTables.billableServiceEntriesStatus,
            filterColumns: [
                { value: 'name', label: 'Tên' }
            ],
            tableColumns: [
                { label: 'Name', field: 'name' },
                { label: 'Mô tả', field: 'description' },
                {
                    label: 'Màu',
                    field: (row) => (
                        <Chip
                            style={{
                                backgroundColor: row.color || '#ccc', // Use the color from the row
                                color: '#fff',                         // White text for contrast
                                borderRadius: '5px',
                                padding: '5px 10px',
                                fontWeight: 'bold',
                            }}
                        />
                    ),
                },
                { 
                    label: 'Chức năng',
                    field: 'function',
                    customRender: (row) => functionMapping(row.function || ''),
                }
            ] as TableColumn<BillableServiceEntriesStatus>[]
        }, 
        {
            key: 'servicePrograms',
            title: 'Gói công việc',
            data: servicePrograms,
            filteredData: filteredData.servicePrograms,
            setFilteredData: (data: ServicePrograms[]) =>
              setFilteredData((prev) => ({ ...prev, servicePrograms: data })),
            visible: visibleTables.servicePrograms,
            filterColumns: [
              { value: 'name', label: 'Tên' },
              { value: 'description', label: 'Mô tả' },
            ],
            tableColumns: [
              { label: 'Tên', field: 'name' },
              { label: 'Mô tả', field: 'description' },
              { label: 'Khoảng đo chính', field: 'primaryMeterType', customRender: (row) => timeIntervalUnit(row.primaryMeterType || '') },
              { label: 'Khoảng đo phụ', field: 'secondaryMeterType', customRender: (row) => timeIntervalUnit(row.secondaryMeterType || '') },
            ] as TableColumn<ServicePrograms>[],
          },
    ];   

    const visibleTablesCount = tablesConfig.filter(({ visible }) => visible).length;

    const dialogConfig = {
        // doing like this to be able to custom later i guess
        workOrderStatus: {
            title: 'Thêm/Chỉnh sửa trạng thái việc cần làm',
            fields: [
                { name: 'name', label: 'Tên', type: 'text' },
                { name: 'description', label: 'Mô tả', type: 'text' },
                { name: 'color', label: 'Màu', type: 'color' }
            ]
        },
        serviceEntriesStatus: {
            title: 'Thêm/Chỉnh sửa trạng thái nhiệm vụ',
            fields: [
                { name: 'name', label: 'Tên', type: 'text' },  
                { name: 'description', label: 'Mô tả', type: 'text' },
                { name: 'color', label: 'Màu', type: 'color' }
            ]
        }, 

        billableServiceEntriesStatus: {
            title: 'Thêm/Chỉnh sửa tình trạng thanh toán',
            fields: [
                { name: 'name', label: 'Tên', type: 'text' },  
                { name: 'description', label: 'Mô tả', type: 'text' },
                { name: 'color', label: 'Màu', type: 'color' }
            ]
        }, 
    }

    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedTable, setSelectedTable] = React.useState<keyof typeof dialogConfig | 'serviceTasks' | 'servicePrograms' | null>(null);
    const [selectedData, setSelectedData] = React.useState<any>(null);
    
    const handleEditDialog = (tableKey: keyof typeof dialogConfig | 'serviceTasks' | 'servicePrograms', rowData: any) => {
        setSelectedTable(tableKey);
        setSelectedData(rowData);
        setOpenDialog(true);
    };

    const handleOpenDialog = (tableKey: keyof typeof dialogConfig | 'serviceTasks' | 'servicePrograms') => {
        setSelectedTable(tableKey);
        setSelectedData(null);
        setOpenDialog(true);
    }

    const mappingForStatusesTabl = {
        workOrderStatus: 'work-order-statuses',
        serviceEntriesStatus: 'service-entries-statuses',
        billableServiceEntriesStatus: 'billable-statuses',
        serviceTasks: 'service-tasks',
        servicePrograms: 'service-programs',
    };

    // a custom function to set the data following the selected table
    const handleSetData = (tableKey: keyof typeof dialogConfig , data: any) => { 
        if (tableKey === 'workOrderStatus') {
            setWorkOrderStatus(data);
        } else if (tableKey === 'serviceEntriesStatus') {
            setServiceEntriesStatus(data);
        } else if (tableKey === 'billableServiceEntriesStatus') {
            setBillableServiceEntriesStatus(data);
        }
    }
    
    const handleAddOrEditStatuses = async (updatedData: any) => {
        try {
            const formData = new FormData();

            if (selectedTable === 'workOrderStatus' || selectedTable === 'serviceEntriesStatus' || selectedTable === 'billableServiceEntriesStatus') {
                formData.append('name', updatedData.name);
                formData.append('description', updatedData.description);
                formData.append('color', updatedData.color);
            } else {
                return alert('Bảng không hợp lệ');
            }

            const apiRoute = `/api/${mappingForStatusesTabl[selectedTable]}`; 

            // check if it is an edit or add
            if (selectedData) {
                // Edit
                const response = await axios.put(`${apiRoute}/${selectedData.id}`, formData);
                const updatedData = response.data;   
                const updatedDataIndex = filteredData[selectedTable].findIndex((item) => item.id === updatedData.id);
                const updatedDataArray = [...filteredData[selectedTable]];
                updatedDataArray[updatedDataIndex] = updatedData;
                setFilteredData(prev => ({ ...prev, [selectedTable]: updatedDataArray }));
                handleSetData(selectedTable, updatedDataArray);
                setSelectedData(null);
            } else {
                // Add
                const response = await axios.post(apiRoute, formData);
                const newData = response.data;
                setFilteredData(prev => ({ ...prev, [selectedTable]: [...prev[selectedTable], newData] }));
                handleSetData(selectedTable, [...filteredData[selectedTable], newData]);
                setSelectedData(null);  
            }
            setOpenDialog(false);
        } catch (error: any) {
            console.error('Lỗi thêm/cập nhập trạng thái :', error); 
            if (error.response && error.response.status === 409) {
                alert('Tên trạng thái đã tồn tại, vui lòng chọn tên khác');
              } else {
                alert('Lỗi thêm/cập nhập dữ liệu. Hãy kiểm tra các ô lại.');
            }  
        }

    };

    const handleAddOrEditServiceTasks = async (updatedData: any) => {
        try {
            let response: AxiosResponse<ServiceTasks>;

            if (selectedData) {
                response = await axios.put(`/api/service-tasks/${selectedData.id}`, updatedData);
                setFilteredData(prev => ({ ...prev, serviceTasks: prev.serviceTasks.map((item) => (item.id === response.data.id ? response.data : item)) }));
                setServiceTasks(prev => prev.map((item) => (item.id === response.data.id ? response.data : item)));
                setSelectedData(null);
            } else {
                response = await axios.post('/api/service-tasks', updatedData);
                setFilteredData(prev => ({ ...prev, serviceTasks: [...prev.serviceTasks, response.data] }));
                setServiceTasks(prev => [...prev, response.data]);
                setSelectedData(null);
            }
            setOpenDialog(false);
            
        } catch (error: any) {
            console.error('Lỗi thêm/cập nhập công việc:', error);
            if (error.response && error.response.status === 409) {
                alert('Tên công việc đã tồn tại, vui lòng chọn tên khác');
              } else {
                alert('Lỗi thêm/cập nhập dữ liệu. Hãy kiểm tra các ô lại.');
            }  
        }
    };

    const handleAddOrEditServicePrograms = async (updatedData: any, existingServiceSchedule: ServiceSchedule[]) => {
        try {
          let response: AxiosResponse<ServicePrograms>;
      
          if (updatedData.id) {
            // Update existing ServiceProgram
            response = await axios.post(`/api/service-programs`, {
              id: updatedData.id,
              name: updatedData.name,
              description: updatedData.description,
              primaryMeterType: updatedData.primaryMeterType,
              secondaryMeter_flg: updatedData.secondaryMeter_flg,
              secondaryMeterType: updatedData.secondaryMeterType,
            });
      
            const serviceProgramId = response.data.id;
      
            // Get existing schedule IDs
            const existingIds = existingServiceSchedule.map((schedule) => schedule.id);
      
            if (updatedData.serviceSchedules && updatedData.serviceSchedules.length > 0) {
              for (const schedule of updatedData.serviceSchedules) {
                if (schedule.id !== `0` && existingIds.includes(schedule.id)) {
                  // Update existing schedule
                  await axios.put(`/api/service-programs/${serviceProgramId}/service-schedules/${schedule.id}`, {
                    serviceTaskIds: schedule.serviceTaskIds,
                    primaryMeterInterval: schedule.primaryMeterInterval,
                    secondaryMeterInterval: schedule.secondaryMeterInterval,
                    timeInterval: schedule.timeInterval,
                    timeIntervalUnit: schedule.timeIntervalUnit,
                  });
                } else {
                  // Create new schedule
                  await axios.post(`/api/service-programs/${serviceProgramId}/service-schedules`, {
                    serviceTaskIds: schedule.serviceTaskIds,
                    primaryMeterInterval: schedule.primaryMeterInterval,
                    secondaryMeterInterval: schedule.secondaryMeterInterval,
                    timeInterval: schedule.timeInterval,
                    timeIntervalUnit: schedule.timeIntervalUnit,
                  });
                }
              }
      
              // Identify schedules to delete
              const updatedIds: string[] = updatedData.serviceSchedules.map((schedule: ServiceSchedule) => schedule.id);
              const schedulesToDelete = existingIds.filter((id) => !updatedIds.includes(id));
      
              if (schedulesToDelete.length > 0) {
                for (const scheduleId of schedulesToDelete) {
                  await axios.delete(`/api/service-programs/${serviceProgramId}/service-schedules/${scheduleId}`);
                }
              }
            } else {
              // If no schedules are left, delete all existing schedules
              for (const schedule of existingServiceSchedule) {
                await axios.delete(`/api/service-programs/${serviceProgramId}/service-schedules/${schedule.id}`);
              }
            }
          } else {
            // Create new ServiceProgram
            response = await axios.post(`/api/service-programs`, {
              name: updatedData.name,
              description: updatedData.description,
              primaryMeterType: updatedData.primaryMeterType,
              secondaryMeter_flg: updatedData.secondaryMeter_flg,
              secondaryMeterType: updatedData.secondaryMeterType,
            });
      
            const serviceProgramId = response.data.id;
      
            // Create associated schedules
            if (updatedData.serviceSchedules && updatedData.serviceSchedules.length > 0) {
              for (const schedule of updatedData.serviceSchedules) {
                await axios.post(`/api/service-programs/${serviceProgramId}/service-schedules`, {
                  serviceTaskIds: schedule.serviceTaskIds,
                  primaryMeterInterval: schedule.primaryMeterInterval,
                  secondaryMeterInterval: schedule.secondaryMeterInterval,
                  timeInterval: schedule.timeInterval,
                  timeIntervalUnit: schedule.timeIntervalUnit,
                });
              }
            }
          }
      
          // Update the state and data in the table
          const updatedPrograms = servicePrograms.map((program) =>
            program.id === response.data.id ? response.data : program
          );
      
          setServicePrograms(updatedPrograms);
          setFilteredData((prev) => ({ ...prev, servicePrograms: updatedPrograms }));
          setSelectedData(null);    
      
          setOpenDialog(false);
        } catch (error) {
          alert('Lỗi thêm/cập nhật chương trình dịch vụ. Hãy kiểm tra các ô lại.');
          console.error('Lỗi thêm/cập nhật chương trình dịch vụ:', error);
        }
    };

    const handleDeleteDefault = async (tableKey: keyof typeof dialogConfig, id: string) => {
        const confirmed = window.confirm('Bạn có chắc chắn muốn xoá mục này?'); 

        if (confirmed) {
            try {
                const apiRoute = `/api/${mappingForStatusesTabl[tableKey]}`;

                await axios.delete(`${apiRoute}/${id}`); // Send delete request to the API
                
                // Remove the deleted item from the state
                setFilteredData((prev) => ({
                    ...prev,
                    [tableKey]: prev[tableKey].filter((item) => item.id !== id),
                }));
                
                // Update the specific state as well (optional if maintaining separate state variables)
                handleSetData(tableKey, filteredData[tableKey].filter((item) => item.id !== id));
                
                alert('Xóa thành công! Tải lại trang để cập nhật dữ liệu mới.');

                window.location.reload();
            } catch (error) {
                alert('Lỗi khi xoá mục. Hãy thử lại sau.');
                console.error('Lỗi khi xoá mục:', error);
            }
        }
    };

    const handleDeleteDefaultSelected = async (tableKey: keyof typeof dialogConfig,  selectedIds: string[]) => {
        const confirmed = window.confirm('Bạn có chắc chắn muốn xoá các mục đã chọn?');

        if (confirmed) {
            try {
                await axios.delete(`/api/${mappingForStatusesTabl[tableKey]}/${JSON.stringify(selectedIds)}`);

                alert ('Xóa thành công!');
                setFilteredData((prev) => ({
                    ...prev,
                    [tableKey]: prev[tableKey].filter((item) => !selectedIds.includes(item.id)),
                }));

                handleSetData(tableKey, filteredData[tableKey].filter((item) => !selectedIds.includes(item.id)));

                window.location.reload();
            } catch (error) {
                alert('Lỗi khi xoá mục. Hãy thử lại sau.');
                console.error('Lỗi khi xoá mục:', error);
            }
        }
    }

    return (
        <Stack spacing={4}>
          <Typography variant="h4">Thiết lập các dữ liệu phụ</Typography>
      
          <div className="table-visibility-controls">
            <Typography variant="h5">Các bảng</Typography>
            {tableLabels.map(({ key, label }) => (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    checked={visibleTables[key as keyof typeof visibleTables]}
                    onChange={() => handleToggleTable(key as keyof typeof visibleTables)}
                  />
                }
                label={label} // Use custom label
              />
            ))}
          </div>
          
          <div className="row">
            {tablesConfig.map(({ key, title, data, filteredData, setFilteredData, visible, filterColumns, tableColumns }, index) =>
                visible ? (
                <div
                    key={title}
                    className={`mb-5 ${
                    visibleTablesCount === 2 ? 'col-md-12' : index === tablesConfig.length - 1 ? 'col-md-12' : 'col-md-6'
                    }`} // Use col-md-12 if only two tables are visible
                >
                    <Stack spacing={3}>
                        <Stack direction="row" spacing={3}>
                            <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                            <Typography variant="h5">{title}</Typography>
                            </Stack>
                            <div>
                            <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={() => handleOpenDialog(key)}>
                                Thêm
                            </Button>
                            </div>
                        </Stack>
                        <GenericFilters
                        columns={filterColumns}
                        onFilterChange={(col, term) => handleFilterChange(key, col, term)}
                        />
                        <GenericTable
                        count={filteredData.length}
                        page={paginationStates[key].page} // Use the specific table's pagination state
                        rows={applyPagination(filteredData, paginationStates[key].page, paginationStates[key].rowsPerPage)}
                        columns={tableColumns as unknown as TableColumn<{ id: string }>[]}
                        rowsPerPage={paginationStates[key].rowsPerPage} // Specific table's rowsPerPage
                        onPageChange={(newPage) => handlePageChange(key, newPage)} // Pass `key` to identify table
                        onRowsPerPageChange={(newRows) => handleRowsPerPageChange(key, newRows)} // Pass `key` for rows per page change
                        onEdit={(id) => {
                            const rowData = data.find((item) => item.id === id); // Find the row data by id
                            handleEditDialog(key as keyof typeof dialogConfig, rowData);
                        }}
                        // Set onDelete explicitly to undefined for statuses
                        onDelete={
                            key === 'workOrderStatus' || key === 'serviceEntriesStatus' || key === 'billableServiceEntriesStatus'
                            ? undefined
                            : (id) => handleDeleteDefault(key as keyof typeof dialogConfig, id)
                        }
                        onDeleteSelected={(ids) => handleDeleteDefaultSelected(key as keyof typeof dialogConfig, ids)} // Bulk delete
                        />
                    </Stack>
                </div>
                ) : null
            )}
          </div>


         {/* Conditionally Render the Appropriate Dialog */}
        {selectedTable === 'serviceTasks' && (
            <ServiceTasksDialog
            open={openDialog}
            data={selectedData}
            onClose={() => setOpenDialog(false)}
            onSubmit={handleAddOrEditServiceTasks}
            />
        )}

        {selectedTable === 'servicePrograms' && (
            // servicePrograms dialog
            <ServiceProgramsDialog 
                open={openDialog}
                data={selectedData}
                onClose={() => setOpenDialog(false)}
                onSubmit={(updatedData, existingServiceSchedule) => {
                    handleAddOrEditServicePrograms(updatedData, existingServiceSchedule);
                  }}           
                   />
        )}

        {selectedTable && selectedTable in dialogConfig && (
        <DynamicDialog
            open={openDialog}
            title={dialogConfig[selectedTable as keyof typeof dialogConfig].title}
            fields={dialogConfig[selectedTable as keyof typeof dialogConfig].fields}
            data={selectedData}
            onClose={() => setOpenDialog(false)}
            onSubmit={handleAddOrEditStatuses}
        />
        )}


        </Stack>
    );

};

// This ensures that this file is treated as a module and resolves the TS1208 error
export { };
