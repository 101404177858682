import React from 'react';
import {
  Box, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Typography, MenuItem,
  Select, InputLabel, FormControl, SelectChangeEvent
} from '@mui/material';

export interface DialogField {
  id: number;
  label: string;
  name: string;
  type: string;
  required: boolean;
  canDelete?: boolean;
}

interface FieldManagementDialogProps {
  open: boolean;
  onClose: () => void;
  fields: DialogField[];
  onFieldSubmit: (fields: DialogField[]) => void;
  addFieldToDatabase: (field: DialogField) => Promise<DialogField>;
  updateFieldInDatabase: (field: DialogField) => Promise<DialogField>;
  deleteFieldFromDatabase: (id: number) => Promise<void>;
}

export const FieldManagementDialog: React.FC<FieldManagementDialogProps> = ({
  open, onClose, fields, onFieldSubmit, addFieldToDatabase, updateFieldInDatabase, deleteFieldFromDatabase
}) => {
  const [localFields, setLocalFields] = React.useState<DialogField[]>(fields);

  const isFirstOpen = React.useRef(true);

  // Utility function to convert text to English-only characters and remove spaces
  const toEnglishCharacters = (text: string) => {
    return text
      .normalize("NFD") // Normalize accents/diacritics
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
      .replace(/[^a-zA-Z0-9]/g, "") // Keep only alphanumeric characters
      .replace(/\s+/g, ""); // Remove spaces
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { name, value } = e.target;
    const updatedFields = [...localFields];
    updatedFields[index] = { ...updatedFields[index], [name]: value };

    // If updating the 'label' field, auto-generate the name
    if (name === 'label') {
      updatedFields[index].name = toEnglishCharacters(value);
    }

    setLocalFields(updatedFields);
  };

  const handleFieldTypeChange = (e: SelectChangeEvent<string>, index: number) => {
    const updatedFields = [...localFields];
    updatedFields[index] = { ...updatedFields[index], type: e.target.value as string };
    setLocalFields(updatedFields);
  };

  const handleDeleteField = async (index: number) => {
    const fieldToDelete = localFields[index];
    try {
      if (fieldToDelete.id) {
        await deleteFieldFromDatabase(fieldToDelete.id);
      }
      const updatedFields = localFields.filter((_, i) => i !== index);
      setLocalFields(updatedFields);
    } catch (error) {
      console.error('Error deleting field:', error);
    }
  };

  const addField = () => {
    const newField: DialogField = {
      id: 0,
      label: '',
      name: '',
      type: 'text',
      required: false,
      canDelete: true
    };
    setLocalFields([...localFields, newField]);
  };

  const handleSubmit = async () => {
    // Validate each field to ensure both `label` and `name` are present
    const validationErrors = localFields.some((field) => !field.label.trim() || !field.name.trim());
  
    if (validationErrors) {
      alert("Vui lòng nhập nhãn tên.");
      return;
    }
  
    try {
      const savedFields = await Promise.all(
        localFields.map(async (field) => {
          if (!fields.some((f) => f.id === field.id)) {
            return await addFieldToDatabase(field);
          } else if (fields.some((f) => f.id === field.id) && field.canDelete) {
            return await updateFieldInDatabase(field);
          }
          return field;
        })
      );
  
      onFieldSubmit(savedFields);

    } catch (error) {
      console.error("Error submitting fields:", error);
    }
    onClose();

  };
  

  // Scroll to the bottom of the correct dialog when it opens and after fields are populated
  React.useEffect(() => {
    if (open && !isFirstOpen.current && localFields.length > 0) {
      isFirstOpen.current = true;
      setTimeout(() => {
        const content = document.getElementById('fieldManageDialogContent');
        if (content) {
          content.scrollTo({ top: content.scrollHeight, behavior: 'smooth' });
        }
      }, 0);
    } else if (!open) {
      isFirstOpen.current = false;
    }
  }, [open]);
  
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Quản lý trường nhập</DialogTitle>
      <DialogContent id="fieldManageDialogContent">
        {localFields.map((field, index) => (
          <Box key={field.id} mb={2} id={`field-${index}`}>
            <Typography variant="h6" gutterBottom>
              Trường nhập {index + 1}
              {field.canDelete && (
                <Button
                  onClick={() => handleDeleteField(index)}
                  color="error"
                  size="small"
                  style={{ marginLeft: '10px' }}
                >
                  Xóa trường
                </Button>
              )}
            </Typography>

            <TextField
              label="Nhãn tên *"
              name="label"
              value={field.label}
              onChange={(e) => handleFieldChange(e, index)}
              fullWidth
              margin="dense"
              disabled={!field.canDelete}

            />
            <TextField
              label="Tên data (Tự động tạo)"
              name="name"
              value={field.name}
              fullWidth
              margin="dense"
              disabled
            />

            <FormControl fullWidth margin="dense" variant="outlined">
              <InputLabel shrink={true} htmlFor={`select-type-${index}`}>Type</InputLabel>
              <Select
                label="Type"
                value={field.type}
                onChange={(e) => handleFieldTypeChange(e, index)}
                disabled={!field.canDelete}
                fullWidth
                inputProps={{ id: `select-type-${index}` }}
              >
                <MenuItem value="text">Trường chữ</MenuItem>
                <MenuItem value="checkbox">Ô check</MenuItem>
                <MenuItem value="number">Trường số</MenuItem>
                <MenuItem value="date">Ngày</MenuItem>
              </Select>
            </FormControl>
          </Box>
        ))}

        <Button onClick={addField} variant="contained" color="primary">
          Thêm trường nhập
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Đóng</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Lưu
        </Button>
      </DialogActions>
    </Dialog>
  );
};
