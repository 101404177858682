import React, { useState } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl,
  Typography, MenuItem, Select, TextField, IconButton, ListItemText, Box, Pagination, InputAdornment, Tooltip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

interface Item {
  id: number;
  name: string;
  color?: string; // Optional color for display
  vehicleNumber?: string;
  description?: string;
}

export interface CustomSelectWithAddProps {
  label: string;
  items: Item[];
  value: number;
  onChange: (value: number) => void;
  addItem?: (item: string, description?: string) => void; // Accept optional description
  editItem?: (id: number, newName: string, description?: string) => void; // Accept optional description
  deleteItem?: (id: number) => void;
  manageItem?: string;
  description_flg?: boolean; // Flag to enable/disable description field
  manageItemAction?: (id: number) => void;
  required?: boolean
}

export const CustomSelectWithAdd: React.FC<CustomSelectWithAddProps> = ({
  label, items, value, onChange, addItem, editItem, deleteItem, manageItem, description_flg = false, manageItemAction, required = false
}) => {
  const [open, setOpen] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);
  const [newItem, setNewItem] = useState('');
  const [newDescription, setNewDescription] = useState(''); // State for the new item's description
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editName, setEditName] = useState('');
  const [editDescription, setEditDescription] = useState(''); // State for editing item's description
  const [editId, setEditId] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 8;
  const filteredItems = items.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase())); 
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const currentItems = filteredItems.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  
  // Validation function
  const validateInput = (name: string, description?: string, description_flg?: boolean): string | null => {
    if (!name.trim()) {
      return "Tên không được để trống."; // Name cannot be empty
    }
    if (description_flg && (!description || !description.trim())) {
      return "Mô tả không được để trống."; // Description cannot be empty
    }
    return null; // Valid input
  };
 
  const handleAddNewItem = () => {
    const validationError = validateInput(newItem, newDescription, description_flg);
    if (validationError) {
      alert(validationError); // Display error to the user
      return;
    }
    if (addItem) {
      addItem(newItem, description_flg ? newDescription : undefined);
    }
    setNewItem('');
    setNewDescription('');
    setOpen(false);
  };

  const handleEdit = (id: number, name: string, description?: string) => {
    if (editItem) {
      setEditId(id);
      setEditName(name);
      setEditDescription(description || '');
      setEditDialogOpen(true);
    }
  };

  const handleEditSubmit = () => {
    const validationError = validateInput(editName, editDescription, description_flg);
    if (validationError) {
      alert(validationError); // Display error to the user
      return;
    }
    if (editId !== null && editItem) {
      editItem(editId, editName, description_flg ? editDescription : undefined);
    }
    setEditDialogOpen(false);
  };

  const handleDelete = (id: number) => {
    if (deleteItem) {
      deleteItem(id);
    }
  };

  const clearSelection = () => {
    onChange(0);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const renderItemLabel = (item: Item): string => {
    switch (true) {
      case !!item.vehicleNumber:
        return `${item.name} (${item.vehicleNumber})`;
      // Add more cases as needed
      // Example:
      // case item.someOtherProperty:
      //   return `${item.name} - ${item.someOtherProperty}`;
      default:
        return item.name;
    }
  };
  
  return (
    <FormControl fullWidth margin="normal" >
      <Typography variant="body1" sx={{ mb: 1 }}><strong>{label}</strong> {required && <span style={{color: "red"}}>*</span>} </Typography>
      <TextField
        fullWidth
        placeholder={`Tìm ${label}`}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <Box display="flex" alignItems="center">
      <Select
        open={selectOpen}
        onOpen={() => setSelectOpen(true)}
        onClose={() => setSelectOpen(false)}
        value={items.some(item => item.id === value) ? value : ''}
        onChange={(e) => {
          const selectedValue = e.target.value === 'add_new' ? null : Number(e.target.value);
          if (selectedValue !== null) {
            onChange(selectedValue);
            setSelectOpen(false);
          } else if (addItem) {
            setOpen(true);
          }
        }}
        displayEmpty
        fullWidth
        renderValue={(selected) => {
          if (!selected) {
            return "Chọn mục";
          }

          const selectedItem = items.find(item => item.id === selected);
          if (!selectedItem) {
            return "Chọn mục";
          }

          return (
            <Box display="flex" alignItems="center">
              {/* Show color circle if present */}
              {selectedItem.color && (
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    backgroundColor: selectedItem.color,
                    marginRight: 1,
                  }}
                />
              )}
              <Typography variant="body1" sx={{ mr: 1 }}>
              {renderItemLabel(selectedItem)}
              </Typography>
            </Box>
          );
        }}
      >

      {currentItems.map((item) => (
        <MenuItem key={`${label}-${item.id}`} value={item.id}>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Box display="flex" alignItems="center">
              {/* Show color if present */}
              {item.color && (
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    backgroundColor: item.color,
                    marginRight: 1,
                  }}
                />
              )}
              <ListItemText primary={renderItemLabel(item)} />
            </Box>
            <Box display="flex" alignItems="center">
              {editItem && (
                <Tooltip title="Chỉnh sửa">
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEdit(item.id, item.name, item.description);
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              {deleteItem && value !== item.id && (
                <Tooltip title="Xóa">
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(item.id);
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              {manageItemAction && (
                <Tooltip title="Coi chi tiết/quản lý">
                  <IconButton
                    aria-label="manage"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      manageItemAction(item.id);
                    }}
                  >
                    <MenuOpenIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        </MenuItem>
      ))}
          {addItem && (
            <MenuItem key={`${label}-add_new`} value="add_new">
              <strong>+ Thêm mục mới</strong>
            </MenuItem>
          )}
          {manageItem && (
            <MenuItem
              key={`${label}-manage`}
              onClick={() => window.open(manageItem, '_blank')}
            >
              <strong>  +- Quản lý các mục của {label}</strong>
            </MenuItem>
          )}
          <Box mt={2} display="flex" justifyContent="center">
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </Select>

        {value !== 0 && (
          <IconButton aria-label="clear" size="small" onClick={clearSelection} sx={{ marginLeft: 1 }}>
            <ClearIcon fontSize="small" />
          </IconButton>
        )}
      </Box>

      {/* Dialog for adding new item */}
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="add-dialog-title">
        <DialogTitle>Thêm dữ liệu cho {label}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={`Thêm  ${label}`}
            type="text"
            fullWidth
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
          />

        {description_flg && (
          <TextField
            margin="dense"
            label={`Thêm mô tả cho ${label}`}
            type="text"
            fullWidth
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
          />
        )}
          {/* optional to be show color */}

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">Hủy</Button>
          <Button onClick={handleAddNewItem} color="primary">Thêm</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for editing an existing item */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)} aria-labelledby="edit-dialog-title">
        <DialogTitle>Chỉnh sửa dữ liệu {label}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={`Chỉnh sửa tên cho ${label}`}
            type="text"
            fullWidth
            value={editName}
            onChange={(e) => setEditName(e.target.value)}
          />
          {description_flg && (
            <TextField
              margin="dense"
              label={`Chỉnh sửa mô tả cho ${label}`}
              type="text"
              fullWidth
              value={editDescription}
              onChange={(e) => setEditDescription(e.target.value)}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} color="primary">Hủy</Button>
          <Button onClick={handleEditSubmit} color="primary">Lưu</Button>
        </DialogActions>
      </Dialog>
    </FormControl>
  );
};
