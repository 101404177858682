import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Chip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { GenericFilters } from '@/components/dashboard/filter/genericFilter';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1'; 
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { InventoryDialog } from './inventoryDialog';
import { formatDate } from '@/hooks/format-date';
import { getContrastTextColor } from '@/hooks/contrast-text-color';
import { PurchaseOrderDialog } from './purchaseOrderDialog';
import { CustomDialog } from '../dashboard/dialog/Dialog';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { FormFields } from '../dashboard/formField/FormFields';

interface Part {
  id: string;
  manufacturerPartNumber: string;
  manufacturerUPC: string;
  partNumber: string;
  name: string; 
  description: string;
  availableQuantity: number;
}

interface Warehouse {
  id: string;
  name: string;
}

interface Aisle {
  id: string;
  name: string
}

interface Row {
  id: string;
  name: string;
}

interface Bin {
  id: string;
  name: string;
}

export interface InventoryLocation {
  id: string;
  codeNumber: string;
  partId: number;
  part: Part;
  availableStatus: string;
  availableQuantity: number;
  quantityLastVerify: Date | string;
  warehouseId: number;
  warehouse: Warehouse;
  aisleId: number;
  aisle: Aisle;
  rowId: number;
  row: Row;
  binId: number;
  bin: Bin;
  reminderOnLowStockFlg: boolean;
  reOrderPoint: number; 
  reOrderQuantity: number;
  leadTimeDays: number;



  customFields: Record<string, string>; 

  cratedAt: Date;
  updateAt: Date
}

function applyPagination(rows: InventoryLocation[], page: number, rowsPerPage: number): InventoryLocation[] {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export default function InventoryPage(): React.JSX.Element {  
  const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5); // Adjust number of rows per page as necessary
    const [data, setData] = React.useState<InventoryLocation[]>([]); // Inspection data state
    const [filteredData, setFilteredData] = React.useState<InventoryLocation[]>([]); // Filtered data state
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedInventoryLocation, setSelectedInventoryLocation] = React.useState<InventoryLocation | null>(null);

    const fetchData = async () => {
      try {
        const response = await axios.get('/api/inventory-locations');
        const sortedData = response.data.sort((a: InventoryLocation, b: InventoryLocation) =>
          a.codeNumber.localeCompare(b.codeNumber)
        );
        setData(sortedData);
        setFilteredData(sortedData);

      } catch (error) {
        console.error('Error fetching data: ',error); 
      }
    }

    React.useEffect(() => {
      fetchData();
    }, []);

    const handlePageChange = (newPage: number) => {
      setPage(newPage);
    };
  
    const handleRowsPerPageChange = (newRowsPerPage: number) => { 
      setRowsPerPage(newRowsPerPage); 
      setPage(0);
    };
  
    const handleOpenDialog = () => {
      setSelectedInventoryLocation(null); // Reset selected inspection when adding a new one
      setOpenDialog(true);
    };

    const handleEditInvetoryLocation = (id: string) => {
      const inventoryLocationToEdit = data.find((inventoryLocation) => inventoryLocation.id === id);  
      if (inventoryLocationToEdit) {
        setSelectedInventoryLocation(inventoryLocationToEdit);
        setOpenDialog(true);
      } 
    };
  
    const handleDeleteInventoryLocation = async (id: string) => {
      const confirmed = window.confirm('Bạn có chắc muốn xóa thẻ này?');
      if (confirmed) {
        try {
          await axios.delete(`/api/inventory-locations/${JSON.stringify(id)}`);
          setData((prevData) => prevData.filter((inventoryLocation) => inventoryLocation.id !== id));
          setFilteredData((prevData) => prevData.filter((inventoryLocation) => inventoryLocation.id !== id));
        } catch (error) {
          console.error('Error deleting inventory location:', error);
        }
      }
    }

    const handleDeleteInventoryLocationSelected = async (selectedIds: string[]) => {
      const confirmed = window.confirm('Bạn có chắc muốn xóa những thẻ đã chọn?');
      if (confirmed) {
        try {
          await axios.delete(`/api/inventory-locations/${JSON.stringify(selectedIds)}`);
          alert('Xóa thành công');  
          setData((prevData) => prevData.filter((inventoryLocation) => !selectedIds.includes(inventoryLocation.id)));
          setFilteredData((prevData) => prevData.filter((inventoryLocation) => !selectedIds.includes(inventoryLocation.id)));
        } catch (error) {
          console.error('Error deleting inventory location:', error);
        }
      }
    }

    const handleAddOrEditInventoryLocation = async (inventoryLocationData: Partial<InventoryLocation>) => {
      try {
        const formData = new FormData();


        Object.keys(inventoryLocationData).forEach((key) => {
          const value = inventoryLocationData[key as keyof InventoryLocation];
          if (value !== undefined) {
            formData.append(key, value as string | Blob);
          }
        });

        if (inventoryLocationData.customFields) {
          formData.set('customFields', JSON.stringify(inventoryLocationData.customFields));
        }

        if (selectedInventoryLocation) {
          const response = await axios.put(`/api/inventory-locations/${selectedInventoryLocation.id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
            },
          });
          const updatedInventoryLocation = response.data;

          setData((prevData) => 
            prevData.map((inventoryLocation) => (inventoryLocation.id === updatedInventoryLocation.id ? updatedInventoryLocation : inventoryLocation))
          );
  
          setFilteredData((prevData) => 
            prevData.map((inventoryLocation) => (inventoryLocation.id === updatedInventoryLocation.id ? updatedInventoryLocation : inventoryLocation)) 
          );
        } else {
          const response = await axios.post('/api/inventory-locations', formData, { 
            headers: {
              'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
            },
          });

          const newPart = response.data;
          setData((prevData) => [ ...prevData, newPart]);  
          setFilteredData((prevData) => [ ...prevData, newPart]); 
        }

        fetchData();
        setOpenDialog(false); 

      } catch (error: any) {

        if (error.request?.status === 400) {
          alert('Đã có linh kiện này ở vị trí này, vui lòng chọn ở vị trí khác hoặc vị trí chi tiết hơn.');
          console.error('Error adding/editing inventory location:', error);

        } else {
          alert('Có lỗi xảy ra, vui lòng thử lại sau');
          console.error('Error adding/editing inventory location:', error);
        }
      } 
    };

    const handleFilterChange = (column: string, searchTerm: string | boolean | null | Dayjs ) => {
      const filtered = data.filter((inventoryLocation) => {
        let value = inventoryLocation[column as keyof InventoryLocation]; 

        if (searchTerm === null) {
          return true;
        }

        switch (column) { 
          case 'partName':
            value = inventoryLocation.part.name;
            break;
          case 'warehouseName': 
            value = inventoryLocation.warehouse.name;
            break;
          case 'aisleName':
            value = inventoryLocation.aisle.name;
            break;
          case 'rowName':
            value = inventoryLocation.row.name;
            break;
          case 'binName':
            value = inventoryLocation.bin.name;
            break;
        }

        if (typeof value === 'string' && typeof searchTerm === 'string') {
          return value.toLowerCase().includes(searchTerm.toLowerCase()); // Case-insensitive search for strings
        }
        
        if (typeof value === 'number' && typeof searchTerm === 'number') {
        return value === Number(searchTerm); // Match numbers exactly
        }
      
        if (typeof value === 'boolean') {
          return value === Boolean(searchTerm); // Match boolean values exactly
        };
      
        return false;
      });

      setFilteredData(filtered);  
    } 

    const filterColumns = [
      { value: 'codeNumber', label: 'Mã thẻ trong kho' },  
      { value: 'partName', label: 'Tên phụ tùng' },
      { value: 'warehouseName', label: 'Tên kho' },
      { value: 'aisleName', label: 'Tên lối đi' },  
      { value: 'rowName', label: 'Tên hàng' },  
      { value: 'binName', label: 'Tên kệ' },  
      { value: 'availableStatus', label: 'Trạng thái số lượng cuối', options: [
        { label: 'Chưa xác định', value: 'Chưa xác định' },
        { label: 'Còn hàng', value: 'Còn hàng' },
        { label: 'Sl thấp', value: 'Sl thấp' },
      ], type: 'dropdown' as const
      
      },  

    ];

    const statusStyles: Record<string, { backgroundColor: string}> = {
      'Chưa xác định': { backgroundColor: '#9e9e9e'}, // Grey
      'Còn hàng': { backgroundColor: '#4caf50'},      // Green
      'Sl thấp': { backgroundColor: '#f44336' },       // Red
    };

    const columns: TableColumn<InventoryLocation>[] = [ 
      { label: "Mã thẻ trong kho", field: "codeNumber" },
      { label: "Tên phụ tùng", field: (row) => row.part?.name || "" },
      { 
        label: 'Trạng thái số lượng', 
        field: (row) => {
          const status = row.availableStatus || 'Chưa xác định'; // Default status
          const style = statusStyles[status]; // Get the style based on the status
          const textColor = getContrastTextColor(style.backgroundColor); // Get the text color based on the background color
          
          return (
            <Chip
              label={status}
              style={{
                backgroundColor: style?.backgroundColor || '#ccc', // Fallback to grey
                color: textColor,                    // Fallback to black
                borderRadius: '5px',
                padding: '5px 10px',
                fontWeight: 'bold',
              }}
            />
          );
        },
      },
      { label: "Số lượng hiện có", field: "availableQuantity" },  
      { label: "Ngày xác nhận số lượng cuối", field: (row) => formatDate(row.quantityLastVerify) },
      { label: "Kho", field: (row) => row.warehouse?.name || "" },
      { label: "Lối đi", field: (row) => row.aisle?.name || "" }, 
      { label: "Hàng", field: (row) => row.row?.name || "" },
      { label: "Kệ", field: (row) => row.bin?.name || "" },
      { label: "Nhắc nhở khi hết hàng", field: (row) => row.reminderOnLowStockFlg ? 'Có' : 'Không' },
      // dưới điểm báo thấp sẽ tính là sắp hết hàng
      { label: "Điểm báo thấp", field: "reOrderPoint" },
      // số lượng đặt hàng khi đặt hàng
      { label: "Số lượng đặt hàng", field: "reOrderQuantity" },
      // trung bình ngày giao hàng (từ lúc đặt hàng tới lúc nhận hàng)
      { label: "Thời gian hàng được giao tới (ngày)", field: "leadTimeDays" },
    ];

    const [openPurchaseOrderDialog, setOpenPurchaseOrderDialog] = React.useState(false);
    const [openQuantityDialog, setOpenQuantityDialog] = React.useState(false);
    const [errorsQuantity, setErrorsQuantity] = React.useState<Record<string, string>>({});

    // value for order quantity
    const [orderQuantity, setOrderQuantity] = React.useState<number>(0);

    const handleAddOrder = (id: string) => {
      const inventoryLocationToEdit = data.find((inventoryLocation) => inventoryLocation.id === id);  
      if (inventoryLocationToEdit) {
        setSelectedInventoryLocation(inventoryLocationToEdit);  
        setOpenQuantityDialog(true);
      }
    };

    const handleCloseOrder = () => {  
      // onClose={() => setOpenQuantityDialog(false)}
      // onClose={() => setOpenPurchaseOrderDialog(false)} 
      setOpenQuantityDialog(false); 
      setOpenPurchaseOrderDialog(false);
      setSelectedInventoryLocation(null);
    }

    const handleSubmitOrderQuantityDialog = async () => {
      if (orderQuantity <= 0) {
        setErrorsQuantity({ orderQuantity: 'Số lượng đặt hàng phải lớn hơn 0' });
        // showing the alert to the user and return
        return alert('Số lượng đặt hàng phải lớn hơn 0');
      }

      setOpenQuantityDialog(false);
      setOpenPurchaseOrderDialog(true); 
    };

    const handleSubmitOrderDialog = async (purchaseOrderData: any) => {
     if (!selectedInventoryLocation) return;
      
      try {
        // using the id from the purchaseOrderData in order to submit the quantity to the server
        const formData = new FormData();

        formData.append('orderQuantity', orderQuantity.toString());
        formData.append('purchaseOrderId', purchaseOrderData.id);
        formData.append('inventoryLocationId', selectedInventoryLocation.id);

        const response = await axios.post('/api/inventory-locations/purchase-order', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        alert('Tạo đơn mua thành công (coi chi tiết tại mục đơn mua)');
        setOpenPurchaseOrderDialog(false);
        setSelectedInventoryLocation(null); 
      } catch (error) {
        alert('Có lỗi xảy ra, vui lòng thử lại sau');
        console.error('Error adding/editing purchase order:', error); 

        // call delete route /api/purchase-order/:id to delete the purchase order
        // if the order is not successful
        await axios.delete(`/api/purchase-order/${purchaseOrderData.id}`);

      }
    };

    const formFieldsOrderQuantity = [
      {
        label: 'Số lượng đặt hàng',
        name: 'orderQuantity',
        type: 'number', 
        value: orderQuantity,
        require: true,
        handleChange: (e: React.ChangeEvent<HTMLInputElement>) => setOrderQuantity(Number(e.target.value)),
        errors: errorsQuantity.orderQuantity, 
      }
    ];

    return (
      <Stack spacing={2}>
        <Stack direction="row" spacing={3}>
          <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
            <Typography variant="h4">Quản lý kho hàng</Typography>
          </Stack>
          <div>
            {/* <Button
                startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />}
                variant="outlined"
                onClick={() => setOpenPurchaseOrderDialog(true)}
                className="me-3"
              >
              Đặt hàng
            </Button> */}

            <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={handleOpenDialog}>
              Thêm
            </Button>
          </div>
        </Stack>

        <GenericFilters 
          columns={filterColumns} onFilterChange={handleFilterChange} placeholder="Tìm thẻ"
        />

        <GenericTable
          count={filteredData.length}
          page={page}
          rows={applyPagination(filteredData, page, rowsPerPage)} // Use transformed data
          columns={columns}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onEdit={handleEditInvetoryLocation  }
          onDelete={handleDeleteInventoryLocation}    
          onDeleteSelected={handleDeleteInventoryLocationSelected} 
          // custom things
          onCustomAction={handleAddOrder} 
          customButtonLabel='Đặt hàng'
          CustomIcon={<ShoppingCartIcon />}
        />

        <InventoryDialog 
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          onSubmit={handleAddOrEditInventoryLocation}
          inventoryLocationData={selectedInventoryLocation ?? undefined}
          refreshData={fetchData}
        />

        <PurchaseOrderDialog 
          open={openPurchaseOrderDialog} 
          onClose={handleCloseOrder}
          purchaseOrderData={undefined}
          onSubmit={handleSubmitOrderDialog}  
        />

        <CustomDialog
          open={openQuantityDialog}
          title="Chọn số lượng đặt hàng"
          onClose={handleCloseOrder}  
          onSubmit={handleSubmitOrderQuantityDialog}
          submitLabel='Tiếp tục'
          dialogWidth='500px'
          dialogMaxWidth='85%'  
        >

          <FormFields fields={formFieldsOrderQuantity}/>  

        </CustomDialog>
  
      
      </Stack>
    )
};

