import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Chip } from '@mui/material';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import ClearIcon from '@mui/icons-material/Clear';
import { GenericFilters } from '@/components/dashboard/filter/genericFilter';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1';
import axios from 'axios';
import { MaintainanceServiceEntryDialog } from './maintainanceServiceEntriesDialog';
import './maintainanceWorkOder.css'; // Import the CSS file  
import { formatDate } from '@/hooks/format-date';
import { getInChargeType } from '@/hooks/mapping';
import { getContrastTextColor } from '@/hooks/contrast-text-color';
import { useLocation, useNavigate } from 'react-router-dom'; // Import the useLocation hook
import dayjs, { Dayjs } from 'dayjs';
import { MaintainanceServiceEntriesNotAssignedDialog } from './MaintainanceServiceEntriesNotAssignedDialog';  

interface Vehicle {
  vehicleNumber: string;
  name: string;
  primaryMeter: number;
  primaryMeterType: string;
  secondaryMeter: number; 
  secondaryMeterType: string;
}

interface BillableStatus {
  name: string;
  color: string;
}

interface WorkOrder{
  workOrderNumber: string;
  completedDate: Date;
}

interface Status {
  name: string;
  description: string;
  color: string;
  function: string;
}

interface Customer {
  name: string;
  accountNumber: string;
}

interface ServiceTask {
  name: string;
  description: string;  
}

export interface MaintainanceServiceEntry {
  id: string;
  serviceEntryNumber: string;
  vehicleId: number;
  vehicle: Vehicle;
  billableStatusId: number;
  billableStatus: BillableStatus;
  workOrderId: number;
  workOrder: WorkOrder; 
  statusId: number;
  status: Status; 
  inChargeType: string; 
  inChargeId: number;
  customerId: number; 
  customer: Customer;
  serviceTaskId: number;
  serviceTask: ServiceTask; 
  completedDate: Date;
  primaryMeter: number;
  createdAt: Date;
  customFields: Record<string, string>;
}

interface MaintainanceServiceEntrysPageProps {
  workOrderId?: string;
}

function applyPagination(rows: MaintainanceServiceEntry[], page: number, rowsPerPage: number): MaintainanceServiceEntry[] {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export default function MaintainanceServiceEntrysPage({ workOrderId }: MaintainanceServiceEntrysPageProps): React.JSX.Element {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5); // Adjust number of rows per page as necessary
  const [data, setData] = React.useState<MaintainanceServiceEntry[]>([]); // MaintainanceServiceEntry data state
  const [filteredData, setFilteredData] = React.useState<MaintainanceServiceEntry[]>([]); // Filtered data state
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedMaintainanceServiceEntry, setSelectedMaintainanceServiceEntry] = React.useState<MaintainanceServiceEntry | null>(null);
  const [workOrderNumberProps, setWorkOrderNumberProps] = React.useState(''); // Use state for workOrderNumberProps
  const [fromIssueFlg, setFromIssueFlg] = React.useState<boolean>(false); // New flag state
  
  const location = useLocation();
  const navigate = useNavigate(); // Add navigate for potential redirects
  const [issueDataLog, setIssueDataLog] = React.useState<any | null>(null);

  // handle the issue data when passedo from the issues page
  React.useEffect(() => {
    if (location.state && (location.state as any).issueData) {
      const { issueData, fromIssueFlg, } = location.state as any;
      setIssueDataLog(issueData); // Store issueData in state


      // Set data or open dialog based on the issue data
      if (issueData) {
        setSelectedMaintainanceServiceEntry({
          vehicleId: issueData.vehicleId, 
        } as MaintainanceServiceEntry);

        setFromIssueFlg(true); // Set the flag if it's coming from an issue
        setOpenDialog(true); // Open dialog with the pre-filled data
        navigate('/dashboard/maintainance-service-entries', { replace: true }); // Clear the state after using it
      }
    }
  }, [location, navigate]);

  // for presearch the workOrderNumber 
  React.useEffect(() => {
    const params = new URLSearchParams(location.search); // Use location from useLocation hook
    const workOrderNumber = params.get('workOrderNumber');
  
    if (workOrderNumber) {
      setWorkOrderNumberProps(workOrderNumber); // Update state
      handleFilterChange('workOrderNumber', workOrderNumber); // Apply filter
    }
  }, []); // Empty dependency array ensures this runs only once
  
  // for presearch the serviceEntryNumber 
  React.useEffect(() => {
    const params = new URLSearchParams(location.search); // Use location from useLocation hook  
    const serviceEntryNumber = params.get('serviceEntryNumber');
  
    if (serviceEntryNumber) {
      setWorkOrderNumberProps(serviceEntryNumber); // Update state
      handleFilterChange('serviceEntryNumber', serviceEntryNumber); // Apply filter
    } 
  }, []); // Empty dependency array ensures this runs only once
  

  const fetchData = async () => {
    try {
      const url = workOrderId 
        ? `/api/maintainance-service-entry?workOrderId=${workOrderId}` 
        : '/api/maintainance-service-entry';
      const response = await axios.get(url);
      const sortedData = response.data.sort((a: MaintainanceServiceEntry, b: MaintainanceServiceEntry) => a.serviceEntryNumber.localeCompare(b.serviceEntryNumber));
      
      setData(sortedData);
      setFilteredData(sortedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [workOrderId]);



  // const paginatedMaintainanceServiceEntrys = applyPagination(filteredData, page, rowsPerPage);

  const handleFilterChange = (column: string, searchTerm: string | boolean | Dayjs | null) => {
    const filtered = data.filter((maintainanceServiceEntry) => {
      let value;
  
      // Map column name to the actual data field
      switch (column) {
        case 'serviceEntryNumber':
          value = maintainanceServiceEntry.serviceEntryNumber;
          break;
        case 'vehicleNumber':
          value = maintainanceServiceEntry.vehicle?.vehicleNumber; // Safely access nested property
          break;
        case 'workOrderNumber':
          value = maintainanceServiceEntry.workOrder?.workOrderNumber; // Safely access nested property
          break;
        default:
          value = maintainanceServiceEntry[column as keyof MaintainanceServiceEntry];
      }
  
      // Check if searchTerm is null or empty to return all data
      if (searchTerm === null || searchTerm === '') {
        return true;
      }
  
      // Handle different value types: string or boolean
      if (typeof value === 'string' && typeof searchTerm === 'string') {
        return value.toLowerCase().includes(searchTerm.toLowerCase());
      }
  
      if (typeof value === 'boolean' && typeof searchTerm === 'boolean') {
        return value === searchTerm; // Match boolean values exactly
      }
  
      return false;
    });
  
    setFilteredData(filtered);
  };
  
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleOpenDialog = () => {
    setSelectedMaintainanceServiceEntry(null); // Reset selected maintainanceServiceEntry when adding a new one
    setFromIssueFlg(false); // Reset the flag when opening the dialog
    setOpenDialog(true);
  };

  const handleEditMaintainanceServiceEntry = (id: string) => {
    const maintainanceServiceEntryToEdit = data.find((maintainanceServiceEntry) => maintainanceServiceEntry.id === id);
    
    // confirm If that status is not COMPLETED
    if (maintainanceServiceEntryToEdit?.status?.function === 'COMPLETED') {
      alert('Không thể sửa nhiệm vụ đã hoàn thành');
      return
    }
    
    if (maintainanceServiceEntryToEdit) {
      setSelectedMaintainanceServiceEntry(maintainanceServiceEntryToEdit); // Populate the dialog with the selected maintainanceServiceEntry
      setFromIssueFlg(false); // Reset the flag when opening the dialog
      setOpenDialog(true); // Open dialog for editing
    }
  };

  const handleDeleteMaintainanceServiceEntry = async (id: string) => {
    const confirmed = window.confirm('Bạn có chắc là xóa thẻ này?');
    if (confirmed) {
      try {
        await axios.delete(`/api/maintainance-service-entry/${JSON.stringify(id)}`);
        setData((prevData) => prevData.filter((maintainanceServiceEntry) => maintainanceServiceEntry.id !== id));
        setFilteredData((prevData) => prevData.filter((maintainanceServiceEntry) => maintainanceServiceEntry.id !== id));
      } catch (error) {
        console.error('Error deleting maintainanceServiceEntry:', error);
      }
    }
  };

  const handleDeleteMaintainanceServiceEntrySelected = async (selectedIds: string[]) => {
    const confirmed = window.confirm('Bạn có chắc là xóa những thẻ đã chọn?');
    if (confirmed) {
      try {
        await axios.delete(`/api/maintainance-service-entry/${JSON.stringify(selectedIds)}`);
        alert('Xóa thành công');
        setData((prevData) => prevData.filter((maintainanceServiceEntry) => !selectedIds.includes(maintainanceServiceEntry.id)));
        setFilteredData((prevData) => prevData.filter((maintainanceServiceEntry) => !selectedIds.includes(maintainanceServiceEntry.id)));
      } catch (error) {
        console.log('Error deleting maintainanceServiceEntry:', error);
      }
    }
  }

  const handleAddOrEditMaintainanceServiceEntry = async (maintainanceServiceEntryData: any) => {
    try {
      if (selectedMaintainanceServiceEntry && !fromIssueFlg) {
        setData((prevData) =>
          prevData.map((maintainanceServiceEntry) => (maintainanceServiceEntry.id === maintainanceServiceEntryData.id ? maintainanceServiceEntryData : maintainanceServiceEntry))
        );
        setFilteredData((prevData) =>
          prevData.map((maintainanceServiceEntry) => (maintainanceServiceEntry.id === maintainanceServiceEntryData.id ? maintainanceServiceEntryData : maintainanceServiceEntry))
        );
      } else {
        if (fromIssueFlg && issueDataLog?.id) {
          // Ensure issueDataLog and its ID are valid
          try {
            const responseIssue = await axios.put(
              `/api/issues/resolved/${issueDataLog.id}`, // Pass issue ID in the URL
              { maintainanceServiceEntryId: maintainanceServiceEntryData.id }, // Pass the maintainanceServiceEntry ID in the body
              {
                headers: {
                  'Content-Type': 'application/json', // Ensure the header is set for JSON data
                },
              }
            );
        
            const updatedIssueData = responseIssue.data;
        
            // Navigate back to issues page with updated data
            navigate('/dashboard/issues', {
              state: {
                issueData: updatedIssueData,
              },
              replace: true,
            });
          } catch (error) {
            alert('Failed to resolve the issue. Please try again.');
            console.error('Error resolving issue:', error);
          }
        }
        setData((prevData) => [...prevData, maintainanceServiceEntryData]);
        // clear the form data
        setSelectedMaintainanceServiceEntry(null);
        setFilteredData((prevData) => [...prevData, maintainanceServiceEntryData]);
      }
      fetchData(); // Fetch data after the operation
      setOpenDialog(false); // Close the dialog after the operation
    } catch (error) {
      alert('Lỗi thêm/cập nhập phiếu nhiệm vụ. Hãy kiểm tra các ô lại.');
      console.error('Lỗi thêm/cập phiếu công việc:', error); 
    }
  };
  
  //filter columns
  const filterColumns = [
    { value: 'serviceEntryNumber', label: 'Mã nhiệm vụ' },
    { value: 'vehicleNumber', label: 'Mã số xe' },
    { value: 'workOrderNumber', label: 'Mã số việc cần làm' },
  ];

  const transformedServiceEntrys = filteredData.map((maintainanceServiceEntry) => {
    return {
      ...maintainanceServiceEntry,
      vehicleNumber: maintainanceServiceEntry.vehicle?.vehicleNumber || 'N/A', // Direct access to vehicleNumber
      statusName: maintainanceServiceEntry.status?.name || 'N/A',              // Direct access to status name
    };
  });
  
  // Table columns with proper labels and fields
  const columns: TableColumn<MaintainanceServiceEntry>[] = [
    { label: 'Mã số nhiệm vụ', field: 'serviceEntryNumber' },
    { label: 'Mã Số Xe', field: (row) => row.vehicle?.vehicleNumber || 'N/A' },
    { label: 'Mã số việc cần làm', field: (row) => row.workOrder?.workOrderNumber || 'N/A' },
    { label: 'Công việc', field: (row) => row.serviceTask?.name || 'N/A' },
    { label: 'Bên làm', field: (row) => getInChargeType(row.inChargeType) || 'N/A' },
    { 
      label: 'Tình trạng nhiệm vụ', 
      field: (row) => {
        const bgColor = row.status?.color || '#ccc'; // Default to gray if no color is found
        const textColor = getContrastTextColor(bgColor); // Get the text color based on the background color
        return (
          <Chip
          label={row.status?.name || 'N/A'}
          style={{
            backgroundColor: row.status?.color || '#ccc', // Use the color from the status
            color: textColor,
            borderRadius: '5px',
            padding: '5px 10px',
            fontWeight: 'bold',
          }}
        /> 
        )
      },
    },
    { label: 'Ngày hoàn thành', field: (row) => formatDate(row.completedDate) || 'N/A' }, 
    { 
      label: 'Tình trạng thanh toán', 
      field: (row) => {
        const bgColor = row.billableStatus?.color || '#ccc'; // Default to gray if no color is found
        const textColor = getContrastTextColor(bgColor); // Get the text color based on the background color

        return (
          <Chip
          label={row.billableStatus?.name || 'N/A'}
          style={{
            backgroundColor: row.billableStatus?.color || '#ccc', // Use the color from the status
            color: textColor,
            borderRadius: '5px',
            padding: '5px 10px',
            fontWeight: 'bold',
          }}
          />
        )

        },
    },
    {
      label: 'ODO chính lúc sửa', field: (row) => row.primaryMeter && row.vehicle?.primaryMeterType ? `${row.primaryMeter} ${row.vehicle.primaryMeterType}` : 'Chưa có',
    },
    { label: 'Khách hàng', field: (row) => row.customer?.name || 'N/A' },
  ];

  // for the service entries import
  const [notAssignedWorkOrderData, setNotAssignedWorkOrderData] = React.useState<any>([]);  
  const [openDialogServiceEntriesImport, setOpenDialogServiceEntriesImport ] = React.useState(false);  
  const [selectedServiceEntries, setSelectedServiceEntries] = React.useState<any>(null);

  const fetchNotAssignedWorkOrderData = async () => { 
    try {
      // serviceEntryNumber, vehicle, task, status
      const response = await axios.get('/api/maintainance-service-entry/not-assigned-work-order');
      setNotAssignedWorkOrderData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  React.useEffect(() => {
    if (workOrderId) { 
      fetchNotAssignedWorkOrderData();  
     }
  }, [workOrderId]);

  const handleAddFromNotAssignedWorkOrder = async (selectedServiceEntries: any) => {
    try {

      fetchNotAssignedWorkOrderData(); // Fetch data after the operation
      fetchData(); // Fetch data after the operation
      setOpenDialogServiceEntriesImport(false); // Close the dialog after the operation
    } catch (error) {
      console.error('Error fetching data:', error);
      alert('Có lỗi khi chuyển dỗi dữ liệu. Vui lòng thử lại sau.');
    } 
  }

  const handleDissasociateServiceEntry = (id: string) => {
    const confirmed = window.confirm('Bạn có chắc là hủy liên kết với việc cần làm này?');  
    if (confirmed) {
      try {
        const response =  axios.put(`/api/maintainance-service-entry/dissasociate/${id}`);
        fetchNotAssignedWorkOrderData(); // Fetch data after the operation
      } catch (error) {
        console.error('Error dissasociating service entry:', error);  
        alert('Có lỗi khi hủy liên kết dữ liệu. Vui lòng thử lại sau.');  
      }
    }
  }

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h4">Nhiệm vụ </Typography>
        </Stack>
        <div>
          { workOrderId && (
            <Button
              startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />}
              variant="outlined"
              onClick={() => setOpenDialogServiceEntriesImport(true)}
              className="me-3"
            >
              Thêm vào mục nhiệm vụ
            </Button>
          )}

          <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={handleOpenDialog}>
            Thêm
          </Button>
        </div>
      </Stack>
      <GenericFilters 
      columns={filterColumns} onFilterChange={handleFilterChange} placeholder="Tìm nhệm vụ"
      preFillColumn="workOrderNumber" // Optionally preselect the column
      // Prefill with the extracted search term
        />
        <GenericTable
          count={filteredData.length}
          page={page}
          rows={applyPagination(transformedServiceEntrys, page, rowsPerPage)} // Use transformed data
          columns={columns}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onEdit={handleEditMaintainanceServiceEntry}
          onDelete={handleDeleteMaintainanceServiceEntry}
          onDeleteSelected={handleDeleteMaintainanceServiceEntrySelected}
          { ...(workOrderId && {
            onCustomAction: handleDissasociateServiceEntry,
            customButtonLabel: 'Hủy liên kết với việc cần làm',
            CustomIcon: <ClearIcon />,
          })}
        />

        {/* Add maintainanceServiceEntry dialog */}
      <MaintainanceServiceEntryDialog open={openDialog} onClose={() =>{ setOpenDialog(false); setFromIssueFlg(false)}} onSubmit={handleAddOrEditMaintainanceServiceEntry} maintainanceServiceEntryData={selectedMaintainanceServiceEntry ?? undefined} 
        refreshData={fetchData} fromIssueFlg={fromIssueFlg} workOrderIdProps={workOrderId}  
      />

      <MaintainanceServiceEntriesNotAssignedDialog 
        open={openDialogServiceEntriesImport}
        onClose={() => setOpenDialogServiceEntriesImport(false)}  
        onSubmit={(selectedServiceEntries) => {
          handleAddFromNotAssignedWorkOrder(selectedServiceEntries);
        }}
        notAssignedItems={notAssignedWorkOrderData}
        workOrderIdProps={workOrderId} 
      />
    </Stack>
  );
}

// This ensures that this file is treated as a module and resolves the TS1208 error
export { };
