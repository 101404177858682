import * as React from 'react';
import { Typography } from '@mui/material';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { Tire } from './TiresPage'; 
import axios from 'axios';
import { FieldManagementDialog, DialogField } from '@/components/dashboard/fieldManagement/FieldManagement';
import  useCustomFieldsUpdate from '@/hooks/field-managementSubmit'; // Import the hook
import { UseFetchVechicle, UseFetchTireSet, UseFetchManufacturer, UseFetchSupplier, UseFetchTireModel, UseFetchTireSize, UseFetchTirePosition, UseFetchWarehouse  } from '@/hooks/fetch-relationship-field'; 
import { formatDate } from '@/hooks/format-date';
import FormThings from './formWithCustomSelectTire';
import TireInpspectionComponents from '@/components/subPlace/TireInspections';

interface TiresDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (fuelEntryData: Partial<Tire>) => void;
  tiresData?: Partial<Tire>;
  refreshData: () => void;  
}

export const TiresDialog: React.FC<TiresDialogProps> = ({ open, onClose, onSubmit, tiresData, refreshData }) => {
  let isEditMode = !!tiresData; 
  const [fields, setFields] = React.useState<DialogField[]>([]);
  const [formData, setFormData] = React.useState<Partial<Tire>>(tiresData || {});
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [tireNumberLoading, setTireNumberLoading] = React.useState(false);  

  const [isFieldManagementOpen, setFieldManagementOpen] = React.useState(false);  
  const [customFieldsData, setCustomFieldsData] = React.useState<Record<string, any>>({});
  
  const { tireSets, addTireSet, editTireSet, deleteTireSet } = UseFetchTireSet();
  const { manufacturers, addManufacturer, editManufacturer, deleteManufacturer } = UseFetchManufacturer(); 
  const { tireModels, addTireModel, editTireModel, deleteTireModel } = UseFetchTireModel();  
  const { tireSizes, addTireSize, editTireSize, deleteTireSize } = UseFetchTireSize();
  const { vehicles } = UseFetchVechicle();
  const { tirePositions, addTirePosition, editTirePosition, deleteTirePosition } = UseFetchTirePosition();
  const { suppliers } = UseFetchSupplier(); // for the vendor field
  const { warehouses, addWarehouse, editWarehouse, deleteWarehouse } = UseFetchWarehouse(); 

  const [vehicleId, setVehicleId] = React.useState<number | null>(null);
  const [tireSetId, setTireSetId] = React.useState<number | null>(null);
  const [manufacturerId, setManufacturerId] = React.useState<number | null>(null);  
  const [tireModelId, setTireModelId] = React.useState<number | null>(null);
  const [tireSizeId, setTireSizeId] = React.useState<number | null>(null);
  const [tirePositionId, setTirePositionId] = React.useState<number | null>(null);
  const [vendorId, setVendorId] = React.useState<number | null>(null);
  const [warehouseId, setWarehouseId] = React.useState<number | null>(null);

    React.useEffect(() => {
    if (open && !isEditMode) {
      fetchNextTireNumber();
    }
  }, [open, tiresData]);

  const fetchNextTireNumber = async () => {
    try {
      setTireNumberLoading(true);
      const response = await axios.get('/api/tires/next-tire-number');
      const nextTireNumber = response.data.nextTireNumber;  
      setFormData({ ...formData, tireNumber: nextTireNumber });
    } catch (error) {
      console.error('Error fetching next tire number: ', error);
    } finally {
      setTireNumberLoading(false);  
    }
  };  

  React.useEffect(() => {
    fetchFields();
  }, []);

  const fetchFields = async () => {
    try {
      const response = await axios.get('/api/tires/tire-dialog-fields');
      setFields(response.data);
    } catch (error) {
      console.error('Error fetching fields: ', error);
    }
  }

  const addFieldToDatabase = async (field: DialogField): Promise<DialogField> => {  
    try {
      const response = await axios.post('/api/tires/tire-dialog-fields', field);
      return response.data;
    } catch (error) {
      console.error('Failed to add dialog field:', error);
      return field;
    }
  }

  const updateFieldInDatabase = async (field: DialogField): Promise<DialogField> => {
    try {
      const response = await axios.put(`/api/tires/tire-dialog-fields/${field.id}`, field);
      return response.data;
    } catch (error) {
      console.error('Error updating field:', error);
      throw error;
    }
  };

  const deleteFieldFromDatabase = async (id: number): Promise<void> => {
    try {
      await axios.delete(`/api/tires/tire-dialog-fields/${id}`);
    } catch (error) {
      console.error('Error deleting field:', error);
      throw error;
    }
  };

  const { handleCustomFieldsUpdate } = useCustomFieldsUpdate(); // Use the custom

  
  const handleFieldManagementSubmit = (updatedFields: DialogField[]) => {
    handleCustomFieldsUpdate(
      fields,                         
      formData.customFields || {},     
      tiresData?.customFields,      
      updatedFields,                   
      setCustomFieldsData,             
      setFormData,                     
      setFields,                       
      setFieldManagementOpen           
    );

    refreshData();  // <-- Trigger refresh after field management is done
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const customFields: Record<string, any> = {};

      fields.forEach((field) => {
        if (field.canDelete) {
          customFields[field.name] = customFieldsData[field.name];
        } 
      });

      onSubmit({
        ...formData,
        vehicleId: vehicleId ?? undefined,
        setId: tireSetId ?? undefined,
        tireModelId: tireModelId ?? undefined,
        tireSizeId: tireSizeId ?? undefined,
        tirePositionId: tirePositionId ?? undefined,
        storageLocationId: warehouseId ? String(warehouseId) : undefined,
        vendorId: vendorId ?? undefined,
        manufacturerId: manufacturerId ?? undefined,
        customFields,
      })
    }
  }

  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};

     // Validate required fields from dynamic form fields
    fields.forEach(field => {
      const fieldValue = formData[field.name as keyof Tire];

      if (field.required && (!fieldValue || typeof fieldValue === 'string' && !fieldValue.trim())) {
        newErrors[field.name] = `${field.label} cần có giá trị`;
      }
    });

    // if the isSetData is true, then the tireSetId is required
    if (formData.isSet && !tireSetId) {
      newErrors['tireSetId'] = 'Số lượng lốp trong 1 bộ cần được chọn';
    }

    // if taxFlg is true, then taxRate is required  
    if (formData.taxFlg && !formData.taxRate) {
      newErrors['taxRate'] = 'Nếu có thuế, hãy nhập giá trị thuế';
    }

    // require treadDepthUnit 
    if (!formData.treadDepthUnit) {
      newErrors['treadDepthUnit'] = 'Đơn vị đo độ sâu cần có giá trị';
    } 
     
    setErrors(newErrors); 
    
    if (Object.keys(newErrors).length > 0) { 
      const errorMessages = Object.values(newErrors)
      .map((message) => `+ ${message  }`)
      .join('\n');  
      alert(`Các mục bị lỗi:\n${errorMessages}`);
    };


    return true;
  }

  React.useEffect(() => {
  if (open) {
    if (isEditMode && tiresData) {
      // Edit mode: populate formData with tiresData
      setFormData({
        ...tiresData,
        purchaseDate: formatDate(tiresData.purchaseDate), // Format purchase date
      });

      if (tiresData.customFields) {
        try {
          // Parse customFields if they exist and are a JSON string
          const parsedCustomFields =
            typeof tiresData.customFields === 'string'
              ? JSON.parse(tiresData.customFields)
              : tiresData.customFields;
          setCustomFieldsData(parsedCustomFields);
        } catch (error) {
          console.error('Error parsing custom fields:', error);
        }
      }

      // Set related fields
      setVehicleId(tiresData.vehicleId || null);
      setTireSetId(tiresData.setId || null);
      setManufacturerId(tiresData.manufacturerId || null);
      setTireModelId(tiresData.tireModelId || null);
      setTireSizeId(tiresData.tireSizeId || null);
      setTirePositionId(tiresData.tirePositionId || null);
      setVendorId(tiresData.vendorId || null);
      setWarehouseId(tiresData.storageLocationId ? Number(tiresData.storageLocationId) : null);
          
    } else {
      // Default data for create mode
      setFormData({
        purchaseDate: formatDate(new Date()), // Default to today's date
        intervalMeter: 5000, // Default interval meter
        intervalTime: 60, // Default interval time
      });

      setCustomFieldsData({}); // Clear custom fields
      setVehicleId(null);
      setTireSetId(null);
      setTireModelId(null);
      setTireSizeId(null);
      setTirePositionId(null);
      setWarehouseId(null);
    }
  }
}, [open, isEditMode, tiresData]); // Ensure dependencies are correct


  const handleCustomFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Check if the input is a checkbox or a regular text input
    setCustomFieldsData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,  // If checkbox, store as boolean; else, store the value
    }));
  };
 

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Update the state
    const updatedFormData = {
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    };
  
    // Handle tax calculation for taxFlg and taxRate
    if (name === 'cost' || name === 'taxRate' || name === 'taxFlg') {
      const cost = parseFloat(String(updatedFormData.cost || '0'));
      const taxRate = updatedFormData.taxFlg ? parseFloat(String(updatedFormData.taxRate || '0')) : 0;
      const taxMultiplier = 1 + taxRate / 100;
  
      updatedFormData.totalCost = parseFloat((cost * taxMultiplier).toFixed(2));
    }
  
    // Handle isSetData checkbox
    if (name === 'isSet' && !checked) {
      tireSetId && setTireSetId(null);
    }
  
  
    // Update the form data state
    setFormData(updatedFormData);
  };

  const formFields: FormField[] = fields.map(field => {
    if (field.name === 'tireNumber') {
      return {
        label: 'Mã số lốp xe',
        name: 'tireNumber', 
        type: 'text', 
        value: formData.tireNumber || '', 
        required: true,
        error: errors['tireNumber'],
      };
    } else if (field.name === 'taxRate' && !formData.taxFlg) {
        return null;
    // only show the  mountedOnVehicleFlg field if there is vehicleId
    } else if (field.name === 'mountedOnVehicleFlg' &&  !vehicleId) {
      return null;

    } else if ((field.name === 'minimumTreadDepth' || field.name === 'intervalMeter' || field.name === 'intervalTime' ) && !formData.warningWhenWornFlg) {
      return null;

    } else {
      const isTotalCost = field.name === 'totalCost'; 

      return {
        label: field.label,
        name: field.name,
        type: field.type === 'treadDepthUnit' ? 'select' : field.type,
        options: field.name === 'treadDepthUnit'
          ? [
              { label: 'Millimeter', value: 'mm' },
              { label: '1/32 inch', value: 'in132' },
            ]
          : undefined,
        value: formData[field.name as keyof Tire] || customFieldsData[field.name] || '',
        required: field.required,
        ...(isTotalCost ? {} : { handleChange: field.canDelete ? handleCustomFieldChange : handleChange }),
        error: errors[field.name],
        handleChange: field.canDelete ? handleCustomFieldChange : handleChange,
      };
    }
  }).filter(Boolean) as FormField[];

  const selectedVehicle = vehicles.find((vehicle) => vehicle.id === vehicleId);

  return (
    <CustomDialog open={open} title="Thêm/Chỉnh sửa thông tin lốp xe " onClose={onClose} manageFields={() => setFieldManagementOpen(true)}
      onSubmit={handleSubmit} submitLabel="Lưu" dialogWidth='1600px' dialogMaxWidth='95%'
    >

      <div className="row">
        <div className={isEditMode ? "col-md-6" : "col-md-12"}>
          <FormThings 
            formFields={formFields}
            vehicles={vehicles}
            vehicleId={vehicleId}
            setVehicleId={setVehicleId} 
            tireSets={tireSets}
            tireSetId={tireSetId}
            setTireSetId={setTireSetId}
            addTireSet={addTireSet} 
            editTireSet={editTireSet}
            deleteTireSet={deleteTireSet}

            manufacturers={manufacturers}
            manufacturerId={manufacturerId} 
            setManufacturerId={setManufacturerId}
            addManufacturer={addManufacturer}
            editManufacturer={editManufacturer}
            deleteManufacturer={deleteManufacturer}

            tireModels={tireModels} 
            tireModelId={tireModelId}
            setTireModelId={setTireModelId}
            addTireModel={addTireModel}
            editTireModel={editTireModel}
            deleteTireModel={deleteTireModel}

            tireSizes={tireSizes}
            tireSizeId={tireSizeId}
            setTireSizeId={setTireSizeId}
            addTireSize={addTireSize} 
            editTireSize={editTireSize} 
            deleteTireSize={deleteTireSize} 
          
            tirePositions={tirePositions}
            tirePositionId={tirePositionId}
            setTirePositionId={setTirePositionId}
            addTirePosition={addTirePosition} 
            editTirePosition={editTirePosition}
            deleteTirePosition={deleteTirePosition}

            vendors={suppliers} 
            vendorId={vendorId} 
            setVendorId={setVendorId} 

            warehouses={warehouses}
            warehouseId={warehouseId}
            setWarehouseId={setWarehouseId}  
            addWarehouse={addWarehouse} 
            editWarehouse={editWarehouse} 
            deleteWarehouse={deleteWarehouse}
            
            isSetData={formData?.isSet || false}
            mountedOnVehicleFlgData={formData?.mountedOnVehicleFlg || false}   
          />

        </div>

            { isEditMode && (
              <div className='col-md-6'>
                <TireInpspectionComponents tireId={tiresData?.id || ''} treadDepthUnit={tiresData?.treadDepthUnit || ''} vehicle={selectedVehicle} warningWhenWornFlg={tiresData?.warningWhenWornFlg || false}  minimumTreadDepth={Number(tiresData?.minimumTreadDepth) || 0}/>

              </div>
              )}
       
      </div>

    

      <FieldManagementDialog
        open={isFieldManagementOpen}
        onClose={() => setFieldManagementOpen(false)}
        fields={fields}
        onFieldSubmit={handleFieldManagementSubmit}
        addFieldToDatabase={addFieldToDatabase}
        updateFieldInDatabase={updateFieldInDatabase}
        deleteFieldFromDatabase={deleteFieldFromDatabase}
      />

    </CustomDialog>
  );
};