import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { Download as DownloadIcon } from '@phosphor-icons/react';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { Upload as UploadIcon } from '@phosphor-icons/react';
import { GenericFilters } from '@/components/dashboard/filter/genericFilter';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1'; 
import axios from 'axios';
import { formatDate } from '@/hooks/format-date';
import dayjs, { Dayjs } from 'dayjs';
import { FuelTankDialog } from './FuelTankDialog';

interface FuelType {
  name: string;
}

export interface FuelTank {
  id: string;
  name: string;
  fuelTypeId: number;
  fuelType: FuelType;
  capacity: number;
  threshold: number;
  createdAt: string;
  updateAt: string;
  customFields: Record<string, any>; 
}

function applyPagination(rows: FuelTank[], page: number, rowsPerPage: number): FuelTank[] {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export default function FuelTankPage(): React.JSX.Element {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5); // Adjust number of rows per page as necessary
  const [data, setData] = React.useState<FuelTank[]>([]);
  const [filteredData, setFilteredData] = React.useState<FuelTank[]>([]); // Filtered data state
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedFuelTank, setSelectedFuelTank] = React.useState<FuelTank | null>(null);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/fuel-tanks');
      const sortedData = response.data.sort((a: FuelTank, b: FuelTank) => {a.name.localeCompare(b.name)});
      setData(sortedData);  
      setFilteredData(sortedData);  
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => { 
    setRowsPerPage(newRowsPerPage); 
    setPage(0);
  };

  const handleOpenDialog = () => {
    setSelectedFuelTank(null); // Reset selected inspection when adding a new one
    setOpenDialog(true);
  };

  const handleEditFuelTank = (id: string) => {
    const fuelTankToEdit = data.find((fuelTank) => fuelTank.id === id);  
    if (fuelTankToEdit) {
      setSelectedFuelTank(fuelTankToEdit);
      setOpenDialog(true);
    } 
  };

  const handleDeleteFuelTank = async (id: string) => {
    const confirmed = window.confirm('Bạn có chắc muốn xóa thẻ này?');
    if (confirmed) {
      try {
        await axios.delete(`/api/fuel-tanks/${JSON.stringify(id)}`);
        setData((prevData) => prevData.filter((fuelTank) => fuelTank.id !== id));
        setFilteredData((prevData) => prevData.filter((fuelEntry) => fuelEntry.id !== id));
      } catch (error) {
        console.error('Error deleting inspection');
      }
    }
  }

  const handleDeleteFuelTankSelected = async (selectedIds: string[]) => {  
    const confirmed = window.confirm('Bạn có chắc muốn xóa các thẻ đã chọn?');
    if (confirmed) {
      try{
          await axios.delete(`/api/fuel-tanks/${JSON.stringify(selectedIds)}`);
          alert('Xóa thành công');
          setData((prevData) => prevData.filter((fuelTank) => !selectedIds.includes(fuelTank.id)));
          setFilteredData((prevData) => prevData.filter((fuelTank) => !selectedIds.includes(fuelTank.id)));  
      } catch (error){
        console.log('Error deleting fuel tank');
      }
    }
  };

  const handleAddOrEditFuelTank = async (fuelTankData: Partial<FuelTank>) => {  
    try {
      const formData = new FormData();

      Object.keys(fuelTankData).forEach((key) => {
        const value = fuelTankData[key as keyof FuelTank];
        if (value !== undefined) {
          // Ensure that all values appended to FormData are strings or Blob objects
          formData.append(key, value as string | Blob);
        }
      });

      // Handle customFields: Convert customFields to a JSON string before appending
      if (fuelTankData.customFields) {
        formData.set('customFields', JSON.stringify(fuelTankData.customFields));
      }

      if (selectedFuelTank) {
        // Editing existing maintainanceWorkOrder
        const response = await axios.put(`/api/fuel-tanks/${selectedFuelTank.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
          },
        });
        const updatedFuelTank = response.data;
  
        setData((prevData) =>
          prevData.map((fuelTank) => (fuelTank.id === updatedFuelTank.id ? updatedFuelTank : fuelTank))
        );
        setFilteredData((prevData) =>
          prevData.map((fuelTank) => (fuelTank.id === updatedFuelTank.id ? updatedFuelTank : fuelTank))
        );
      } else {
        // Adding new maintainanceWorkOrder
        const response = await axios.post('/api/fuel-tanks', formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
          },
        });
        const newFuelTank = response.data;
        setData((prevData) => [newFuelTank, ...prevData ]);
        setFilteredData((prevData) => [newFuelTank, ...prevData ]);
      }
      fetchData(); // Fetch data after the operation
      setOpenDialog(false); // Close the dialog after the operation
    } catch (error: any) {
      console.error('Error adding/editing fuel tank');
      if (error.response && error.response.status === 409) {
        alert('Tên kho xăng đã tồn tại, vui lòng chọn tên khác');  
      } else {
        alert('Có lỗi xảy ra, vui lòng thử lại sau');

      }
    }
  };

  const handleFilterChange = (column: string, searchTerm: string | boolean | null | Dayjs ) => {
    const filtered = data.filter((fuelTank) => {
			let value = fuelTank[column as keyof FuelTank]; // Access the column value dynamically

      if (searchTerm === null) {
        // If no searchTerm, include all items (e.g., dropdown initial state)
        return true;
      }

      if (typeof value === 'string' && typeof searchTerm === 'string') {
        return value.toLowerCase().includes(searchTerm.toLowerCase()); // Case-insensitive search for strings
        }
      
        if (typeof value === 'number' && typeof searchTerm === 'number') {
        return value === searchTerm; // Match numbers exactly
        }
      
        if (typeof value === 'boolean') {
        return value === searchTerm; // Match boolean values
        }
      
      if (column === 'date' && dayjs.isDayjs(searchTerm)) {
        // Compare dates for `ngay_kham`
        const date = dayjs(value as string).startOf('day'); // Normalize to date only
        const filterDate = searchTerm.startOf('day'); // Normalize search date
        return date.isSame(filterDate);
      }
  
      return false; // Default return false if value type does not match the expected types
    });
    
    setFilteredData(filtered);
  };

  const filterColumns = [
		{ value: 'name', label: 'Số thẻ' },
    { value: 'fuelType', label: 'Tên loại nhiên liệu' },
		
	]

  const columns: TableColumn<FuelTank>[] = [
    { label: "Tên", field: "name"},
    { label: "Loại nhiên liệu", field: (row) => row.fuelType?.name || ""},
    { label: "Dung tích", field: "capacity"},
    { label: "Ngưỡng báo thấp", field: "threshold"},
    { label: "Ngày tạo", field: (row) => formatDate(row.createdAt)},
    { label: "Ngày cập nhật", field: (row) => formatDate(row.updateAt)},
  ];    

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h5">Quản lý bồn chứa xăng</Typography> 
        </Stack>
        <div>
          <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={handleOpenDialog}>
            Thêm
          </Button>
        </div>
      </Stack>
      <GenericFilters columns={filterColumns} onFilterChange={handleFilterChange} placeholder="Tìm thẻ "/>
      

      <GenericTable
        count={filteredData.length}
        page={page}
        rows={applyPagination(filteredData, page, rowsPerPage)} // Use transformed data
        columns={columns}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onEdit={handleEditFuelTank}
        onDelete={handleDeleteFuelTank}
        onDeleteSelected={handleDeleteFuelTankSelected} 
      />

      <FuelTankDialog 
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSubmit={handleAddOrEditFuelTank}
        fuelTankData={selectedFuelTank ?? undefined}
        refreshData={fetchData}
      />

    </Stack>
  );

}