import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import profileImg from '@/assets/images/profile_pic1.png';
import { HandleSignout as signout } from '@/hooks/authService';
import { Link } from 'react-router-dom';
import './Nav.css'

const NavAvatar: React.FC = () => {
    const [username, setUsername] = useState<string | null>(null);
    
    useEffect(() => {
        const token = localStorage.getItem('token');
        
        // tryna to decode from the token
        if (token) {
            const decoded: any = jwtDecode(token); 
            console.log('decoded', decoded);

            setUsername(decoded?.username || 'Tài khoản ');
        }
    }, []);

    const handleSignout = () => {
        const confirmBox = window.confirm('Bạn có chắc chắn muốn đăng xuất?');
        if (confirmBox) {
            signout();  // Call the signout function to remove the token
        }
    };


    return (
        <li className='nav-item dropdown pe-3'>
            <a className='nav-link nav-profile d-flex align-items-center pe-0'
                href='#'
                data-bs-toggle='dropdown'   
            >
                <img src={profileImg}  alt="Profile" className='rounded-circle' />
                <span className='d-none d-md-block dropdown-toggle ps-2'>{username}</span>
            </a>

            <ul className='dropdown-menu dropdown-menu-end dropdown-menu-arrow profile'>
                <li className='dropdown-header'>
                    <h6>{username}</h6>
                    <span>Người dùng</span>
                </li>
                <li>
                    <hr className='dropdown-divider' />
                </li>
                {/* <li>
                    <a className='dropdown-item d-flex align-items center'
                        href='#'
                    >
                        <i className='bi bi-person'></i>
                        <span>Hồ sơ</span>
                    </a>
                </li> */}
                {/* <li>
                    <hr className='dropdown-divider' />
                </li>
                <li>
                    <a className='dropdown-item d-flex align-items-center'
                        href='users-profile.html'
                    >   
                        <i className="bi bi-gear"></i>
                        <span>Cài đặt tài khoản</span>
                    </a>
                </li> */}
{/* 
                <li>
                    <hr className='dropdown-divider' />
                </li> */}

                <li>
                    <Link to="/" onClick={handleSignout} className='dropdown-item d-flex align-items-center'>
                        <i className='bi bi-box-arrow-right'></i>
                        <span>Đăng xuất</span>
                    </Link>
                </li>
            </ul>
        </li>

    );

}

export default NavAvatar;
