import React, { useState } from 'react';
import { Button, TextField, Typography, Stack, IconButton, MenuItem, Pagination } from '@mui/material';
import { AddCircleOutline, DeleteOutline } from '@mui/icons-material';
import { Part } from '@/components/subPlace/subEntriesTypeMaintainance'; // Import the component
import './entries.css';

interface PartEntriesProps<EntryType> {
  entries: EntryType[];
  availableParts: Part[];
  onEntriesChange: (updatedEntries: EntryType[]) => void;
  partLabel?: string;
  quantityLabel?: string;
  measureLabel?: string;
  addButtonLabel?: string;
  entryFields: { partIdField: keyof EntryType; quantityField: keyof EntryType; measureField: keyof EntryType };
}

export const PartEntriesComponent = <EntryType extends { [key: string]: any }>({
  entries,
  availableParts,
  onEntriesChange,
  partLabel = 'Linh kiện',
  quantityLabel = 'Số lượng',
  measureLabel = 'Đơn vị',
  addButtonLabel = 'Thêm linh kiện được dùng',
  entryFields,
}: PartEntriesProps<EntryType>) => {
  const { partIdField, quantityField, measureField } = entryFields;

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const handleEntryChange = (index: number, key: keyof EntryType, value: any) => {
    const updatedEntries = [...entries];
    updatedEntries[index][key] = value;
    onEntriesChange(updatedEntries);
  };

  const handleAddEntry = () => {
    const newEntry = { [partIdField]: 0, [quantityField]: 1 } as EntryType;
    onEntriesChange([...entries, newEntry]);
  };

  const handleRemoveEntry = (index: number) => {
    const updatedEntries = entries.filter((_, i) => i !== index);
    onEntriesChange(updatedEntries);
  };

  // Get paginated entries for the current page
  const paginatedEntries = entries.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  // Handle page change
  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  // Set of already selected part IDs to filter available parts
  const selectedPartIds = new Set(entries.map(entry => entry[partIdField]).filter(id => id !== 0));

  return (
    <div className="proof mt-2">
      <Stack spacing={2}>
        <Typography variant="h6">{partLabel}</Typography>
        {paginatedEntries.map((entry, index) => {
          const selectedPart = availableParts.find(part => part.id === entry[partIdField]);
          const availableQuantity = selectedPart ? selectedPart.totalQuantity : Infinity;

          return (
            <Stack key={index} direction="row" spacing={1} alignItems="center">
              <TextField
                select
                label={partLabel}
                value={entry[partIdField] ?? ''}
                onChange={(e) => handleEntryChange(index, partIdField, Number(e.target.value))}
                fullWidth
                sx={{ width: '70%' }}
              >
                <MenuItem value="">Chọn linh kiện</MenuItem>
                {availableParts
                  .filter(part => !selectedPartIds.has(part.id as EntryType[keyof EntryType]) || part.id === entry[partIdField])
                  .map((part) => (
                    <MenuItem key={part.id} value={part.id}>
                      {part.name} (Sẵn có: {part.totalQuantity} {part.measurementUnit.shortName})
                    </MenuItem>
                  ))}

              </TextField>
              <TextField
                label={quantityLabel}
                type="number"
                value={entry[quantityField] ?? ''}
                onChange={(e) => {
                  const value = Math.min(Number(e.target.value), availableQuantity); // Limit to available quantity
                  handleEntryChange(index, quantityField, value);
                }}
                inputProps={{ min: 1, max: availableQuantity }} // Restrict value in the input field
                sx={{ width: '25%' }}
              />
              <TextField
                label={measureLabel}
                type="text"
                value={selectedPart ? selectedPart.measurementUnit.name : ''}
                disabled
              />
              <IconButton onClick={() => handleRemoveEntry(index)}>
                <DeleteOutline />
              </IconButton>
            </Stack>
          );
        })}
        <Button startIcon={<AddCircleOutline />} onClick={handleAddEntry}>
          {addButtonLabel}
        </Button>
        <Pagination
          count={Math.ceil(entries.length / itemsPerPage)} // Calculate total pages
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Stack>
    </div>
  );
};
