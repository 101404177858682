import * as React from 'react';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { FuelInput } from './FuelInputTab'; 
import { formatDate } from '@/hooks/format-date';

interface FuelInputDialogProps {    
  open: boolean;
  onClose: () => void;
  onSubmit: (fuelInputData: Partial<FuelInput>) => void;
  fuelInputData?: Partial<FuelInput>;
  refreshData: () => void;  
}

export const FuelInputDialog: React.FC<FuelInputDialogProps> = ({open, onClose, onSubmit, fuelInputData, refreshData}) => {
  let isEditMode = !!fuelInputData; // add another flag if chagne the edit mode mecha
  const [formData, setFormData] = React.useState<Partial<FuelInput>>(fuelInputData || {});
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

  const handleSubmit = async () => {  
    if (validateForm()){

      onSubmit({
        ...formData,
      });
    }
  };

  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};

    // require the date only
    if (!formData.date) {
      newErrors.date = 'Vui lòng chọn ngày.';
    }

    setErrors(newErrors);
    // If there are errors, show them in an alert
    if (Object.keys(newErrors).length > 0) {
      const errorMessages = Object.values(newErrors)
        .map((message) => `+ ${message}`) // Add "+ " before each error message
        .join('\n'); // Combine all error messages

      alert(`Các mục bị lỗi:\n${errorMessages}`); // Show alert with errors
    }

    return Object.keys(newErrors).length === 0;
  }

  React.useEffect(() => {
    if (open) {
      if (isEditMode && fuelInputData) {
        setFormData({...fuelInputData, 
          date: formatDate(fuelInputData?.date),
        });
      
      } else {
        setFormData({
        }); 
      }
    }
  }, [open, isEditMode]);  

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Handle checkbox separately to update the state with boolean values
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const formFields: FormField[] = [
    { 
      label: "Ngày *",
      name: "date",
      type: "date",
      value: formData.date || "",
      handleChange: handleChange, 
      error: errors.date, 
    },
    {
      label: "Số lượng (lít)",
      name: "quantity", 
      type: "number", 
      value: formData.quantity || "",
      handleChange: handleChange,
      error: errors.quantity,  
    },  
    {
      label: "Mô tả", 
      name: "description",
      type: "text",
      value: formData.description || "",
      handleChange: handleChange,
      error: errors.description,  
    }
  ];

  return (
    <CustomDialog open={open} title={isEditMode ? "Chỉnh sửa thông tin thêm nhiên liệu" : "Thêm nhiên liệu"} onClose={onClose}  onSubmit={handleSubmit} submitLabel="Lưu" dialogWidth="850px" dialogMaxWidth="95%" > 

      <FormFields fields={formFields} />

    </CustomDialog>

  )

};