import React, { useEffect, useState } from 'react';
import { InspectionForm } from '@/components/inspection/InspectionIssuesSetup'; // Import the component
import { InspectionItems } from '@/components/inspection/InspectionIssuesSetup'; // Import the component 
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import axios from 'axios';
import { convertStringToArray } from '@/hooks/converString&Array'; 

interface InspectionFormDialogProps {
  open: boolean;
  onClose: () => void;    
  onSubmit: (data: any) => void;  
  data?: Partial<InspectionForm>;
  availableInspectionItems?: InspectionItems[];
}

export const InspectionFormDialog: React.FC<InspectionFormDialogProps> = ({ open, data, onClose, onSubmit, availableInspectionItems: availableInspectionItemsProp }) => {
  const [inspectionFormData, setInspectionFormData] = useState(data || {});
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [inspectionItemsId, setInspectionItemsId] = useState<string[]>([]);
  const [availableInspectionItems, setAvailableInspectionItems] = useState<InspectionItems[]>(availableInspectionItemsProp || []);

  useEffect(() => {
    // Fetch available inspection items if not provided through props
    if (!availableInspectionItemsProp) {
      const fetchInspectionItems = async () => {
        try {
          const response = await axios.get('/api/inspection-items');
          setAvailableInspectionItems(response.data);
        } catch (error) {
          console.error('Failed to fetch inspection items:', error);
        }
      };
      fetchInspectionItems();
    }
  }, [availableInspectionItemsProp]);

  useEffect(() => {
    if (open) {
      if (data) {
        setInspectionFormData(data);
        setInspectionItemsId(
          Array.isArray(data.inspectionItemsId) ? data.inspectionItemsId.map((item) => item.toString()) : []
        );
      } else {
        setInspectionFormData({});
        setInspectionItemsId([]);
      }
    }
  }, [open, data]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: any }>) => {
    const { name, value } = e.target;
    setInspectionFormData((prev) => ({ ...prev, [name as string]: value }));
  };

  const handleSelectChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value } = e.target;
    setInspectionItemsId(Array.isArray(value) ? (value as string[]) : []);
  };

  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};

    if (!inspectionFormData.name) {
      newErrors.name = 'Tên mẫu kiểm tra là bắt buộc';
    }

    // Require at least one inspection item
    if (inspectionItemsId.length === 0) {
      newErrors.inspectionItems = 'Vui lòng chọn ít nhất một mục kiểm tra';
    }

    // Validate description
    if (!inspectionFormData.description) {
      newErrors.description = 'Mô tả là bắt buộc';
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      alert(Object.values(newErrors).join('\n'));
    }
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setErrors({});
      onSubmit({
        ...inspectionFormData,
        inspectionItemsId: inspectionItemsId.map((id) => parseInt(id, 10)), // Ensure IDs are numbers before submission
      });
    }
  };

  const formFields: FormField[] = [
    {
      name: 'name',
      label: 'Tên mẫu kiểm tra *',
      type: 'text',
      value: inspectionFormData.name || '',
      handleChange: handleChange,
      error: errors.name,
    },
    {
      name: 'description',
      label: 'Mô tả *',
      type: 'text',
      value: inspectionFormData.description || '',
      handleChange: handleChange,
      error: errors.description,
    },
    {
      label: 'Chọn các mục kiểm tra',
      name: 'inspectionItems',
      type: 'select',
      required: true,
      value: inspectionItemsId || [],
      handleChange: handleSelectChange,
      options: availableInspectionItems.map((item) => ({ label: item.name, value: item.id.toString() })),
      multiple: true,
    }
  ];

  return (
    <>
      <CustomDialog open={open} title="Thêm/Chỉnh sửa mẫu kiểm tra" onClose={onClose} onSubmit={handleSubmit} submitLabel="Lưu">
        <FormFields fields={formFields} />
      </CustomDialog>
    </>
  );
};
