import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { GenericFilters } from '@/components/dashboard/filter/genericFilter';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1'; 
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { PartDialog } from './partsDialog';


interface Manufacturer {
  name: string;
}

interface MeasurementUnit {
  name: string;
}

export interface Part {
  id: string;

  manufacturerId: number;
  manufacturer: Manufacturer ;
  manufacturerPartNumber: string;
  manufacturerUPC: string;

  partNumber: string; 
  pictureUrl: string;
  name: string;
  description: string;
  measurementUnitId: number;
  measurementUnit: MeasurementUnit;

  availableQuantity: number;  
  warrantyFlg: boolean;
  durationWarranty: number;   
  unitCost: number;   
  unitPrice: number;

  createdAt: Date;    
  updateAt: Date;
  
  customFields: Record<string, string>; 
}

function applyPagination(rows: Part[], page: number, rowsPerPage: number): Part[] {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export default function PartsPage(): React.JSX.Element {    
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5); // Adjust number of rows per page as necessary
  const [data, setData] = React.useState<Part[]>([]); // Customer data state
  const [filteredData, setFilteredData] = React.useState<Part[]>([]); // Filtered data state
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedPart, setSelectedPart] = React.useState<Part | null>(null);
  
  const fetchData = async () => {
    try {
      const response = await axios.get('/api/parts');
      const sortedData = response.data.sort((a: Part, b: Part) => {a.partNumber.localeCompare(b.partNumber)});
      setData(sortedData);
      setFilteredData(sortedData)
    } catch (error) {
      console.error('Error fetching data:', error); 
    }
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  const paginatedCustomers = applyPagination(filteredData, page, rowsPerPage);


  const handleFilterChange = (column: string, searchTerm: string | boolean | null | Dayjs ) => {
    const filtered = data.filter((part) => {
      let value = part[column as keyof Part]; // Access the column value dynamically  

      if (searchTerm === null) {
        return true;  
      }

      switch (column) {
        case 'manufacturerName': 
          value = part.manufacturer?.name || "";
          break;
        case 'measurementUnitName':
          value = part.measurementUnit?.name || "";
          break;
      } 
      
      if (typeof value === 'string' && typeof searchTerm === 'string') {
        return value.toLowerCase().includes(searchTerm.toLowerCase()); // Case-insensitive search for strings
      }
      
      if (typeof value === 'number' && typeof searchTerm === 'number') {
      return value === Number(searchTerm); // Match numbers exactly
      }
    
      if (typeof value === 'boolean') {
        return value === Boolean(searchTerm); // Match boolean values exactly
      }
      
   
      return false; // Default return false if value type does not match the expected types
    });

    setFilteredData(filtered);  
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => { 
    setRowsPerPage(newRowsPerPage); 
    setPage(0);
  };

  const handleOpenDialog = () => {
    setSelectedPart(null); // Reset selected customer when adding a new one
    setOpenDialog(true);
  };

  const handleEditPart = (id: string) => {
    const partToEdit = data.find((part) => part.id === id);
    if (partToEdit) {
      setSelectedPart(partToEdit); // Populate the dialog with the selected customer
      setOpenDialog(true); // Open dialog for editing
    }
  };

  const handleDeletePart = async (id: string) => {
    const confirmed = window.confirm('Bạn có chắc là xóa thẻ này?');
    if (confirmed) {
      try {
        await axios.delete(`/api/parts/${JSON.stringify(id)}`);
        setData((prevData) => prevData.filter((part) => part.id !== id));
        setFilteredData((prevData) => prevData.filter((part) => part.id !== id));
      } catch (error) {
        console.error('Error deleting part:', error);
      }
    }
  };
  
  const handleDeletePartSelected = async (selectedIds: string[]) => {
    const confirmed = window.confirm('Bạn có chắc là xóa những thẻ đã chọn?'); 
    if (confirmed) {
      try{
          await axios.delete(`/api/parts/${JSON.stringify(selectedIds)}`);
          alert('Xóa thành công');
          setData((prevData) => prevData.filter((part) => !selectedIds.includes(part.id)));
          setFilteredData((prevData) => prevData.filter((part) => !selectedIds.includes(part.id)));  
      } catch (error){
        console.log('Error deleting part:', error);
      }
    }
  }

  const handleAddOrEditPart = async (partData: Partial<Part>, imageFile?: File) => {
    try {
      const formData = new FormData();

      Object.keys(partData).forEach((key) => {
        const value = partData[key as keyof Part];
        if (value !== undefined) {
          formData.append(key, value as string | Blob);
        }
      });

      if (partData.customFields) {
        formData.set('customFields', JSON.stringify(partData.customFields));
      }

      // Append the image file, if it exists
      if (imageFile) {
        formData.append('picture', imageFile); // 'picture' is the field name for the image file
      }
      
      
      if (selectedPart) {
        const response = await axios.put(`/api/parts/${selectedPart.id}`, formData, { 
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
          },
        });
        const updatedPart = response.data;

        setData((prevData) => 
          prevData.map((part) => (part.id === updatedPart.id ? updatedPart : part))
        );

        setFilteredData((prevData) => 
          prevData.map((part) => (part.id === updatedPart.id ? updatedPart : part)) 
        );
      } else {
        const response = await axios.post('/api/parts', formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
          },
        }); 
        
        const newPart = response.data;
        setData((prevData) => [ ...prevData, newPart]);  
        setFilteredData((prevData) => [ ...prevData, newPart]); 
      }

      setOpenDialog(false); 
    } catch (error) {
      alert('Lỗi thêm/cập nhập ther linh kiện. Hãy kiểm tra các ô lại.');
      console.error('Lỗi thêm/cập nhập người dùng:', error);
    }
  };

  const filterColumns = [
    { value: 'name', label: 'Tên' },
    { value: 'partNumber', label: 'Số thẻ' }, 
    { value: 'manufacturerName', label: 'Nhà sản xuất' },
    { value: 'manufacturerPartNumber', label: 'Mã số sản phẩm (theo nhà sản xuất)' },
    { value: 'manufacturerUPC', label: 'Mã số UPC (theo nhà sản xuất)' },
    { value: 'measurementUnitName', label: 'Đơn vị' },
    { value: "warrantyFlg", label: 'Bảo hành', type: 'dropdown' as const, options: [{ label: "Có✅", value: true  }, { label: "Không❌", value: false }] ,
    }, 
  ];

  const warrantyMap: Record<string, string> = {
    true: "Có ✅",
    false: "Không ❌",
  };
  
  const columns: TableColumn<Part>[] = [
    { label: 'Ảnh', field: 'pictureUrl', isImage: true },
    { label: 'Mã số linh kiện', field: (row) => row.partNumber },
    { label: 'Nhà sản xuất', field: (row) => row.manufacturer?.name || "" },
    { label: 'Mã số sản phẩm (theo nhà sản xuất)', field: (row) => row.manufacturerPartNumber },
    { label: 'Mã số UPC (theo nhà sản xuất)', field: (row) => row.manufacturerUPC },
    { label: 'Tên', field: (row) => row.name },
    { label: 'Đơn vị', field: (row) => row.measurementUnit?.name || "" },
    { 
      label: 'Bảo hành', 
      field: (row) => warrantyMap[String(row.warrantyFlg)] || "Không xác định", 
    },
    { label: 'Thời gian bảo hành (tháng)', field: "durationWarranty" },
    {
      label: 'Chi phí trên 1 linh kiện (đồng)', 
      field: (row) => row.unitCost.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' }), 
    },
    {
      label: 'Giá trên 1 đơn vị (đồng)', 
      field: (row) => row.unitPrice.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' }), 
    },
  ];
  

  return (
    <Stack spacing={3}>
    <Stack direction="row" spacing={3}>
      <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
        <Typography variant="h4">Linh kiện</Typography>
      </Stack>
      <div>
        <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={handleOpenDialog}>
          Thêm
        </Button>
      </div>
    </Stack>
    <GenericFilters columns={filterColumns} onFilterChange={handleFilterChange} placeholder="Tìm thẻ" />
    <GenericTable
      count={filteredData.length}
      page={page}
      rows={paginatedCustomers}
      columns={columns}
      rowsPerPage={rowsPerPage}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      onEdit={handleEditPart}
      onDelete={handleDeletePart}
      onDeleteSelected={handleDeletePartSelected}
    />

    <PartDialog 
      open={openDialog}
      onClose={() => setOpenDialog(false)}  
      onSubmit={handleAddOrEditPart}  
      partData={selectedPart ?? undefined}
      refreshData={fetchData} 

    />
   
  </Stack>
  );


};