import * as React from 'react';
import { Button, Stack, Typography, Menu, MenuItem } from '@mui/material';
import { Calendar, dateFnsLocalizer, Views, View} from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import vi from 'date-fns/locale/vi';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios, { AxiosResponse } from 'axios';
import { VehicleAssignDialog } from './vehicleAssignDialog';
import { ResizableBox, ResizeCallbackData } from 'react-resizable';  // Import ResizeCallbackData
import 'react-resizable/css/styles.css';  // Import the styles for react-resizable
import { CustomToolbar } from '@/assets/data/calendarCustom';  // Import the custom toolbar  

export interface VehicleAssignment {
  id: string;
  vehicleId: number;
  vehicleName: string;
  userId: number;
  username: string;
  startKilometer: number;
  endKilometer: number; 
  startDate: Date;
  endDate: Date;
  notes?: string;
}

const locales = {
  vi: vi,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { locale: vi }),
  getDay,
  locales,
});



export default function VehicleAssignmentPage(): React.JSX.Element {
  const [assignments, setAssignments] = React.useState<VehicleAssignment[]>([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [newAssignment, setNewAssignment] = React.useState<Partial<VehicleAssignment>>({});
  const [view, setView] = React.useState<View>(Views.MONTH);
  const [contextMenu, setContextMenu] = React.useState<{ mouseX: number; mouseY: number; assignment: VehicleAssignment | null } | null>(null);
  const [calendarHeight, setCalendarHeight] = React.useState(500);  // Add state for calendar height
  const [currentRange, setCurrentRange] = React.useState<{ start: Date, end: Date }>({
    start: new Date(moment().subtract(6, 'months').toDate()),
    end: new Date(moment().add(6, 'months').toDate()),
  });

  const fetchAssignments = async (startDate: Date, endDate: Date) => {
    try {
      const response = await axios.get('/api/assignments-vehicles', {
        params: { startDate: startDate.toISOString(), endDate: endDate.toISOString() },
      });
  
      console.log('startDate / endDate', startDate, endDate); 
      console.log('response.data', response.data);
  
      setAssignments(response.data);
    } catch (error) {
      console.error('Error fetching assignments:', error);
    }
  };
  
  
  React.useEffect(() => {
    fetchAssignments(currentRange.start, currentRange.end);
  }, [])

  const handleOpenDialog = () => {
    setNewAssignment({});
    setOpenDialog(true);
  };


  const handleAddOrUpdateAssignment = async (vehicleAssignData: Partial<VehicleAssignment>) => {
    try {
      let response: AxiosResponse<VehicleAssignment>;
  
      if (vehicleAssignData.id) {
        // Edit existing assignment
        response = await axios.put<VehicleAssignment>(
          `/api/assignments-vehicles/${vehicleAssignData.id}`,
          vehicleAssignData
        );
      } else {
        // Add new assignment
        response = await axios.post<VehicleAssignment>(
          '/api/assignments-vehicles',
          vehicleAssignData
        );
      }
  
      // Update the assignments state
      setAssignments((prev) =>
        vehicleAssignData.id
          ? prev.map((assignment) =>
              assignment.id === response.data.id ? response.data : assignment
            )
          : [...prev, response.data]
      );
  
      setOpenDialog(false);
      setNewAssignment({});
      fetchAssignments(currentRange.start, currentRange.end);
    } catch (error: any) {
      // If a conflict is detected, handle it
      if (error.response && error.response.status === 409) {
        window.alert(
          'Đã có xe hoặc người đảm nhiệm được giao xe trong trong ngày này. Hãy kiểm tra lại'
        );

          return;
      } else {
        console.error('Error adding or updating assignment:', error);
      }
    }
  };
  

  const handleContextMenu = (event: React.MouseEvent, assignment: VehicleAssignment) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      assignment,
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleEdit = () => {
    if (contextMenu?.assignment) {
      setNewAssignment(contextMenu.assignment);
      setOpenDialog(true);
    }
    handleCloseContextMenu();
  };

  const handleDelete = async (id: string) => {
    try {
      await axios.delete(`/api/assignments-vehicles/${id}`);
      setAssignments((prev) => prev.filter((assignment) => assignment.id !== id));
    } catch (error) {
      console.error('Error deleting assignment:', error);
    }
    setOpenDialog(false);
    handleCloseContextMenu();
  };

  const events = assignments.map((assignment) => ({
    id: assignment.id,
    title: `Xe ${assignment.vehicleName} giao cho ${assignment.username}`,
    start: new Date(assignment.startDate),
    end: new Date(assignment.endDate),
  }));

  // Custom event component to handle right-click
  const CustomEvent = ({ event }: { event: { id: string; title: string; start: Date; end: Date } }) => {
    const assignment = assignments.find(a => a.id === event.id);
    return (
      <div onContextMenu={(e) => handleContextMenu(e, assignment!)}>
        {event.title}
      </div>
    );
  };

  const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCalendarHeight(Number(e.target.value));
  };

  const getDynamicStyles = () => {
    // Adjust font size, row height, and other properties based on the calendar height
    const fontSize = `${Math.max(12, calendarHeight / 50)}px`; // Adjust font size dynamically
    const rowHeight = `${Math.max(20, calendarHeight / 10)}px`; // Adjust row height dynamically

    return {
      fontSize,
      rowHeight,
    };
  };

  const dynamicStyles = getDynamicStyles();  // Get the dynamic styles


  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Typography variant="h4">Quản lý thời gian giao xe</Typography>
        <Button variant="contained" onClick={handleOpenDialog}>
          Thêm
        </Button>
      </Stack>

      {/* Height Slider */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <button onClick={() => setCalendarHeight((prev) => Math.max(300, prev - 50))}>🔍−</button>
        <input
          type="range"
          min="300"
          max="1000"
          value={calendarHeight}
          onChange={handleHeightChange}
          style={{ margin: '0 10px', width: '200px' }}
        />
        <button onClick={() => setCalendarHeight((prev) => Math.min(800, prev + 50))}>🔍+</button>
      </div>

      {/* Wrap the calendar inside a resizable box */}
      <ResizableBox
        width={Infinity}
        height={calendarHeight}  // Set height based on state
        minConstraints={[300, 300]}  // Minimum size of the calendar
        maxConstraints={[Infinity, 1000]}  // Maximum size of the calendar
        onResize={(e, data) => setCalendarHeight(data.size.height)}  // Handle resize event
      >
     <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        defaultView="month"
        views={{ month: true, week: true, day: true }}
        components={{
          event: CustomEvent,
          toolbar: CustomToolbar, // Attach the custom toolbar here
          month: {
            // Customize the weekday headers for the month view
            header: ({ date }) => (
              <div style={{ fontSize: dynamicStyles.fontSize }}>
                {/* Format weekday in Vietnamese */}
                {format(date, 'EEEE', { locale: vi })}
              </div>
            ),
          },
          week: {
            // Customize the time headers for the week view
            header: ({ date }) => (
              <div style={{ fontSize: dynamicStyles.fontSize }}>
                {/* Format weekday in Vietnamese */}
                {format(date, 'EEEE, dd', { locale: vi })}
              </div>
            ),
          },
          day: {
            // Customize the time headers for the day view
            header: ({ date }) => (
              <div style={{ fontSize: dynamicStyles.fontSize }}>
                {/* Format day name in Vietnamese */}
                {format(date, 'EEEE, dd MMMM yyyy', { locale: vi })}
              </div>
            ),
          },
        }}
        style={{
          height: calendarHeight,
          fontSize: dynamicStyles.fontSize,
        }}
      />

      </ResizableBox>

      <Menu
        open={!!contextMenu}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={() => handleDelete(contextMenu?.assignment?.id!)}>Delete</MenuItem>
      </Menu>

      <VehicleAssignDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSubmit={handleAddOrUpdateAssignment}
        onDelete={handleDelete}
        vehicleAssignData={newAssignment ?? undefined}
      />
    </Stack>
  );
}

  // Detect when the user navigates to a different month and fetch more data if needed
  // const handleViewChange = (newViewDate: Date) => {
  //   const newViewStart = new Date(moment(newViewDate).startOf('month').toDate());
  //   const newViewEnd = new Date(moment(newViewDate).endOf('month').toDate());
  
  //   // Check if the new view falls outside the current range
  //   if (newViewEnd > currentRange.end) {
  //     const newEndRange = new Date(moment(newViewEnd).add(6, 'months').toDate());
  //     setCurrentRange((prev) => ({
  //       ...prev,
  //       end: newEndRange,
  //     }));
  //     fetchAssignments(currentRange.start, newEndRange);  // Fetch data for the next 3 months
  //   } else if (newViewStart < currentRange.start) {
  //     const newStartRange = new Date(moment(newViewStart).subtract(6, 'months').toDate());
  //     setCurrentRange((prev) => ({
  //       ...prev,
  //       start: newStartRange,
  //     }));
  //     fetchAssignments(newStartRange, currentRange.end);  // Fetch data for the previous 3 months
  //   }
  // };
  