import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { CustomSelectWithAdd , CustomSelectWithAddProps } from '@/components/dashboard/customSelectWithAdd/customSelectWithAdd';

const FormThings = ({
    formFields,
    departments,
    jobTitles,
    groups,
    departmentId,
    setDepartmentId,
    jobTitleId,
    setJobTitleId,
    groupId,
    setGroupId,
    addDepartment,
    editDepartment,
    deleteDepartment,
    addJobTitle,
    editJobTitle,
    deleteJobTitle,
    addGroup,
    editGroup,
    deleteGroup,
  }: {
    formFields: FormField[];
    departments: any[];
    jobTitles: any[];
    groups: any[];
    departmentId: number | null;
    setDepartmentId: (id: number) => void;
    jobTitleId: number | null;
    setJobTitleId: (id: number) => void;
    groupId: number | null;
    setGroupId: (id: number) => void;
    addDepartment:(item: string) => void;
    editDepartment: (id: number, newName: string) => void;
    deleteDepartment: (id: number) => void;
    addJobTitle: (item: string) => void;
    editJobTitle: (id: number, newName: string) => void;
    deleteJobTitle: (id: number) => void;
    addGroup: (item: string) => void;
    editGroup: (id: number, newName: string) => void;
    deleteGroup: (id: number) => void;
  }) => {
    return (
      <form>
        {formFields.map((field, index) => (
          <div key={index}>
            {/* Render form fields */}
            <FormFields fields={[field]} />
  
            {/* Inject custom select components at specific positions */}
            {index === 9 && (
              <CustomSelectWithAdd
                label='Bộ Phận'
                items={departments}
                value={departmentId || 0}
                onChange={setDepartmentId}
                addItem={addDepartment}
                editItem={editDepartment}
                deleteItem={deleteDepartment}
              />
            )}
            {index === 9 && (
              <CustomSelectWithAdd
                label='Chức vụ'
                items={jobTitles}
                value={jobTitleId || 0}
                onChange={setJobTitleId}
                addItem={addJobTitle}
                editItem={editJobTitle}
                deleteItem={deleteJobTitle}
              />
            )}
            {index === 9 && (
              <CustomSelectWithAdd
                label='Nhóm'
                items={groups}
                value={groupId || 0}
                onChange={setGroupId}
                addItem={addGroup}
                editItem={editGroup}
                deleteItem={deleteGroup}
              />
            )}
          </div>
        ))}
      </form>
    );
};
  
  export default FormThings;