import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CustomSelectWithAdd } from '@/components/dashboard/customSelectWithAdd/customSelectWithAdd';
import { UseFetchAccount, UseFetchVechicle } from '@/hooks/fetch-relationship-field';
import { VehicleAssignment } from './vehicleAssign';
import { customVi } from '@/assets/data/calendarCustom';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';  

export interface VehicleAssignDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (vehicleAssignData: Partial<VehicleAssignment>) => void;
  onDelete: (id: string) => void;
  vehicleAssignData?: Partial<VehicleAssignment>;
  refreshData?: () => void;
}

export const VehicleAssignDialog: React.FC<VehicleAssignDialogProps> = ({
  open,
  onClose,
  onSubmit,
  onDelete,
  vehicleAssignData,
  refreshData,
}) => {
  const [formData, setFormData] = React.useState<Partial<VehicleAssignment>>(vehicleAssignData || {});
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

  const { assignees } = UseFetchAccount();
  const { vehicles } = UseFetchVechicle();

  const handleSubmit = async () => {
    const newErrors: { [key: string]: string } = {};

    if (!formData.vehicleId) {
      newErrors.vehicleId = 'Vui lòng chọn xe.';
    }

    if (!formData.userId) {
      newErrors.userId = 'Vui lòng chọn người dùng.';
    }

    if (!formData.startDate) {
      newErrors.startDate = 'Vui lòng nhập ngày bắt đầu.';
    }

    if (!formData.endDate) {
      newErrors.endDate = 'Vui lòng nhập ngày kết thúc.';
    }

    if (formData.startDate && formData.endDate && formData.endDate < formData.startDate) {
      newErrors.endDate = 'Ngày kết thúc phải sau ngày bắt đầu.';
    }

    if (
      formData.startKilometer === undefined ||
      formData.startKilometer === null ||
      formData.startKilometer < 0
    ) {
      newErrors.startKilometer = 'Số km bắt đầu phải là số không âm.';
    }

    if (
      formData.endKilometer !== undefined && formData.startKilometer !== undefined &&
      formData.endKilometer < formData.startKilometer
    ) {
      newErrors.endKilometer = 'Số km kết thúc phải lớn hơn hoặc bằng số km bắt đầu.';
    }
    
    

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Clear errors if validation passes
    setErrors({});

    onSubmit({
      ...formData,
      vehicleId: Number(formData.vehicleId),
      userId: Number(formData.userId),
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Handle checkbox separately to update the state with boolean values
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  React.useEffect(() => {
    if (vehicleAssignData) {
      setFormData({
        ...vehicleAssignData,
        startDate: vehicleAssignData.startDate ? new Date(vehicleAssignData.startDate) : undefined,
        endDate: vehicleAssignData.endDate ? new Date(vehicleAssignData.endDate) : undefined,
      });
    } else {
      setFormData({});
    }
  }, [vehicleAssignData]);

  const formFields: FormField[] = [
    {
      label: 'Kilometer bắt đầu *',
      name: 'startKilometer',
      type: 'number',
      value: formData.startKilometer || '',
      handleChange: handleChange,
      error: errors.startKilometer,
    },
    {
      label: 'Kilometer kết thúc',
      name: 'endKilometer',
      type: 'number',
      value: formData.endKilometer || '',
      handleChange: handleChange,
      error: errors.endKilometer,
    },
    {
      label: 'Ghi Chú',
      name: 'notes',
      type: 'text',
      value: formData.notes || '',
      handleChange: handleChange,
    },
  ];

  // the date and time here will showing local time from the utc time 
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Giao xe</DialogTitle>
      <DialogContent>
        <CustomSelectWithAdd
          label="Phương tiện *"
          items={vehicles}
          value={formData.vehicleId || 0}
          onChange={(value) => setFormData((prev) => ({ ...prev, vehicleId: value }))}
          manageItem='/dashboard/vehicles'
        />
        {errors.vehicleId && <p style={{ color: 'red' }}>{errors.vehicleId}</p>}

        <CustomSelectWithAdd
          label="Người được giao *"
          items={assignees}
          value={formData.userId || 0}
          onChange={(value) => setFormData((prev) => ({ ...prev, userId: value }))}
          manageItem='/dashboard/employees'
        />
        {errors.userId && <p style={{ color: 'red' }}>{errors.userId}</p>}

        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={customVi} // Apply custom weekday labels
        >
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Ngày bắt đầu *</strong>
          </Typography>
          <DateTimePicker
            value={formData.startDate || null}
            onChange={(date: Date | null) => setFormData((prev) => ({ ...prev, startDate: date || undefined }))}
            slotProps={{
              textField: {
                fullWidth: true,
                margin: 'dense',
                placeholder: 'dd/MM/yyyy HH:mm',
              },
            }}
          />
          {errors.startDate && <p style={{ color: 'red' }}>{errors.startDate}</p>}


          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Ngày kết thúc *</strong>
          </Typography>
          <DateTimePicker
            value={formData.endDate || null}
            onChange={(date: Date | null) => setFormData((prev) => ({ ...prev, endDate: date || undefined }))}
            slotProps={{
              textField: {
                fullWidth: true,
                margin: 'dense',
                placeholder: 'dd/MM/yyyy HH:mm',
              },
            }}
          />
          {errors.endDate && <p style={{ color: 'red' }}>{errors.endDate}</p>}
        </LocalizationProvider>


        {formFields.map((field, index) => (
          <div key={index}>
            {/* Render form fields */}
            <FormFields fields={[field]} />

            </div>
        ))}
  
  
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {formData.id && (  // Only show the Delete button if there is an ID
          <Button
            onClick={() => onDelete(formData.id!)}  // Call onDelete with the assignment ID
            variant="contained"
            color="error"
          >
            Xóa
          </Button>
        )}
        <Button onClick={handleSubmit} variant="contained">
          Lưu
        </Button>
      </DialogActions>
    </Dialog>
  );
};
