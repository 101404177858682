import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography'; 
import { Button } from '@mui/material';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1'; 
import axios from 'axios';
import { formatDate } from '@/hooks/format-date';
import { PieChart, Pie, Sector, Cell, Label, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import InfoIcon from '@mui/icons-material/Info';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';


export interface FuelTankHistory {
    id: string;
    fuelTankId: string;
    quantityChange: number;
    previousQuantity: number;   
    newQuantity: number;    
    date: Date | string;
    sourceType: string;
    sourceId: number;   
    createdAt: Date | string;
    updateAt: Date | string;   
}

interface FuelTankHistoryProps {
    fuelTankId: string;
    fuelTankCapacity: number;    
    fuelTankThreshHold: number;
}

function applyPagination(rows: FuelTankHistory[], page: number, rowsPerPage: number): FuelTankHistory[] {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export default function FuelTankHistory({ fuelTankId, fuelTankCapacity, fuelTankThreshHold }: FuelTankHistoryProps): React.JSX.Element {   
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5); // Adjust number of rows per page as necessary
    const [data, setData] = React.useState<FuelTankHistory[]>([]); // Inspection data state
    const [filteredData, setFilteredData] = React.useState<FuelTankHistory[]>([]); // Filtered data state
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedFuelTankHistory, setSelectedFuelTankHistory] = React.useState<FuelTankHistory | null>(null);

    const fetchData = async () => {
        try {
            const response = await axios.get(`/api/fuel-tanks/fuel-tank-history/${fuelTankId}`);
            
            const sortedData = response.data.sort((a: FuelTankHistory, b: FuelTankHistory) => {
                const dateA = new Date(a.date).toISOString(); // Convert to ISO string for consistent comparison
                const dateB = new Date(b.date).toISOString(); 
                return dateB.localeCompare(dateA);
            });
    
            setData(sortedData);
            setFilteredData(sortedData);
        } catch (error) {
            console.error("error fetching data: ", error);
        }
    };
    
    React.useEffect(() => {
        fetchData();
    }, []);

    const handlePageChange = (newPage: number) => { 
        setPage(newPage);
    };

    const handleRowsPerPageChange = (newRowsPerPage: number) => { 
        setRowsPerPage(newRowsPerPage); 
        setPage(0);
    };

    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);
  
    const chartData = data.map((item) => ({
      date: formatDate(item.date), // Format the date
      newQuantity: item.newQuantity,
    }));
  

    const latestData = filteredData.length > 0 ? filteredData.reduce((latest, item) => new Date(item.date) > new Date(latest.date) ? item : latest, filteredData[0]) : null;
    const currentFuelLevel = latestData?.newQuantity || 0;
  
    const fuelPercentage = (currentFuelLevel / fuelTankCapacity) * 100;

    const pieData = [
    { name: 'Threshold', value: Math.max(0, fuelTankThreshHold - fuelPercentage) }, // Light red for threshold
      { name: 'Current Level', value: fuelPercentage },
      { name: 'Remaining Capacity', value: 100 - Math.max(fuelTankThreshHold, fuelPercentage) },
    ];

    const thresholdAngle = (fuelTankThreshHold / 100) * 360;

    const columns: TableColumn<FuelTankHistory>[] = [   
        { label: 'SL thay đổi', field: 'quantityChange', customRender: (row) => row.sourceType === 'FuelInput'  ? <Typography color="success">+{row.quantityChange}</Typography> : <Typography color="error">-{row.quantityChange}</Typography> },
        { label: 'SL trước', field: (row) => row.previousQuantity },
        { label: 'SL sau ', field: (row) => row.newQuantity },
        { label: 'Nguồn', field: (row) => fuelFromTypeMap[row.sourceType] },
        { label: 'Ngày', field: (row) => formatDate(row.date) },

    ];
    
    const fuelFromTypeMap: { [key in FuelTankHistory['sourceType']]: string } = {
        FuelEntry: 'Hóa đơn xăng (ra)',
        FuelInput: "Đổ Xăng (vào)"
    };

    return (    
        <Stack spacing={2}>   

            {latestData && (
                <Stack alignItems="center" spacing={2}>
                <Typography variant="h6">Mức nhiên liệu hiện tại</Typography>
                <PieChart width={300} height={300}>
                    <Pie
                    data={pieData}
                    cx="50%"
                    cy="50%"
                    innerRadius={80}
                    outerRadius={100}
                    startAngle={90}
                    endAngle={450}
                    dataKey="value"
                    >
                                            <Cell key="Threshold" fill="#FFCCCC" /> {/* Light red for threshold */}          

                    <Cell key="Current Level" fill={fuelPercentage <= fuelTankThreshHold ? '#FF0000' : '#0088FE'} />
                    <Cell key="Remaining Capacity" fill="#FFBB28" />
                    </Pie>

                     {/* Add Threshold Indicator */}
                    <Sector
                    cx={150}
                    cy={150}
                    innerRadius={70}
                    outerRadius={110}
                    startAngle={90 + thresholdAngle}
                    endAngle={90 + thresholdAngle + 2} // Small angle to represent the threshold line
                    fill="red"
                    />

                    <Label value={`${fuelPercentage.toFixed(2)}%`} position="center" />
                </PieChart>
                <Typography variant="body1">
                    Dung tích: {currentFuelLevel} / {fuelTankCapacity} ({fuelPercentage.toFixed(2)}%)
                </Typography>
                <Typography variant="body2" color={fuelPercentage < fuelTankThreshHold ? 'error' : 'textSecondary'}>
                    Ngưỡng báo thấp: {fuelTankThreshHold}%
                </Typography>
                </Stack>
            )}  

            <Stack direction="row" spacing={3}>
                <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                <Typography variant="h4">Lịch sử nạp</Typography>
                </Stack>
                <div>
                <Button startIcon={<InfoIcon />} variant="contained" onClick={handleOpenDialog}>
                    Coi chi tiết
                </Button>
                </div>
            </Stack>  

            <CustomDialog open={openDialog} title="Biểu đồ lịch sử chung" onClose={handleCloseDialog} >
                <AreaChart
                    width={700}
                    height={300}
                    data={chartData}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Area type="monotone" dataKey="newQuantity" stroke="#8884d8" fill="#8884d8" name='Mức xăng sau thay đổi' />
                </AreaChart>
            </CustomDialog>

            <GenericTable 
               count={filteredData.length}
               page={page}
               rows={applyPagination(filteredData, page, rowsPerPage)} // Use transformed data
               columns={columns}
               rowsPerPage={rowsPerPage}
               onPageChange={handlePageChange}
               onRowsPerPageChange={handleRowsPerPageChange}
               
            />

        </Stack>
    );
}