import * as React from 'react';
import { Typography } from '@mui/material';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { FuelEntry } from './FuelEntryPage'; 
import axios from 'axios';
import { FieldManagementDialog, DialogField } from '@/components/dashboard/fieldManagement/FieldManagement';
import  useCustomFieldsUpdate from '@/hooks/field-managementSubmit'; // Import the hook
import { UseFetchVechicle, UseFetchAccount, UseFetchFuelType, UseFetchSupplier, UseFetchFuelTank } from '@/hooks/fetch-relationship-field'; 
import FormThings from './formWithCustomSelectsFuelEntry'; // Import the form component
import { formatDate } from '@/hooks/format-date';

interface FuelEntryDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (fuelEntryData: Partial<FuelEntry>) => void;
  fuelEntryData?: Partial<FuelEntry>;
  refreshData: () => void;  
}

export const FuelEntryDialog: React.FC<FuelEntryDialogProps> = ({open, onClose, onSubmit, fuelEntryData, refreshData}) => {
  let isEditMode = !!fuelEntryData; // add another flag if chagne the edit mode mecha 
  const [fields, setFields] = React.useState<DialogField[]>([]);
  const [formData, setFormData] = React.useState<Partial<FuelEntry>>(fuelEntryData || {});
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [fuelEntryNumberLoading, setFuelEntryNumberLoading] = React.useState(false);  

  const [isFieldManagementOpen, setFieldManagementOpen] = React.useState(false);
  const [customFieldsData, setCustomFieldsData] = React.useState<Record<string, any>>({});
  
  const { drivers } = UseFetchAccount();
  const { vehicles } = UseFetchVechicle();
  const { fuelTypes, addFuelType, editFuelType, deleteFuelType } = UseFetchFuelType(); 
  const { fuelSuppliers } = UseFetchSupplier();
  const { fuelTanks } = UseFetchFuelTank(); 

  const [vehicleId, setVehicleId] = React.useState<number | null>(null);
  const [driverId, setDriverId] = React.useState<number | null>(null);  
  const [fuelTypeId, setFuelTypeId] = React.useState<number | null>(null);
  const [fuelFromId, setFuelFromId] = React.useState<number | null>(null);

  const fetchNextFuelEntryNumber = async () => {
    try {
      setFuelEntryNumberLoading(true);
      const response = await axios.get('/api/fuel-entry/next-fuel-entry-number');
      const nextFuelEntryNumber = response.data.nextFuelEntryNumber;
      setFormData(prevFormData => ({
        ...prevFormData,
        fuelEntryNumber: nextFuelEntryNumber,
      }));

    } catch (error) {
      console.error('Failed to fetch next vehicle number:', error);
    } finally {
      setFuelEntryNumberLoading(false);
    }
  };

  React.useEffect(() => {
    fetchFields();
  }, []);

  const fetchFields = async () => {
    try {
      const response = await axios.get('/api/fuel-entry-dialog-fields');
      setFields(response.data);
    } catch (error) {
      console.error('Failed to fetch fields:', error);
    } 
  }

  const addFieldToDatabase = async (field: DialogField): Promise<DialogField> => {  
    try {
      const response = await axios.post('/api/fuel-entry-dialog-fields', field);
      return response.data;
    } catch (error) {
      console.error('Failed to add dialog field:', error);
      return field;
    }
  }

  const updateFieldInDatabase = async (field: DialogField): Promise<DialogField> => {
    try {
      const response = await axios.put(`/api/fuel-entry-dialog-fields/${field.id}`, field);
      return response.data;
    } catch (error) {
      console.error('Error updating field:', error);
      throw error;
    }
  };

  const deleteFieldFromDatabase = async (id: number): Promise<void> => {
    try {
      await axios.delete(`/api/fuel-entry-dialog-fields/${id}`);
    } catch (error) {
      console.error('Error deleting field:', error);
      throw error;
    }
  };

  const { handleCustomFieldsUpdate } = useCustomFieldsUpdate(); // Use the custom

  const handleFieldManagementSubmit = (updatedFields: DialogField[]) => {
    handleCustomFieldsUpdate(
      fields,                         
      formData.customFields || {},     
      fuelEntryData?.customFields,      
      updatedFields,                   
      setCustomFieldsData,             
      setFormData,                     
      setFields,                       
      setFieldManagementOpen           
    );

    refreshData();  // <-- Trigger refresh after field management is done
  };

  const handleSubmit = async () => {
    if (validateForm()) {

      // require fuel entry number / vehicleId / date   
      const customFields: Record<string, any> = {}; 

      fields.forEach((field) => {
        if (field.canDelete) {
          // Merge custom fields with form data
          customFields[field.name] = formData[field.name as keyof typeof formData] || customFieldsData[field.name];
        }
      });

      onSubmit({
        ...formData,
        vehicleId: vehicleId ?? undefined,
        driverId: driverId ?? undefined,
        fuelTypeId: fuelTypeId ?? undefined,
        fuelFromId: fuelFromId ?? undefined,
        customFields,
      })
    }
  };

  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};
    fields.forEach(field => {
      const fieldValue = formData[field.name as keyof FuelEntry];

      if (field.required && (!fieldValue || typeof fieldValue === 'string' && !fieldValue.trim())) {
        newErrors[field.name] = `${field.label} cần có giá trị`;
      }
    });

    if (!vehicleId) {
      newErrors['vehicleId'] = 'Vui lòng chọn phương tiện';
    } 

    if (!formData.date) {
      newErrors['date'] = 'Vui lòng chọn ngày'; 
    }

    if (!formData.fuelEntryNumber) {
      newErrors['fuelEntryNumber'] = 'Vui lòng nhập mã số thẻ';
    }

    // if there is fuelFromType, fuelFromId is required 
    if (formData.fuelFromType && formData.fuelFromType !== 'None' && !fuelFromId) {
      newErrors['fuelFromId'] = 'Vui lòng chọn nguồn xăng';
    }

    setErrors(newErrors);
    // If there are errors, show them in an alert
    if (Object.keys(newErrors).length > 0) {
      const errorMessages = Object.values(newErrors)
        .map((message) => `+ ${message}`) // Add "+ " before each error message
        .join('\n'); // Combine all error messages

      alert(`Các mục bị lỗi:\n${errorMessages}`); // Show alert with errors
    }

    return Object.keys(newErrors).length === 0;
  };

  React.useEffect(() => {
    if (open) {
      if (isEditMode && fuelEntryData) {
        setFormData({...fuelEntryData, 
          date: formatDate(fuelEntryData?.date),
        });

        if (fuelEntryData.customFields) {
          try {
            const parsedCustomFields = typeof fuelEntryData.customFields === 'string' ? JSON.parse(fuelEntryData.customFields) : fuelEntryData.customFields;
            setCustomFieldsData(parsedCustomFields);
          } catch (error) {
            console.log('Failed to parse custom fields:', error);
          }
        }

        setVehicleId(fuelEntryData.vehicleId || null);
        setDriverId(fuelEntryData.driverId || null);  
        setFuelTypeId(fuelEntryData.fuelTypeId || null);
        setFuelFromId(fuelEntryData.fuelFromId || null);

      } else {
        setFormData({});
        setCustomFieldsData({});
        setVehicleId(null);
        setDriverId(null);
        setFuelTypeId(null);
        setFuelFromId(null);

        if (!isEditMode) {
          fetchNextFuelEntryNumber();
        }
      }
    } 
  }, [open, isEditMode]);

  const handleCustomFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Check if the input is a checkbox or a regular text input
    setCustomFieldsData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,  // If checkbox, store as boolean; else, store the value
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Update the state
    const updatedFormData = {
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    };
  
    // Automatically calculate `totalCost` if `unitCost`, `quantity`, or `taxRate` changes
    if (name === 'unitCost' || name === 'quantity' || name === 'taxRate' || name === 'taxFlg') {
      const unitCost = parseFloat(String(updatedFormData.unitCost || '0'));
      const quantity = parseFloat(String(updatedFormData.quantity || '0'));
      const taxRate = updatedFormData.taxFlg ? parseFloat(String(updatedFormData.taxRate || '0')) : 0;
      const taxMultiplier = 1 + taxRate / 100;
  
      updatedFormData.totalCost = parseFloat((unitCost * quantity * taxMultiplier).toFixed(2));
    }
  
    // Reset `fuelFromId` when `fuelFromType` changes
    if (name === 'fuelFromType') {
      updatedFormData.fuelFromId = undefined;
      setFuelFromId(null);
    }
  
    setFormData(updatedFormData);
  };
  
  const formFields: FormField[] = fields
  .map(field => {
    if (field.name === 'fuelEntryNumber') {
      return {
        label: 'Mã số thẻ',
        name: 'fuelEntryNumber',
        type: 'text',
        value: formData.fuelEntryNumber || '',
        required: true,
        error: errors['fuelEntryNumber'],
      };
    } else if (field.name === 'taxRate' && !formData.taxFlg) {
      // Return null for taxRate if taxFlg is not checked
      return null;
    } else {
      const isTotalCost = field.name === 'totalCost';
      return {
        label: field.label,
        name: field.name,
        type: field.type === 'fuelFromType' ? 'select' : field.type,
        options: field.name === 'fuelFromType'
          ? [
              { label: 'Khác', value: 'None' },
              { label: 'Nhà cung cấp', value: 'Vendor' },
              { label: 'Kho xăng', value: 'FuelTank' },
            ]
          : undefined,
        value: formData[field.name as keyof FuelEntry] || customFieldsData[field.name] || '',
        required: field.required,
        ...(isTotalCost ? {} : { handleChange: field.canDelete ? handleCustomFieldChange : handleChange }),
        error: errors[field.name],
      };
    }
  })
  .filter(Boolean) as FormField[]; // Filter out null values and cast to FormField[]

  
  return (    
    <CustomDialog open={open} title="Thêm/Chỉnh sửa thông tin thẻ nhiên liệu" onClose={onClose} manageFields={() => setFieldManagementOpen(true)}
      onSubmit={handleSubmit} submitLabel="Lưu" dialogWidth='800px' dialogMaxWidth='95%'
      >
        <FormThings 
          formFields={formFields}
          vehicles={vehicles}
          vehicleId={vehicleId}
          setVehicleId={setVehicleId}
          drivers={drivers}
          driverId={driverId}
          setDriverId={setDriverId}
          fuelTypes={fuelTypes} 
          fuelTypeId={fuelTypeId}
          setFuelTypeId={setFuelTypeId}
          addFuelType={addFuelType}
          editFuelType={editFuelType}
          deleteFuelType={deleteFuelType}
          fuelFromType={formData.fuelFromType}
          fuelFromId={fuelFromId} 
          setFuelFromId={setFuelFromId} 
          fuelSuppliers={fuelSuppliers} 
          fuelTanks={fuelTanks}
        />

        <FieldManagementDialog
          open={isFieldManagementOpen}
          onClose={() => setFieldManagementOpen(false)}
          fields={fields}
          onFieldSubmit={handleFieldManagementSubmit}
          addFieldToDatabase={addFieldToDatabase}
          updateFieldInDatabase={updateFieldInDatabase}
          deleteFieldFromDatabase={deleteFieldFromDatabase}
        />

    </CustomDialog>
 )
};


