import React from 'react';
import './Header.css';
import Logo from './Logo';
import SearchBar from './SearchBar';
import Nav from './Nav';

interface HeaderProps {
    onToggleSidebar: () => void;
}

const Header: React.FC<HeaderProps> = ({ onToggleSidebar }) => {
    return (
        <header id='header' className="header fixed-top d-flex align-items-center">
            <Logo onToggleSidebar={onToggleSidebar} />  {/* Pass onToggleSidebar to Logo */}
            <SearchBar />
            <Nav />
        </header>
    );
}   

export default Header;