import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

interface CustomDialogProps {
  open: boolean;
  title: string;
  onClose: () => void;
  manageFields?: () => void; // Optional, so it can be undefined
  detailsFields?: () => void; // Optional, so it can be undefined 
  onSubmit?: () => void;
  children: React.ReactNode; // This will allow you to pass custom form content
  submitLabel?: string; // Optional submit button label
  onDelete?: () => void; // Optional delete button
  dialogWidth?: string; // Custom width for MuiDialog-paper
  dialogMaxWidth?: string; // Custom max-width for MuiDialog-paper
}

export const CustomDialog: React.FC<CustomDialogProps> = ({
  open,
  title,
  onClose,
  manageFields,
  detailsFields,
  onSubmit,
  children,
  onDelete,
  submitLabel = 'Lưu',
  dialogWidth = '800px', // Default width if not provided
  dialogMaxWidth = '95%', // Default max-width if not provided
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ '& .MuiDialog-paper': { width: dialogWidth, maxWidth: dialogMaxWidth } }}
    >    
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {children} {/* Render custom form content here */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Đóng</Button>
        {manageFields && (
          <Button onClick={manageFields} variant="contained">
            Thêm/Xóa ô nhập dữ liệu
          </Button>
        )}
        {detailsFields && (
          <Button onClick={detailsFields} variant="contained">
            Thêm/Chỉnh sửa nội dung tuyển dụng
          </Button>
        )}

        {/* optional delete button */}
        {onDelete && (
          <Button
            onClick={onDelete}
            variant="contained"
            color="error"
          >
            Xóa
          </Button>
        )}

      {onSubmit && (
        <Button 
          onClick={onSubmit} 
          variant="contained"
          sx={{ backgroundColor: 'green', '&:hover': { backgroundColor: 'darkgreen' } }}
        >
          {submitLabel}
        </Button>
        
      )}
      </DialogActions>
    </Dialog>
  );
};
