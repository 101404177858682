import * as React from 'react';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { Customer } from './Customer'; // Import your Customer type
import { CustomSelectWithAdd } from '@/components/dashboard/customSelectWithAdd/customSelectWithAdd';
import axios from 'axios';
import { FieldManagementDialog, DialogField } from '@/components/dashboard/fieldManagement/FieldManagement';
import useCustomFieldsUpdate from '@/hooks/field-managementSubmit'; // Import the hook

interface CustomerDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (customerData: Partial<Customer>) => void;
  customerData?: Partial<Customer>;
  refreshData: () => void; // <-- pass fetchData here
}

export const CustomerDialog: React.FC<CustomerDialogProps> = ({ open, onClose, onSubmit, customerData, refreshData }) => {
  let isEditMode = !!customerData;
  const [fields, setFields] = React.useState<DialogField[]>([]);
  const [formData, setFormData] = React.useState<Partial<Customer>>(customerData || {});

  // Error handling state
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

  // Field management dialog
  const [isFieldManagementOpen, setFieldManagementOpen] = React.useState(false);

  // Custom field state
  const [customFieldsData, setCustomFieldsData] = React.useState<Record<string, any>>({});

  React.useEffect(() => {
    fetchFields();
  }, []);

  const fetchFields = async () => {
    try {
      const response = await axios.get('/api/customer-dialog-fields');
      setFields(response.data);
    } catch (error) {
      console.error('Failed to fetch dialog fields:', error);
    }
  };

  const addFieldToDatabase = async (field: DialogField): Promise<DialogField> => {
    try {
      const response = await axios.post('/api/customer-dialog-fields', field);
      return response.data;
    } catch (error) {
      console.error('Failed to add dialog field:', error);
      return field;
    }
  }

  const updateFieldInDatabase = async (field: DialogField): Promise<DialogField> => {
    try {
      const response = await axios.put(`/api/customer-dialog-fields/${field.id}`, field);
      return response.data;
    } catch (error) {
      console.error('Error updating field:', error);
      throw error;
    }
  };

  const deleteFieldFromDatabase = async (id: number): Promise<void> => {
    try {
      await axios.delete(`/api/customer-dialog-fields/${id}`);
    } catch (error) {
      console.error('Error deleting field:', error);
      throw error;
    }
  };

  const { handleCustomFieldsUpdate } = useCustomFieldsUpdate(); // Use the custom

  const handleFieldManagementSubmit = (updatedFields: DialogField[]) => {
    handleCustomFieldsUpdate(
      fields,                         // Original fields before update
      formData.customFields || {},     // Current custom fields data
      customerData?.customFields,      // Customer custom fields
      updatedFields,                   // Updated fields
      setCustomFieldsData,             // Update customFieldsData
      setFormData,                     // Update formData
      setFields,                       // Update fields
      setFieldManagementOpen           // Close dialog
    );

    refreshData();  // <-- Trigger refresh after field management is done
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const customFields: Record<string, any> = {};

      fields.forEach((field) => {
        if (field.canDelete) {
          // Merge custom fields with form data
          customFields[field.name] = formData[field.name as keyof typeof formData] || customFieldsData[field.name];
        }
      });

      onSubmit({
        ...formData,
        customFields,  // Include custom fields in the submission
      });
    }
  };
  
  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};
    fields.forEach(field => {
      const fieldValue = formData[field.name as keyof Customer];

      // Handle required fields
      if (field.required && (!fieldValue || typeof fieldValue === 'string' && !fieldValue.trim())) {
        newErrors[field.name] = `${field.label} cần có giá trị`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  React.useEffect(() => {
    if (open) {
      if (customerData) {
        setFormData({
          ...customerData,
          // format data
        });

        // Populate custom fields data from customerData.customFields if it exists
        if (customerData.customFields) {
          try {
            const parsedCustomFields = typeof customerData.customFields === 'string' ? JSON.parse(customerData.customFields) : customerData.customFields;
            setCustomFieldsData(parsedCustomFields);
          } catch (error) {
            console.log('Error parsing custom fields:', error);
          }
        }

      } else {
        setFormData({
          name: '',
          accountNumber: '',
          website: '',
          email: '',
          phoneNumber: '',
          address: '',
          city: '',
          discount: 0,
        });
        setCustomFieldsData({});
      }
    }
  }, [open, customerData]);

  const handleCustomFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;

    console.log('custom Field changed:')


    // Handle custom field changes
    setCustomFieldsData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;

    console.log('Field changed:')

    // Handle general field changes
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const formFields: FormField[] = fields.map(field => ({
    label: field.label, // Directly use the label
    name: field.name,
    type: field.name === 'categoryFuelCustomer' || field.name === 'categoryServiceProvider' || field.name === 'categoryVehicleDealer' || field.name === 'categoryPartCustomer' || field.name === 'categoryFinancialServiceProvider' || field.name === 'categoryInsuranceCompany'
      ? 'checkbox'
      : field.type, // Checkboxes for specific fields, otherwise use the field's type
    value: formData[field.name as keyof Customer] || customFieldsData[field.name] || '',  // Custom field values included
    required: field.required, // Required flag as per field settings
    handleChange: field.canDelete
      ? handleCustomFieldChange  // Custom field change handler for deletable fields
      : handleChange, // General change handler
    error: errors[field.name], // Include error handling
  }));

  return (
    <CustomDialog open={open} title="Thêm/Chỉnh sửa khách hàng" onClose={onClose} manageFields={() => setFieldManagementOpen(true)}
    onSubmit={handleSubmit} submitLabel="Lưu" dialogWidth='800px' dialogMaxWidth='95%'>

      <FormFields fields={formFields} />

      <FieldManagementDialog
        open={isFieldManagementOpen}
        onClose={() => setFieldManagementOpen(false)}
        fields={fields}
        onFieldSubmit={handleFieldManagementSubmit}
        addFieldToDatabase={addFieldToDatabase}
        updateFieldInDatabase={updateFieldInDatabase}
        deleteFieldFromDatabase={deleteFieldFromDatabase}
      />

    </CustomDialog>
  );
};