import * as React from 'react';
import { Button, Badge, Typography, Stack } from '@mui/material';
import { Chip } from '@mui/material';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { GenericFilters } from '@/components/dashboard/filter/genericFilter';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1'; 
import axios from 'axios';
import { formatDate } from '@/hooks/format-date';
import { getContrastTextColor } from '@/hooks/contrast-text-color';
import { IssueDialog } from './IssuesDialog';
import { NotPassedToIssueDialog } from './notPassedToIssueDialog'; 
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs'; // Import dayjs to get the current date

interface Vehicle {
    vehicleNumber: string;
    name: string;
}

interface AssignPerson {
    username: string;
    firstName: string;
    lastName: string;
}

export interface IssuePriorityStatus{
    id: number;
    name: string;
    description: string;    
    color: string;
}

export interface Issues {
    id: string;
    issueNumber: string;
    vehicleId: number;
    vehicle: Vehicle;
    name: string;
    description: string;
    photoUrl: string;
    reportedAt: Date | string;
    primaryMeterValue: number;
    reportedById: number;
    reportedBy: AssignPerson; 
    assignedToId: number;
    assignedTo: AssignPerson;
    priorityId: number;
    priority: IssuePriorityStatus;
    dueDate: Date | string;
    resolved_flg: boolean;
    resolvedWithType: string;   
    resolvedNote: string;
    resolvedAt: Date | string;  
    createdAt: Date;    
    updateAt: Date;
    resolvedServiceEntryId: number;
    resolvedServiceEntry: any;
    notPassed_flg?: boolean; 
}

function applyPagination(rows: Issues[], page: number, rowsPerPage: number): Issues[] {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export default function IssuesPage(): React.JSX.Element {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5); // Adjust number of rows per page as necessary
  const [data, setData] = React.useState<Issues[]>([]); // Inspection data state
  const [filteredData, setFilteredData] = React.useState<Issues[]>([]); // Filtered data state
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedIssue, setSelectedIssue] = React.useState<Issues | null>(null);
  
  // for the data passed from the service entries
  const location = useLocation();
  const navigate = useNavigate(); // Add navigate for potential redirects

  React.useEffect(() => {
    if (location.state && (location.state as any).issueData) {
      const issueData = (location.state as any).issueData;
  
      // Extract the issue ID from the passed issueData
      const issueId = issueData.id;
  
      if (issueId) {
        // Check if the issue exists in the current state
        const existingIssue = data.find((issue) => issue.id === issueId);
  
        if (existingIssue) {
          // Open dialog with existing issue
          setSelectedIssue(existingIssue);
          setOpenDialog(true);
        } 
      }
    }
  }, [location, data]); // Add `data` as a dependency to react to changes in the dataset
  



  const fetchData = async () => {
    try {
      const response = await axios.get('/api/issues');
      const sortedData = response.data.sort((a: Issues, b: Issues) => a.name.localeCompare(b.name));
      setData(sortedData);
      setFilteredData(sortedData);  
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const paginatedInspections = applyPagination(filteredData, page, rowsPerPage);

  // comeback to this later if nececssary
  const handleFilterChange = (column: string, searchTerm: string | boolean | Dayjs | null) => {
    const filtered = data.filter((issue) => {
      const value = issue[column as keyof Issues]; // Access the column value dynamically
  
      if (searchTerm === null) {
        // If no searchTerm, include all items (e.g., dropdown initial state)
        return true;
      }
  
      if (typeof value === 'string') {
        return typeof value === 'string' && value.toLowerCase().includes((searchTerm as string).toLowerCase()); // Case-insensitive search for strings
      }
  
      if (typeof value === 'number') {
        return value === Number(searchTerm); // Match numbers exactly
      }
        // For dropdowns with predefined values, match exactly

      if (typeof value === 'string') {
        return value === searchTerm;
      }

      if (typeof value ==='boolean') {  
        return value === Boolean(searchTerm); // Match boolean values exactly
      }

      return false;  
      });
    
      setFilteredData(filtered);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0); 
  };

  const handleOpenDialog = () => {  
    setSelectedIssue(null);
    setOpenDialog(true);  
  };
  
  const handleEditIssues = (id: string) => {
    const issuetoEdit = data.find((issue) => issue.id === id);
    if (issuetoEdit) {
      setSelectedIssue({ ...issuetoEdit });
      setOpenDialog(true);
    } 
  };

  const handleDeleteIssue = async (id: string) => {
    const confirmed = window.confirm('Bạn có chắc bạn muốn xóa phần vấn đề này?');
    if (confirmed) {
      try {
        await axios.delete(`/api/issues/${JSON.stringify(id)}`);
        setData(data.filter((issue) => issue.id !== id)); 
        setFilteredData(filteredData.filter((issue) => issue.id !== id)); 

      } catch (error) {
        console.error('Error deleting issue:', error);
      }
    }   
  };

  const hanldeDeleteIssuesSelected = async (ids: string[]) => {  
    const confirmed = window.confirm('Bạn có chắc bạn muốn xóa phần vấn đề này?');
    if (confirmed) {
      try {
        await axios.delete(`/api/issues/${JSON.stringify(ids)}`);
        setData(data.filter((issue) => !ids.includes(issue.id))); 
        setFilteredData(filteredData.filter((issue) => !ids.includes(issue.id))); 
      } catch (error) {
        console.error('Error deleting issue:', error);
      }
    }
  }

  const handleAddOrEditIssue = async (IssueData: Partial<Issues>, imageFile?: File, notPassed_flg?: Boolean) => { 
    try {
      const formData = new FormData();


      Object.keys(IssueData).forEach((key) => { 
        const value = IssueData[key as keyof Issues];  
        if (value !== undefined) {
          formData.append(key, value as string);
        } 
      }); 
      
      if (imageFile) {
        formData.append('photo', imageFile);
      }

      if (selectedIssue && !notPassed_flg) {
        const response = await axios.put(`/api/issues/${selectedIssue.id}`, formData , {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }); 
        const updatedIssue = response.data; 

        setData((prevData) => 
          prevData.map((issue) => (issue.id === updatedIssue.id ? updatedIssue : issue))
        );
        setFilteredData((prevData) =>  
          prevData.map((issue) => (issue.id === updatedIssue.id ? updatedIssue : issue))
        );
    
      } else {
        const response = await axios.post('/api/issues', formData, {  
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        const newCustomer = response.data;
        setData((prevData) => [newCustomer, ...prevData]);  
        setFilteredData((prevData) => [newCustomer, ...prevData]);

      }

      setOpenDialog(false);

    } catch (error) {
      alert('Có lỗi xảy ra, vui lòng thử lại sau');
      console.error('Error adding or editing issue:', error);  
    }

  };

  const filterColumns = [
    { label: 'Số vấn đề', value: 'issueNumber' },
    { label: 'Mã số xe', value: 'vehicleNumber', },
    { label: 'Người báo', value: 'reportedBy' },
    { label: 'Người được giao', value: 'assignedTo' },
    { label: 'Mức độ ưu tiên', value: 'priority' },
  ];

  const columns: TableColumn<Issues>[] = [  
    { label: 'Mã Số vấn đề', field: 'issueNumber' },
    { label: 'Mã Số Xe', field: (row) => row.vehicle?.vehicleNumber || 'N/A' },
    { label: 'Tên Xe', field: (row) => row.vehicle?.name || 'N/A' },
    { label: 'Người báo', field: (row) => row.reportedBy?.username || 'N/A' },
    { label: 'Người được giao', field: (row) => row.assignedTo?.username || 'N/A' },
    { label: 'Mức độ ưu tiên', 
      field: (row) => {
        const bgColor = row.priority?.color || '#ccc'; // Default to gray if no color is found
        const textColor = getContrastTextColor(bgColor); // Get the text color based on the background color
        return (
          <Chip
          label={row.priority?.name || 'N/A'}
          style={{
            backgroundColor: row.priority?.color || '#ccc', // Use the color from the status
            color: textColor,
            borderRadius: '5px',
            padding: '5px 10px',
            fontWeight: 'bold',
          }}
        />
        )
      }
    },
    {
      label: 'Hoàn thành', field: 'resolved_flg', customRender: (row) => (row.resolved_flg ? 'Đã hoàn thành✅' : 'Chưa hoàn thành❌')
    },
    {
      label: 'Ảnh vấn đề', field: 'photoUrl', isImage: true
    },
    { label: 'Ngày báo cáo', field: 'reportedAt', customRender: (row) => formatDate(row.reportedAt) },
    { label: 'Ngày đến hạn', field: 'dueDate', customRender: (row) => formatDate(row.dueDate) },  
    { label: 'Đã giải quyết', field: 'resolved_flg', customRender: (row) => (row.resolved_flg ? 'Rồi✅' : 'Chưa giải quyết❌') },
  ];

  // for notification and add from not passed item
  const [notPassedData, setNotPassedData] = React.useState<[]>([]); // Inspection data state
  const [ openDialogNotPassed , setOpenDialogNotPassed] = React.useState(false);
  const [selectedFailedItem, setSelectedFailedItem] = React.useState<any>(null);
  
  const fetchNotPassedData = async () => { 
    try {
      const response = await axios.get('/api/inspections/failures');
      setNotPassedData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  React.useEffect(() => {
    fetchNotPassedData();
  }, []);

  const countFailedInspectionItems = (inspections: any[]) => {
    let totalFailedItems = 0;
  
    inspections.forEach((inspection) => {
      if (inspection.inspectionItems && Array.isArray(inspection.inspectionItems)) {
        const failedItemsCount = inspection.inspectionItems.filter((item: any) => item.status === 'Fail').length;
        totalFailedItems += failedItemsCount;
      }
    });
  
    return totalFailedItems;
  };
  
  // Use the function to calculate the notification count
  const notificationCount = countFailedInspectionItems(notPassedData);

  const handleAddFromInspection = (inspectionData: any) => {
    // Set the selected issue with pre-filled vehicleId and reportedById
    // clear the selected issue
    setSelectedIssue(null);
    setSelectedFailedItem(null);

    setSelectedIssue({
      ...selectedFailedItem,
      vehicleId: inspectionData.vehicleId,
      reportedById: inspectionData.reportedById,
      description: inspectionData.description || '', 
      notPassed_flg: true,
    });

    setOpenDialogNotPassed(false);

    setOpenDialog(true);
  };

  
  return (
    <Stack spacing={3}> 
      <Stack direction="row" spacing={3}>
      <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h4">Vấn đề</Typography>
        </Stack>
        <div >
          {/* Button with Notification Badge */}
          <Badge badgeContent={notificationCount > 9 ? '9+' : notificationCount} color="error" className="me-3">
            <Button
                startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />}
                variant="outlined"
                onClick={() => setOpenDialogNotPassed(true)}
              >
              Thêm vào từ các mục ko đạt
            </Button>
          </Badge>

          <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={handleOpenDialog}>
            Thêm
          </Button>
        </div>
      </Stack>

      <GenericFilters columns={filterColumns} onFilterChange={handleFilterChange} placeholder="Tìm" />


      <GenericTable
        count={filteredData.length}
        page={page}
        rows={paginatedInspections}
        columns={columns}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onEdit={handleEditIssues}
        onDelete={handleDeleteIssue}  
        onDeleteSelected={hanldeDeleteIssuesSelected} 
      />

      <IssueDialog open={openDialog} onClose={() => setOpenDialog(false)} onSubmit={handleAddOrEditIssue} issueData={selectedIssue ?? undefined} refreshData={fetchData}/>
      
          {/* Not Passed to Issue Dialog */}
      <NotPassedToIssueDialog
        open={openDialogNotPassed}
        onClose={() => setOpenDialogNotPassed(false)}
        onSubmit={(selectedItem) => {
          handleAddFromInspection(selectedItem); // Populate the dialog with the selected inspection data
        }}
        notPassedData={notPassedData}
      />

    </Stack>
  );
}

// This ensures that this file is treated as a module and resolves the TS1208 error
export {};
