import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { Chip, Button, Table } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Download as DownloadIcon } from '@phosphor-icons/react';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { Upload as UploadIcon } from '@phosphor-icons/react';
import { GenericFilters } from '@/components/dashboard/filter/genericFilter';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1';
import axios, { AxiosResponse } from 'axios';
import {DynamicDialog } from '@/components/subPlace/statusDialog';
import { timeIntervalUnit }  from '@/hooks/mapping'; // Import the function
import { Refresh, Rowing } from '@mui/icons-material';
import { functionMapping } from '@/hooks/mapping';
import dayjs, { Dayjs } from 'dayjs';
import { formatDate } from '@/hooks/format-date';
import { PartMeasurementUnitDialog } from './partMeasurementUnitDialog';
import {  WarehouseDialog } from './warehouseDialog';
import { ManufacturerDialog } from './manufacturerDialog';  

export interface PartMeasurementUnit {
  id: string;
  name: string
  shortName: string;  
  type: string;
  createdAt: string;
  updateAt: string;
}

export interface Warehouse {
  id: string;
  name: string;
  default_flg: boolean; 
  address: string;
  address2: string;
  city: string;
  createdAt: string;
  updateAt: string;
}

export interface PurchaseOrderStatus {
  id: string;
  name: string;
  description: string;
  color: string;
  function: string;
  createdAt: string;
  updateAt: string;  
}


export interface Manufacturer {  
  id: string;
  name: string;
  website: string;
  comment: string;
  createdAt: string;
  updateAt: string; 
}


function applyPagination<T>(data: T[], page: number, limit: number): T[] {
  return data.slice(page * limit, (page + 1) * limit);
}

export default function InventorySetupPage(): React.JSX.Element {

  const [ partMeasurementUnits, setPartMeasurementUnits ] = React.useState<PartMeasurementUnit[]>([]);  
  const [ warehouses, setWarehouses ] = React.useState<Warehouse[]>([]);
  const [ purchaseOrderStatus, setPurchaseOrderStatus ] = React.useState<PurchaseOrderStatus[]>([]);
  const [ manufacturers, setManufacturers ] = React.useState<Manufacturer[]>([]); 

  const [filteredData, setFilteredData] = React.useState({
    partMeasurementUnits: [] as PartMeasurementUnit[],
    warehouses: [] as Warehouse[],  
    purchaseOrderStatus: [] as PurchaseOrderStatus[],
    manufacturers: [] as Manufacturer[],
  });

  type TableKey = "partMeasurementUnits" | "warehouses" | "purchaseOrderStatus" | "manufacturers";  

  const paginationStatesInitial: Record<TableKey, { page: number; rowsPerPage: number }> = ({
    partMeasurementUnits: { page: 0, rowsPerPage: 5 },
    warehouses: { page: 0, rowsPerPage: 5 },
    purchaseOrderStatus: { page: 0, rowsPerPage: 5 }, 
    manufacturers: { page: 0, rowsPerPage: 5 }, 
  });

  const [paginationStates, setPaginationStates] = React.useState(paginationStatesInitial);  

  const tableLabels = [
    { key: 'partMeasurementUnits', label: 'Đơn vị cho linh kiện' },
    { key: 'warehouses', label: 'Kho' },
    { key: 'purchaseOrderStatus', label: 'Trạng thái đơn hàng' },
    { key: 'manufacturers', label: 'Nhà sản xuất lốp xe/linh kiện' },  
  ];

  const [visibleTables, setVisibleTables] = React.useState({
    partMeasurementUnits: true,
    warehouses: true,
    purchaseOrderStatus: true,
    manufacturers: true,    
  });

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const [partMeasurementUnitsRes, warehousesRes, purchaseOrderStatusRes, manufacturersRes] = await Promise.all([
          axios.get('/api/part-measurement-unit/'),
          axios.get('/api/warehouse/full'),
          axios.get('/api/purchase-order-statuses'),
          axios.get('/api/manufacturer/full'),          
        ]);

        setPartMeasurementUnits(partMeasurementUnitsRes.data);  
        setFilteredData((prev) => ({ ...prev, partMeasurementUnits: partMeasurementUnitsRes.data }));

        setWarehouses(warehousesRes.data);
        setFilteredData((prev) => ({ ...prev, warehouses: warehousesRes.data })); 

        setPurchaseOrderStatus(purchaseOrderStatusRes.data);  
        setFilteredData((prev) => ({ ...prev, purchaseOrderStatus: purchaseOrderStatusRes.data })); 

        setManufacturers(manufacturersRes.data);
        setFilteredData((prev) => ({ ...prev, manufacturers: manufacturersRes.data }));

      } catch (error) {
        console.error(error);
      }
    }
    fetchData();  
  }, []);

  const handleFilterChange = (table: string, column: string, searchTerm: string | boolean | Dayjs | null) => { 
    const tableConfig = tablesConfig.find((t) => t.key === table);
      
    if (!tableConfig) {
      console.warn(`Table configuration for "${table}" not found.`);
      return;
    }
  
    const { data, setFilteredData } = tableConfig;
  
    if (searchTerm === null || searchTerm === '') {
      // Reset to original data if searchTerm is null or empty
      setFilteredData(data);
      return;
    }
  
    const filtered = data.filter((row) => {
      const value = row[column as keyof typeof row];
      
      if (typeof value === 'string' && typeof searchTerm === 'string') {
        return value.toLowerCase().includes(searchTerm.toLowerCase());
      }
  
      if (typeof value === 'boolean' && typeof searchTerm === 'boolean') {
        return value === searchTerm; // Match boolean values exactly
      }
  
      return false; // Default to false if value type does not match the expected types
    });
  
    setFilteredData(filtered);
  };

  
  const handlePageChange = (tableKey: keyof typeof paginationStates, newPage: number) => {
    setPaginationStates(prev => ({
        ...prev,
        [tableKey]: { ...prev[tableKey], page: newPage }
    }));
  };

  const handleRowsPerPageChange = (tableKey: keyof typeof paginationStates, newRowsPerPage: number) => {
      setPaginationStates(prev => ({
          ...prev,
          [tableKey]: { page: 0, rowsPerPage: newRowsPerPage }
      }));
  };

  const handleToggleTable = (tableKey: keyof typeof visibleTables) => {
      setVisibleTables(prev => ({ ...prev, [tableKey]: !prev[tableKey] }));
  };

  const mappingForPartMeasurementUnitType = {
    Weight: 'Cân nặng',
    Length: 'Độ dài',
    Unit: 'Đơn vị',
    Volume: 'Thể tích', 
  }

  const tablesConfig: Array<{
    key: TableKey;
    title: string;
    data: any[];
    filteredData: any[];  
    setFilteredData: (data: any[]) => void; 
    visible: boolean;
    filterColumns: { value: string; label: string}[];
    tableColumns: TableColumn<any>[]; 
  }> = [
    {
      key: 'partMeasurementUnits',
      title: 'Đơn vị cho linh kiện',
      data: partMeasurementUnits,
      filteredData: filteredData.partMeasurementUnits,  
      setFilteredData: (data: PartMeasurementUnit[]) => setFilteredData((prev) => ({ ...prev, partMeasurementUnits: data })),
      visible: visibleTables.partMeasurementUnits,
      filterColumns: [
        { value: 'name', label: 'Tên' },
        { value: 'shortName', label: 'Tên ngắn' },
        // add the list of options later
        // { value: 'type', label: 'Loại' },
      ],
      tableColumns: [
        { field: 'name', label: 'Tên' },  
        { field: 'shortName', label: 'Tên ngắn' },
        { label: 'Loại', field: (row) => mappingForPartMeasurementUnitType[row.type as keyof typeof mappingForPartMeasurementUnitType] || row.type // Apply mapping and fallback to original value
        },
        { label: 'Ngày tạo', isDate: true,  field: (row) => formatDate(row.createdAt) },  
        { label: 'Ngày cập nhật', isDate: true, field: (row) => formatDate(row.updateAt) }, 
      ] as TableColumn<PartMeasurementUnit>[]
    },
    {
      key: 'warehouses',
      title: 'Thông tin các kho',
      data: warehouses,
      filteredData: filteredData.warehouses,  
      setFilteredData: (data: Warehouse[]) => setFilteredData((prev) => ({ ...prev, warehouses: data })),
      visible: visibleTables.warehouses,  
      filterColumns: [
        { value: 'name', label: 'Tên' },  
        { value: 'address', label: 'Địa chỉ' },
        { value: 'address2', label: 'Địa chỉ 2' },  
        { value: 'city', label: 'Thành phố' },
      ],
      tableColumns: [
        { label: 'Tên', field: 'name' },  
        { label: 'Địa chỉ', field: 'address' },
        { label: 'Địa chỉ 2', field: 'address2' },
        { label: 'Thành phố', field: 'city' },
        { label: 'Ngày tạo', isDate: true, field: (row) => formatDate(row.createdAt) },
        { label: 'Ngày cập nhật', isDate: true, field: (row) => formatDate(row.updateAt) }, 
      ] as TableColumn<Warehouse>[] 
    }, 
    {
      key: 'purchaseOrderStatus', 
      title: 'Trạng thái đơn hàng', 
      data: purchaseOrderStatus,
      filteredData: filteredData.purchaseOrderStatus, 
      setFilteredData: (data: PurchaseOrderStatus[]) => setFilteredData((prev) => ({ ...prev, purchaseOrderStatus: data })),  
      visible: visibleTables.purchaseOrderStatus,
      filterColumns: [
        { value: 'name', label: 'Tên' },
        { value: 'description', label: 'Mô tả' },
      ],
      tableColumns: [
        { label: 'Tên', field: 'name' },
        { label: 'Mô tả', field: 'description' },
        {
          label: 'Màu',
          field: (row) => 
          {
            return (
              <Chip 
              style={{
                backgroundColor: row.color || '#ccc',
                borderRadius: '5px',
                padding: '5px 10px',
                fontWeight: 'bold',
              }}
              />
            )
          },
        },
        // { label: 'Chức năng', field: 'function' },
        { label: 'Ngày tạo', isDate: true, field: (row) => formatDate(row.createdAt) }, 
        { label: 'Ngày cập nhật', isDate: true, field: (row) => formatDate(row.updateAt) },
      ] as TableColumn<PurchaseOrderStatus>[]
    },
    {
      key: 'manufacturers',
      title: 'Thông tin các nhà sản xuất lốp xe/linh kiện',
      data: manufacturers,
      filteredData: filteredData.manufacturers, 
      setFilteredData: (data: Manufacturer[]) => setFilteredData((prev) => ({ ...prev, manufacturers: data })),
      visible: visibleTables.manufacturers,
      filterColumns: [
        { value: 'name', label: 'Tên' },
        { value: 'website', label: 'Website' },
        { value: 'comment', label: 'Ghi chú' },
      ],
      tableColumns: [
        { label: 'Tên', field: 'name' },
        { label: 'Website', field: 'website' },
        { label: 'Ghi chú', field: 'comment' },
        { label: 'Ngày tạo', isDate: true, field: (row) => formatDate(row.createdAt) }, 
        { label: 'Ngày cập nhật', isDate: true, field: (row) => formatDate(row.updateAt) },  
      ] as TableColumn<Manufacturer>[]  
    }
  ];

  const visibleTablesCount = tablesConfig.filter(({ visible }) => visible).length;  

  const dialogConfig = {
    // partMeasurementUnits: {
    //   title: 'Thêm/Chỉnh sửa đơn vị cho linh kiện',
    //   fields: [
    //     { name: 'name', label: 'Tên', type: 'text' },
    //     { name: 'shortName', label: 'Tên ngắn', type: 'text' },
    //     { name: 'type', label: 'Loại', type: 'select', options: [
    //       { label: 'Cân nặng', value: 'Weight' },
    //       { label: 'Độ dài', value: 'Length' }, 
    //       { label: 'Đơn vị', value: 'Unit' }, 
    //     ]},
    //   ]
    // },
    // warehouses: {
    //   title: 'Thêm/Chỉnh sửa kho',
    //   fields: [
    //     { name: 'name', label: 'Tên', type: 'text' },
    //     { name: 'deafult_flg', label: 'Địa chỉ mặc định', type: 'checkbox' },
    //     { name: 'address', label: 'Địa chỉ', type: 'text' },
    //     { name: 'address2', label: 'Địa chỉ 2', type: 'text' },
    //     { name: 'city', label: 'Thành phố', type: 'text' },
    //   ]
    // },
    purchaseOrderStatus: {
      title: 'Thêm/Chỉnh sửa trạng thái đơn hàng',
      fields: [
        { name: 'name', label: 'Tên', type: 'text' },
        { name: 'description', label: 'Mô tả', type: 'text' },
        { name: 'color', label: 'Màu', type: 'color' },
      ]
    },
    // manufacturers: {
    //   title: 'Thêm/Chỉnh sửa nhà sản xuất lốp xe/linh kiện',
    //   fields: [
    //     { name: 'name', label: 'Tên', type: 'text' },
    //     { name: 'website', label: 'Website', type: 'text' },
    //     { name: 'comment', label: 'Ghi chú', type: 'text' },
    //   ]
    // }
  };

  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedTable, setSelectedTable] = React.useState<keyof typeof dialogConfig | 'partMeasurementUnits' | 'warehouses' | 'manufacturers' | null>(null);
  const [selectedData, setSelectedData] = React.useState<any | null>(null);

  const handleEditDialog = (tableKey: keyof typeof dialogConfig | 'partMeasurementUnits' | 'warehouses' | 'manufacturers', data: any) => { 
    setSelectedTable(tableKey);
    setSelectedData(data);  
    setOpenDialog(true);  
  };

  const handleOpenDialog = (tableKey: keyof typeof dialogConfig | 'partMeasurementUnits' | 'warehouses' | 'manufacturers') => { 
    setSelectedTable(tableKey);
    setSelectedData(null);
    setOpenDialog(true);  
  };

  const mappingForStatusesTabl = {
    partMeasurementUnits: 'part-measurement-unit',
    warehouses: 'warehouse',
    purchaseOrderStatus: 'purchase-order-statuses',
    manufacturers: 'manufacturer',
  };

  const handleSetData = (tableKey: keyof typeof dialogConfig, data: any) => {
   if (tableKey === 'purchaseOrderStatus') {  
      setPurchaseOrderStatus(data);
    }
  }
    
  const handleAddOrEditStatuses = async (updatedData: any) => { 
    try {
      const formData = new FormData();

      if (selectedTable === 'purchaseOrderStatus') {
        formData.append('name', updatedData.name);  
        formData.append('description', updatedData.description);
        formData.append('color', updatedData.color);
      } else {
        return alert('Chức năng không hợp lệ');
      }

      const apiRoute = `/api/${mappingForStatusesTabl[selectedTable]}`;
      
      if (selectedData) {
        const response = await axios.put(`${apiRoute}/${selectedData.id}`, formData);
        const updatedData = response.data;   
        const updatedDataIndex = filteredData[selectedTable].findIndex((item) => item.id === updatedData.id);
        const updatedDataArray = [...filteredData[selectedTable]];
        updatedDataArray[updatedDataIndex] = updatedData;
        setFilteredData(prev => ({ ...prev, [selectedTable]: updatedDataArray }));
        handleSetData(selectedTable, updatedDataArray);
        setSelectedData(null);
      } else {
          // Add
          const response = await axios.post(apiRoute, formData);
          const newData = response.data;
          setFilteredData(prev => ({ ...prev, [selectedTable]: [...prev[selectedTable], newData] }));
          handleSetData(selectedTable, [...filteredData[selectedTable], newData]);
          setSelectedData(null);  
      } 

      setOpenDialog(false); 
    } catch (error: any) {
      console.error(error);

      if (error.response) {
        const { status  } = error.response;
  
        if (status === 409) {
          alert(`❌ Lỗi tên trạng thái này đã được sử dụng. Vui lòng chọn một tên khác.`);
        } else if (status === 400) {
          alert(`⚠️ Lỗi dữ liệu không hợp lệ`);
        } else if (status === 404) {
          alert('❌ Không tìm thấy tranng thái.');
        } else {
          alert('❌ Có lỗi xảy ra khi thêm/chỉnh sửa đơn vị đo lường. Vui lòng thử lại sau.');
        }
      } else {
        alert('❌ Không thể kết nối đến máy chủ. Vui lòng kiểm tra mạng và thử lại.');
      }

    }
  };

  const handleAddOrEditPartMeasurementUnit = async (updatedData: any) => {
    try {

      if (selectedData) {
        // response = await axios.put(`/api/part-measurement-unit/${selectedData.id}`, updatedData);
        setFilteredData(prev => ({ ...prev, partMeasurementUnits: prev.partMeasurementUnits.map((item) => (item.id === updatedData.id ? updatedData : item)) }));
        setPartMeasurementUnits(prev => prev.map((item) => (item.id === updatedData.id ? updatedData : item))); 
        setSelectedData(null);
      } else {
          // response = await axios.post('/api/part-measurement-unit', updatedData);
          setFilteredData(prev => ({ ...prev, partMeasurementUnits: [...prev.partMeasurementUnits, updatedData] }));
          setPartMeasurementUnits(prev => [...prev, updatedData]);
          setSelectedData(null);
      }
      setOpenDialog(false);
        
    } catch (error) {
      alert('Lỗi thêm/cập nhập đơn vị linh kiện. Hãy kiểm tra các ô lại.');
      console.error('Lỗi thêm/cập nhập đơn vị linh kiện:', error);
    }
  };

  const handleAddOrEditWarehouses = async (updatedData: any) => { 
    try {
      if (selectedData) {
        setFilteredData(prev => ({ ...prev, warehouses: prev.warehouses.map((item) => (item.id === updatedData.id ? updatedData : item)) }));
        setWarehouses(prev => prev.map((item) => (item.id === updatedData.id ? updatedData : item))); 
        setSelectedData(null);
      } else {
        setFilteredData(prev => ({ ...prev, warehouses: [...prev.warehouses, updatedData] }));
        setWarehouses(prev => [...prev, updatedData]);
        setSelectedData(null);
      }

      setOpenDialog(false);

    } catch (error) {
      alert('Lỗi thêm/cập nhập kho . Hãy kiểm tra các ô lại.');
      console.error('Lỗi thêm/cập nhập kho:', error);
    }
  }

  const handleAddOrEditManufacturers = async (updatedData: any) => { 
    try {
      if (selectedData) {
        setFilteredData(prev => ({ ...prev, manufacturers: prev.manufacturers.map((item) => (item.id === updatedData.id ? updatedData : item)) }));
        setManufacturers(prev => prev.map((item) => (item.id === updatedData.id ? updatedData : item))); 
        setSelectedData(null);  
      } else {
        setFilteredData(prev => ({ ...prev, manufacturers: [...prev.manufacturers, updatedData] }));
        setManufacturers(prev => [...prev, updatedData]);  
        setSelectedData(null);  
      }

      setOpenDialog(false);
    } catch (error) {
      alert('Lỗi thêm/cập nhập nhà sản xuất. Hãy kiểm tra các ô lại.');
      console.error('Lỗi thêm/cập nhập nhà sản xuất:', error);  
    }
  };

  const handleDeleteDefault = async (tableKey: keyof typeof dialogConfig, id: string) => { 
    const confirmed = window.confirm('Bạn có chắc chắn muốn xóa mục này không?');

    if (confirmed) {
      try {
          const apiRoute = `/api/${mappingForStatusesTabl[tableKey]}`;

          await axios.delete(`${apiRoute}/${id}`);

          setFilteredData((prev) => ({
            ...prev,
            [tableKey]: prev[tableKey].filter((item) => item.id !== id),
          }));

          // Update the specific state as well (optional if maintaining separate state variables)
          handleSetData(tableKey, filteredData[tableKey].filter((item) => item.id !== id));
          
          alert('Xóa thành công! Tải lại trang để cập nhật dữ liệu mới.');

          window.location.reload();
      } catch (error) {
        alert('Lỗi xóa dữ liệu. Hãy thử lại sau.');
        console.error('Lỗi xóa dữ liệu:', error);
      }

    } 
  };

  const handleDeleteDefaultSelected = async (tableKey: keyof typeof dialogConfig, selectedIds: string[]) => {
    const confirmed = window.confirm('Bạn có chắc chắn muốn xóa các mục đã chọn không?'); 

    if (confirmed) {
      try {

        await axios.delete(`/api/${mappingForStatusesTabl[tableKey]}/${JSON.stringify(selectedIds)}`);  
        setFilteredData((prev) => ({  
          ...prev,
          [tableKey]: prev[tableKey].filter((item) => !selectedIds.includes(item.id)) 
        }));

        handleSetData(tableKey, filteredData[tableKey].filter((item) => !selectedIds.includes(item.id)));
        
        alert('Xóa dữ liệu thành công');  

        window.location.reload(); 
      } catch (error) {
        alert('Lỗi xóa dữ liệu. Hãy thử lại sau.');
        console.error('Lỗi xóa dữ liệu:', error); 
      }
    }
  }

  return (
    <Stack spacing={4}>
      <Typography variant="h4">Thiết lập các dữ liệu phụ</Typography>

      <div className="table-visibility-controls">
        <Typography variant="h5">Các bảng</Typography>
        {tableLabels.map(({ key, label }) => (
          <FormControlLabel
            key={key}
            control={
              <Checkbox
                checked={visibleTables[key as keyof typeof visibleTables]}
                onChange={() => handleToggleTable(key as keyof typeof visibleTables)}
              />
            }
            label={label} // Use custom label
          />
        ))}
      </div>

      <div className="row">
        {tablesConfig.map(({ key, title, data, filteredData, setFilteredData, visible, filterColumns, tableColumns }, index) =>
          visible ? (
            <div
              key={title}
              className={`mb-5 ${
              visibleTablesCount <= 2 ? 'col-md-12' : 'col-md-6'
              }`} // Use col-md-12 if only one or two tables are visible
            >
            <Stack spacing={3}>
              <Stack direction="row" spacing={3}>
              <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                <Typography variant="h5">{title}</Typography>
                </Stack>
                <div>
                <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={() => handleOpenDialog(key)}>
                    Thêm
                </Button>
                </div>
              </Stack>

              <GenericFilters 
                columns={filterColumns}
                onFilterChange={(col, term) => handleFilterChange(key, col, term)}
              />

              <GenericTable 
                count ={filteredData.length}
                page={paginationStates[key].page}
                rows={applyPagination(filteredData, paginationStates[key].page, paginationStates[key].rowsPerPage)} 
                columns={tableColumns as unknown as TableColumn<{ id: string }>[]}
                rowsPerPage={paginationStates[key].rowsPerPage} // Specific table's rowsPerPage
                onPageChange={(newPage) => handlePageChange(key, newPage)} // Pass `key` to identify table
                onRowsPerPageChange={(newRows) => handleRowsPerPageChange(key, newRows)} // Pass `key` for rows per page change
                onEdit={(id) => {
                    const rowData = data.find((item) => item.id === id); // Find the row data by id
                    handleEditDialog(key as keyof typeof dialogConfig, rowData);
                }}   
                onDelete={
                  key === 'purchaseOrderStatus' ? undefined : (id) => handleDeleteDefault(key as keyof typeof dialogConfig, id)
                }   
                onDeleteSelected={key === 'purchaseOrderStatus' ? undefined : (ids) => handleDeleteDefaultSelected(key as keyof typeof dialogConfig, ids)}
                />
            </Stack>
            </div>
          ) : null
        )}
      </div>

      {selectedTable === 'partMeasurementUnits' && (
        <PartMeasurementUnitDialog
          open={openDialog} 
          data={selectedData}
          onClose={() => setOpenDialog(false)}
          onSubmit={handleAddOrEditPartMeasurementUnit}
        /> 

      )}
 
      {selectedTable === 'warehouses' && (
        <WarehouseDialog
          open={openDialog}
          data={selectedData}
          onClose={() => setOpenDialog(false)}
          onSubmit={handleAddOrEditWarehouses}
        />
      )}

      {selectedTable === 'manufacturers' && (
        <ManufacturerDialog
          open={openDialog}
          data={selectedData}
          onClose={() => setOpenDialog(false)}
          onSubmit={handleAddOrEditManufacturers}
        />  
      )}  
        

      { selectedTable && selectedTable in dialogConfig && (
        <DynamicDialog
          open={openDialog}
          title={dialogConfig[selectedTable as keyof typeof dialogConfig].title}
          fields={dialogConfig[selectedTable as keyof typeof dialogConfig].fields}
          data={selectedData}
          onClose={() => setOpenDialog(false)}
          onSubmit={handleAddOrEditStatuses}
        />

      )}


    </Stack>
  )
}


