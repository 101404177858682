import * as React from 'react';
import {
  Card,
  InputAdornment,
  OutlinedInput,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { MagnifyingGlass as MagnifyingGlassIcon } from '@phosphor-icons/react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { customVi } from '@/assets/data/calendarCustom';

interface FilterColumn {
  value: string;
  label: string;
  isSortOnly?: boolean; // Indicates if this option is for sorting only
  options?: { label: string; value: string | boolean }[]; // Options for select dropdown if applicable
  type?: 'text' | 'dropdown' | 'date'; // New type property
}

interface GenericFiltersProps {
  columns: FilterColumn[]; // Dynamic columns for filtering
  onFilterChange: (column: string, searchTerm: string | boolean | null | Dayjs) => void;
  placeholder?: string; // Optional placeholder for search input
  defaultColumn?: string; // Optional default column to filter by
  preFillColumn?: string; // Column to prefill
  preFillValue?: string; // Value to prefill
}

export function GenericFilters({
  columns,
  onFilterChange,
  placeholder = 'Search...', // Default placeholder if not provided
  defaultColumn,
  preFillColumn,
  preFillValue,
}: GenericFiltersProps): React.JSX.Element {
  const [filterColumn, setFilterColumn] = React.useState(preFillColumn || defaultColumn || columns[0].value);
  const [searchTerm, setSearchTerm] = React.useState(preFillValue || '');
  const [isDropdown, setIsDropdown] = React.useState(false);
  const [isDatePicker, setIsDatePicker] = React.useState(false);
  const [dropdownValue, setDropdownValue] = React.useState('');
  const [dateValue, setDateValue] = React.useState<Dayjs | null>(null);

  const hasPrefilled = React.useRef(false);

  React.useEffect(() => {
    if ((preFillColumn || preFillValue) && !hasPrefilled.current) {
      const selectedColumn = columns.find((col) => col.value === preFillColumn) || columns[0];
      setFilterColumn(preFillColumn || columns[0].value);
      setSearchTerm(preFillValue || '');
      setIsDropdown(selectedColumn.type === 'dropdown');
      setIsDatePicker(selectedColumn.type === 'date');
      setDropdownValue(preFillValue || '');
      onFilterChange(preFillColumn || columns[0].value, preFillValue || '');
    }
  }, [preFillColumn, preFillValue, columns, onFilterChange]);

  const handleColumnChange = (event: SelectChangeEvent<string>) => {
    hasPrefilled.current = true;
    const column = event.target.value;
    const selectedColumn = columns.find((col) => col.value === column);

    setFilterColumn(column);
    setIsDropdown(selectedColumn?.type === 'dropdown');
    setIsDatePicker(selectedColumn?.type === 'date');

    if (selectedColumn?.type === 'dropdown') {
      // For dropdown options, reset the dropdown value
      setDropdownValue('');
      onFilterChange(column, null); // Notify parent with null as no option is selected yet
    } else if (selectedColumn?.type === 'date') {
      // For date filters, reset the date value
      setDateValue(null);
      onFilterChange(column, null); // Notify parent with null as no date is selected yet
    } else {
      // For regular input
      onFilterChange(column, searchTerm);
    }
  };

  const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    hasPrefilled.current = true;
    const search = event.target.value;
    setSearchTerm(search);
    onFilterChange(filterColumn, search);
  };

  const handleDropdownChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value;
    setDropdownValue(event.target.value);
    onFilterChange(filterColumn, value); // Notify parent with the selected dropdown value
  };

  const handleDateChange = (newDate: Dayjs | null) => {
    setDateValue(newDate);
    onFilterChange(filterColumn, newDate); // Notify parent with the selected date
  };

  return (
    <Card sx={{ p: 2 }}>
      <FormControl sx={{ minWidth: 150, marginRight: 2 }}>
        <InputLabel id="column-filter-label">Lọc theo</InputLabel>
        <Select
          labelId="column-filter-label"
          value={filterColumn}
          label="Lọc theo"
          onChange={handleColumnChange}
        >
          {columns.map((col) => (
            <MenuItem key={col.value} value={col.value}>
              {col.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {isDropdown ? (
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="dropdown-filter-label">Chọn giá trị</InputLabel>
          <Select
            labelId="dropdown-filter-label"
            value={dropdownValue}
            onChange={handleDropdownChange}
            label="Chọn giá trị"
          >
            {columns
              .find((col) => col.value === filterColumn)
              ?.options?.map((option) => (
                <MenuItem key={option.value.toString()} value={option.value.toString()}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      ) : isDatePicker ? (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={customVi}>
        
          <DatePicker
            value={dateValue ? dateValue.toDate() : null}
            onChange={(date: Date | null) => handleDateChange(date ? dayjs(date) : null)}
            slotProps={{
              textField: {
                placeholder: 'Chọn ngày để lọc',
              },
            }}
          />
        </LocalizationProvider>

      ) : (
        <OutlinedInput
          value={searchTerm}
          onChange={handleSearchTermChange}
          fullWidth
          placeholder={placeholder}
          startAdornment={
            <InputAdornment position="start">
              <MagnifyingGlassIcon fontSize="var(--icon-fontSize-md)" />
            </InputAdornment>
          }
          sx={{ maxWidth: '500px' }}
        />
      )}
    </Card>
  );
}
