import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Chip } from '@mui/material';
import { Download as DownloadIcon } from '@phosphor-icons/react';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { Upload as UploadIcon } from '@phosphor-icons/react';
import { GenericFilters } from '@/components/dashboard/filter/genericFilter';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1';
import axios from 'axios';
import { MaintainanceWorkOrderDialog } from './maintainanceWorkOrderDialog';
import './maintainanceWorkOder.css'; // Import the CSS file  
import { formatDate } from '@/hooks/format-date';
import { getInChargeType } from '@/hooks/mapping';
import { getContrastTextColor } from '@/hooks/contrast-text-color';
import dayjs, { Dayjs } from 'dayjs';
// **NOTE: in this code just prefered the maintainanceWorkOrder to the accoutns instead (tempalte used)

interface Vehicle {
  vehicleNumber: string;
  name: string;
}

interface Status {
  name: string;
  description: string;
  color: string;
}

export interface MaintainanceWorkOrder {
  id: string;
  workOrderNumber: string;
  vehicleId: number;
  vehicle: Vehicle;
  status: Status;
  statusId: number;
  dueDate: Date | string;
  inChargeType: string;
  inChargeId: number;
  approvedDate: Date | string;
  activeDate: Date | string ;
  completedDate: Date | string;   
  closedDate: Date | string; 
  createdAt: Date;
  customFields: Record<string, string>;
  // Add these fields directly to the interface
  vehicleNumber: string;
  statusName: string;
}


function applyPagination(rows: MaintainanceWorkOrder[], page: number, rowsPerPage: number): MaintainanceWorkOrder[] {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export default function MaintainanceWorkOrdersPage(): React.JSX.Element {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5); // Adjust number of rows per page as necessary
  const [data, setData] = React.useState<MaintainanceWorkOrder[]>([]); // MaintainanceWorkOrder data state
  const [filteredData, setFilteredData] = React.useState<MaintainanceWorkOrder[]>([]); // Filtered data state
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedMaintainanceWorkOrder, setSelectedMaintainanceWorkOrder] = React.useState<MaintainanceWorkOrder | null>(null);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/maintainance-work-orders');
      const sortedData = response.data.sort((a: MaintainanceWorkOrder, b: MaintainanceWorkOrder) => { a.workOrderNumber.localeCompare(b.workOrderNumber) });
      setData(sortedData);
      setFilteredData(sortedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  // const paginatedMaintainanceWorkOrders = applyPagination(filteredData, page, rowsPerPage);

  const handleFilterChange = (column: string, searchTerm: string | boolean | Dayjs | null) => {
    const filtered = data.filter((maintainanceWorkOrder) => {
      let value;
  
      // Map column name to the actual data field
      switch (column) {
        case 'workOrderNumber':
          value = maintainanceWorkOrder.workOrderNumber;
          break;
        case 'vehicleNumber':
          value = maintainanceWorkOrder.vehicle?.vehicleNumber; // Access nested vehicleNumber
          break;
        default:
          value = maintainanceWorkOrder[column as keyof MaintainanceWorkOrder];
      }
  
      // If no searchTerm, include all items (e.g., dropdown initial state)
      if (searchTerm === null) {
        return true;
      }
  
      // Handle different types of value (string, number, boolean)
      if (typeof value === 'string') {
        return value.toLowerCase().includes((searchTerm as string).toLowerCase()); // Case-insensitive search for strings
      }
  
      if (typeof value === 'number') {
        return value === Number(searchTerm); // Match numbers exactly
      }
  
      if (typeof value === 'boolean') {
        return value === Boolean(searchTerm); // Match boolean values exactly
      }
  
      return false; // Default return false if value type does not match the expected types
    });
  
    setFilteredData(filtered);
  };
  

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleOpenDialog = () => {
    setSelectedMaintainanceWorkOrder(null); // Reset selected maintainanceWorkOrder when adding a new one
    setOpenDialog(true);
  };

  const handleEditMaintainanceWorkOrder = (id: string) => {
    const maintainanceWorkOrderToEdit = data.find((maintainanceWorkOrder) => maintainanceWorkOrder.id === id);
    if (maintainanceWorkOrderToEdit) {
      setSelectedMaintainanceWorkOrder(maintainanceWorkOrderToEdit); // Populate the dialog with the selected maintainanceWorkOrder
      setOpenDialog(true); // Open dialog for editing
    }
  };

  const handleDeleteMaintainanceWorkOrder = async (id: string) => {
    const confirmed = window.confirm('Bạn có chắc là xóa thẻ này?');
    if (confirmed) {
      try {
        await axios.delete(`/api/maintainance-work-orders/${JSON.stringify(id)}`);
        setData((prevData) => prevData.filter((maintainanceWorkOrder) => maintainanceWorkOrder.id !== id));
        setFilteredData((prevData) => prevData.filter((maintainanceWorkOrder) => maintainanceWorkOrder.id !== id));
      } catch (error) {
        console.error('Error deleting maintainanceWorkOrder:', error);
      }
    }
  };

  const handleDeleteMaintainanceWorkOrderSelected = async (selectedIds: string[]) => {
    const confirmed = window.confirm('Bạn có chắc là xóa những thẻ đã chọn?');
    if (confirmed) {
      try {
        await axios.delete(`/api/maintainance-work-orders/${JSON.stringify(selectedIds)}`);
        alert('Xóa thành công');
        setData((prevData) => prevData.filter((maintainanceWorkOrder) => !selectedIds.includes(maintainanceWorkOrder.id)));
        setFilteredData((prevData) => prevData.filter((maintainanceWorkOrder) => !selectedIds.includes(maintainanceWorkOrder.id)));
      } catch (error) {
        console.log('Error deleting maintainanceWorkOrder:', error);
      }
    }
  }

  const handleAddOrEditMaintainanceWorkOrder = async (maintainanceWorkOrderData: Partial<MaintainanceWorkOrder>) => {
    try {
      // Create a FormData object to handle both file and maintainanceWorkOrder data
      const formData = new FormData();
  
      // Append the maintainanceWorkOrder data fields to the FormData object
      Object.keys(maintainanceWorkOrderData).forEach((key) => {
        const value = maintainanceWorkOrderData[key as keyof MaintainanceWorkOrder];
        if (value !== undefined) {
          // Ensure that all values appended to FormData are strings or Blob objects
          formData.append(key, value as string | Blob);
        }
      });

      // Handle customFields: Convert customFields to a JSON string before appending
      if (maintainanceWorkOrderData.customFields) {
        formData.set('customFields', JSON.stringify(maintainanceWorkOrderData.customFields));
      }
  
      if (selectedMaintainanceWorkOrder) {
        // Editing existing maintainanceWorkOrder
        const response = await axios.put(`/api/maintainance-work-orders/${selectedMaintainanceWorkOrder.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
          },
        });
        const updatedMaintainanceWorkOrder = response.data;
  
        setData((prevData) =>
          prevData.map((maintainanceWorkOrder) => (maintainanceWorkOrder.id === updatedMaintainanceWorkOrder.id ? updatedMaintainanceWorkOrder : maintainanceWorkOrder))
        );
        setFilteredData((prevData) =>
          prevData.map((maintainanceWorkOrder) => (maintainanceWorkOrder.id === updatedMaintainanceWorkOrder.id ? updatedMaintainanceWorkOrder : maintainanceWorkOrder))
        );
      } else {
        // Adding new maintainanceWorkOrder
        const response = await axios.post('/api/maintainance-work-orders', formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
          },
        });
        const newMaintainanceWorkOrder = response.data;
        setData((prevData) => [newMaintainanceWorkOrder, ...prevData ]);
        setFilteredData((prevData) => [newMaintainanceWorkOrder, ...prevData ]);
      }
      fetchData(); // Fetch data after the operation
      setOpenDialog(false); // Close the dialog after the operation
    } catch (error) {
      alert('Lỗi thêm/cập nhập phiếu công việc. Hãy kiểm tra các ô lại.');
      console.error('Lỗi thêm/cập phiếu công việc:', error); 
    }
  };
  
  //filter columns
  const filterColumns = [
    { value: 'workOrderNumber', label: 'Mã công việc' },
    { value: 'vehicleNumber', label: 'Mã số xe' },  
  ];

  const transformedWorkOrders = filteredData.map((maintainanceWorkOrder) => {
    return {
      ...maintainanceWorkOrder,
      vehicleNumber: maintainanceWorkOrder.vehicle?.vehicleNumber || 'N/A', // Direct access to vehicleNumber
      statusName: maintainanceWorkOrder.status?.name || 'N/A',              // Direct access to status name
    };
  });
  
  // Table columns with proper labels and fields
  const columns: TableColumn<MaintainanceWorkOrder>[] = [
    { label: 'Mã Công Việc', field: 'workOrderNumber' },
    { 
      label: 'Tình Trạng', 
      field: (row) => {
        const bgColor = row.status?.color || '#ccc';
        const textColor = getContrastTextColor(bgColor);

        return (
          <Chip
            label={row.status?.name || 'N/A'}
            style={{
              backgroundColor: bgColor,
              color: textColor, // Dynamically set text color
              borderRadius: '5px',
              padding: '5px 10px',
              fontWeight: 'bold',
            }}
          />
        );
      },
    },
    { label: 'Mã Số Xe', field: (row) => row.vehicle?.vehicleNumber || 'N/A' },
    { label: 'Bên Làm', field: 'inChargeType', customRender: (rowData) => getInChargeType(rowData.inChargeType) },
    { label: 'Ngày Tạo', field: 'createdAt', customRender: (rowData) => formatDate(rowData.createdAt), isDate: true },  
    { label: 'Ngày Đến Hạn', field: 'dueDate', customRender: (rowData) => formatDate(rowData.dueDate), isDate: true },
    { label: 'Ngày Phê Duyệt', field: 'approvedDate', customRender: (rowData) => formatDate(rowData.approvedDate), isDate: true },
    { label: 'Ngày Bắt Đầu', field: 'activeDate', customRender: (rowData) => formatDate(rowData.activeDate), isDate: true },
    { label: 'Ngày Hoàn Thành', field: 'completedDate', customRender: (rowData) => formatDate(rowData.completedDate), isDate: true },
    { label: 'Ngày Đóng', field: 'closedDate', customRender: (rowData) => formatDate(rowData.closedDate), isDate: true },
  ];

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h4">Việc cần làm</Typography>
        </Stack>
        <div>
          <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={handleOpenDialog}>
            Thêm
          </Button>
        </div>
      </Stack>
      <GenericFilters columns={filterColumns} onFilterChange={handleFilterChange} placeholder="Tìm thẻ việc" />
      <GenericTable
        count={filteredData.length}
        page={page}
        rows={applyPagination(transformedWorkOrders, page, rowsPerPage)} // Use transformed data
        columns={columns}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onEdit={handleEditMaintainanceWorkOrder}
        onDelete={handleDeleteMaintainanceWorkOrder}
        onDeleteSelected={handleDeleteMaintainanceWorkOrderSelected}
      />

      {/* Add maintainanceWorkOrder dialog */}
      <MaintainanceWorkOrderDialog open={openDialog} onClose={() => setOpenDialog(false)} onSubmit={handleAddOrEditMaintainanceWorkOrder} maintainanceWorkOrderData={selectedMaintainanceWorkOrder ?? undefined} 
        refreshData={fetchData} // <-- pass fetchData here
      />
    </Stack>
  );
}

// This ensures that this file is treated as a module and resolves the TS1208 error
export { };
