import * as React from 'react';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { Inspection } from './Inspections'; // Import your inspectiontype
import { ImageUpload } from '@/components/dashboard/imageUpload/ImageUpLoad';
import { CustomSelectWithAdd } from '@/components/dashboard/customSelectWithAdd/customSelectWithAdd';
import axios from 'axios';
import { FieldManagementDialog, DialogField } from '@/components/dashboard/fieldManagement/FieldManagement';
import useImageDownload from '@/hooks/image-download'; // Import the hook
import  useCustomFieldsUpdate from '@/hooks/field-managementSubmit'; // Import the hook
import { UseFetchAccount, UseFetchInspectionForm, UseFetchVechicle } from '@/hooks/fetch-relationship-field'; // Import the hooks
import FormThings from "./formWithCustomSelectsInspection";
import { formatDate } from '@/hooks/format-date';
import { Typography } from '@mui/material';
import { InspectionFormCheckComponent } from '@/components/subPlace/InspectionFormCheckComponent';
import { set } from 'date-fns';

interface InspectionDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (inspectionData: Partial<Inspection>, imageFile?: File) => void;
  inspectionData?: Partial<Inspection>;
  refreshData: () => void; // <-- pass fetchData here
}

export const InspectionDialog: React.FC<InspectionDialogProps> = ({ open, onClose, onSubmit, inspectionData, refreshData }) => {  
  const isEditMode = !!inspectionData;

  const [fields, setFields] = React.useState<DialogField[]>([]);
  const [formData, setFormData] = React.useState<Partial<Inspection>>(inspectionData || {});
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [inspectionItemsStatus, setInspectionItemsStatus] = React.useState<{
    inspectionItemId: number;
    inspectionItemCategoryId?: number;
    inspectionItemName: string;
    inspectionRequireCommentOnFail?: boolean; // Optional property
    inspectionComment?: string; // Optional property  
    inspectionDescription?: string; // Optional property
    status: string;
  }[]>([]);
  
  
  //field management
  const [isFieldManagementOpen, setFieldManagementOpen] = React.useState(false);

  // custom field 
  const [customFieldsData, setCustomFieldsData] = React.useState<Record<string, any>>({});

  const { assignees } = UseFetchAccount();
  const { vehicles } = UseFetchVechicle();
  const { inspectionForms } = UseFetchInspectionForm();

  const [ inspectionById, setInspectionById ] = React.useState<number | null>(null);
  const [ vehicleId, setVehicleId ] = React.useState<number | null>(null); 
  const [ inspectionFormId, setInspectionFormId ] = React.useState<number | null>(null);

  const [inspectionNumberLoading, setInspectionNumberLoading] = React.useState(false);

  React.useEffect(() => {
    if (open && !isEditMode) {
      fetchNextInspectionNumber();
    } 
  }, [open, inspectionData]);

  const fetchNextInspectionNumber = async () => { 
    try {
        setInspectionNumberLoading(true);
        const response = await axios.get('/api/inspections/next-inspection-number'); 
        const nextInspectionNumber = response.data.nextInspectionNumber;  
        setFormData((prev) => ({ ...prev, inspectionNumber: nextInspectionNumber }));
    } catch (error) {
        console.error('Failed to fetch next inspection number:', error);
    }  finally { 
        setInspectionNumberLoading(false);
    }

  };

  // for the inspection items
  React.useEffect(() => {
    if (inspectionFormId) {
      fetchInspectionItems(inspectionFormId);
    }
  }, [inspectionFormId]);

  const fetchInspectionItems = async (formId: number) => {
    try {
      const response = await axios.get(`/api/inspection-forms/${formId}/items/${inspectionData?.id}`);
      console.log('Inspection items:', response.data);
      setInspectionItemsStatus(response.data.map((item: any) => ({inspectionItemId: item.id, inspectionCategoryId: item.categoryId, inspectionItemName: item.name, inspectionRequireCommentOnFail: item.requireCommentOnFail,  inspectionComment: item.comment, inspectionDescription: item.description, status: item.status || 'Not_Checked' })));
    } catch (error) {
      console.error('Failed to fetch inspection items:', error);
    }
  };

  React.useEffect(() => {
    fetchFields();
  }, []);
  
  const fetchFields = async () => {
    try {
      const response = await axios.get('/api/inspection-dialog-fields');
      setFields(response.data);
    } catch (error) {
      console.error('Failed to fetch dialog fields:', error);
    }
  };

  const addFieldToDatabase = async (field: DialogField): Promise<DialogField> => {  
    try {
      const response = await axios.post('/api/inspection-dialog-fields', field);
      return response.data;
    } catch (error) {
      console.error('Failed to add dialog field:', error);
      return field;
    }
  }

  const updateFieldInDatabase = async (field: DialogField): Promise<DialogField> => {
    try {
      const response = await axios.put(`/api/inspection-dialog-fields/${field.id}`, field);
      return response.data;
    } catch (error) {
      console.error('Error updating field:', error);
      throw error;
    }
  };

  const deleteFieldFromDatabase = async (id: number): Promise<void> => {
    try {
      await axios.delete(`/api/inspection-dialog-fields/${id}`);
    } catch (error) {
      console.error('Error deleting field:', error);
      throw error;
    }
  };

  const { handleCustomFieldsUpdate } = useCustomFieldsUpdate();

  const handleFieldManagementSubmit = (updatedFields: DialogField[]) => {
    handleCustomFieldsUpdate(
      fields,                         // Original fields before update
      formData.customFields || {},     // Current custom fields data
      inspectionData?.customFields,      // Customer custom fields
      updatedFields,                   // Updated fields
      setCustomFieldsData,             // Update customFieldsData
      setFormData,                     // Update formData
      setFields,                       // Update fields
      setFieldManagementOpen       // Close dialog
    );

    refreshData();  // <-- Trigger refresh after field management is done
  };

 
  const handleSubmit = async () => {
    if (validateForm()) {
      const customFields: Record<string, any> = {};
  
      fields.forEach((field) => {
        if (field.canDelete) {
          customFields[field.name] = formData[field.name as keyof typeof formData] || customFieldsData[field.name];
        }
      });
  
      onSubmit({
        ...formData,
        customFields,
        inspectionFormId: inspectionFormId ?? undefined,
        vehicleId: vehicleId ?? undefined,
        inspectionById: inspectionById ?? undefined,
        inspectionItemsStatus,
      });
    }
  };
  
  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};
  
    // Validate required fields from dynamic form fields
    fields.forEach(field => {
      const fieldValue = formData[field.name as keyof Inspection];

      if (field.required && (!fieldValue || typeof fieldValue === 'string' && !fieldValue.trim())) {
        newErrors[field.name] = `${field.label} cần có giá trị`;
      }
    });
        
  
    // Validate vehicleId
    if (!vehicleId || vehicleId === 0) {
      newErrors.vehicleId = 'Vui lòng chọn phương tiện.';
    }
  
    // Validate inspectionFormId
    if (!inspectionFormId || inspectionFormId === 0) {
      newErrors.inspectionFormId = 'Vui lòng chọn tên đơn kiểm tra.';
    }
  
    // Validate inspectionById
    if (!inspectionById || inspectionById === 0) {
      newErrors.inspectionById = 'Vui lòng chọn kiểm tra bởi.';
    }
  
    // Validate inspectionNumber
    if (!formData.inspectionNumber || formData.inspectionNumber === '') {
      newErrors.inspectionNumber = 'Vui lòng nhập mã số kiểm tra.';
    }
  
    // Validate inspectionDate
    if (!formData.inspectionDate) {
      newErrors.inspectionDate = 'Vui lòng nhập ngày kiểm tra hợp lệ.';
    }
  
    // Validate primaryMeter
    if (!formData.primaryMeter) {
      newErrors.primaryMeter = 'Vui lòng nhập số ODO hợp lệ.';
    }
  
    // Validate nextDuePrimaryMeter if primaryMeter is present
    if (formData.primaryMeter) {
      const selectedVehicle = vehicles.find((v) => v.id === vehicleId);
      if (selectedVehicle) {
        if (
          formData.primaryMeter &&
          formData.primaryMeter <= selectedVehicle.primaryMeter
        ) {
          newErrors.nextDuePrimaryMeter =
            'ODO chính lúc thực hiện lớn hơn khoảng đo chính hiện tại của phương tiện.';
        }
      } else {
        newErrors.vehicleId = 'Dữ liệu phương tiện không hợp lệ.';
      }
    }
  
    // Validate inspection items requiring comments for failed status
    const itemsWithFailStatus = inspectionItemsStatus.filter((item) => item.status === 'Fail');
    const itemsWithoutComment = itemsWithFailStatus.filter(
      (item) => item.inspectionRequireCommentOnFail && !item.inspectionComment
    );
    if (itemsWithoutComment.length > 0) {
      newErrors.inspectionItemsStatus = 'Vui lòng nhập ghi chú cho các mục kiểm tra không đạt.';
    }

    setErrors(newErrors);


    if (Object.keys(newErrors).length > 0) { 
      const errorMessages = Object.values(newErrors)
      .map((message) => `+ ${message  }`)
      .join('\n');  
      alert(`Các mục bị lỗi:\n${errorMessages}`);
    };
  
    return Object.keys(newErrors).length === 0;
  };
  

  React.useEffect(() => {
    if (open) {
      if (inspectionData) {

        setFormData({...inspectionData, inspectionDate: formatDate(inspectionData.inspectionDate) });

        if (inspectionData.customFields){
          try {
            const parsedCustomFields = typeof inspectionData.customFields === 'string' ? JSON.parse(inspectionData.customFields) : inspectionData.customFields; 
            setCustomFieldsData(parsedCustomFields);
          } catch (error){
            console.log('Error setting custom fields data:', error);
          }
        }
        
        setInspectionById(inspectionData.inspectionById || null);
        setVehicleId(inspectionData.vehicleId || null);
        setInspectionFormId(inspectionData.inspectionFormId || null);
      
      }
    } else {
      setFormData({});

      setInspectionById(null);
      setVehicleId(null);
      setInspectionFormId(null);

      setCustomFieldsData({});

    }
  }, [open, inspectionData]);

    
  const handleCustomFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Check if the input is a checkbox or a regular text input
    setCustomFieldsData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,  // If checkbox, store as boolean; else, store the value
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Handle checkbox separately to update the state with boolean values
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleInspectionFormStatusChange = (
    updatedItems: {
      inspectionItemId: number;
      status: string;
      inspectionComment?: string;
    }[]
  ) => {
    setInspectionItemsStatus((prevItems) => {
      // Create a copy of previous items to avoid state mutation issues.
      const updatedFormItems = prevItems.map((item) => {
        // Find the updated version of this item.
        const updatedItem = updatedItems.find((updated) => updated.inspectionItemId === item.inspectionItemId);
        if (updatedItem) {
          // If we found an updated version, return the updated item data.
          return {
            ...item,
            ...updatedItem, // Spread all updated properties, not just status or comment
          };
        }
        // Otherwise, return the unchanged item.
        return item;
      });
      return [...updatedFormItems];
    });
  };
  
  const formFields: FormField[] = fields.map(field => {
    if (field.name === 'inspectionNumber') {
      return {
        label: 'Mã số kiểm tra',
        name: 'inspectionNumber',
        type: 'text',
        value: formData.inspectionNumber || '',
        required: true,
        error: errors['inspectionNumber'],

      };
    } else {
      return {
        label: field.label,
        name: field.name,
        type:  field.type,
        value: formData[field.name as keyof Inspection] || customFieldsData[field.name] || '',
        required: field.required,
        handleChange: field.canDelete ? handleCustomFieldChange : handleChange,
        error: errors[field.name],
      };
    }
  });

  const handleDialogClose = () => {
    // Reset form data
    setFormData({});
    
    // Reset custom fields
    setCustomFieldsData({});
    
    // Reset individual IDs
    setInspectionById(null);
    setVehicleId(null);
    setInspectionFormId(null);
    
    // Reset inspection items
    setInspectionItemsStatus([]);
    
    // Close the dialog
    onClose();
  };

  return (
    <CustomDialog open={open} title="Thêm/Chỉnh sửa thông tin thẻ kiểm tra" onClose={handleDialogClose} manageFields={() => setFieldManagementOpen(true)}
      onSubmit={handleSubmit} submitLabel="Lưu" dialogWidth='1600px' dialogMaxWidth='95%'>

        {/* <FormFields fields={formFields} />*/}
        <div className="row">
          <div className={isEditMode ? "col-md-6" : "col-md-12"}>
          <FormThings
              formFields={formFields}
              vehicles={vehicles}
              vehicleId={vehicleId}
              setVehicleId={setVehicleId}
              inspectionBy={assignees}
              inspectionById={inspectionById}
              setInspectionById={setInspectionById}  
              inspectionForms={inspectionForms}
              inspectionFormId={inspectionFormId}
              setInspectionFormId={setInspectionFormId}
            />
          </div>
          {isEditMode && (
            <div className="col-md-6">
              <Typography variant="h5" gutterBottom>Các mục cần kiểm tra</Typography>

              <InspectionFormCheckComponent
                  form={inspectionItemsStatus}
                  onFormChange={handleInspectionFormStatusChange}
                />
            </div>
          )}
        </div>

        <FieldManagementDialog 
          open={isFieldManagementOpen} 
          onClose={() => setFieldManagementOpen(false)} 
          fields={fields} 
          onFieldSubmit={handleFieldManagementSubmit} 
          addFieldToDatabase={addFieldToDatabase} 
          updateFieldInDatabase={updateFieldInDatabase} 
          deleteFieldFromDatabase={deleteFieldFromDatabase}
        />


    </CustomDialog>
  )

}