
import dayjs from 'dayjs'; // Import dayjs to get the current date

// format-date.ts

// Utility function to set default dates
export const getDefaultDate = (fieldName: string): string => {
  switch (fieldName) {
    case 'reportedAt':
      return formatDate(dayjs().startOf('day').toISOString()); // Default to today's date at 00:00:00
    default:
      return ''; // Return an empty string for other fields
  }
};


// Converts date to dd/mm/yyyy format
export const formatDate = (date: Date | string | null | undefined): string => {
  if (!date) {
    return '';
  }
  const d = new Date(date);
  if (isNaN(d.getTime())) {
    return '';
  }
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  return `${day}/${month}/${year}`; // dd/mm/yyyy format
};

// Converts date to yyyy-MM-dd format for <input type="date">
export const parseDate = (date: Date | string | null | undefined): string => {
  if (!date) {
    return '';
  }
  const d = new Date(date);
  if (isNaN(d.getTime())) {
    return '';
  }
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`; // yyyy-MM-dd format
};
