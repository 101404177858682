import React from 'react';
import { Box, Divider, Typography, Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

interface FormData {
  resolved_flg: boolean;
  resolvedWithType: string;
  reference?: string;
  serviceTask?: string;
  status?: {
    name: string;
    color: string;
  } | null;
  resolvedAt?: string;
}

export const ResolvedServiceEntry = ({
  formData,
  onDissociateServiceEntry,
}: {
  formData: FormData;
  onDissociateServiceEntry: () => void;
}) => {
  if (formData.resolved_flg && formData.resolvedWithType === 'ServiceEntries') {
    return (
      <Box
        sx={{
          p: 2,
          border: '1px solid #ccc',
          borderRadius: 2,
          backgroundColor: '#f8f8f8',
        }}
      >
        <Typography variant="h6" gutterBottom>
          Giải quyết bằng thẻ nhiệm vụ
        </Typography>
        <Divider sx={{ mb: 2 }} />

        {/* Reference */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2"><strong>Mã số nhiệm vụ</strong></Typography>
          <Typography
            variant="body1"
            color="primary"
            sx={{ fontWeight: 'bold' }}
          >
            {formData.reference || 'SE000000'}
          </Typography>
        </Box>

        {/* Service Task */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2"><strong>Tên công việc</strong></Typography>
          <Typography variant="body1">
            {formData.serviceTask || 'No task specified'}
          </Typography>
        </Box>

        {/* Status */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2"><strong>Tình trạng</strong></Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: 16,
                height: 16,
                backgroundColor: formData.status?.color || 'grey',
                borderRadius: '50%',
              }}
            />
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {formData.status?.name || 'Unknown'}
            </Typography>
          </Box>
        </Box>

        {/* Completed At */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2"><strong>Thêm vào thẻ nhiệm vụ từ</strong></Typography>
          <Typography variant="body1">
            {formData.resolvedAt || 'Chưa xác định'}
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Dissociate Button */}
        <Button
          variant="contained"
          color="error"
          startIcon={<CancelIcon />}
          onClick={onDissociateServiceEntry}
          fullWidth
        >
          Hủy liên kết thẻ nhiệm vụ
        </Button>
      </Box>
    );
  }
  return null; // Return nothing if the condition isn't met
};

export default ResolvedServiceEntry;
