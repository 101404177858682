import * as React from 'react';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';

interface DynamicDialogProps {
  open: boolean;
  title: string;
  fields: Array<{ name: string; label: string; type: string }>;
  data: any;
  onClose: () => void;
  onSubmit: (updatedData: any) => void;
}

export const DynamicDialog: React.FC<DynamicDialogProps> = ({ open, title, fields, data, onClose, onSubmit }) => {
  const [formData, setFormData] = React.useState(data || {});

  React.useEffect(() => {
    if (open){
      if (data){
        setFormData(data);
      };
    } else {
      setFormData({});
    }
  }, [open, data]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const errors: string[] = [];
  
    // Check for required fields
    if (!formData.name || formData.name === '') {
      errors.push('Ô tên không được để trống'); // "Name field cannot be empty"
    }
  
    if (!formData.color || formData.color === '') {
      errors.push('Ô màu không được để trống'); // "Color field cannot be empty"
    }
  
    if (errors.length > 0) {
      // Combine all errors into a single message
      alert(errors.join('\n'));
      return false;
    }
  
    return true;
  };


  const handleSubmit = () => {
    // onSubmit(formData);
    // onClose();
    if (validateForm()){
      onSubmit(formData);
    } 
  };

  // Convert fields to FormField array with values from formData
  const formFields: FormField[] = fields.map(field => ({
    ...field,
    value: formData[field.name] || '', // Add value from formData or empty string if undefined
    handleChange: handleChange,
  }));


  return (
    <CustomDialog open={open} title={title} onClose={onClose} onSubmit={handleSubmit} submitLabel="Lưu">
      <FormFields fields={formFields} /> {/* Pass mapped formFields here */}
    </CustomDialog>
  );
};
