import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Download as DownloadIcon } from '@phosphor-icons/react';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { Upload as UploadIcon } from '@phosphor-icons/react';
import { GenericFilters } from '@/components/dashboard/filter/genericFilter';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1';
import axios from 'axios';
import { VehicleDialog } from './vehicleDialog';
import dayjs, { Dayjs } from 'dayjs';

export interface Vehicle {
  id: string;
  name: string; // Tên xe
  vin: string; // Số VIN
  vehicleNumber: string; // Mã số xe  
  makeId: number ; // Hãng
  modelId: number; // Model
  trim: string; // Trim
  year: number; // Năm
  color: string; // Màu
  vehicleTypeId: number; // Chức năng
  departmentId: number; // Phòng ban
  groupId: number; // Nhóm
  fuelTypeId: number; // Loại nhiên liệu
  licensePlateNumber: string; // Biển số
  licensePlateTypeId: number; // Loại biển số
  renewalDate: Date | string; // Ngày đăng kiểm
  primaryMeterType: string; // Đơn vị đo
  primaryMeter: number; // Đồng hồ đo
  secondaryMeterType: string; // Đơn vị đo phụ
  secondaryMeter: number; // Đồng hồ đo phụ
  isTrailer: boolean; // Là xe rơ moóc
  trailerAttachedToId: number; // id rơ moóc
  createdAt: Date; // Thời gian được tạo
  pictureUrl: string; // Ảnh
  // Keeping custom fields as it might still be relevant
  customFields: Record<string, string>;
}

function applyPagination(rows: Vehicle[], page: number, rowsPerPage: number): Vehicle[] {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export default function VehiclesPage(): React.JSX.Element {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5); // Adjust number of rows per page as necessary
  const [data, setData] = React.useState<Vehicle[]>([]); // Vehicle data state
  const [filteredData, setFilteredData] = React.useState<Vehicle[]>([]); // Filtered data state
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedVehicle, setSelectedVehicle] = React.useState<Vehicle | null>(null);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/vehicles');
      const sortedData = response.data.sort((a: Vehicle, b: Vehicle) => { a.name.localeCompare(b.name) });
      setData(sortedData);
      setFilteredData(sortedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const paginatedVehicles = applyPagination(filteredData, page, rowsPerPage);

  const handleFilterChange = (column: string, searchTerm: string | boolean | Dayjs | null) => {
    const filtered = data.filter((vehicle) => {
      const value = vehicle[column as keyof Vehicle]; // Access the column value dynamically
  
      if (searchTerm === null) {
        // If no searchTerm, include all items (e.g., dropdown initial state)
        return true;
      }
  
      if (typeof value === 'string') {
        return value.toLowerCase().includes((searchTerm as string).toLowerCase()); // Case-insensitive search for strings
      }
  
      if (typeof value === 'number') {
        return value === Number(searchTerm); // Match numbers exactly
      }
  
      if (typeof value === 'boolean') {  
        return value === Boolean(searchTerm); // Match boolean values exactly
      }

      return false;  
    });
    
    setFilteredData(filtered);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleOpenDialog = () => {
    setSelectedVehicle(null); // Reset selected vehicle when adding a new one
    setOpenDialog(true);
  };

  const handleEditVehicle = (id: string) => {
    const vehicleToEdit = data.find((vehicle) => vehicle.id === id);
    if (vehicleToEdit) {
      setSelectedVehicle(vehicleToEdit); // Populate the dialog with the selected vehicle
      setOpenDialog(true); // Open dialog for editing
    }
  };

  const handleDeleteVehicle = async (id: string) => {
    const confirmed = window.confirm('Bạn có chắc là xóa xe này?');
    if (confirmed) {
      try {
        await axios.delete(`/api/vehicles/${JSON.stringify(id)}`);
        setData((prevData) => prevData.filter((vehicle) => vehicle.id !== id));
        setFilteredData((prevData) => prevData.filter((vehicle) => vehicle.id !== id));
      } catch (error) {
        console.error('Error deleting vehicle:', error);
      }
    }
  };

  const handleDeleteVehicleSelected = async (selectedIds: string[]) => {
    const confirmed = window.confirm('Bạn có chắc là xóa những xe đã chọn?');
    if (confirmed) {
      try {
        await axios.delete(`/api/vehicles/${JSON.stringify(selectedIds)}`);
        alert('Xóa thành công');
        setData((prevData) => prevData.filter((vehicle) => !selectedIds.includes(vehicle.id)));
        setFilteredData((prevData) => prevData.filter((vehicle) => !selectedIds.includes(vehicle.id)));
      } catch (error) {
        console.log('Error deleting vehicle:', error);
      }
    }
  }

  const handleAddOrEditVehicle = async (vehicleData: Partial<Vehicle>, imageFile?: File) => {
    try {
      // Create a FormData object to handle both file and vehicle data
      const formData = new FormData();
  
      // Append the vehicle data fields to the FormData object
      Object.keys(vehicleData).forEach((key) => {
        const value = vehicleData[key as keyof Vehicle];
        if (value !== undefined) {
          // Ensure that all values appended to FormData are strings or Blob objects
          formData.append(key, value as string | Blob);
        }
      });
        
      // Handle customFields: Convert customFields to a JSON string before appending
      if (vehicleData.customFields) {
        formData.set('customFields', JSON.stringify(vehicleData.customFields));
      }
  
      // Append the image file, if it exists
      if (imageFile) {
        formData.append('picture', imageFile); // 'picture' is the field name for the image file
      }
  
      if (selectedVehicle) {
        // Editing existing vehicle
        const response = await axios.put(`/api/vehicles/${selectedVehicle.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
          },
        });
        const updatedVehicle = response.data;
  
        setData((prevData) =>
          prevData.map((vehicle) => (vehicle.id === updatedVehicle.id ? updatedVehicle : vehicle))
        );
        setFilteredData((prevData) =>
          prevData.map((vehicle) => (vehicle.id === updatedVehicle.id ? updatedVehicle : vehicle))
        );
      } else {
        // Adding new vehicle
        const response = await axios.post('/api/vehicles', formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
          },
        });
        const newVehicle = response.data;
        setData((prevData) => [...prevData, newVehicle]);
        setFilteredData((prevData) => [...prevData, newVehicle]);
      }
      fetchData(); // Fetch data after the operation
      setOpenDialog(false); // Close the dialog after the operation
    } catch (error) {
      alert('Lỗi thêm/cập nhập xe. Hãy kiểm tra các ô lại.');
      console.error('Lỗi thêm/cập nhập xe:', error); 
    }
   
  };
  
  //filter columns
  const filterColumns = [
    { value: 'licensePlateNumber', label: 'Biển số' },
    { value: 'vehicleNumber', label: 'Mã số xe' },  
    { value: 'name', label: 'Tên xe' },
    { value: 'vin', label: 'Số VIN' },
    { value: 'make', label: 'Hãng' },
  ];
 
  // còn người sử dụng / customer / technicle inspection

  //table columns
  const columns: TableColumn<Vehicle>[] = [
    { label: 'Ảnh', field: 'pictureUrl', isImage: true },
    { label: 'Tên xe ', field: 'name' },
    { label: 'Số VIN', field: 'vin' },
    { label: 'Biển số', field: 'licensePlateNumber' },
    // { label: 'Hãng', field: 'makeId' },
    { label: 'Trim', field: 'trim' },
    { label: 'Năm', field: 'year' },
    // { label: 'Chức năng', field: 'vehicleType' },
    // { label: 'Phòng ban', field: 'department' },
    // { label: 'Nhóm', field: 'group' },
    { label: 'Thời gian được tạo', field: 'createdAt', isDate: true },
  ];

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h4">Quản lý xe</Typography>
        </Stack>
        <div>
          <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={handleOpenDialog}>
            Thêm
          </Button>
        </div>
      </Stack>
      <GenericFilters columns={filterColumns} onFilterChange={handleFilterChange} placeholder="Tìm theo tên xe" />
      <GenericTable
        count={filteredData.length}
        page={page}
        rows={paginatedVehicles}
        columns={columns}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onEdit={handleEditVehicle}
        onDelete={handleDeleteVehicle}
        onDeleteSelected={handleDeleteVehicleSelected}
      />
      {/* Add vehicle dialog */}
      <VehicleDialog open={openDialog} onClose={() => setOpenDialog(false)} onSubmit={handleAddOrEditVehicle} vehicleData={selectedVehicle ?? undefined} 
        refreshData={fetchData} // <-- pass fetchData here
      />
    </Stack>
  );
}

// This ensures that this file is treated as a module and resolves the TS1208 error
export { };
