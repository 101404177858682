import React, { useState } from 'react';
import { TextField, Typography } from '@mui/material';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { CustomSelectWithAdd, CustomSelectWithAddProps } from '@/components/dashboard/customSelectWithAdd/customSelectWithAdd';
import { DatePicker } from '@mui/x-date-pickers';

const FormThings = ({
  formFields,
  status,
  statusId,
  setStatusId,
  completedDate,
  setCompletedDate,
  vehicles,
  vehicleId,
  inChargeId,
  setInChargeId,
  setVehicleId,
  customers,
  customerId,
  setCustomerId,
  technicians,
  suppliers,
  inChargeType,
  workOrders,
  workOrderId,
  setWorkOrderId,
  billableStatus,
  billableStatusId,
  setBillableStatusId,
  serviceTasks,
  serviceTaskId,
  setServiceTaskId,
  addServiceTask,
  editServiceTask,
  deleteServiceTask,
  isEditMode
}: {
  formFields: FormField[];
  status: any[];
  statusId: number | null;
  setStatusId: (id: number) => void;
  completedDate: Date | null; 
  setCompletedDate: (date: Date | null) => void;
  vehicles: any[];
  vehicleId: number | null;
  setVehicleId: (id: number) => void;
  customers: any[];
  customerId: number | null;
  setCustomerId: (id: number) => void;
  technicians: any[];
  suppliers: any[];
  inChargeId: number | null;
  setInChargeId: (id: number) => void;
  inChargeType: string | undefined;
  workOrders: any[];
  workOrderId: number | null;
  setWorkOrderId: (id: number) => void;
  billableStatus: any[];
  billableStatusId: number | null;
  setBillableStatusId: (id: number) => void;
  serviceTasks: any[];
  serviceTaskId: number | null;
  setServiceTaskId: (id: number) => void;
  addServiceTask: (item: string, description: string) => void;
  editServiceTask: (id: number, newName: string, description: string) => void; 
  deleteServiceTask: (id: number) => void;
  isEditMode: boolean
}) => {

  const selectedVehicle = vehicles.find((vehicle) => vehicle.id === vehicleId);
  const selectedStatus = status.find((s) => s.id === statusId);

  // choose to show complete status or not based on isEditMode
  const availableStatuses = isEditMode ? status : status.filter((s) => s.function !== 'COMPLETED');

  return (
    <form>   
          {formFields.map((field, index) => (
            <div key={index}>
              {/* Render form fields */}
              <FormFields fields={[field]} />

              {index === 0 && (
                <CustomSelectWithAdd
                  label="Phương tiện"
                  items={vehicles}
                  value={vehicleId || 0}
                  onChange={setVehicleId}
                  manageItem="/dashboard/vehicles"
                  required={true}
                />
              )}

              {index === 0 && (
                <CustomSelectWithAdd
                  label="Chọn công việc cho nhiệm vụ"
                  items={serviceTasks}
                  value={serviceTaskId || 0}
                  onChange={setServiceTaskId}
                  addItem={(name: string, description?: string) => addServiceTask(name, description || '')}
                  editItem={(id: number, name: string, description?: string) => editServiceTask(id, name, description || '')}
                  deleteItem={deleteServiceTask}
                  description_flg={true}
                  required={true}
                />
              )}

              {index === 0 && (
                <CustomSelectWithAdd
                  label="Tình trạng"
                  items={availableStatuses}
                  value={statusId || 0}
                  onChange={(id) => {
                    setStatusId(id); // Update the selected status
                    const selectedStatus = status.find((s) => s.id === id);
                    if (selectedStatus?.function !== 'COMPLETED') {
                      setCompletedDate(null); // Clear the date when the status is not COMPLETED
                    }
                  }}
                  manageItem="/dashboard/maintenance-management"
                  required={true}

                />
              )}

              {/* Move the Ngày hoàn thành field outside the loop */}
              {selectedStatus?.function === 'COMPLETED' && (
                <div className='mb-4'>
                  <Typography variant="body1" sx={{ mt: 1, mb: 1 }}><strong>Ngày hoàn thành *</strong></Typography>
                  <DatePicker
                    value={completedDate}
                    onChange={(value) => setCompletedDate(value)} // Handle value extraction
                    slots={{ textField: TextField }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        placeholder: 'dd/mm/yyyy',

                      },
                    }}
                  />
                </div>
              )}

              {index === 0 && (
                <CustomSelectWithAdd
                  label="Thuộc mục việc cần làm"
                  items={workOrders}
                  value={workOrderId || 0}
                  onChange={setWorkOrderId}
                  manageItem="/dashboard/maintenance-management"
                />
              )}

              {index === 1 && (
                <CustomSelectWithAdd
                  label="Người phụ trách"
                  items={inChargeType === 'TECHNICIAN' ? technicians : suppliers}
                  value={inChargeId || 0}
                  onChange={setInChargeId}
                  manageItem={inChargeType === 'TECHNICIAN' ? '/dashboard/employees' : '/dashboard/suppliers'}
                  required={true} 
                />
              )}

              {index === 1 && (
                <CustomSelectWithAdd
                  label="Tình trạng thanh toán"
                  items={billableStatus}
                  value={billableStatusId || 0}
                  onChange={setBillableStatusId}
                  manageItem="/dashboard/maintenance-management"
                  required={true} 
                />
              )}

              {index === 1 && (
                <CustomSelectWithAdd
                  label="Khách hàng thanh toán"
                  items={customers}
                  value={customerId || 0}
                  onChange={setCustomerId}
                  manageItem="/dashboard/maintenance-management"
                />
              )}

              {/* Display vehicle details based on selected status */}
              {index === 2 && selectedVehicle && selectedStatus?.function === 'COMPLETED' && (
                <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
                  Odo chính của xe: {selectedVehicle.primaryMeter} {selectedVehicle.primaryMeterType}
                </Typography>
              )}

              {index === 3 && selectedVehicle && selectedStatus?.function === 'COMPLETED' && (
                <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
                  Odo phụ của xe: {selectedVehicle.secondaryMeter} {selectedVehicle.secondaryMeterTYpe}
                </Typography>
              )}
            </div>
          ))}

       
    </form>
  );
};

export default FormThings;
