import React, { useState } from 'react';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { CustomSelectWithAdd } from '@/components/dashboard/customSelectWithAdd/customSelectWithAdd';
import { RenewalReminderName } from '@/types/dialog';
import { ParentType } from './RenewalPage';

const FormThings = ({
  formFields,
  vehicles,
  customers,
  suppliers,
  assignees,
  parentId,
  parentType,
  setParentId,
  reminderNameId,
  setReminderNameId,
  addRenewalReminderName,
  editRenewalReminderName,
  deleteRenewalReminderName,
  vehicleNames,
  assigneeNames,
  supplierNames,
  customerNames,
}: {
  formFields: FormField[];
  vehicles: any[];
  customers: any[];
  suppliers: any[];
  assignees: any[];
  parentId: number | null;
  parentType: ParentType | undefined;
  reminderNameId: string | null;
  setReminderNameId: (id: string | null) => void;
  addRenewalReminderName: (parentType: ParentType, name: string, description?: string) => void;
  editRenewalReminderName: (id: number, newName: string, description: string) => void;
  deleteRenewalReminderName: (id: number, parentType: ParentType) => Promise<void>;
  setParentId: (id: number) => void;
  vehicleNames: RenewalReminderName[];
  assigneeNames: RenewalReminderName[];
  supplierNames: RenewalReminderName[];
  customerNames: RenewalReminderName[];
}) => {

  return (
    <form>
      {formFields.map((field, index) => (
        <div key={index}>
          <FormFields fields={[field]} />

          {index === 0 && parentType && (
            <CustomSelectWithAdd
              label="Mục tiêu *"
              items={
                parentType === ParentType.Vehicle
                  ? vehicles
                  : parentType === ParentType.Customer
                  ? customers
                  : parentType === ParentType.Supplier
                  ? suppliers
                  : assignees
              }
              value={parentId || 0}
              onChange={setParentId}
              manageItem={
                parentType === ParentType.Vehicle
                  ? '/dashboard/vehicles'
                  : parentType === ParentType.Customer
                  ? '/dashboard/customers'
                  : parentType === ParentType.Supplier
                  ? '/dashboard/suppliers'
                  : '/dashboard/employees'
              }
            />
          )}

          {index === 0 && parentId && (
            <CustomSelectWithAdd
              label="Mục nhắc nhở *"
              items={
                parentType === ParentType.Vehicle
                  ? vehicleNames
                  : parentType === ParentType.Customer
                  ? customerNames
                  : parentType === ParentType.Supplier
                  ? supplierNames
                  : assigneeNames
              }
              value={reminderNameId ? Number(reminderNameId) : 0}
              onChange={(id: number) => setReminderNameId(id.toString())}
              addItem={(name, description) =>
                addRenewalReminderName(parentType || ParentType.Vehicle, name, description)
              }
              editItem={(id, name, description = '') => editRenewalReminderName(Number(id), name, description)}
              deleteItem={(id) =>
                deleteRenewalReminderName(Number(id), parentType || ParentType.Vehicle)
              }
              description_flg={true}

            />
          )}
        </div>
      ))}
    </form>
  );
};

export default FormThings;
