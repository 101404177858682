import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Chip } from '@mui/material';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { GenericFilters } from '@/components/dashboard/filter/genericFilter';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1'; 
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { PurchaseOrderDialog } from './purchaseOrderDialog';
import { formatDate } from '@/hooks/format-date';
import { getContrastTextColor } from '@/hooks/contrast-text-color';

interface MeasurementUnit {
  name: string;
  shortName: string;
}

interface Part {
  name: string;
  measurementUnit: MeasurementUnit;
}

interface Vendor {
  id: string;
  name: string;
  number: string;
  email: string;
  phoneNumber: string;
  address: string;
  city: string;
}

interface PurchaseOrderStatus {
  id: string;
  name: string;
  description: string;
  color: string;
  function: string;
}

interface Warehouse {
  id: string;
  name: string;
}

interface InventoryLocation {
  id: string;
  part: Part;
}

interface PurchaseOrderQuantity {
  id: string;
  quantity: number;
  inventoryLocationId: number;
  inventoryLocation: InventoryLocation;
}

export interface PurchaseOrder {
  id: string;
  purchaseOrderNumber: string;

  vendorId: number;
  vendor: Vendor;

  statusId: number;
  status: PurchaseOrderStatus;

  warehouseId: number;
  warehouse: Warehouse;

  expectedDeliveryDate: Date | string;

  customFields: Record<string, string>;

  createdAt: Date;
  updatedAt: Date;

  purchaseOrderQuantity: PurchaseOrderQuantity[]; // Add this to capture the quantity data
}


function applyPagination(rows: PurchaseOrder[], page: number, rowsPerPage: number): PurchaseOrder[] {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}


export default function PurchaseOrderPage(): React.JSX.Element {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5); // Adjust number of rows per page as necessary
  const [data, setData] = React.useState< PurchaseOrder []>([]); // Customer data state
  const [filteredData, setFilteredData] = React.useState< PurchaseOrder []>([]); // Filtered data state
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedPurchaseOrder , setSelectedPurchaseOrder ] = React.useState< PurchaseOrder  | null>(null);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/purchase-order');
      const sortedData = response.data.sort((a: PurchaseOrder, b: PurchaseOrder) => 
        dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf()
      );
      setData(sortedData);
      setFilteredData(sortedData)
    } catch (error) {
      console.error('Error fetching data:', error); 
    }
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => { 
    setRowsPerPage(newRowsPerPage); 
    setPage(0);
  };

  const handleEditPurchaseOrder = (id: string) => {
    const partToEdit = data.find((part) => part.id === id);
  
    if (partToEdit) {
      // Check if the order status function is 'Order Completed Status'
      if (partToEdit.status.function === 'Order Completed Status') {
        alert("Đơn hàng này đã hoàn thành và không thể chỉnh sửa.");
        return; // Prevent editing
      }
  
      setSelectedPurchaseOrder(partToEdit); // Populate the dialog with the selected purchase order
      setOpenDialog(true); // Open dialog for editing
    }
  };
  

  const handleDeletePurchaseOrder = async (id: string) => {
    const confirmed = window.confirm('Bạn có chắc là xóa thẻ này?');
    if (confirmed) {
      try {
        await axios.delete(`/api/purchase-order/${JSON.stringify(id)}`);
        alert('Xóa thành công');  
        setData((prevData) => prevData.filter((order) => order.id !== id));
        setFilteredData((prevData) => prevData.filter((order) => order.id !== id));
      } catch (error) {
        console.error('Error deleting purchase order:', error);
      }
    }
  };
  
  const handleDeletePurchaseOrderSelected = async (selectedIds: string[]) => {
    const confirmed = window.confirm('Bạn có chắc muốn xóa những thẻ đã chọn?');
    if (confirmed) {
      try {
        await axios.delete(`/api/purchase-order/${JSON.stringify(selectedIds)}`);
        alert('Xóa thành công');  
        setData((prevData) => prevData.filter((order) => !selectedIds.includes(order.id)));
        setFilteredData((prevData) => prevData.filter((order) => !selectedIds.includes(order.id)));
      } catch (error) {
        console.error('Error deleting purchase order:', error);
      }
    }
  }

  const handleAddOrEditPurchaseOrder = async (purchaseOrder: Partial<PurchaseOrder>) => {  
    try {
      if (selectedPurchaseOrder) {
        setData((prevData) =>
          prevData.map((order) =>
            order.id === selectedPurchaseOrder.id
              ? { ...order, ...purchaseOrder } // Merge new data instead of replacing
              : order
          )
        );
  
        setFilteredData((prevData) =>
          prevData.map((order) =>
            order.id === selectedPurchaseOrder.id
              ? { ...order, ...purchaseOrder } // Merge new data instead of replacing
              : order
          )
        );
      } else {
        setData((prevData) => [...prevData, { ...purchaseOrder } as PurchaseOrder]); 
        setFilteredData((prevData) => [...prevData, { ...purchaseOrder } as PurchaseOrder]); 
      }
  
      fetchData();
      setOpenDialog(false);
    } catch (error) {
      alert('Có lỗi xảy ra, vui lòng thử lại sau');
      console.error('Error adding/editing purchase order:', error);
    }
  };
  

  const handleFilterChange = (column: string, searchTerm: string | boolean | null | Dayjs ) => {
    const filtered = data.filter((part) => {
      let value = part[column as keyof PurchaseOrder]; // Access the column value dynamically  

      switch (column) {
        case ' vendorName': 
          value = part.vendor.name;
          break;
        case 'status':  
          value = part.status.name; 
          break;
      }

      if (searchTerm === null || searchTerm === '') {
        return true;  
      }
  
      if (typeof value === 'string' && typeof searchTerm === 'string') {
        return value.toLowerCase().includes(searchTerm.toLowerCase()); // Case-insensitive search for strings
      }
      
      if (typeof value === 'number' && typeof searchTerm === 'number') {
      return value === Number(searchTerm); // Match numbers exactly
      }
    
      if (typeof value === 'boolean') {
        return value === Boolean(searchTerm); // Match boolean values exactly
      }

            
      if (column === 'date' && dayjs.isDayjs(searchTerm)) {
        // Compare dates for `ngay_kham`
        const date = dayjs(value as string).startOf('day'); // Normalize to date only
        const filterDate = searchTerm.startOf('day'); // Normalize search date
        return date.isSame(filterDate);
      }
          
      return false; // Default return false if value type does not match the expected types
    });

    setFilteredData(filtered);  
  };

  const filterColumns = [
    { value: 'purchaseOrderNumber', label: 'Mã Số đơn hàng' }, 
    { value: 'vendorName', label: 'Nhà cung cấp' }, 
    { value: 'status', label: 'Trạng thái' }, 
    { value: 'expectedDeliveryDate', label: 'Ngày dự kiến giao hàng', type: 'date' as 'date' }, 
  ];

  const columns: TableColumn<PurchaseOrder>[] = [
    { label: 'Mã Số đơn hàng', field: 'purchaseOrderNumber' },  
    { label: 'Trạng thái đơn hàng', 
      field: (row) => {
        const bgColor = row.status?.color || "#ccc"; 
        const textColor = getContrastTextColor(bgColor);

        return (
          <Chip
            label={row.status?.name || 'N/A'}
            style={{
              backgroundColor: row.status?.color || '#ccc', // Use the color from the status
              color: textColor, 
              borderRadius: '5px',
              padding: '5px 10px',
              fontWeight: 'bold',
            }}
          />

        )
      }
    },   
    { 
      label: "Số lượng", 
      field: (row) => 
        row.purchaseOrderQuantity
          .map(qty => `${qty.quantity} ${qty.inventoryLocation?.part?.measurementUnit?.shortName || ''}`)
          .join(', ') || 'N/A'
    },
    { label: 'Nhà cung cấp', field: (row) => row.vendor?.name || "" }, 
    { label: 'Kho hàng', field: (row) => row.warehouse?.name || "" },
    { label: 'Ngày dự kiến giao hàng', field: (row) => formatDate(row.expectedDeliveryDate) },

  ];

  // 

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h4">Quản lý hóa đơn mua</Typography>
        </Stack>
        <div>
      

          {/* <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={handleOpenDialog}>
            Thêm
          </Button> */}
        </div>
      </Stack>

      <GenericFilters 
        columns={filterColumns} onFilterChange={handleFilterChange} placeholder="Tìm thẻ"
      />

      <GenericTable
        count={filteredData.length}
        page={page}
        rows={applyPagination(filteredData, page, rowsPerPage)} // Use transformed data
        columns={columns}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onEdit={handleEditPurchaseOrder}
        onDelete={handleDeletePurchaseOrder}    
        onDeleteSelected={handleDeletePurchaseOrderSelected} 
      />

      <PurchaseOrderDialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)} 
        onSubmit={handleAddOrEditPurchaseOrder} 
        purchaseOrderData={selectedPurchaseOrder ?? undefined}
        refreshData={fetchData  }
      />


  </Stack>
  );

}

