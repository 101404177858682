import React, { useState } from 'react';
import { Typography, Chip, Box, Pagination } from '@mui/material';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { formatDate } from '@/hooks/format-date'; // Import your date formatting function
import { getContrastTextColor } from '@/hooks/contrast-text-color';
import { Vehicle } from "@/types/dialog";

interface ReminderServiceEntriesProps {
  entries: {
    serviceEntryNumber: string;
    serviceTask: { name: string };
    inCharge?: { name: string }; // Assuming inCharge is optional
    status?: { name: string; color: string }; // Assuming status is optional
    completedDate?: string; // Assuming completedDate is optional
    customer?: { name: string; accountNumber: string }; // Assuming customer is optional
    workOrder?: { workOrderNumber: string }; // Assuming workOrder is optional
    primaryMeter?: number;
    secondaryMeter?: number;
  }[];

  vehicleId: number | null; 
  vehicles: Vehicle[];  
}

const ReminderServiceEntries: React.FC<ReminderServiceEntriesProps> = ({ entries, vehicleId, vehicles }) => {
  const [currentPage, setCurrentPage] = useState(1); // State to track the current page
  const entriesPerPage = 5; // Limit entries per page

  // Calculate the number of pages
  const totalPages = Math.ceil(entries.length / entriesPerPage);

  // Get the entries for the current page
  const currentEntries = entries.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );

  // Handle page change
  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const selectedVehicle: Vehicle | undefined = vehicles.find((vehicle: Vehicle) => vehicle.id === vehicleId);

  return (
    <Box mt={2}>
      {entries.length === 0 ? (
        <Typography>Không có nhiệm vụ nào được tìm thấy.</Typography>
      ) : (
        <>
          {currentEntries.map((entry, index) => (
            <Link
              to={`/dashboard/maintainance-service-entries?serviceEntryNumber=${entry.serviceEntryNumber}`}
              key={index}
              style={{ textDecoration: 'none', color: 'inherit' }} // Remove default link styles
            >
              <Box
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  padding: '16px',
                  backgroundColor: "#f9f9f9",
                  cursor: 'pointer', // Add pointer cursor
                  transition: 'box-shadow 0.3s ease', // Add hover effect
                  '&:hover': { boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' },
                }}
                className="mb-2"
              >
                <Typography variant="h6" gutterBottom>
                  <strong> Mã nhiệm vụ: {entry.serviceEntryNumber}</strong>
                </Typography>
                <Typography gutterBottom>
                  <strong> Tên công việc: {entry.serviceTask?.name} </strong>
                </Typography>
                {entry.inCharge && (
                  <Typography gutterBottom>
                    <strong>Người phụ trách: {entry.inCharge.name}</strong>
                  </Typography>
                )}
                {entry.status && (
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography><strong>Trạng thái:</strong></Typography>
                    <Chip
                      label={entry.status.name}
                      style={{
                        backgroundColor: entry.status.color,
                        color: getContrastTextColor(entry.status.color), // Dynamically set text color
                        fontWeight: 'bold',
                        marginLeft: '10px',
                      }}
                    />
                  </Box>
                )}
                {entry.completedDate && (
                  <Typography gutterBottom>
                    <strong> Ngày hoàn thành: </strong> {formatDate(entry.completedDate)}
                  </Typography>
                )}
                {entry.customer && (
                  <Typography gutterBottom>
                    <strong>Khách hàng:</strong> {entry.customer.name}
                  </Typography>
                )}
                {entry.workOrder && (
                  <Typography gutterBottom>
                    <strong>Mã số việc cần làm: </strong>{entry.workOrder.workOrderNumber}
                  </Typography>
                )}
                {entry.primaryMeter && selectedVehicle && (
                  <Typography gutterBottom>
                    <strong>Odo chính lúc thực hiện: </strong>{entry.primaryMeter}  {selectedVehicle.primaryMeterType}
                  </Typography> 
                )}
                { entry.secondaryMeter && selectedVehicle && (
                  <Typography gutterBottom>
                    <strong>Odo phụ lúc thực hiện: </strong>{entry.secondaryMeter}  {selectedVehicle.secondaryMeterType}
                  </Typography>
                )}

              </Box>
            </Link>
          ))}

          {/* Pagination Component */}
          <Box display="flex" justifyContent="center" mt={2}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ReminderServiceEntries;
