import { configureStore } from '@reduxjs/toolkit';
import { vehicleSlice } from './slices/vehicleSlice'; // example slice

export const store = configureStore({
  reducer: {
    vehicle: vehicleSlice.reducer,
  },
});

// For TypeScript
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
