import * as React from 'react';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { Button, List, ListItem, ListItemText, Checkbox, Divider, Typography, Pagination, Box, CircularProgress } from '@mui/material';
import axios from 'axios';

interface DialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (serviceEntryData: any) => void;
  notAssignedItems: SelectedServiceEntry[]; 
  workOrderIdProps?: string;
}

interface SelectedServiceEntry {
  id: true;
  serviceEntryNumber: string;
  vehicleId: number;
  vehicle: {
    name: string;
    vehicleNumber: string;
  };
  serviceTaskId: number;
  serviceTask: {
    name: string;
  };
  status: {
    name: string;
    color: string;
    function: string;
  };
}

export const MaintainanceServiceEntriesNotAssignedDialog : React.FC<DialogProps> = ({ open, onClose, onSubmit, notAssignedItems, workOrderIdProps }) => {
  const [selectedItem, setSelectedItem] = React.useState<SelectedServiceEntry | null>(null);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState<boolean>(false);
  const itemsPerPage = 5;

  const paginatedItems = notAssignedItems.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  const handleToggle = (item: SelectedServiceEntry) => {
    setSelectedItem(prevSelected =>
      prevSelected?.id === item.id ? null : item
    );
  };

  const handleSubmit = async () => {
    if (selectedItem && workOrderIdProps !== undefined) {
      try {
        const submitData = {
          id: selectedItem.id,
          workOrderId: workOrderIdProps
        };
  
        const response = await axios.post(
          '/api/maintainance-service-entry/assign-service-entry-work-order', 
          submitData, 
          {
            headers: {
              'Content-Type': 'multipart/form-data', // Fixed incorrect "multiple/form-data"
            },
          }
        );
  
        const result = response.data;
        onSubmit(result);
        setSelectedItem(null);
      } catch (error) {
        console.error("Error submitting data:", error);
        alert("Đã xảy ra lỗi khi gửi dữ liệu. Vui lòng thử lại sau."); // User-friendly error message
      }
    } else {
      alert('Hãy chọn ít nhất 1 mục');
    }
  };
  

  return (
    <CustomDialog open={open} title="Danh sách hạng mục bảo trì chưa có Work Order" onClose={onClose} onSubmit={handleSubmit} submitLabel="Thêm">
      {loading ? (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
        </Box>
      ) : notAssignedItems.length > 0 ? (
        <>
          <List>
            {paginatedItems.map((item) => (
              <React.Fragment key={item.serviceEntryNumber}>
                <ListItem>
                  <Checkbox
                    checked={selectedItem?.serviceEntryNumber === item.serviceEntryNumber}
                    onChange={() => handleToggle(item)}
                  />
                  <ListItemText
                    primary={
                      <Typography component="span" variant="body1">
                        <strong>Số bảo trì:</strong> {item.serviceEntryNumber}, <strong>Phương tiện:</strong> {item.vehicle.name} ({item.vehicle.vehicleNumber}), 
                        <strong> Nhiệm vụ:</strong> {item.serviceTask.name}, <strong>Trạng thái:</strong> 
                        <span style={{ color: item.status.color }}> {item.status.name}</span>
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>

          {/* Pagination Controls */}
          <Box display="flex" justifyContent="center" mt={2}>
            <Pagination
              count={Math.ceil(notAssignedItems.length / itemsPerPage)}
              page={page}
              onChange={(_, value) => setPage(value)}
              color="primary"
            />
          </Box>
        </>
      ) : (
        <Typography variant="body1" color="textSecondary" align="center" sx={{ mt: 2 }}>
          Không có dữ liệu
        </Typography>
      )}
    </CustomDialog>
  );
};