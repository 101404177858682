import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Download as DownloadIcon } from '@phosphor-icons/react';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { Upload as UploadIcon } from '@phosphor-icons/react';
import { GenericFilters } from '@/components/dashboard/filter/genericFilter';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1';
import axios from 'axios';
import { CustomerDialog } from './customerDialog';
import { formatDate } from '@/hooks/format-date';
import { Dayjs } from 'dayjs';

export interface Customer {
  id: string;
  name: string;
  accountNumber: string;
  website: string;
  email: string;
  phoneNumber: string;
  address: string;
  city: string;
  discount: number;
  createdAt: Date;
  customFields: Record<string, string>;   
}

function applyPagination(rows: Customer[], page: number, rowsPerPage: number): Customer[] {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export default function CustomersPage(): React.JSX.Element {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5); // Adjust number of rows per page as necessary
  const [data, setData] = React.useState<Customer[]>([]); // Customer data state
  const [filteredData, setFilteredData] = React.useState<Customer[]>([]); // Filtered data state
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedCustomer, setSelectedCustomer] = React.useState<Customer | null>(null);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/customers');
      const sortedData = response.data.sort((a: Customer, b: Customer) => { a.name.localeCompare(b.name) });
      setData(sortedData);
      setFilteredData(sortedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const paginatedCustomers = applyPagination(filteredData, page, rowsPerPage);

  const handleFilterChange = (column: string, searchTerm: string | boolean | Dayjs | null) => {
    const filtered = data.filter((customer) => {
      const value = customer[column as keyof Customer]; // Access the column value dynamically
  
      if (searchTerm === null) {
        // If no searchTerm, include all items (e.g., dropdown initial state)
        return true;
      }
  
      if (typeof value === 'string') {
        return value.toLowerCase().includes((searchTerm as string).toLowerCase()); // Case-insensitive search for strings
      }
  
      if (typeof value === 'number') {
        return value === Number(searchTerm); // Match numbers exactly
      }
  
      if (typeof value === 'boolean') {  
        return value === Boolean(searchTerm); // Match boolean values exactly
      }

      return false;  
    });
    
    setFilteredData(filtered);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleOpenDialog = () => {
    setSelectedCustomer(null); // Reset selected customer when adding a new one
    setOpenDialog(true);
  };

  const handleEditCustomer = (id: string) => {
    const customerToEdit = data.find((customer) => customer.id === id);
    if (customerToEdit) {
      setSelectedCustomer(customerToEdit); // Populate the dialog with the selected customer
      setOpenDialog(true); // Open dialog for editing
    }
  };

  const handleDeleteCustomer = async (id: string) => {
    const confirmed = window.confirm('Bạn có chắc là xóa khách hàng này?');
    if (confirmed) {
      try {
        await axios.delete(`/api/customers/${JSON.stringify(id)}`);
        setData((prevData) => prevData.filter((customer) => customer.id !== id));
        setFilteredData((prevData) => prevData.filter((customer) => customer.id !== id));
        alert('Xóa thành công');
      } catch (error) {
        console.error('Error deleting customer:', error);
      }
    }
  };

  const handleDeleteCustomerSelected = async (selectedIds: string[]) => {
    const confirmed = window.confirm('Bạn có chắc là xóa những khách hàng  đã chọn?');
    if (confirmed) {
      try {
        await axios.delete(`/api/customers/${JSON.stringify(selectedIds)}`);
        setData((prevData) => prevData.filter((customer) => !selectedIds.includes(customer.id)));
        setFilteredData((prevData) => prevData.filter((customer) => !selectedIds.includes(customer.id)));
        alert('Xóa thành công');
      } catch (error) {
        console.log('Error deleting customer:', error);
      }
    }
  }

  const handleAddOrEditCustomer = async (customerData: Partial<Customer>, imageFile?: File) => {
    try {
      // Create a FormData object to handle both file and customer data
      const formData = new FormData();
  
      // Append the customer data fields to the FormData object
      Object.keys(customerData).forEach((key) => {
        const value = customerData[key as keyof Customer];
        if (value !== undefined) {
          // Ensure that all values appended to FormData are strings or Blob objects
          formData.append(key, value as string | Blob);
        }
      });
  
        
      // Handle customFields: Convert customFields to a JSON string before appending
      if (customerData.customFields) {
        formData.set('customFields', JSON.stringify(customerData.customFields));
      }
  
      // Append the image file, if it exists
      if (imageFile) {
        formData.append('picture', imageFile); // 'picture' is the field name for the image file
      }
  
      if (selectedCustomer) {
        console.log('Editing customer');
        //iterate the formdata to console.log the formData
        formData.forEach((value, key) => {
          console.log(`${key}: ${value}`);
        });

        // Editing existing customer
        const response = await axios.put(`/api/customers/${selectedCustomer.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
          },
        });
        const updatedCustomer = response.data;
  
        setData((prevData) =>
          prevData.map((customer) => (customer.id === updatedCustomer.id ? updatedCustomer : customer))
        );
        setFilteredData((prevData) =>
          prevData.map((customer) => (customer.id === updatedCustomer.id ? updatedCustomer : customer))
        );
      } else {

        // Adding new customer
        const response = await axios.post('/api/customers', formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
          },
        });
        const newCustomer = response.data;
        setData((prevData) => [...prevData, newCustomer]);
        setFilteredData((prevData) => [...prevData, newCustomer]);
      }
      fetchData(); // Fetch data after the operation
      setOpenDialog(false); // Close the dialog after the operation
    } catch (error: any) {
      console.error('Lỗi thêm/cập nhập người dùng:', error); 
      if (error.response && error.response.status === 409) {
        const errorFlag = error.response.data.errorFlag;
        switch (errorFlag) {
          case 'CUSTOMERNAME_EXISTS': 
            alert('Tên khách hàng đã tồn tại. Xin hãy thử lại với tên khác'); 
            break;
          default:
            alert('Lỗi thêm/cập nhập người dùng. Xin hãy thử lại sau');
            break;
        }
      } else {
        alert('Lỗi thêm/cập nhập người dùng. Xin hãy thử lại sau');
      }
     }
  };
  
  //filter columns
  const filterColumns = [
    { value: 'name', label: 'Tên khách hàng' },
    { value: 'phoneNumber', label: 'Số Điện thoại' },
    { value: 'email', label: 'Email' },
  ];

  //table columns
  const columns: TableColumn<Customer>[] = [
    { label: 'Tên khách hàng', field: 'name' },
    { label: 'Số tài khoản', field: 'accountNumber' },  
    { label: 'Website', field: 'website' },
    { label: 'Email', field: 'email' },
    { label: 'Địa chỉ', field: 'address' },
    { label: 'Số Điện thoại', field: 'phoneNumber' },
    { label: 'Thời gian được tạo', field: 'createdAt', customRender: (rowData) => formatDate(rowData.createdAt) , isDate: true },
  ];

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h4">Khách hàng</Typography>
        </Stack>
        <div>
          <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={handleOpenDialog}>
            Thêm
          </Button>
        </div>
      </Stack>
      <GenericFilters columns={filterColumns} onFilterChange={handleFilterChange} placeholder="Tìm nhà cung cấp" />
      <GenericTable
        count={filteredData.length}
        page={page}
        rows={paginatedCustomers}
        columns={columns}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onEdit={handleEditCustomer}
        onDelete={handleDeleteCustomer}
        onDeleteSelected={handleDeleteCustomerSelected}
      />
      {/* Add customer dialog */}
      <CustomerDialog open={openDialog} onClose={() => setOpenDialog(false)} onSubmit={handleAddOrEditCustomer} customerData={selectedCustomer ?? undefined} 
        refreshData={fetchData} // <-- pass fetchData here
      />
    </Stack>
  );
}

// This ensures that this file is treated as a module and resolves the TS1208 error
export { };
