import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Manufacturer } from './InventorySetup'; // Import the component
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields } from '@/components/dashboard/formField/FormFields';
import { PartEntriesComponent } from '@/components/subPlace/PartEntriesComponents'; // Import the component
import axios from 'axios';
import { set } from 'date-fns';
import { toBeRequired } from '@testing-library/jest-dom/matchers';

interface manufacturerDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  data?: Partial<Manufacturer>;
}

export const ManufacturerDialog: React.FC<manufacturerDialogProps> = ({ open, data, onClose, onSubmit }) => {
  const isEditMode = !!data;
  const [manufacturerData, setManufacturerData] = React.useState(data || {}); 
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (data) {
      setManufacturerData(data);
    } else {
      setManufacturerData({});
    }
  }, [open, data]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setManufacturerData({ ...manufacturerData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};  
    const errorMessages: string[] = [];

    // validate the name 

    if (!manufacturerData.name || manufacturerData.name.trim() === '') {
      newErrors.name = 'Vui lòng nhập tên nhà sản xuất.'; 
    } 

    setErrors(newErrors); 

    if (errorMessages.length > 0) {
      const formattedErrorMessages = errorMessages.map((message, index) => `+ ${message}`);
      alert(`Vui lòng kiểm tra lại các trường thông tin sau:\n${formattedErrorMessages.join('\n')}`);
    }

    return Object.keys(newErrors).length === 0; 
  }

  const handleSubmit = async () => {
    if (!validateForm()) return;  

    try {
      const manufacturerDataSubmit = {
        ...manufacturerData 
      }

      const formDataSubmit = new FormData();  
      Object.keys(manufacturerDataSubmit).forEach(key => {
        const value = manufacturerDataSubmit[key as keyof Manufacturer];
        formDataSubmit.append(key, value as string | Blob);
      });

      let response; 
      if (!isEditMode) {
        response = await axios.post('/api/manufacturer/full', formDataSubmit, {  
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      } else {
        response = await axios.put(`/api/manufacturer/full/${manufacturerData.id}`, formDataSubmit, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      }

      const updatedManufacturerData = response.data;
      onSubmit(updatedManufacturerData);

      setManufacturerData({});  
      setErrors({});  
    } catch (error: any) {
      if (error.response) {
        const { status, data } = error.response;
  
        if (status === 409) {
          alert(`❌ Lỗi tên nhầ sản xuất đã tồn tại. Vui lòng chọn một tên khác.`);
        } else if (status === 400) {
          alert(`⚠️ Lỗi dữ liệu không hợp lệ: ${data.error}`);
        } else if (status === 404) {
          alert('❌ Không tìm thấy nhà sản xuất.');
        } else {
          alert('❌ Có lỗi xảy ra khi thêm/chỉnh sửa nhà sản xuất. Vui lòng thử lại sau.');
        }
      } else {
        alert('❌ Không thể kết nối đến máy chủ. Vui lòng kiểm tra mạng và thử lại.');
      }
    }
  }

  const formFields = [
    { name: 'name', label: 'Tên nhà sản xuất', type: 'text', value: manufacturerData.name, handleChange: handleChange, error: errors.name, required: true },
    { name: 'website', label: 'Website', type: 'text', value: manufacturerData.website, handleChange: handleChange, error: errors.website },
    { name: 'comment', label: 'Ghi chú', type: 'text', value: manufacturerData.comment, handleChange: handleChange, error: errors.comment },  
  ]

  return (
    <CustomDialog open={open} title={ isEditMode ? 'Chỉnh sủa thông tin nhà sản xuất' : 'Thêm thông tin nhà sản xuất'} onClose={onClose} onSubmit={handleSubmit} dialogWidth='1200px' dialogMaxWidth='95%'>
      <FormFields fields={formFields} />
        
    </CustomDialog>
  )
};