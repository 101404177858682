// Hooks for managing and fetching departments, job titles, and groups
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Department, JobTitle, Group, Make, Model, VehicleType, FuelType, LicensePlateType, 
  TrailerAttachedTo, WorkOrderStatus, ServiceEntriesStatus, Assignee , Vehicle, Technician, Customer,
  Supplier, WorkOrder, BillableStatus, ServiceTask, InspectionForm, InspectionCategory, PriorityStatus,
 RenewalReminderName, Driver, FuelTank, TireSet, TireModel, TireSize, TirePosition, Warehouse, Manufacturer,
 PartMeasurementUnit, Part, Aisle, Row, Bin, PurchaseOrderStatus,  InventoryLocationForPart
 } from '@/types/dialog';

export const UseFetchDepartments = () => {
  const [departments, setDepartments] = useState<Department[]>([]);
  const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchDepartments();
    }
  }, []);

  const fetchDepartments = async () => {
    try {
      const response = await axios.get(`/api/departments`);
      setDepartments(response.data);
    } catch (error) {
      console.error('Failed to fetch departments', error);
    }
  };

   const addDepartment = async (newDepartmentName: string) => {
    try {
      const response = await axios.post(`/api/add-department`, { name: newDepartmentName });
      setDepartments([...departments, response.data]);
    } catch (error: any) {
      console.error('Failed to add department:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên bộ phận đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi thêm bộ phận, vui lòng thử lại sau.');
      }
    }
  };

  const editDepartment = async (id: number, newDepartmentName: string) => {
    try {
      const response = await axios.put(`/api/edit-department/${id}`, { name: newDepartmentName });
      setDepartments(departments.map(dept => (dept.id === id ? { ...dept, name: newDepartmentName } : dept)));
    } catch (error: any) {
      console.error('Failed to edit department:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên bộ phận đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi cập nhập bộ phận, vui lòng thử lại sau.');
      }
    }
  };

  const deleteDepartment = async (id: number) => {
    try {
      await axios.delete(`/api/delete-department/${id}`);
      setDepartments(departments.filter(department => department.id !== id));
    } catch (error) {
      console.error('Failed to delete department:', error);
      alert('Lỗi xóa bộ phận, vui lòng thử lại sau');
    }
  };

  return { departments, addDepartment, editDepartment, deleteDepartment };
};

export const UseFetchJobTitles = () => {
const [jobTitles, setJobTitles] = useState<JobTitle[]>([]);
const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchJobTitles();
    }
  }, []);

  const fetchJobTitles = async () => {
    try {
      const response = await axios.get(`/api/job-titles`);
      setJobTitles(response.data);
    } catch (error) {
      console.error('Failed to fetch job titles', error);
    }
  };

  const addJobTitle = async (newJobTitle: string) => {
    try {
      const response = await axios.post(`/api/add-job-title`, { name: newJobTitle });
      setJobTitles([...jobTitles, response.data]);
    } catch (error: any) {
      console.error('Failed to edit job title: ', error);
      if (error.response && error.response.status === 409) {
        alert('Tên chức vụ đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi thêm chức vụ, vui lòng thử lại sau.');
      }    
    }
  };

  const editJobTitle = async (id: number, newJobTitle: string) => {
    try{
      const response = await axios.put(`/api/edit-job-title/${id}`, { name: newJobTitle });
      setJobTitles(jobTitles.map(jobTitle => (jobTitle.id === id ? { ...jobTitle, name: newJobTitle } : jobTitle)));
    } catch(error: any){
      console.error('Failed to edit job title: ', error);
      if (error.response && error.response.status === 409) {
        alert('Tên chức vụ đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi cập nhập chức vụ, vui lòng thử lại sau.');
      }    
    }
  };

  const deleteJobTitle = async (id: number) => {
    try {
      await axios.delete(`/api/delete-job-title/${id}`);
      setJobTitles(jobTitles.filter(jobTitle => jobTitle.id !== id));
    } catch (error) {
      console.error('Failed to delete job title:', error);
      alert('Lỗi xóa bộ phận, vui lòng thử lại sau.');
    }
  };

  return { jobTitles, addJobTitle, editJobTitle, deleteJobTitle };
};

export const UseFetchGroups = () => {
    const [groups, setGroups] = useState<Group[]>([]);
    const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchGroups();
    }
  }, []);

  const fetchGroups = async () => {
    try {
      const response = await axios.get(`/api/groups`);
      setGroups(response.data);
    } catch (error) {
      console.error('Failed to fetch groups', error);
    }
  };

  const addGroup = async (newGroupName: string) => {
    try {
      const response = await axios.post(`/api/add-group`, { name: newGroupName });
      setGroups([...groups, response.data]);
    } catch (error: any) {
      console.error('Failed to edit group: ', error);
      if (error.response && error.response.status === 409) {
        alert('Tên nhóm đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi tạo thêm nhóm, vui lòng thử lại sau.');
      }     
    }
  };

  const editGroup = async (id: number, newGroupName: string) => {
    try{
      const response = await axios.put(`/api/edit-group/${id}`, { name: newGroupName });
      setGroups(groups.map(group => (group.id === id ? { ...group, name: newGroupName } : group)));
    } catch(error: any){
      console.error('Failed to edit group: ', error);
      if (error.response && error.response.status === 409) {
        alert('Tên nhóm đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi cập nhập nhóm, vui lòng thử lại sau.');
      }    
     }
  };

  const deleteGroup = async (id: number) => {
    try {
      await axios.delete(`/api/delete-group/${id}`);
      setGroups(groups.filter(group => group.id !== id));
    } catch (error) {
      console.error('Failed to delete group:', error);
      alert('Lỗi xóa nhóm, vui lòng thử lại sau.')
    }
  };

  return { groups, addGroup, editGroup, deleteGroup };
};

export const UseFetchMake = () => {
  const [makes, setMakes] = useState<Make[]>([]);
  const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches 

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchMakes();
    }
  }, []);

  const fetchMakes = async () => {
    try {
      const response = await axios.get(`/api/makes`);
      setMakes(response.data);
    } catch (error) {
      console.error('Failed to fetch makes', error);
    }
  };
  
  const addMake = async (newMake: string) => {  
    try{
      const response = await axios.post(`/api/makes`, { name: newMake });
      setMakes([...makes, response.data]);
    } catch (error: any){
      console.error('Failed to add make:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên hãng xe đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi tạo thêm hãng xe, vui lòng thử lại sau.');
      }   

    } 
  };

  const editMake = async (id: number, newMake: string) => {
    try{
      const response = await axios.put(`/api/makes/${id}`, { name: newMake });
      setMakes(makes.map(make => (make.id === id ? {...make, name: newMake} : make))); // Update the entire object
    } catch (error: any){
      console.error('Failed to edit make:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên hãng xe đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi cập nhập hãng xe, vui lòng thử lại sau.');
      }    
    }
  };

  const deleteMake = async (id: number) => {  
    try{
      await axios.delete(`/api/makes/${id}`);
      setMakes(makes.filter(make => make.id !== id));
    } catch (error){
      console.error('Failed to delete make:', error);
    }
  }

  return { makes, addMake, editMake, deleteMake };
};

// for model 
export const UseFetchModel = () => {
  const [models, setModels] = useState<Model[]>([]);
  const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches 

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchModels();
    }
  }, []);

  const fetchModels = async () => {
    try {
      const response = await axios.get(`/api/models`);
      setModels(response.data);
    } catch (error) {
      console.error('Failed to fetch models', error);
    }
  };
  
  const addModel = async (newModel: string) => {  
    try{
      const response = await axios.post(`/api/models`, { name: newModel });
      setModels([...models, response.data]);
    } catch (error: any){
      console.error('Failed to add model:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên dòng xe đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi cập nhập dòng xe, vui lòng thử lại sau.');
      }  
    } 
  };

  const editModel = async (id: number, newModel: string) => {
    try{
      const response = await axios.put(`/api/models/${id}`, { name: newModel });
      setModels(models.map(model => (model.id === id ? {...model, name: newModel}: model)));
    } catch (error: any){
      console.error('Failed to edit model:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên dòng xe đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi cập nhập dòng xe, vui lòng thử lại sau.');
      }  
    }
  };

  const deleteModel = async (id: number) => {  
    try{
      await axios.delete(`/api/models/${id}`);
      setModels(models.filter(model => model !== models[id]));
    } catch (error){
      console.error('Failed to delete model:', error);
    }
  }

  return { models, addModel, editModel, deleteModel };

};

// for vehicleTypeId
export const UseFetchVehicleType = () => {
  const [vehicleTypes, setVehicleTypes] = useState<VehicleType[]>([]);
  const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchVehicleTypes();
    }
  }, []);

  const fetchVehicleTypes = async () => {
    try {
      const response = await axios.get(`/api/vehicle-types`);
      setVehicleTypes(response.data);
    } catch (error) {
      console.error('Failed to fetch vehicle types', error);
    }
  };
  
  const addVehicleType = async (newVehicleType: string) => {  
    try{
      const response = await axios.post(`/api/vehicle-types`, { name: newVehicleType });
      setVehicleTypes([...vehicleTypes, response.data]);
    } catch (error: any){
      console.error('Failed to add vehicle type:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên loại xe đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi cập nhập loại xe, vui lòng thử lại sau.');
      }   
    } 
  };

  const editVehicleType = async (id: number, newVehicleType: string) => {
    try{
      const response = await axios.put(`/api/vehicle-types/${id}`, { name: newVehicleType });
      setVehicleTypes(vehicleTypes.map(vehicleType => (vehicleType.id === id ? {...vehicleType, name: newVehicleType}: vehicleType)));
    } catch (error: any){
      console.error('Failed to add vehicle type:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên loại xe đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi cập nhập loại xe, vui lòng thử lại sau.');
      }       
    }
  };

  const deleteVehicleType = async (id: number) => {  
    try{
      await axios.delete(`/api/vehicle-types/${id}`);
      setVehicleTypes(vehicleTypes.filter(vehicleType => vehicleType !== vehicleTypes[id]));
    } catch (error){
      console.error('Failed to delete vehicle type:', error);
    }
  }

  return { vehicleTypes, addVehicleType, editVehicleType, deleteVehicleType };
};

// for fuelTypeId
export const UseFetchFuelType = () => {
  const [fuelTypes, setFuelTypes] = useState<FuelType[]>([]);
  const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchFuelTypes();
    }
  }, []);

  const fetchFuelTypes = async () => {
    try {
      const response = await axios.get(`/api/fuel-types`);
      setFuelTypes(response.data);
    } catch (error) {
      console.error('Failed to fetch fuel types', error);
    }
  };
  
  const addFuelType = async (newFuelType: string) => {  
    try{
      const response = await axios.post(`/api/fuel-types`, { name: newFuelType });
      setFuelTypes([...fuelTypes, response.data]);
    } catch (error: any){
      console.error('Failed to add fuel type:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên loại nhiên liệu đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi cập nhập loại nhiên liệu, vui lòng thử lại sau.'); 
      }
    } 
  };

  const editFuelType = async (id: number, newFuelType: string) => {
    try{
      const response = await axios.put(`/api/fuel-types/${id}`, { name: newFuelType });
      setFuelTypes(fuelTypes.map(fuelType => (fuelType.id === id ? {...fuelType, name: newFuelType} : fuelType)));
    } catch (error: any){
      console.error('Failed to edit fuel type:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên loại nhiên liệu đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi cập nhập loại nhiên liệu, vui lòng thử lại sau.'); 
      }
    }
  };

  const deleteFuelType = async (id: number) => {  
    try{
      await axios.delete(`/api/fuel-types/${id}`);
      setFuelTypes(fuelTypes.filter(fuelType => fuelType.id !== id));
    } catch (error){
      console.error('Failed to delete fuel type:', error);
    }
  }

  return { fuelTypes, addFuelType, editFuelType, deleteFuelType };
};

// for licensePlateTypeId
export const UseFetchLicensePlateType = () => {
  const [licensePlateTypes, setLicensePlateTypes] = useState<LicensePlateType[]>([]);
  const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchLicensePlateTypes();
    }
  }, []);

  const fetchLicensePlateTypes = async () => {
    try {
      const response = await axios.get(`/api/license-plate-types`);
      setLicensePlateTypes(response.data);
    } catch (error) {
      console.error('Failed to fetch license plate types', error);
    }
  };
  
  const addLicensePlateType = async (newLicensePlateType: string) => {  
    try{
      const response = await axios.post(`/api/license-plate-types`, { name: newLicensePlateType });
      setLicensePlateTypes([...licensePlateTypes, response.data]);
    } catch (error: any){
      console.error('Failed to add license plate type:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên loại biển số đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi tạo loại biển số , vui lòng thử lại sau.');
      }  
    } 
  };

  const editLicensePlateType = async (id: number, newLicensePlateType: string) => {
    try{
      const response = await axios.put(`/api/license-plate-types/${id}`, { name: newLicensePlateType });
      setLicensePlateTypes(licensePlateTypes.map(licensePlateType => (licensePlateType.id === id ? {...licensePlateType, name: newLicensePlateType} : licensePlateType)));
    } catch (error: any){
      console.error('Failed to edit license plate type:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên loại biển số đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi chỉnh sửa loại biển số , vui lòng thử lại sau.');
      }  
    }
  };

  const deleteLicensePlateType = async (id: number) => {  
    try{
      await axios.delete(`/api/license-plate-types/${id}`);
      setLicensePlateTypes(licensePlateTypes.filter(licensePlateType => licensePlateType.id !== id));
    } catch (error){
      console.error('Failed to delete license plate type:', error);
    }
  }

  return { licensePlateTypes, addLicensePlateType, editLicensePlateType, deleteLicensePlateType };
};

// for trailerAttachedTo  /api/trailer-attached-to
export const UseFetchTrailerAttachedTo = () => {
  const [trailerAttachedTo, setTrailerAttachedTo] = useState<TrailerAttachedTo[]>([]);
  const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches
  
  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchTrailerAttachedTos();
    }
  }, []);

  const fetchTrailerAttachedTos = async () => {
    try {
      const response = await axios.get(`/api/vehicles/trailer-attached-to`);
      setTrailerAttachedTo(response.data);
    } catch (error) {
      console.error('Failed to fetch trailer attached to', error);
    }
  };

  return { trailerAttachedTo };

};


// fix the later about the add/edit/delete because its relate to the user account
export const UseFetchAccount = () => {  
  const [assignees, setAssignees] = useState<Assignee[]>([]);
  const [technicians, setTechnicians] = useState<Technician[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]); 
  const [drivers, setDrivers] = useState<Driver[]>([]); 
  const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchAssigneesVehicleAssign();
      fetchTechnicians();
      fetchCustomers();
      fetchDrivers();
    }
  }, []);

  // for the fetch vevhicle assign 
  const fetchAssigneesVehicleAssign = async () => {
    try {
      const response = await axios.get(`/api/assignees`);
      // change the username of the data to name to fit with the custom select
      const modifiedAssignees = response.data.map((assignee: { id: number; username: string }) => ({
        id: assignee.id,
        name: assignee.username
      }));
      setAssignees(modifiedAssignees);
    } catch (error) {
      console.error('Failed to fetch assignees', error);
    }
  };

  // for the fetch technician
  const fetchTechnicians = async () => {
    try {
      const response = await axios.get(`/api/technicians`);
      const modifyTechnicians = response.data.map((technician: { id: number; username: string }) => ({
        id: technician.id,
        name: technician.username
      }));
      setTechnicians(modifyTechnicians);
    } catch (error) {
      console.log('Failed to fetch technicians', error);
    }
  };

  const fetchDrivers = async () => {
    try {
      const response = await axios.get(`/api/drivers`);
      const modifyDrivers = response.data.map((driver: { id: number; username: string }) => ({
        id: driver.id,
        name: driver.username
      }));
      setDrivers(modifyDrivers);  
    } catch (error) {
      console.log('Failed to fetch technicians', error);
    }
  }

  const fetchCustomers = async () => {  
    try {
      const response = await axios.get(`/api/customers/min`);
      const modifiedCustomers = response.data.map((customer: { id: number; name: string }) => ({
        id: customer.id,
        name: customer.name
      }));  
      setCustomers(modifiedCustomers);
    } catch (error) {
      console.error('Failed to fetch customers', error);
    }
  };
  return { assignees, technicians, customers, drivers };  
}

// also fix the later about the add/edit/delete because its relate to the vehicle table
export const UseFetchVechicle = () => {
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);  
  const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches

  useEffect(() => {
    if (!isFetching){
      setIsFetching(true);
      fetchVehicles();
    }
  }, []);

  const fetchVehicles = async () => {
    try {
      const response = await axios.get(`/api/vehicles/min`);
      setVehicles(response.data);
    } catch (error) {
      console.error('Failed to fetch vehicles', error);
    }
  };

  return { vehicles };
}

// also fix the add/edit/delete
export const UseFetchSupplier = () => {
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [fuelSuppliers, setFuelSuppliers] = useState<Supplier[]>([]); 
  const [partSuppliers, setPartSuppliers] = useState<Supplier[]>([]);
  const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetch

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchSuppliers();
      fetchFuelSuppliers();
      fetchPartSuppliers();
    }
  }, []);

  const fetchSuppliers = async () => {
    try {
      const response = await axios.get(`/api/suppliers/min`);
      setSuppliers(response.data);
    } catch (error) {
      console.error('Failed to fetch suppliers', error);
    }
  };

  const fetchFuelSuppliers = async () => {
    try {
      const response = await axios.get(`/api/supplier/min/fuel`);
      setFuelSuppliers(response.data);  
    } catch (error) {
      console.error('Failed to fetch fuel suppliers', error);
    }
  };

  const fetchPartSuppliers = async () => {
    try {
      const response = await axios.get(`/api/supplier/min/part`);
      setPartSuppliers(response.data);
    } catch (error) {
      console.error('Failed to fetch part suppliers', error);
    }
  };

  return { suppliers, fuelSuppliers, partSuppliers };
}

//services entries things here

export const UseFetchWorkOrder = () => {
  const [workOrders, setWorkOrders] = useState<WorkOrder[]>([]);
  const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchWorkOrders();
    }
  }, []);

  const fetchWorkOrders = async () => {
    try {
      const response = await axios.get(`/api/work-order-min`);
      setWorkOrders(response.data);
    } catch (error) {
      console.error('Failed to fetch work orders', error);
    }
  };

  return { workOrders };
}

// for workOrderStatus
export const UseFetchWorkOrderStatus = () => {
  const [workOrderStatus, setWorkOrderStatus] = useState<WorkOrderStatus[]>([]);
  const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchWorkOrderStatus();
    }
  }, []); // Empty dependency array ensures this only runs once

  const fetchWorkOrderStatus = async () => {
    try {
      const response = await axios.get(`/api/work-order-statuses`); 
      setWorkOrderStatus(response.data);
    } catch (error) {
      console.error('Failed to fetch work order status', error);
    }
  }


  return { workOrderStatus };
}

// for service entries status
export const useFetchServiceEntriesStatus = () => {
  const [serviceEntriesStatus, setServiceEntriesStatus] = useState<ServiceEntriesStatus[]>([]);
  const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchServiceEntriesStatus();
    } 
  });

  const fetchServiceEntriesStatus = async () => { 
    try {
      const response = await axios.get(`/api/service-entries-statuses`);
      setServiceEntriesStatus(response.data);
    } catch (error) {
      console.error('Failed to fetch service entries status', error);
    }
  };

  return { serviceEntriesStatus, }; 
}

export const UseFetchBillableStatus = () => { 
  const [billableStatus, setBillableStatus] = useState<BillableStatus[]>([]);
  const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchBillableStatus();
    }
  });
  
  const fetchBillableStatus = async () => {
    try {
      const response = await axios.get(`/api/billable-statuses`);
      setBillableStatus(response.data);
    } catch (error) {
      console.error('Failed to fetch billable status', error);
    }
  };

  return { billableStatus,  };
}

export const UseFetchServiceTask = () => {
  const [serviceTasks, setServiceTasks] = useState<ServiceTask[]>([]);
  const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchServiceTasks();
    }
  }, [isFetching]);

  const fetchServiceTasks = async () => {
    try {
      const response = await axios.get(`/api/service-tasks`);
      setServiceTasks(response.data);
    } catch (error) {
      console.error('Failed to fetch service tasks', error);
    }
  }

  const addServiceTask = async (newServiceTask: string, description: string) => {
    try {
      const response = await axios.post(`/api/service-tasks`, {
        name: newServiceTask,
        description: description,
      });
      setServiceTasks([...serviceTasks, response.data]);
    } catch (error: any) {
      console.log('Failed to add service task:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên công việc đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi tạo công việc, vui lòng thử lại sau.');
      }  
    }
  };

  const editServiceTask = async (id: number, newServiceTask: string, description: string) => {
    try {
      const response = await axios.put(`/api/service-tasks/${id}`, {
        name: newServiceTask,
        description: description,
      });
      setServiceTasks(serviceTasks.map(serviceTask => (serviceTask.id === id ? {...serviceTask, name: newServiceTask, description: description} : serviceTask)));
    } catch (error: any) {
      console.error('Failed to edit service task:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên công việc đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi chỉnh sửa công việc, vui lòng thử lại sau.');
      }  
    }
  };

  const deleteServiceTask = async (id: number) => {
    try {
      await axios.delete(`/api/service-tasks/${id}`);
      setServiceTasks(serviceTasks.filter(serviceTask => serviceTask.id !== id));
    } catch (error) {
      console.error('Failed to delete service task:', error);
    }
  }

  return { serviceTasks, addServiceTask, editServiceTask, deleteServiceTask };
}

export const UseFetchInspectionForm = () => {
  const [inspectionForms, setInspectionForms] = useState<InspectionForm[]>([]);
  const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchInspectionForms();
    }
  });

  const fetchInspectionForms = async () => {
    try {
      const response = await axios.get(`/api/inspection-forms`);
      setInspectionForms(response.data);
    } catch (error) {
      console.error('Failed to fetch inspection forms', error);
    }
  };

  return { inspectionForms };
}

export const UseFetchInspectionCategory = () => {
  const [inspectionCategories, setInspectionCategories] = useState<InspectionCategory[]>([]);
  const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchInspectionCategories();
    }
  });

  const fetchInspectionCategories = async () => {
    try {
      const response = await axios.get(`/api/inspection-categories`);
      setInspectionCategories(response.data);
    } catch (error) {
      console.error('Failed to fetch inspection categories', error);
    }
  };

  const addInspectionCategory = async (newInspectionCategory: string) => {
    try {
      const response = await axios.post(`/api/inspection-categories`, { name: newInspectionCategory });
      setInspectionCategories([...inspectionCategories, response.data]);
    } catch (error) {
      console.error('Failed to add inspection category:', error);
    }
  };

  const editInspectionCategory = async (id: number, newInspectionCategory: string) => {
    try {
      const response = await axios.put(`/api/inspection-categories/${id}`, { name: newInspectionCategory });
      setInspectionCategories(inspectionCategories.map(inspectionCategory => (inspectionCategory.id === id ? {...inspectionCategory, name: newInspectionCategory} : inspectionCategory)));
    } catch (error) {
      console.error('Failed to edit inspection category:', error);
    }
  };

  const deleteInspectionCategory = async (id: number) => {
    try {
      await axios.delete(`/api/inspection-categories/${id}`);
      setInspectionCategories(inspectionCategories.filter(inspectionCategory => inspectionCategory.id !== id));
    } catch (error) {
      console.error('Failed to delete inspection category:', error);
    }
  };


  return { inspectionCategories, addInspectionCategory, editInspectionCategory, deleteInspectionCategory };

}

export const UseFetchPriorityStatus = () => {
  const [priorityStatus, setPriorityStatus] = useState<PriorityStatus[]>([]);
  const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchPriorityStatus();
    }
  }, []);

  const fetchPriorityStatus = async () => { 
    try {
      const response = await axios.get(`/api/priority-statuses`); 
      setPriorityStatus(response.data);  
    } catch (error) {
      console.log('Failed to fetch priority status', error);
    }
  };

  return {priorityStatus};

}

// use fetch for name for the renewal reminder dialog
export const UseFetchRenewalReminderName = () => {
  const [vehicleNames, setVehicleNames] = useState<RenewalReminderName[]>([]);
  const [assigneeNames, setAssigneeNames] = useState<RenewalReminderName[]>([]);
  const [supplierNames, setSupplierNames] = useState<RenewalReminderName[]>([]);
  const [customerNames, setCustomerNames] = useState<RenewalReminderName[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchRenewalReminderName();
    }
  }, []);

  // Fetch data
  const fetchRenewalReminderName = async () => {
    try {
      const response = await axios.get<RenewalReminderName[]>('/api/renewal-reminder-name');
      const grouped = groupByParentType(response.data);

      // Set states for each group
      setVehicleNames(grouped.Vehicle || []);
      setAssigneeNames(grouped.Assignee || []);
      setSupplierNames(grouped.Supplier || []);
      setCustomerNames(grouped.Customer || []);
    } catch (error) {
      console.error('Failed to fetch renewal reminder name', error);
    }
  };

  // Add a new renewal reminder name
  const addRenewalReminderName = async (parentType: string, name: string, description?: string) => {
    try {
      const newItem: Omit<RenewalReminderName, 'id'> = {
        parentType: parentType as RenewalReminderName['parentType'],
        name,
        description,
        createdAt: new Date(),
        renewalReminderId: 0, // Adjust if necessary
      };
      const response = await axios.post<RenewalReminderName>('/api/renewal-reminder-name', newItem);
      const addedItem = response.data;

      // Update state
      updateStateByParentType(addedItem, 'add');
    } catch (error) {
      console.error('Failed to add renewal reminder name', error);
    }
  };

  // Edit an existing renewal reminder name
  const editRenewalReminderName = async (id: number, name: string, description: string) => {
    try {
      const response = await axios.put<RenewalReminderName>(`/api/renewal-reminder-name/${id}`, {
        name,
        description,
      });
      const editedItem = response.data;

      // Update state
      updateStateByParentType(editedItem, 'edit');
    } catch (error) {
      console.error('Failed to edit renewal reminder name', error);
    }
  };

  // Delete a renewal reminder name
  const deleteRenewalReminderName = async (id: number, parentType: RenewalReminderName['parentType']) => {
    try {
      await axios.delete(`/api/renewal-reminder-name/${id}`);

      // Update state
      updateStateByParentType({ id, parentType } as RenewalReminderName, 'delete');
    } catch (error) {
      console.error('Failed to delete renewal reminder name', error);
    }
  };

  // Helper to update the state by parentType
  const updateStateByParentType = (item: RenewalReminderName, action: 'add' | 'edit' | 'delete') => {
    const updateState = (current: RenewalReminderName[], newItem: RenewalReminderName) => {
      switch (action) {
        case 'add':
          return [...current, newItem];
        case 'edit':
          return current.map((i) => (i.id === newItem.id ? { ...i, ...newItem } : i));
        case 'delete':
          return current.filter((i) => i.id !== newItem.id);
        default:
          return current;
      }
    };

    switch (item.parentType) {
      case 'Vehicle':
        setVehicleNames((current) => updateState(current, item));
        break;
      case 'Assignee':
        setAssigneeNames((current) => updateState(current, item));
        break;
      case 'Supplier':
        setSupplierNames((current) => updateState(current, item));
        break;
      case 'Customer':
        setCustomerNames((current) => updateState(current, item));
        break;
    }
  };

  // Group data by parentType
  const groupByParentType = (data: RenewalReminderName[]) => {
    const initialGroup: Record<'Vehicle' | 'Assignee' | 'Supplier' | 'Customer', RenewalReminderName[]> = {
      Vehicle: [],
      Assignee: [],
      Supplier: [],
      Customer: [],
    };

    return data.reduce((acc, item) => {
      acc[item.parentType as keyof typeof acc].push(item);
      return acc;
    }, initialGroup);
  };

  return {
    vehicleNames,
    assigneeNames,
    supplierNames,
    customerNames,
    addRenewalReminderName,
    editRenewalReminderName,
    deleteRenewalReminderName,
  };
};

export const UseFetchFuelTank = () => { 
  const [fuelTanks, setFuelTanks] = useState<FuelTank[]>([]); 
  const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches 

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchFuelTanks();
    } 
  });

  const fetchFuelTanks = async () => {
    try {
      const response = await axios.get(`/api/fuel-tanks/min`);
      setFuelTanks(response.data);

      console.log('fuel tanks', response.data);
    } catch (error) {
      console.error('Failed to fetch fuel tanks', error);
    }
  };

  return { fuelTanks };
};
 
export const UseFetchTireSet = () => {  
  const [tireSets, setTireSets] = useState<TireSet[]>([]);  
  const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchTireSets();
    }
  }, [isFetching]);

  const fetchTireSets = async () => {
    try {
      const response = await axios.get(`/api/tire-sets`);
      setTireSets(response.data);
    } catch (error) {
      console.error('Failed to fetch tire sets', error);  
    }
  }

  const addTireSet = async (newTireSet: string) => { 
    try {
      const response = await axios.post(`/api/tire-sets`, { name: newTireSet });
      setTireSets([...tireSets, response.data]);  
    } catch (error) {
      console.log('Failed to add tire set:', error);
    }
  }

  const editTireSet = async (id: number, newTireSet: string) => { 
    try {
      const response = await axios.put(`/api/tire-sets/${id}`, { name: newTireSet });
      setTireSets(tireSets.map(tireSet => (tireSet.id === id ? { ...tireSet, name: newTireSet } : tireSet)))
    } catch (error) {
      console.log('Failed to edit tire set:', error);
    }
  }

  const deleteTireSet = async (id: number) => {
    try {
      await axios.delete(`/api/tire-sets/${id}`);
      setTireSets(tireSets.filter(tireSet => tireSet.id !== id));
    } catch (error) {
      console.log('Failed to delete tire set:', error);
    }
  }

  return { tireSets, addTireSet, editTireSet, deleteTireSet };
};

export const UseFetchTireModel = () => {
  const [tireModels, setTireModels] = useState<TireModel[]>([]);
  const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchTireModels();
    }
  }, [isFetching]);

  const fetchTireModels = async () => {
    try {
      const response = await axios.get('/api/tire-model');
      setTireModels(response.data);
    } catch (error) {
      console.error('Failed to fetch tire models', error);
    }
  };

  const addTireModel = async (newTireModel: string) => {
    try {
      const response = await axios.post('/api/tire-model', { name: newTireModel });
      setTireModels([...tireModels, response.data]);
    } catch (error: any) {
      console.error('Failed to add tire model:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên mẫu lốp đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi tạo mẫu lốp, vui lòng thử lại sau.');
      }
    }
  };

  const editTireModel = async (id: number, newTireModel: string) => {
    try {
      const response = await axios.put(`/api/tire-model/${id}`, { name: newTireModel });
      setTireModels(
        tireModels.map((tireModel) =>
          tireModel.id === id ? { ...tireModel, name: response.data.name } : tireModel
        )
      );
    } catch (error: any) {
      console.error('Failed to edit tire model:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên mẫu lốp đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi chỉnh sửa mẫu lốp, vui lòng thử lại sau.');
      }
    }
  };

  const deleteTireModel = async (id: number) => {
    try {
      await axios.delete(`/api/tire-model/${id}`);
      setTireModels(tireModels.filter((tireModel) => tireModel.id !== id));
    } catch (error) {
      console.error('Failed to delete tire model:', error);
    }
  };

  return { tireModels, addTireModel, editTireModel, deleteTireModel };
};

export const UseFetchTireSize = () => {
  const [tireSizes, setTireSizes] = useState<TireSize[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchTireSizes();
    }
  }, [isFetching]);

  const fetchTireSizes = async () => {
    try {
      const response = await axios.get('/api/tire-size');
      setTireSizes(response.data);
    } catch (error) {
      console.error('Failed to fetch tire sizes', error);
    }
  };

  const addTireSize = async (newTireSize: string) => {
    try {
      const response = await axios.post('/api/tire-size', { name: newTireSize });
      setTireSizes([...tireSizes, response.data]);
    } catch (error: any) {
      console.error('Failed to add tire size:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên kích thước lốp đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi tạo kích thước lốp, vui lòng thử lại sau.');
      }
    }
  };

  const editTireSize = async (id: number, newTireSize: string) => {
    try {
      const response = await axios.put(`/api/tire-size/${id}`, { name: newTireSize });
      setTireSizes(
        tireSizes.map((tireSize) =>
          tireSize.id === id ? { ...tireSize, name: response.data.name } : tireSize
        )
      );
    } catch (error: any) {
      console.error('Failed to edit tire size:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên kích thước lốp đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi chỉnh sửa kích thước lốp, vui lòng thử lại sau.'); 
      }
    }
  };

  const deleteTireSize = async (id: number) => {
    try {
      await axios.delete(`/api/tire-size/${id}`);
      setTireSizes(tireSizes.filter((tireSize) => tireSize.id !== id));
    } catch (error) {
      console.error('Failed to delete tire size:', error);
    }
  };

  return { tireSizes, addTireSize, editTireSize, deleteTireSize };
};

export const UseFetchTirePosition = () => {
  const [tirePositions, setTirePositions] = useState<TirePosition[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchTirePositions();
    }
  }, [isFetching]);

  const fetchTirePositions = async () => {
    try {
      const response = await axios.get('/api/tire-position');
      setTirePositions(response.data);
    } catch (error) {
      console.error('Failed to fetch tire positions', error);
    }
  };

  const addTirePosition = async (newTirePosition: string) => {
    try {
      const response = await axios.post('/api/tire-position', { name: newTirePosition });
      setTirePositions([...tirePositions, response.data]);
    } catch (error: any) {
      console.error('Failed to add tire position:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên vị trí lốp đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi tạo vị trí lốp, vui lòng thử lại sau.');
      }
    }
  };

  const editTirePosition = async (id: number, newTirePosition: string) => {
    try {
      const response = await axios.put(`/api/tire-position/${id}`, { name: newTirePosition });
      setTirePositions(
        tirePositions.map((tirePosition) =>
          tirePosition.id === id ? { ...tirePosition, name: response.data.name } : tirePosition
        )
      );
    } catch (error: any) {
      console.error('Failed to edit tire position:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên vị trí lốp đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi chỉnh sửa vị trí lốp, vui lòng thử lại sau.');
      }
    }
  };

  const deleteTirePosition = async (id: number) => {
    try {
      await axios.delete(`/api/tire-position/${id}`);
      setTirePositions(tirePositions.filter((tirePosition) => tirePosition.id !== id));
    } catch (error) {
      console.error('Failed to delete tire position:', error);
    }
  };

  return { tirePositions, addTirePosition, editTirePosition, deleteTirePosition };
};

export const UseFetchWarehouse = () => {
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchWarehouses();
    }
  }, [isFetching]);

  const fetchWarehouses = async () => {
    try {
      const response = await axios.get('/api/warehouse');
      setWarehouses(response.data);
    } catch (error) {
      console.error('Failed to fetch warehouses:', error);
    }
  };

  const addWarehouse = async (newWarehouseName: string) => {
    try {
      const response = await axios.post('/api/warehouse', { name: newWarehouseName });
      setWarehouses([...warehouses, response.data]);
    } catch (error: any) {
      console.error('Failed to add warehouse:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên kho đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi tạo kho, vui lòng thử lại sau.');
      }
    }
  };

  const editWarehouse = async (id: number, updatedWarehouseName: string) => {
    try {
      const response = await axios.put(`/api/warehouse/${id}`, { name: updatedWarehouseName });
      setWarehouses(
        warehouses.map((warehouse) =>
          warehouse.id === id ? { ...warehouse, name: response.data.name } : warehouse
        )
      );
    } catch (error: any) {
      console.error('Failed to edit warehouse:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên kho đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi chỉnh sửa kho, vui lòng thử lại sau.');  
      }
    }
  };

  const deleteWarehouse = async (id: number) => {
    try {
      await axios.delete(`/api/warehouse/${id}`);
      setWarehouses(warehouses.filter((warehouse) => warehouse.id !== id));
    } catch (error) {
      console.error('Failed to delete warehouse:', error);
    }
  };

  return { warehouses, addWarehouse, editWarehouse, deleteWarehouse };
};

export const UseFetchManufacturer = () => {
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchManufacturers();
    }
  }, [isFetching]);

  const fetchManufacturers = async () => {
    try {
      const response = await axios.get('/api/manufacturer');
      setManufacturers(response.data);
    } catch (error) {
      console.error('Failed to fetch tire manufacturer:', error);
    }
  };

  const addManufacturer = async (newManufacturerName: string) => {
    try {
      const response = await axios.post('/api/manufacturer', { name: newManufacturerName });
      setManufacturers([...manufacturers, response.data]);
    } catch (error: any) {
      console.error('Failed to add manufacturer:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên nhà sản xuất đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi tạo nhà sản xuất, vui lòng thử lại sau.'); 
      }
    }
  };

  const editManufacturer = async (id: number, updatedManufacturerName: string) => {
    try {
      const response = await axios.put(`/api/manufacturer/${id}`, { name: updatedManufacturerName });
      setManufacturers(
        manufacturers.map((manufacturer) =>
          manufacturer.id === id ? { ...manufacturer, name: response.data.name } : manufacturer
        )
      );
    } catch (error: any) {
      console.error('Failed to edit manufacturer:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên nhà sản xuất đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi chỉnh sửa nhà sản xuất, vui lòng thử lại sau.'); 
      }
    }
  };

  const deleteManufacturer = async (id: number) => {
    try {
      await axios.delete(`/api/manufacturer/${id}`);
      setManufacturers(manufacturers.filter((manufacturer) => manufacturer.id !== id));
    } catch (error) {
      console.error('Failed to delete manufacturer:', error);
    }
  };

  return { manufacturers, addManufacturer, editManufacturer, deleteManufacturer };
};

export const UseFetchPartMeasurementUnit = () => {
  const [partMeasurementUnits, setPartMeasurementUnits] = useState<PartMeasurementUnit[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchPartMeasurementUnits();
    }
  }, [isFetching]);

  const fetchPartMeasurementUnits = async () => {
    try {
      const response = await axios.get('/api/part-measurement-unit');
      setPartMeasurementUnits(response.data);
    } catch (error) {
      console.error('Failed to fetch part measurement units:', error);
    }
  };

  return { partMeasurementUnits };
}

export const UseFetchPart = () => {
  const [parts, setParts] = useState<Part[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchParts();
    }
  }, [isFetching]);

  const fetchParts = async () => {
    try {
      const response = await axios.get('/api/parts/min');
      setParts(response.data);
    } catch (error) {
      console.error('Failed to fetch parts:', error);
    }
  };


  return { parts };  
};

export const UseFetchInventoryLocationForPart = (partId: number | null) => {
  const [inventoryLocationsPart, setInventoryLocationsPart] = useState<InventoryLocationForPart[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (!partId) {
      setInventoryLocationsPart([]);
      setIsFetching(false); 
    }

    if (!isFetching) {
      setIsFetching(true);
      fetchInventoryLocationsPart();
    }
  }, [partId, isFetching]);

  const fetchInventoryLocationsPart = async () => {
    try {
      if (!partId) return;
      const response = await axios.get(`/api/inventory-location/part/${partId}`);
      
      // Transform the data: add a `name` property from `warehouse.name`
      const transformedData = response.data.map((location: InventoryLocationForPart) => ({
        ...location,
        name: `${location.warehouse?.name} (${location.aisle?.name} / ${location.row?.name} / ${location.bin?.name})`  || '', // Ensure name is always available
      }));

      setInventoryLocationsPart(transformedData);
    } catch (error) {
      console.error('Failed to fetch inventory locations for part:', error);
    }
  };

  return { inventoryLocationsPart };
};



export const UseFetchAisle = () => {
  const [aisles, setAisles] = useState<Aisle[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchAisles();
    }
  }, [isFetching]);

  const fetchAisles = async () => {
    try {
      const response = await axios.get('/api/aisle');
      setAisles(response.data);
    } catch (error) {
      console.error('Failed to fetch aisles:', error);
    }
  };

  const addAisle = async (newAisleName: string) => {
    try {
      const response = await axios.post('/api/aisle', { name: newAisleName });
      setAisles([...aisles, response.data]);
    } catch (error: any) {
      console.error('Failed to add aisle:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên ngăn đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi tạo ngăn, vui lòng thử lại sau.');
      }
    }
  };

  const editAisle = async (id: number, updatedAisleName: string) => { 
    try {
      const response = await axios.put(`/api/aisle/${id}`, { name: updatedAisleName });
      setAisles(aisles.map(aisle => (aisle.id === id ? { ...aisle, name: updatedAisleName} : aisle)));
    } catch (error: any) {
      console.error('Failed to edit aisle:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên ngăn đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi chỉnh sửa ngăn, vui lòng thử lại sau.');
      }
    }
  };

  const deleteAisle = async (id: number) => {
    try {
      await axios.delete(`/api/aisle/${id}`);
      setAisles(aisles.filter(aisle => aisle.id !== id));
    } catch (error) { 
      console.error('Failed to delete aisle:', error);
    }

  }

  return { aisles, addAisle, editAisle, deleteAisle };

}

export const UseFetchRow = () => {
  const [rows, setRows] = useState<Row[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchRows();
    }
  }, [isFetching]);

  const fetchRows = async () => {
    try {
      const response = await axios.get('/api/row');
      setRows(response.data);
    } catch (error) {
      console.error('Failed to fetch rows:', error);
    }
  };

  const addRow = async (newRowName: string) => {
    try {
      const response = await axios.post('/api/row', { name: newRowName });
      setRows([...rows, response.data]);
    } catch (error) {
      console.error('Failed to add row:', error);
    }
  };

  const editRow = async (id: number, updatedRowName: string) => {
    try {
      const response = await axios.put(`/api/row/${id}`, { name: updatedRowName });
      setRows(rows.map(row => (row.id === id ? { ...row, name: updatedRowName } : row)));
    } catch (error: any) {
      console.error('Failed to edit row:', error);
    }
  };

  const deleteRow = async (id: number) => {
    try {
      await axios.delete(`/api/row/${id}`);
      setRows(rows.filter(row => row.id !== id));
    } catch (error: any) {
      console.error('Failed to delete row:', error);
    }
  };

  return { rows, addRow, editRow, deleteRow };
};

export const UseFetchBin = () => {
  const [bins, setBins] = useState<Bin[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchBins();
    }
  }, [isFetching]);

  const fetchBins = async () => {
    try {
      const response = await axios.get('/api/bin');
      setBins(response.data);
    } catch (error) {
      console.error('Failed to fetch bins:', error);
    }
  };

  const addBin = async (newBinName: string) => {
    try {
      const response = await axios.post('/api/bin', { name: newBinName });
      setBins([...bins, response.data]);
    } catch (error: any) {
      console.error('Failed to add bin:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên kệ đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi tạo kệ, vui lòng thử lại sau.');
      }
    }
  };

  const editBin = async (id: number, updatedBinName: string) => {
    try {
      const response = await axios.put(`/api/bin/${id}`, { name: updatedBinName });
      setBins(bins.map(bin => (bin.id === id ? { ...bin, name: updatedBinName } : bin)));
    } catch (error: any) {
      console.error('Failed to edit bin:', error);
      if (error.response && error.response.status === 409) {
        alert('Tên kệ đã tồn tại, vui lòng chọn tên khác');
      } else {
        alert('Lỗi chỉnh sửa kệ, vui lòng thử lại sau.');
      }
    }
  };

  const deleteBin = async (id: number) => {
    try {
      await axios.delete(`/api/bin/${id}`);
      setBins(bins.filter(bin => bin.id !== id));
    } catch (error) {
      console.error('Failed to delete bin:', error);
    }
  };

  return { bins, addBin, editBin, deleteBin };
};

export const UseFetchPurchaseOrderStatus = () => {  
  const [purchaseOrderStatus, setPurchaseOrderStatus] = useState<PurchaseOrderStatus[]>([]);
  const [isFetching, setIsFetching] = useState(false); // Prevent duplicate fetches

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchPurchaseOrderStatus(); 
    } 
  }, []);

  const fetchPurchaseOrderStatus = async () => {
    try {
      const response = await axios.get(`/api/purchase-order-statuses`);
      setPurchaseOrderStatus(response.data);
    } catch (error) {
      console.error('Failed to fetch purchase order status', error);
    }
  }

  return {purchaseOrderStatus};
};

