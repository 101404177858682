import TireRepairIcon from '@mui/icons-material/TireRepair';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import React from 'react'; // Import React because you're using JSX

interface NavItem {
  title: string;
  icon: JSX.Element;  // JSX.Element to store JSX for icons
  link?: string;
  children?: {
    title: string;
    link: string;
    icon: JSX.Element;
  }[];
}

export const navItems: NavItem[] = [
  {
    title: 'Trang chủ',
    icon: <i className="bi bi-grid"></i>, // Bootstrap icon as JSX
    link: '/dashboard',
  },
  {
    title: 'Quản lý tài khoản',
    icon: <i className="bi bi-person-badge"></i>,
    children: [
      { title: 'Nhân viên công ty', link: '/dashboard/employees', icon: <i className="bi bi-circle"></i> },
      { title: 'Nhà cung cấp', link: '/dashboard/suppliers', icon: <i className="bi bi-circle"></i> },
      { title: 'Khách hàng', link: '/dashboard/customers', icon: <i className="bi bi-circle"></i> },
    ],
  },
  {
    title: 'Quản lý xe',
    icon: <i className="bi bi-truck-front"></i>,
    children: [
      { title: 'Xe', link: '/dashboard/vehicles', icon: <i className="bi bi-circle"></i> },
      { title: 'Lịch trình của xe', link: '/dashboard/vehicles-schedules', icon: <i className="bi bi-circle"></i> },
    ],
  },
  {
    title: 'Bảo dưỡng',
    icon: <i className="bi bi-tools"></i>,
    children: [
      { title: 'Việc cần làm', link: '/dashboard/maintainance-work-orders', icon: <i className="bi bi-circle"></i> },
      { title: 'Giao nhiệm vụ', link: '/dashboard/maintainance-service-entries', icon: <i className="bi bi-circle"></i> },
      { title: 'Các thiết lập phụ', link: '/dashboard/maintenance-management', icon: <i className="bi bi-circle"></i> },
    ],
  },
  {
    title: 'Kiểm tra và vấn đề',
    icon: <i className="bi bi-exclamation-diamond-fill"></i>,
    children: [
      { title: 'Kiểm tra', link: '/dashboard/inspections', icon: <i className="bi bi-circle"></i> },
      { title: 'Vấn đề', link: '/dashboard/issues', icon: <i className="bi bi-circle"></i> },
      { title: 'Các thiết lập phụ', link: '/dashboard/inspections-issues-management', icon: <i className="bi bi-circle"></i> },
    ],
  },
  {
    title: 'Nhắc nhở',
    icon: <i className="bi bi-clock-history"></i>,
    children: [
      { title: 'Nhắn nhở sửa chữa', link: '/dashboard/repair-reminders', icon: <i className="bi bi-circle"></i> },
      { title: 'Nhắc nhở gia hạn', link: '/dashboard/renewal-reminders', icon: <i className="bi bi-circle"></i> },
      { title: 'Nhắc nhở kiểm tra', link: '/dashboard/inspection-reminders', icon: <i className="bi bi-circle"></i> },
    ],
  },
  {
    title: 'Nhiên liệu',
    icon: <i className="bi bi-fuel-pump"></i>,
    children: [
      { title: 'Hóa đơn xăng', link: '/dashboard/fuel-bills', icon: <i className="bi bi-circle"></i> },
      { title: 'Quản lý kho xăng', link: '/dashboard/fuel-tank-management', icon: <i className="bi bi-circle"></i> },
    ],
  },
  {
    title: 'Lốp xe',
    icon: <TireRepairIcon />,  // Material UI icon
    link: '/dashboard/tires',
  },
  {
    title: 'Kho',
    icon: <Inventory2Icon />,  // Material UI icon
    children: [
      { title: 'Quản lý linh kiện', link: '/dashboard/part', icon: <i className="bi bi-circle"></i> },
      { title: 'Quản lý kho (vị trí linh kiện)', link: '/dashboard/inventory', icon: <i className="bi bi-circle"></i> }, 
      { title: 'Đơn mua', link: '/dashboard/purchase-order', icon: <i className="bi bi-circle"></i> },  
      { title: 'Các thiết lập phụ ', link: '/dashboard/inventory-management', icon: <i className="bi bi-circle"></i> },
    ],
  },
];