import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { Download as DownloadIcon } from '@phosphor-icons/react';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { Upload as UploadIcon } from '@phosphor-icons/react';
import { GenericFilters } from '@/components/dashboard/filter/genericFilter';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1'; 
import axios from 'axios';
import { statusMap, parentTypeMap, statusOrder, reverseStatusOrder } from '@/hooks/mapping';
import { RenewalReminderDialog } from './RenewalDialog';  
import { formatDate } from '@/hooks/format-date';
import ResolveOptions from '../subPlace/IssueResolvedOptions';
import dayjs, { Dayjs } from 'dayjs';
import { getContrastTextColor } from '@/hooks/contrast-text-color';

// Enum for ParentType
export enum ParentType {
    Vehicle = 'Vehicle',
    Assignee = 'Assignee',
    Supplier = 'Supplier',
    Customer = 'Customer',
  }
  
// Interface for RenewalReminderParent
export interface RenewalReminder {
  id: string; 
  parentType: ParentType; 
  parentId: number; 
  nextDueDate?: Date | string; 
  preReminderDate: number; 
  createdAt: Date;
  updateAt?: Date; 
  reminderNameId: string;
  reminderName: RenewalReminderName; 
  status: string;
  parentName: string;

  customFields: Record<string, string>;
}

export interface RenewalReminderName {
  id: string; 
  parentType: ParentType; 
  name: string; 
  description?: string; 
  createdAt: Date; 
  updateAt?: Date; 
}
  
function applyPagination(rows: RenewalReminder [], page: number, rowsPerPage: number): RenewalReminder[] {
    return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}


export default function RenewalPage(): React.JSX.Element {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5); // Adjust number of rows per page as necessary
    const [data, setData] = React.useState<RenewalReminder[]>([]); // MaintainanceServiceEntry data state
    const [filteredData, setFilteredData] = React.useState<RenewalReminder[]>([]); // Filtered data state
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedRenewalReminder, setSelectedRenewalReminder] = React.useState<RenewalReminder | null>(null);

    const fetchData = async () => {
        try {
            const response = await axios.get('/api/renewal-reminders');
            const sortedData = response.data.sort((a: RenewalReminder, b: RenewalReminder) => a.id.toString().localeCompare(b.id.toString()));
            setData(sortedData);
            setFilteredData(sortedData);
        } catch (error) {
            console.error('Error fetching data: ', error);
        }
    };

    React.useEffect(() => {
        fetchData();
    }, []);


  //   const filterColumns = [
  //     { value: 'parentType', label: 'Loại mục tiêu', options: [
  //       { label: 'Xe', value: 'Vehicle' },
  //       { label: 'Người được giao', value: 'Assignee' },
  //       { label: 'Nhà cung cấp', value: 'Supplier' },
  //       { label: 'Khách hàng', value: 'Customer' },
  //     ] },
  //     { value:  'parentName', label: 'Tên mục tiêu' },
  //     { value: 'nextDueDateOrder', label: 'Sắp xếp theo ngày đến hạn', 
  //       options:  [
  //         { label: 'Ngày sắp đến hạn -> Ngày đến hạn xa nhất ', value: 'asc' },
  //         { label: 'Ngày đến hạn xa nhất -> Ngày sắp đến hạn', value: 'desc' },
  //       ]
  //     },
  //     { value: 'status', label: 'Trạng thái' , 
  //       options: [
  //         { label: 'Chưa đến hạn', value: 'NotDue' },
  //         { label: 'Sắp đến hạn', value: 'DueSoon' },
  //         { label: 'Đã đến hạn', value: 'Overdue' },
  //       ]
  //     }, 
  //     { value: 'statusOrder', label: 'Sắp xếp theo trạng thái',
  //       options: [
  //         { label: 'Chưa đến hạn -> Quá hạn', value: 'asc' },
  //         { label: 'Quá hạn -> Chưa đến hạn', value: 'desc' },
  //       ]
  //     }
  // ];
    

    const handleFilterChange = (column: string, searchTerm: string | boolean | Dayjs | null) => {
      if (column === 'statusOrder') {
        const sortByStatusAndDate = (data: typeof filteredData, order: 'asc' | 'desc') => {
          const orderMap = order === 'asc' ? statusOrder : reverseStatusOrder;
          return [...data].sort((a, b) => {
            const statusA = (a.status as keyof typeof orderMap) || 'NotDue';
            const statusB = (b.status as keyof typeof orderMap) || 'NotDue';
    
            const statusComparison = orderMap[statusA] - orderMap[statusB];
            if (statusComparison !== 0) {
              return statusComparison;
            }
    
            return (
              new Date(a.nextDueDate ?? '').getTime() -
              new Date(b.nextDueDate ?? '').getTime()
            );
          });
        };
    
        const sorted = sortByStatusAndDate(filteredData, searchTerm === 'asc' ? 'asc' : 'desc');
        setFilteredData(sorted);
        return;
      }
      
      if (column === 'nextDueDateOrder') {
        const sortByNextDueDate = (data: typeof filteredData, order: 'asc' | 'desc') => {
          return [...data].sort((a, b) => {
            const dateA = new Date(a.nextDueDate ?? '').getTime();
            const dateB = new Date(b.nextDueDate ?? '').getTime();
    
            return order === 'asc' ? dateA - dateB : dateB - dateA;
          });
        };
    
        const sorted = sortByNextDueDate(filteredData, searchTerm === 'asc' ? 'asc' : 'desc');
        setFilteredData(sorted);
        return;
      }
      
      const filtered = data.filter((renewalReminder) => { 
        let value;

        switch (column) {
          case 'parentType':
            value = renewalReminder.parentType;
            break;  
          case 'status':  
            value = renewalReminder.status;
            break;
          case 'parentName':
            value = renewalReminder.parentName;
            break;
        }
        
        if (searchTerm === null || searchTerm === '') {
          return true;
        }
    
        if (typeof value === 'string' && typeof searchTerm === 'string') {
          return value.toLowerCase().includes(searchTerm.toLowerCase());
        }
    
        if (typeof value === 'boolean' && typeof searchTerm === 'boolean') {
          return value === searchTerm;
        }
    
        return false;
      });
      setFilteredData(filtered);
    };

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (newRowsPerPage: number) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset page to 0 when rowsPerPage changes 

    };

    const handleOpenDialog = () => {
        setSelectedRenewalReminder(null);
        setOpenDialog(true);
    }

    const handleEditRenewalReminder = (id: string) => {  
      const renewalReminderToEdit = data.find((renewalReminder) => renewalReminder.id === id);
      if (renewalReminderToEdit) {
        setSelectedRenewalReminder(renewalReminderToEdit);
        setOpenDialog(true);
      } 
    };

    const handleDeleteRenewalReminder = async (id: string) => {   
        const confirmed = window.confirm('Bạn có chắc muốn xóa thẻ này?'); 
        if (confirmed) {
            try {
              await axios.delete(`/api/renewal-reminders/${id}`);
              alert('Xóa thành công');
              setData((prevData) => prevData.filter((serviceReminder) => serviceReminder.id !== id));
              setFilteredData((prevData) => prevData.filter((serviceReminder) => serviceReminder.id !== id));
            } catch (error) {
              console.log('Error deleting renewal reminders:', error);
            }
        }
    };

    const handleDeleteRenewalReminderSelected = async (selectedIds: string[]) => {
        const confirmed = window.confirm('Bạn có chắc muốn xóa những thẻ đã chọn?'); 
        if (confirmed) {
          try {
            await axios.delete(`/api/renewal-reminders/${JSON.stringify(selectedIds)}`);
            alert('Xóa thành công');
            setData((prevData) => prevData.filter((renewalReminder) => !selectedIds.includes(renewalReminder.id)));
            setFilteredData((prevData) => prevData.filter((renewalReminder) => !selectedIds.includes(renewalReminder.id)));
          } catch (error) {
            console.log('Error deleting renewal reminders:', error);
          }
        }
    }

    const handleAddOrEditRenewalReminder = async (renewalReminder: Partial<RenewalReminder>) => {    
        try {

          const formData = new FormData();

          Object.keys(renewalReminder).forEach((key) => { 
            const value = renewalReminder[key as keyof RenewalReminder];
            if (value !== undefined && value !== null) {
              formData.append(key, value.toString());
            } 
          });

          if (renewalReminder.customFields){
            formData.set('customFields', JSON.stringify(renewalReminder.customFields));
          }

          if (selectedRenewalReminder) {
            const reponse = await axios.put(`/api/renewal-reminders/${selectedRenewalReminder.id}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',  
              },
            });

            const updateRenewalReminder = reponse.data;

            setData((prevData) => 
              prevData.map((renewalReminder) => (renewalReminder.id === updateRenewalReminder.id ? updateRenewalReminder : renewalReminder))
            );

            setFilteredData((prevData) => 
              prevData.map((renewalReminder) => (renewalReminder.id === updateRenewalReminder.id ? updateRenewalReminder : renewalReminder))
            );
          } else {
            const reponse = await axios.post('/api/renewal-reminders', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',  
              }
            });

            const newRenewalReminder = reponse.data;  
            setData((prevData) => [newRenewalReminder, ...prevData]);
            setFilteredData((prevData) => [newRenewalReminder, ...prevData]);
          }

          fetchData();
          setOpenDialog(false);
        } catch (error) {
            alert('Có lỗi xảy ra');
            console.error('Error adding or editing renewal reminder');
        }
    }

    const filterColumns = [
        { value:  'parentName', label: 'Tên mục tiêu' },
        { value: 'parentType', label: 'Loại mục tiêu',
          options: [
          { label: 'Xe', value: 'Vehicle' },
          { label: 'Người được giao', value: 'Assignee' },
          { label: 'Nhà cung cấp', value: 'Supplier' },
          { label: 'Khách hàng', value: 'Customer' },
          ],
          type: 'dropdown' as const,
        },
        { value: 'nextDueDateOrder', label: 'Sắp xếp theo ngày đến hạn', 
          options:  [
            { label: 'Ngày sắp đến hạn -> Ngày đến hạn xa nhất ', value: 'asc' },
            { label: 'Ngày đến hạn xa nhất -> Ngày sắp đến hạn', value: 'desc' },
          ]
        },
        { value: 'status', label: 'Trạng thái' , 
          options: [
            { label: 'Chưa đến hạn', value: 'NotDue' },
            { label: 'Sắp đến hạn', value: 'DueSoon' },
            { label: 'Đã đến hạn', value: 'Overdue' },
          ],
          type: 'dropdown' as const,
        }, 
        { value: 'statusOrder', label: 'Sắp xếp theo trạng thái',
          options: [
            { label: 'Chưa đến hạn -> Quá hạn', value: 'asc' },
            { label: 'Quá hạn -> Chưa đến hạn', value: 'desc' },
          ],
          type: 'dropdown' as const,
        }
    ];

    type StatusKey = keyof typeof statusMap; // Type for keys of statusMap
    
    const columns: TableColumn<RenewalReminder>[] = [
      { label: 'Loại đối tượng', field: 'parentType', customRender: (rowData) => parentTypeMap[rowData.parentType] },
      { label: 'Mục tiêu', field: 'parentName' },
      { label: 'Mục nhắc nhở', field: (row) => row.reminderName?.name },
      { label: 'Ngày đến hạn', field: 'createdAt', customRender: (rowData) => formatDate(rowData.nextDueDate), isDate: true }, 
      { 
        label: "Trạng thái",  
        field: (row) => {
          const status = (row.status as StatusKey) || 'NotDue';
          const statusInfo = statusMap[status];
          const textColor = getContrastTextColor(statusInfo.color);

          return (
            <Chip 
              label={statusInfo.label}
              style={{
                backgroundColor: statusInfo.color,
                color: textColor,
                fontWeight: 'bold',
                borderRadius: '5px',
                padding: '5px 10px',
              }}
            />
          );

        }

      },
      {label: 'Số ngày báo sắp đến hạn', field: 'preReminderDate'},
      { label: 'Ngày tạo', field: 'createdAt', customRender: (rowData) => formatDate(rowData.createdAt), isDate: true },
    ];

    return (
        <Stack spacing={3}>
        <Stack direction="row" spacing={3}>
          <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
            <Typography variant="h4">Nhắc nhở gia hạn</Typography>
          </Stack>
          <div>
            <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={handleOpenDialog}>
              Thêm
            </Button>
          </div>
        </Stack>

        <GenericFilters 
        columns={filterColumns} onFilterChange={handleFilterChange} placeholder="Tìm thẻ nhắc nhở"
        />

        <GenericTable
          count={filteredData.length}
          page={page}
          rows={applyPagination(filteredData, page, rowsPerPage)} // Use transformed data
          columns={columns}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onEdit={handleEditRenewalReminder }
          onDelete={handleDeleteRenewalReminder} 
          onDeleteSelected={handleDeleteRenewalReminderSelected }
        />

        <RenewalReminderDialog open={openDialog} onClose={() => setOpenDialog(false)} 
        onSubmit={handleAddOrEditRenewalReminder} 
        renewalReminderData={selectedRenewalReminder ?? undefined}  
        refreshData={fetchData} 
        
        />
  
      </Stack>
    );

}
