// File: ResolveOptions.js
import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import NoteIcon from '@mui/icons-material/Note';

interface ResolveOptionsProps {
  onServiceEntryClick: () => void;
  onNoteClick: () => void;
}

export const ResolveOptions: React.FC<ResolveOptionsProps> = ({ onServiceEntryClick, onNoteClick }) => {
  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Giải quyết bằng
      </Typography>
      <Stack direction="row" spacing={2}>
        {/* Button to resolve with service entry */}
        <Button
          variant="contained"
          startIcon={<DirectionsCarIcon />}
          color="primary"
          onClick={onServiceEntryClick}
        >
          Giải quyết bằng phiếu nhiệm vụ
        </Button>

        {/* Button to resolve with a note */}
        <Button
          variant="contained"
          startIcon={<NoteIcon />}
          color="secondary"
          onClick={onNoteClick}
        >
          Giải quyết bằng ghi chú
        </Button>
      </Stack>
    </div>
  );
};

export default ResolveOptions;
