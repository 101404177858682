import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import axios from 'axios';
import styles from './SearchBar.module.css';

interface SearchFormInputs {
    query: string;
}

const SearchBar: React.FC = () => {
    const { register, handleSubmit, formState: {errors} } = useForm<SearchFormInputs>(); 
    const [error, setError] = useState('');
    const [searchResults, setSearchResults] = useState<string[]>([]);

    const onSubmit: SubmitHandler<SearchFormInputs> = async (data) => {
        setError('');
        try {
            const response = await axios.get(`/api/search`, {params: {query: data.query}});
            setSearchResults(response.data);
        } catch {
            setError('Error fetching search results');
        }
    }

    return (
        <div className={`${styles.search__bar}`}>
            <form className={`${styles.search__form} d-flex align-items-center`} onSubmit={handleSubmit(onSubmit)}>
                <input type="text" 
                        {...register('query', {required: 'Search query is required'})}
                        placeholder='Search' 
                        title="Enter search keyword"/>
                {errors.query && <p className={styles.error}>{errors.query.message}</p>}
                <button type="submit" title="Search">
                    <i className='bi bi-search'></i>
                </button>
            </form>
            {error && <p className={styles.error}>{error}</p>}

            {/* {searchResults.length > 0 && (
                <ul className={styles.search_results}>
                    {searchResults.map((result, index) => (
                        <li key={index}>{result}</li>
                    ))}
                </ul>
            )} */}

        </div>
    );
}

export default SearchBar;