import React from 'react';
import './Main.css';
import { Outlet } from 'react-router-dom';

interface MainProps {
  activePage: string;
  activeIcon?: JSX.Element;
  activeChildPage?: string;
  children?: React.ReactNode;
}

const Main: React.FC<MainProps> = ({ activePage, activeIcon, activeChildPage }) => {
  return (
    <main id="main" className="main">
      <div className='page-title'>
      <nav>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item'>
            <a href='#' className='d-flex justify-content-between'>
              {activeIcon}
              <span className='breadcrumb-gap'>{activeChildPage}</span>
            </a>
          </li>
         
        </ol>
      </nav>
    </div>
      <div className="page-content-admin">
        <Outlet /> {/* This is where nested routes will be rendered */}

      </div>


    </main>
  );
};

export default Main;
