import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Stack, IconButton } from '@mui/material';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields } from '@/components/dashboard/formField/FormFields';
import { ServicePrograms, ServiceTasks } from '@/components/subPlace/subEntriesTypeMaintainance'; // Import interfaces
import { ServiceSchedule } from '@/components/subPlace/subEntriesTypeMaintainance';
import { ServiceScheduleComponent } from '@/components/subPlace/ServiceScheduleComponents'; // Import the component


interface ServiceProgramsDialogProps {
  open: boolean;
  data?: Partial<ServicePrograms>;
  onClose: () => void;
  onSubmit: (data: Partial<ServicePrograms>, existingServiceSchedule: ServiceSchedule[]) => void; // Pass existing schedules
}

export const ServiceProgramsDialog: React.FC<ServiceProgramsDialogProps> = ({
  open,
  data,
  onClose,
  onSubmit,
}) => {
  const [programData, setProgramData] = useState<Partial<ServicePrograms>>(data || {});
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [tasks, setTasks] = useState<ServiceTasks[]>([]); // All available ServiceTasks
  const [serviceSchedule, setServiceSchedule] = useState<ServiceSchedule[]>(data?.serviceSchedules || []);  
  const [existingServiceSchedule, setExistingServiceSchedule] = useState<ServiceSchedule[]>([]);

  useEffect(() => {
    if (data) {
      setProgramData(data);
      fetchServiceSchedule(data.id); // Fetch existing service schedule if `data` is provided

    } else {
      setProgramData({});
      setServiceSchedule([]);
    }

    // Fetch available tasks
    async function fetchTasks() {
      const response = await fetch('/api/service-tasks');
      const tasksData = await response.json();
      setTasks(tasksData);
    }
    fetchTasks();
  }, [data]);

  async function fetchServiceSchedule(programId?: string) {
    if (programId) {
      const response = await fetch(`/api/service-programs/${programId}/service-schedules`);
      const serviceScheduleData = await response.json();
  
      // Convert `id` to string if necessary
      const formattedData = serviceScheduleData.map((schedule: any) => ({
        ...schedule,
        id: String(schedule.id), // Ensure `id` is a string
      }));

      setExistingServiceSchedule(formattedData); // Store existing schedules
  
      setServiceSchedule(formattedData);
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
  
    setProgramData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value, // Handle checkbox and other input types
    }));
  };
  
  const handleServiceScheduleChange = (updatedSchedule: ServiceSchedule[]) => {
    setServiceSchedule(updatedSchedule);
  }

  const handleSubmit = () => {
    const newErrors: { [key: string]: string } = {};
  
    if (!programData.name || programData.name.trim() === '') {
      newErrors.name = 'Tên gói là bắt buộc.';
    }

    // validate the rest of the fields
    if (!programData.description || programData.description.trim() === '') {  
      newErrors.description = 'Cần phải có mô tả.';
    }

    if (!programData.primaryMeterType) {
      newErrors.primaryMeterType = 'Khoảng đo chính là bắt buộc.';
    }

    // validet the secondaryMeterType field ì the secondaryMeter_flg is checked
    if (programData.secondaryMeter_flg && !programData.secondaryMeterType) {
      newErrors.secondaryMeterType = 'Khoảng đo phụ là bắt buộc khi bạn tick ô khoảng đo phụ.';
    }
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    setErrors({});
  
    const updatedData: Partial<ServicePrograms> = {
      ...programData,
      serviceSchedules: serviceSchedule, // Include updated service schedules
    };
  
    onSubmit(updatedData, existingServiceSchedule); // Pass existing schedules
  };
  
  const formFields = [
    {
      name: 'name',
      label: 'Tên gói',
      type: 'text',
      value: programData.name || '',
      handleChange: handleChange,
      required: true,
    },
    {
      name: 'description',
      label: 'Mô Tả',
      type: 'text',
      value: programData.description || '',
      handleChange: handleChange,
      required: true,
    },
    {
      name: 'primaryMeterType',
      label: 'Khoảng đo chính',
      type: 'select',
      value: programData.primaryMeterType || '',
      handleChange: handleChange,
      required: true,
      options: [
        { value: 'hour', label: 'Giờ' },
        { value: 'kilometer', label: 'Kilometer' },
        { value: 'mile', label: 'Dặm' },
      ],
    },
    {
      name: 'secondaryMeter_flg',
      label: 'Có khoảng đo phụ không?',
      type: 'checkbox',
      value: programData.secondaryMeter_flg || false,
      handleChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
  
        setProgramData((prev) => ({
          ...prev,
          secondaryMeter_flg: isChecked,
          secondaryMeterType: isChecked ? prev.secondaryMeterType : '', // Clear secondaryMeterType if unchecked
        }));
      },
    },
    ...(programData.secondaryMeter_flg
      ? [
          {
            name: 'secondaryMeterType',
            label: 'Khoảng đo phụ',
            type: 'select',
            value: programData.secondaryMeterType || '',
            handleChange: handleChange,
            required: false,
            options: [
              { value: 'hour', label: 'Giờ' },
              { value: 'kilometer', label: 'Kilometer' },
              { value: 'mile', label: 'Dặm' },
            ],
          },
        ]
      : []), // Conditionally add the secondaryMeterType field
  ];
  
  
  return (
    <CustomDialog
      open={open}
      title="Thêm/Chỉnh sửa gói dịch vụ"
      onClose={onClose}
      onSubmit={handleSubmit}
      submitLabel="Lưu"
      dialogWidth='1600px' 
      dialogMaxWidth='95%'
    >
      <div className='row'>
        <div className='col-md-6'>
          <FormFields fields={formFields} />
        </div>

        <div className='col-md-6'>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Công việc và lịch nhắc
          </Typography>
          <ServiceScheduleComponent
            schedules={serviceSchedule}
            onSchedulesChange={handleServiceScheduleChange}
            availableTasks={tasks}
            primaryMeterType={programData.primaryMeterType}

            secondaryMeterType={programData.secondaryMeterType} 
          />
        </div>
      </div>
    </CustomDialog>
  );
};
