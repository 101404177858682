import * as React from 'react';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import {  PurchaseOrder } from './PurchaseOrderPage'; // Import your customertype
import { ImageUpload } from '@/components/dashboard/imageUpload/ImageUpLoad';
import { CustomSelectWithAdd } from '@/components/dashboard/customSelectWithAdd/customSelectWithAdd';
import axios from 'axios';
import { FieldManagementDialog, DialogField } from '@/components/dashboard/fieldManagement/FieldManagement';
import useImageDownload from '@/hooks/image-download'; // Import the hook
import  useCustomFieldsUpdate from '@/hooks/field-managementSubmit'; // Import the hook
import { UseFetchSupplier, UseFetchWarehouse, UseFetchPurchaseOrderStatus } from '@/hooks/fetch-relationship-field'; // Import the hooks
import { formatDate } from '@/hooks/format-date';


interface PurchaseOrderDialogProps {
  open: boolean;
  onClose: () => void;  
  onSubmit?: (partData: Partial<PurchaseOrder>) => void;
  purchaseOrderData?: Partial<PurchaseOrder>;
  refreshData?: () => void;
}

export const PurchaseOrderDialog: React.FC<PurchaseOrderDialogProps> = ({ open, onClose, onSubmit, purchaseOrderData, refreshData }) => { 
  const isEditMode = !!purchaseOrderData;
  const [fields, setFields] = React.useState<DialogField[]>([]);
  const [formData, setFormData] = React.useState<Partial<PurchaseOrder>>(purchaseOrderData || {});  

  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  //field management
  const [isFieldManagementOpen, setFieldManagementOpen] = React.useState(false);
  const [customFields, setCustomFields] = React.useState<FormField[]>([]);  
  
  const { suppliers } = UseFetchSupplier(); // Fetch supplier data

  const { purchaseOrderStatus } = UseFetchPurchaseOrderStatus(); // Fetch purchase order status data 

  const { warehouses, addWarehouse, editWarehouse, deleteWarehouse } = UseFetchWarehouse(); // Fetch warehouse data 

  const [customFieldsData, setCustomFieldsData] = React.useState<Record<string, any>>({});
  
  const [ vendorId, setVendorId ] = React.useState<number | null>(null);  

  const [ statusId, setStatusId ] = React.useState<number | null>(null);   

  const [warehouseId, setWarehouseId] = React.useState<number | null>(null);  

  const [purchaseOrderNumberLoading, setPurchaseOrderNumberLoading] = React.useState(false);  

  const fetchNextPurchaseOrderNumber = async () => {
    try {
      setPurchaseOrderNumberLoading(true);
      const response = await axios.get('/api/purchase-order/next-purchase-order-number');
      setFormData({ ...formData, purchaseOrderNumber: response.data.nextPurchaseOrderNumber });

      setPurchaseOrderNumberLoading(false);
    } catch (error) {
      console.error('Error fetching next purchase order number:', error);
    } finally {
      setPurchaseOrderNumberLoading(false);
    }
  }

  React.useEffect(() => {
    fetchFields();
  }, []); 

  const fetchFields = async () => {
    try {
      const response = await axios.get('/api/purchase-order/purchase-order-dialog-fields'); 
      setFields(response.data);  
    } catch (error) {
      console.error('Error fetching fields:', error);
    }
  };

  const addFieldToDatabase = async (field: DialogField): Promise<DialogField> => {  
    try {
      const response = await axios.post('/api/purchase-order/purchase-order-dialog-fields', field);


      return response.data;
    } catch (error) {
      console.error('Failed to add dialog field:', error);
      return field;
    }
  }

  const updateFieldInDatabase = async (field: DialogField): Promise<DialogField> => {
    try {
      const response = await axios.put(`/api/purchase-order/purchase-order-dialog-fields/${field.id}`, field);
      return response.data;
    } catch (error) {
      console.error('Error updating field:', error);
      throw error;
    }
  };

  const deleteFieldFromDatabase = async (id: number): Promise<void> => {
    try {
      await axios.delete(`/api/purchase-order/purchase-order-dialog-fields/${id}`);
    } catch (error) {
      console.error('Error deleting field:', error);
      throw error;
    }
  };

  const { handleCustomFieldsUpdate } = useCustomFieldsUpdate();

  const handleFieldManagementSubmit = async (updatedFields:  DialogField[]) => {
    handleCustomFieldsUpdate(
      fields,                         // Original fields before update
      formData.customFields || {},     // Current custom fields data
      purchaseOrderData?.customFields,      // Customer custom fields
      updatedFields,                   // Updated fields
      setCustomFieldsData,             // Update customFieldsData
      setFormData,                     // Update formData
      setFields,                       // Update fields
      setFieldManagementOpen           // Close dialog
    );

    if (refreshData)  {
      refreshData();  // <-- Trigger refresh after field management is done
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
  
    try {
      const purchaseOrderData = {
        ...formData,
        vendorId,
        statusId,
        warehouseId,
        customFields: JSON.stringify(customFieldsData),
      };
  
      // Check if the selected status is "Order Completed Status" in edit mode
      const selectedStatus = purchaseOrderStatus.find(status => status.id === statusId);
      if (isEditMode && selectedStatus?.function === 'Order Completed Status') {
        const isConfirmed = window.confirm(
          "Nếu bạn chọn trạng thái 'Đã hoàn thành', đơn hàng sẽ không thể chỉnh sửa nữa và số lượng đã đặt sẽ được thêm vào kho. Bạn có chắc chắn không?"
        );
  
        if (!isConfirmed) {
          return; // Stop submission if not confirmed
        }
      }
  
      const formDataSubmit = new FormData();
      Object.keys(purchaseOrderData).forEach((key) => {
        const value = purchaseOrderData[key as keyof PurchaseOrder];
        formDataSubmit.append(key, value as any);
      });
  
      let response;
      if (isEditMode) {
        response = await axios.put(`/api/purchase-order/${purchaseOrderData.id}`, formDataSubmit, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      } else {
        response = await axios.post('/api/purchase-order', formDataSubmit, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      }
  
      const updatedPurchaseOrder = response.data;
  
      if (onSubmit) {
        onSubmit(updatedPurchaseOrder);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Có lỗi xảy ra khi thêm/chỉnh sửa dữ liệu. Vui lòng thử lại sau.');
    }
  };

  const validateForm = (): boolean => {
    const newErrors: {  [key: string]: string} = {};
    
    fields.forEach(field => {
      const fieldValue = formData[field.name as keyof PurchaseOrder];

      if (field.required && (!fieldValue || typeof fieldValue === 'string' && !fieldValue.trim())) {
        newErrors[field.name] = `${field.label} cần có giá trị`;
      }
    });

    // check if there is statusId or not  
    if (!statusId) {
      newErrors['statusId'] = 'Trạng thái duyệt cần được chọn';
    }
      
    setErrors(newErrors);
    // If there are errors, show them in an alert
    if (Object.keys(newErrors).length > 0) {
      const errorMessages = Object.values(newErrors)
        .map((message) => `+ ${message}`) // Add "+ " before each error message
        .join('\n'); // Combine all error messages

      alert(`Các mục bị lỗi:\n${errorMessages}`); // Show alert with errors
    }

    return Object.keys(newErrors).length === 0;
  }

  React.useEffect(() => {
    if (open) {
      if(isEditMode){
        setFormData({
          ...purchaseOrderData,
          vendorId: purchaseOrderData.vendorId ?? undefined, 
          expectedDeliveryDate: formatDate(purchaseOrderData.expectedDeliveryDate),
        });

        if (purchaseOrderData.customFields) {
          try{
            // If customFields is a string, parse it. Otherwise, use it as it is.
            const parsedCustomFields = typeof purchaseOrderData.customFields === 'string' ? JSON.parse(purchaseOrderData.customFields) : purchaseOrderData.customFields; 
            setCustomFieldsData(parsedCustomFields);
          } catch(error){
            console.log('Error parsing custom fields:', error);
          }
        }

        setVendorId(purchaseOrderData.vendorId ?? null);  
        setStatusId(purchaseOrderData.statusId ?? null);  
        setWarehouseId(purchaseOrderData.warehouseId ?? null);
        

      } else {
        setFormData({
          purchaseOrderNumber: '',
          vendorId: undefined,
          statusId: undefined,
          warehouseId: undefined, 
          expectedDeliveryDate: '',
          customFields: {},

        });
        setStatusId(null);
        setVendorId(null);  
        setWarehouseId(null);
        setCustomFieldsData({});  

        if (!isEditMode) {
          fetchNextPurchaseOrderNumber();
        }
      }
    }
  }, [open, purchaseOrderData]);

  const handleCustomFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Check if the input is a checkbox or a regular text input
    setCustomFieldsData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,  // If checkbox, store as boolean; else, store the value
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {  
    const { name, type, value, checked } = e.target;
    // Update the state
    const updatedFormData = {
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    };

    setFormData(updatedFormData); 
  };

  const formFields: FormField[] = fields.map((field) => {  
    if (field.name === 'purchaseOrderNumber') {
      return {
        label: 'Mã số đặt hàng',
        name: 'purchaseOrderNumber',
        type: 'text',
        value: formData.purchaseOrderNumber || '',
        required: true,
        error: errors['partNumber'],
      }
    } else {
      return {
        label: field.label,
        name: field.name,
        type: field.type,
        value: formData[field.name as keyof PurchaseOrder] || customFieldsData[field.name] || '',
        required: field.required, 
        handleChange: field.canDelete ? handleCustomFieldChange : handleChange,
        error: errors[field.name],
        multiline: field.type === 'text',

      }
    }
  }).filter(Boolean) as FormField[]; // Filter out null values and cast to FormField[]

  return (
    <CustomDialog open={open} manageFields={() => setFieldManagementOpen(true)} 
       onClose={onClose} title={isEditMode ? 'Chỉnh sửa thẻ' : 'Thêm thẻ'} 
       onSubmit={handleSubmit} submitLabel='Lưu'>

      {formFields.map((field, index) => (
        <div key={index}>

          <FormFields fields={[field]} />

          { index === 0 && ( 

            <>
              <CustomSelectWithAdd
                label='Nhà cung cấp *'
                items={suppliers}
                value={vendorId || 0}
                onChange={setVendorId}
                manageItem="/dashboard/suppliers"
              />

              <CustomSelectWithAdd
                label="Trạng thái duyệt"
                items={purchaseOrderStatus.filter(status => isEditMode || status.function !== 'Order Completed Status')}
                value={statusId || 0}
                onChange={setStatusId}
                manageItem="/dashboard/suppliers"
              />

              <CustomSelectWithAdd
                label='Kho'
                items={warehouses}
                value={warehouseId || 0}
                onChange={setWarehouseId}
                addItem={addWarehouse}
                editItem={editWarehouse}  
                deleteItem={deleteWarehouse}  
              />
            </>
          )}

        </div>
      ))}

      <FieldManagementDialog
        open={isFieldManagementOpen}
        onClose={() => setFieldManagementOpen(false)}
        fields={fields}
        onFieldSubmit={handleFieldManagementSubmit}
        addFieldToDatabase={addFieldToDatabase}
        updateFieldInDatabase={updateFieldInDatabase}
        deleteFieldFromDatabase={deleteFieldFromDatabase}
      />

    </CustomDialog>
        
  );
};




