import React, { useState } from 'react';
import {
  Typography,
  Stack,
  Pagination,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
} from '@mui/material';
import { AddCircleOutline, DeleteOutline } from '@mui/icons-material';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields } from '@/components/dashboard/formField/FormFields';
import { InspectionSchedule, InspectionForm } from '@/components/inspection/InspectionIssuesSetup';
import { timeIntervalUnit } from '@/hooks/mapping';
import { inspect } from 'util';

interface InspectionScheduleProps {
    schedules: InspectionSchedule[];
    availableInspectionForms: InspectionForm[];    
    onInspectionScheduleChange: (schedules: InspectionSchedule[]) => void;  
    primaryMeterType?: string;   
    secondaryMeterType?: string;
}

export const InspectionScheduleComponent: React.FC<InspectionScheduleProps> = ({ schedules, availableInspectionForms, onInspectionScheduleChange, primaryMeterType, secondaryMeterType }) => { 
    const [currentPage, setCurrentPage] = useState(1);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedSchedule, setSelectedSchedule] = useState<InspectionSchedule | null>(null);

    const itemsPerPage = 6;

    const handleOpenDialog = (schedule?: InspectionSchedule) => {
        // Check if the primaryMeterInterval value is set before opening the dialog
        if (!primaryMeterType || !primaryMeterType.length) {
            alert('Vui lòng chọn giá trị đo chính trước khi thêm lịch.');
            return;
        }
    
        setSelectedSchedule(
            schedule || {
                id: `0`,
                inspectionProgramId: 0,
                inspectionFormIds: [],
                primaryMeterInterval: undefined,
                secondaryMeterInterval: undefined,
                timeInterval: undefined,
                timeIntervalUnit: 'days',
                createdAt: new Date(),
                updateAt: new Date(),
            }
        );
        setOpenDialog(true);
    };
    
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedSchedule(null);
    };

    const handleSaveSchedule = () => {  
        if (selectedSchedule) {
          // Validation logic
          const errors: string[] = [];
      
          // Check if all required fields are filled
          if (!selectedSchedule.primaryMeterInterval || selectedSchedule.primaryMeterInterval <= 0) {
            errors.push('Dữ liệu đo chính phải lớn hơn 0.');
          }
          if (secondaryMeterType && (!selectedSchedule.secondaryMeterInterval || selectedSchedule.secondaryMeterInterval <= 0)) {
            errors.push('Dữ liệu đo phụ phải lớn hơn 0.');
          }
          if (!selectedSchedule.timeInterval || selectedSchedule.timeInterval <= 0) {
            errors.push('Khoảng thời gian phải lớn hơn 0.');
          }
          if (!selectedSchedule.timeIntervalUnit) {
            errors.push('Đơn vị thời gian là bắt buộc.');
          }
          if (!selectedSchedule.inspectionFormIds || selectedSchedule.inspectionFormIds.length === 0) {
            errors.push('Phải chọn ít nhất một công việc dịch vụ.');
          }
      
          // If there are validation errors, display them and return
          if (errors.length > 0) {
            alert(`Vui lòng sửa các lỗi sau:\n- ${errors.join('\n- ')}`);
            return;
          }
      
          // Sanitize the schedule
          const sanitizedSchedule = {
            ...selectedSchedule,
            serviceTaskIds: selectedSchedule.inspectionFormIds.map(Number), // Ensure task IDs are numbers
          };
            
          // Determine if the schedule exists in the array
          const scheduleIndex = schedules.findIndex(
            (schedule) => schedule.id === sanitizedSchedule.id
          );
      
          let updatedSchedules;
          if (scheduleIndex > -1) {
            // Update existing schedule
            updatedSchedules = schedules.map((schedule, index) =>
              index === scheduleIndex ? sanitizedSchedule : schedule
            );
          } else {
            // Add new schedule
            updatedSchedules = [
              ...schedules,
              {
                ...sanitizedSchedule,
                id: `${Date.now()}`, // Assign a unique ID
                createdAt: new Date(),
                updateAt: new Date(),
              },
            ];
          }
      
          // Update the schedules state
          onInspectionScheduleChange(updatedSchedules);
        }
        handleCloseDialog();
    }

    const paginatedSchedules = schedules.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);  
    };

    const handleFieldChange = ( 
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
     ) => {
        const { name, value } = e.target;

        if (selectedSchedule){
            setSelectedSchedule({
                ...selectedSchedule,
                [name]: 
                 name === "inspectionFormIds" && e.target instanceof HTMLSelectElement
                 ? Array.from(e.target.selectedOptions).map((option) => Number(option.value))
                 : ["timeInterval", "primaryMeterInterval", "secondaryMeterInterval"].includes(name)
                 ? Number(value)
                 : value,
            });
        }
    };

    const handleSpecificFieldChange = (fieldName: string, fieldValue: any) => {
        if (selectedSchedule){
            setSelectedSchedule({
                ...selectedSchedule,
                [fieldName]: fieldValue,
            });
        }
    };
    
    const handleDeleteSchedule = (id: string) => {
        const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa lịch này?');
        if (confirmDelete) {
            const updatedSchedules = schedules.filter((schedule) => schedule.id !== id);
            onInspectionScheduleChange(updatedSchedules);
        }
    };
    
    const formFields = [
        {
          name: "primaryMeterInterval",
          label: `Dữ liệu đo chính (${timeIntervalUnit(primaryMeterType || '')})`, // Dynamically apply the unit
          type: "number",
          value: selectedSchedule?.primaryMeterInterval || "",
          handleChange: handleFieldChange,
          required: true,
        },
        ...(secondaryMeterType === 'hour' || secondaryMeterType === 'kilometer' || secondaryMeterType === 'mile' ? [{
          name: "secondaryMeterInterval",
          label: `Dự liệu đo phụ (${timeIntervalUnit(secondaryMeterType)})`, // Dynamically apply the unit
          type: "number",
          value: selectedSchedule?.secondaryMeterInterval || "",
          handleChange: handleFieldChange,
          required: true,
        }] : []), // Conditionally add this field based on secondaryMeterType=
        {
            name: "inspectionFormIds",
            label: "Chọn mẫu kiểm tra",
            type: "select",
            value: selectedSchedule?.inspectionFormIds.map(String) || [], // Ensure strings for select
            handleChange: handleFieldChange, // Pass the same handler
            options: availableInspectionForms.map((form) => ({
              value: form.id.toString(), // Ensure the value is a string
              label: form.name,
            })),
            multiple: true,
            required: true,
        },
    ];
    
    return (
        <div className='proof mt-2'>
            <Stack spacing={2}>
                {paginatedSchedules.map((schedule) => (
                    <Stack
                        key={schedule.id}
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="space-between"
                        className="schedule-item"
                    >
                        <Typography>
                          <strong>Mỗi:</strong> {schedule.timeInterval || 'N/A'}  {timeIntervalUnit(schedule.timeIntervalUnit || '')}
                        </Typography>
                        <Typography>
                          <strong>Các Việc:</strong>{' '}
                          {schedule.inspectionFormIds.length > 0 ? (
                            schedule.inspectionFormIds.map((id, index) => {
                              const form = availableInspectionForms.find((form) => Number(form.id) === Number(id));
                              if (!form) {
                                console.warn(`Form with ID ${id} not found in availableInspectionForms.`);
                              }
                              return (
                                <React.Fragment key={id}>
                                  {index === 0 && <br />}
                                  + {form ? form.name : `Form ID ${id} not found`}
                                  <br />
                                </React.Fragment>
                              );
                            })
                          ) : (
                            'No tasks'
                          )}
                        </Typography>

            
            
                        <div className='button-group'> 
                          <Button className='me-1' variant="outlined" onClick={() => handleOpenDialog(schedule)}>
                            Sửa
                          </Button>
                          <Button variant="outlined" color="error"  onClick={() => handleDeleteSchedule(schedule.id)}>
                          Xóa
                          </Button>
                        </div>
                    </Stack>
                ))}

                <Button startIcon={<AddCircleOutline />} onClick={() => handleOpenDialog()}>
                    Thêm lịch
                    </Button>
                    <Pagination
                    count={Math.ceil(schedules.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    />
            </Stack>
            
            {openDialog && selectedSchedule && (
                <CustomDialog 
                    open={openDialog}
                    title={selectedSchedule.id ? 'Chỉnh sửa' : 'Thêm'}
                    onClose={handleCloseDialog}
                    onSubmit={handleSaveSchedule}
                    dialogWidth="600px"
                    dialogMaxWidth="80%"
                >
                    <FormFields fields={formFields} />

                    <Typography variant="body1" sx={{ mb: 1, mt: 2 }}>
                            <strong>Thời gian nhắc (bắt đầu từ lúc thêm gói này vào)</strong> *
                    </Typography>
                    <Stack direction="row" spacing={2} alignItems="center">
                    <TextField
                        label="Khoảng thời gian (số)"
                        type="number"
                        value={selectedSchedule?.timeInterval || ''}
                        onChange={(e) =>
                        handleSpecificFieldChange('timeInterval', Number(e.target.value))
                        }
                    />
                    <FormControl fullWidth>
                    <Select
                        value={selectedSchedule?.timeIntervalUnit || ''}
                        onChange={(e) =>
                        handleSpecificFieldChange('timeIntervalUnit', e.target.value)
                        }
                        displayEmpty // Enables displaying the placeholder when the value is empty
                    >
                        <MenuItem value="" disabled >
                        Đơn vị
                        </MenuItem>
                        <MenuItem value="day">Ngày</MenuItem>
                        <MenuItem value="week">Tuần</MenuItem>
                        <MenuItem value="month">Tháng</MenuItem>
                        <MenuItem value="year">Năm</MenuItem>
                    </Select>
                    </FormControl>
                    </Stack>
                </CustomDialog>
            )};

        </div>
    );


};


