import * as React from 'react';
import { Tabs, Tab, Box, Typography, Stack, Button } from '@mui/material';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { FuelTank } from './FuelTank';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import axios from 'axios';
import { FieldManagementDialog, DialogField } from '@/components/dashboard/fieldManagement/FieldManagement';
import  useCustomFieldsUpdate from '@/hooks/field-managementSubmit'; // Import the hook
import { UseFetchFuelType} from '@/hooks/fetch-relationship-field'; 
import FormThings from './formWithCustomSelectFuelTank'; // Import the form component
import { TabPanel } from '@/components/dashboard/tabPanel/tabPanel'; // Import the tab panel component
import { GenericTable } from '@/components/dashboard/table/genericTable';
import FuelInputTab from '@/components/subPlace/FuelInputTab'; // Import the fuel input tab component
import FuelTankHistory from '@/components/subPlace/FuelTankHistory';
import FuelEntryPage from './FuelEntryPage';


interface FuelTankDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (fuelEntryData: Partial<FuelTank>) => void;
  fuelTankData?: Partial<FuelTank>;
  refreshData: () => void;  
}

export const FuelTankDialog: React.FC<FuelTankDialogProps> = ({open, onClose, onSubmit, fuelTankData, refreshData}) => {
  let isEditMode = !!fuelTankData; // add another flag if chagne the edit mode mecha 
  const [fields, setFields] = React.useState<DialogField[]>([]);
  const [formData, setFormData] = React.useState<Partial<FuelTank>>(fuelTankData || {});
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

  const [isFieldManagementOpen, setFieldManagementOpen] = React.useState(false);
  const [customFieldsData, setCustomFieldsData] = React.useState<Record<string, any>>({});

  const { fuelTypes, addFuelType, editFuelType, deleteFuelType } = UseFetchFuelType(); 
  
  const [fuelTypeId, setFuelTypeId] = React.useState<number | null>(null);
  
  React.useEffect(() => {
    fetchFields();
  }, []);

  const fetchFields = async () => {
    try {
      const response = await axios.get('/api/fuel-tank-dialog-fields');
      setFields(response.data);
    } catch (error) {
      console.error('Failed to fetch fields:', error);
    } 
  }

  const addFieldToDatabase = async (field: DialogField): Promise<DialogField> => {  
    try {
      const response = await axios.post('/api/fuel-tank-dialog-fields', field);
      return response.data;
    } catch (error) {
      console.error('Failed to add dialog field:', error);
      return field;
    }
  }

  const updateFieldInDatabase = async (field: DialogField): Promise<DialogField> => {
    try {
      const response = await axios.put(`/api/fuel-tank-dialog-fields/${field.id}`, field);
      return response.data;
    } catch (error) {
      console.error('Error updating field:', error);
      throw error;
    }
  };

  const deleteFieldFromDatabase = async (id: number): Promise<void> => {
    try {
      await axios.delete(`/api/fuel-tank-dialog-fields/${id}`);
    } catch (error) {
      console.error('Error deleting field:', error);
      throw error;
    }
  };

  const { handleCustomFieldsUpdate } = useCustomFieldsUpdate(); // Use the custom

  const handleFieldManagementSubmit = (updatedFields: DialogField[]) => {
    handleCustomFieldsUpdate(
      fields,                         
      formData.customFields || {},     
      fuelTankData?.customFields,      
      updatedFields,                   
      setCustomFieldsData,             
      setFormData,                     
      setFields,                       
      setFieldManagementOpen           
    );

    refreshData();  // <-- Trigger refresh after field management is done
  };

  const handleSubmit = async () => {
    if (validateForm()) {

      // require fuel entry number / vehicleId / date 
      const customFields: Record<string, any> = {}; 

      fields.forEach((field) => {
        if (field.canDelete) {
          // Merge custom fields with form data
          customFields[field.name] = formData[field.name as keyof typeof formData] || customFieldsData[field.name];
        }
      });

      onSubmit({
        ...formData,
        fuelTypeId: fuelTypeId ?? undefined,
        customFields
      })

    
    }
  };

  
  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};
    fields.forEach(field => {
      const fieldValue = formData[field.name as keyof FuelTank];

      if (field.required && (!fieldValue || typeof fieldValue === 'string' && !fieldValue.trim())) {
        newErrors[field.name] = `${field.label} cần có giá trị`;
      }
    });

    if (!fuelTypeId) {  
      newErrors.fuelTypeId = 'Cần phải chọn loại nhiên liệu.'; 
    }

    // validate to make the threshhold value only from 1-100
    if (formData.threshold && (formData.threshold < 1 || formData.threshold > 99)) {
      newErrors.threshold = 'Ngưỡng báo thấp phải nằm trong khoảng từ 1 đến 99.';
    }

    setErrors(newErrors);
    // If there are errors, show them in an alert
    if (Object.keys(newErrors).length > 0) {
      const errorMessages = Object.values(newErrors)
        .map((message) => `+ ${message}`) // Add "+ " before each error message
        .join('\n'); // Combine all error messages

      alert(`Các mục bị lỗi:\n${errorMessages}`); // Show alert with errors
    }

    return Object.keys(newErrors).length === 0;
  };
  

  React.useEffect(() => {
    if (open) {
      if (isEditMode && fuelTankData) {

        setFormData(fuelTankData); // Set the form data when editing an existing entry

        if (fuelTankData.customFields) {
          try {
            const parsedCustomFields = typeof fuelTankData.customFields === 'string' ? JSON.parse(fuelTankData.customFields) : fuelTankData.customFields;
            setCustomFieldsData(parsedCustomFields);
          } catch (error) {
            console.log('Failed to parse custom fields:', error);
          }
        }

      } else {
        setFormData({}); // Clear the form data when adding a new entry
        setCustomFieldsData({}); // Clear the custom fields data when adding a new entry  
        setFuelTypeId(null); 
      }

    } 
  }, [open, isEditMode]);


 
  const handleCustomFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Check if the input is a checkbox or a regular text input
    setCustomFieldsData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,  // If checkbox, store as boolean; else, store the value
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Update the state
    const updatedFormData = {
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    };
  
    setFormData(updatedFormData);
  };

  const formFields: FormField[] = fields.map(field => {
    return {
      label: field.label,
      name: field.name,
      type:  field.type,
      value: formData[field.name as keyof FuelTank] || customFieldsData[field.name] || '',
      required: field.required,
      handleChange: field.canDelete ? handleCustomFieldChange : handleChange,
      error: errors[field.name],
    };
  });

  // the sub part
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };



  return (
    <CustomDialog open={open} title="Thêm/Chỉnh sửa thông tin bình xăng" onClose={onClose} manageFields={() => setFieldManagementOpen(true)}
      onSubmit={handleSubmit} submitLabel="Lưu" dialogWidth='1600px' dialogMaxWidth='95%'>
      <div className="row">
      <div className={isEditMode ? "col-md-6" : "col-md-12"}>
          <FormThings
            formFields={formFields}
            fuelTypes={fuelTypes}
            fuelTypeId={fuelTypeId}
            setFuelTypeId={setFuelTypeId}
            addFuelType={addFuelType}
            editFuelType={editFuelType}
            deleteFuelType={deleteFuelType}
          />
        </div>

        {isEditMode && (
          <div className="col-md-6">  
            <Typography variant="h5" gutterBottom>Lịch sử hoạt động của bình xăng</Typography>

            {/* Tabs for history and additional information */}
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={activeTab} onChange={handleTabChange} aria-label="Fuel Tank History Tabs" variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile  // MUI v5+ only
                >
                <Tab label="Lịch sửa chung" />
                <Tab label="Lịch sử nạp bình xăng" />
                <Tab label="Lịch sử dùng" />
              </Tabs>
            </Box>

            <TabPanel value={activeTab} index={0}>
              <FuelTankHistory fuelTankId={fuelTankData?.id ?? ''} fuelTankCapacity={fuelTankData?.capacity ?? 0} fuelTankThreshHold={fuelTankData?.threshold ?? 0} />
            </TabPanel>
            {/* lịch sư nạp bình xăng */}
            <TabPanel value={activeTab} index={1}>

              <FuelInputTab fuelTankId={fuelTankData?.id || ''} />
          
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <FuelEntryPage  fuelTankId={fuelTankData?.id}/>
            </TabPanel>

          </div>
        )};

      </div>

       <FieldManagementDialog
          open={isFieldManagementOpen}
          onClose={() => setFieldManagementOpen(false)}
          fields={fields}
          onFieldSubmit={handleFieldManagementSubmit}
          addFieldToDatabase={addFieldToDatabase}
          updateFieldInDatabase={updateFieldInDatabase}
          deleteFieldFromDatabase={deleteFieldFromDatabase}
        />

    </CustomDialog>
  )
};

