import React, { useEffect, useState } from 'react';
import { PartMeasurementUnit } from './InventorySetup'; // Import the component
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields } from '@/components/dashboard/formField/FormFields';
import axios from 'axios';

interface partMeasurementUnitDialogProps {
  open: boolean;
  onClose: () => void;  
  onSubmit: (data: any) => void;  
  data?: Partial<PartMeasurementUnit>; 
}

export const PartMeasurementUnitDialog: React.FC<partMeasurementUnitDialogProps> = ({ open, data, onClose, onSubmit }) => {
  const isEditMode = !!data;  
  const [partMeasurementUnitData, setPartMEasurementUnitData] = React.useState(data || {}); 
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

  console.log('data', data);  

  useEffect(() => {
    if (data) {
      setPartMEasurementUnitData(data);
    } else {
      setPartMEasurementUnitData({});
    }
  }, [open, data]);

 const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => { 
  setPartMEasurementUnitData({ ...partMeasurementUnitData, [e.target.name]: e.target.value });
 };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    const errorMessages: string[] = []; 

    // validate name
    if (!partMeasurementUnitData.name || partMeasurementUnitData.name.trim() === '') {
      newErrors.name = 'Vui lòng nhập tên đơn vị đo lường.';
    }

    // validate shortName
    if (!partMeasurementUnitData.shortName || partMeasurementUnitData.shortName.trim() === '') {
      newErrors.shortName = 'Vui lòng nhập tên viết tắt.';
    }

    // validate type
    if (!partMeasurementUnitData.type || partMeasurementUnitData.type.trim() === '') {  
      newErrors.type = 'Vui lòng chọn loại đơn vị đo lường.';
    }
    
    setErrors(newErrors);

    // showing the alert combined with the error messages
    if (errorMessages.length > 0) { 
      const formattedErrorMessages = errorMessages.map((message, index) => `+ ${message}`);  
      alert(`Vui lòng kiểm tra lại các trường thông tin sau:\n${formattedErrorMessages.join('\n')}`);
    }

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
  
    try {
      const partMeasurementUnitDataSubmit = {
        ...partMeasurementUnitData
      };
  
      const formDataSubmit = new FormData();
      Object.keys(partMeasurementUnitDataSubmit).forEach(key => {
        const value = partMeasurementUnitDataSubmit[key as keyof PartMeasurementUnit];
        formDataSubmit.append(key, value as string | Blob);
      });
  
      let response;
      if (isEditMode) {
        response = await axios.put(
          `/api/part-measurement-unit/${partMeasurementUnitData.id}`,
          formDataSubmit,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );
      } else {
        response = await axios.post('/api/part-measurement-unit', formDataSubmit, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      }
  
      const updatedPartMeasurementUnitData = response.data;
      onSubmit(updatedPartMeasurementUnitData);
  
      setPartMEasurementUnitData({});
      setErrors({});
    } catch (error: any) {
      console.error('Error submitting the part measurement unit data:', error);
  
      if (error.response) {
        const { status, data } = error.response;
        const errorFlag = data?.errorFlag;
  
        if (status === 409) {
          switch (errorFlag) {
            case "NAME_ALREADY_EXISTS":
              alert(`❌ Lỗi: Tên này đã được sử dụng. Vui lòng chọn một tên khác.`);
              break;
            case "SHORTNAME_ALREADY_EXISTS":
              alert(`❌ Lỗi: Tên viết tắt này đã được sử dụng. Vui lòng chọn một tên khác.`);
              break;
            case "NAME_AND_SHORTNAME_ALREADY_EXISTS":
              alert(`❌ Lỗi: Cả tên và tên viết tắt đều đã được sử dụng. Vui lòng chọn giá trị khác.`);
              break;
            default:
              alert(`❌ Lỗi: Đơn vị đo lường đã tồn tại.`);
          }
        } else if (status === 400) {
          alert(`⚠️ Lỗi: Dữ liệu không hợp lệ. Vui lòng kiểm tra lại.`);
        } else if (status === 404) {
          alert('❌ Không tìm thấy đơn vị đo lường.');
        } else {
          alert('❌ Có lỗi xảy ra khi thêm/chỉnh sửa đơn vị đo lường. Vui lòng thử lại sau.');
        }
      } else {
        alert('❌ Không thể kết nối đến máy chủ. Vui lòng kiểm tra mạng và thử lại.');
      }
    }
  };
  

  const formFields = [
    { name: 'name', label: 'Tên đơn vị đo lường', type: 'text', value: partMeasurementUnitData.name, handleChange: handleChange, required: true},
    { name: 'shortName', label: 'Tên viết tắt', type: 'text', value: partMeasurementUnitData.shortName, handleChange: handleChange, required: true},
    { name: 'type', label: 'Loại đơn vị đo lường', type: 'select', value: partMeasurementUnitData.type, handleChange: handleChange, options: [{ value: 'Length', label: 'Chiều dài' }, { value: 'Weight', label: 'Khối lượng' }, { value: 'Volume', label: 'Thể tích' }, {value: 'Unit', label: "Đơn vị"}], required: true},
  ]

  return (
    <>
      <CustomDialog open={open} title={ isEditMode ? 'Chỉnh sửa đơn vị đo linh kiện' : 'Thêm đơn vị đo linh kiện'} onClose={onClose} onSubmit={handleSubmit} dialogWidth='1200px' dialogMaxWidth='95%'>
        <FormFields fields={formFields} />
      </CustomDialog>
    </>
  )

};
