import React from 'react';

const NavMessage: React.FC = () => {
    return (
        <li className='nav-item dropdown'>
            <a className='nav-link nav-icon' href="#" data-bs-toggle="dropdown">
                <i className='bi bi-chat-left-text'>
                    <span className='badge bg-success badge-number'>3</span>
                </i>
            </a>

            <ul className='dropdown-menu dropdown-menu-end dropdown-menu-arrow messages'>
                <li className='dropdown-header'>
                    You Have 3 messages
                    <a href='#'>
                        <span className='badge rounded-pill bg-primary p-2 ms-2'>
                            View All
                        </span>
                    </a>
                </li>

                <li>
                    <hr className='dropdown-divider' />
                </li>

                <li className='messages-item'>
                    <a href='#'>
                        <img src="assets/img/messages-1.jpg" 
                            alt=""
                            className='rounded-circle'
                        />
                        <div>
                            <h4>Ai đó 1</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            </p>
                            <p>5 giờ trước</p>
                        </div>
                    </a>
                </li>

                <li>
                    <hr className='dropdown-divider' />
                </li>

                
                <li className='messages-item'>
                    <a href='#'>
                        <img src="assets/img/messages-1.jpg" 
                            alt=""
                            className='rounded-circle'
                        />
                        <div>
                            <h4>Ai đó 2</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            </p>
                            <p>10 giờ trước</p>
                        </div>
                    </a>
                </li>

                <li>
                    <hr className='dropdown-divider' />
                </li>
                      
                <li className='messages-item'>
                    <a href='#'>
                        <img src="assets/img/messages-1.jpg" 
                            alt=""
                            className='rounded-circle'
                        />
                        <div>
                            <h4>Ai đó 3</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            </p>
                            <p>2 ngày trước</p>
                        </div>
                    </a>
                </li>

                <li>
                    <hr className='dropdown-divider' />
                </li>

                <li className='dropdown-footer'>
                    <a href='#'>
                        View All Messages
                    </a>
                </li>



            </ul>

        </li>

    );

}

export default NavMessage;
