import * as React from 'react';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { Supplier } from './Supplier'; // Import your Supplier type
import { ImageUpload } from '@/components/dashboard/imageUpload/ImageUpLoad';
import axios from 'axios';
import { FieldManagementDialog, DialogField } from '@/components/dashboard/fieldManagement/FieldManagement';
import useImageDownload from '@/hooks/image-download'; // Import the hook
import  useCustomFieldsUpdate from '@/hooks/field-managementSubmit'; // Import the hook

interface SupplierDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (supplierData: Partial<Supplier>, imageFile?: File) => void;
  supplierData?: Partial<Supplier>;
  refreshData: () => void; // <-- pass fetchData here
}

export const SupplierDialog: React.FC<SupplierDialogProps> = ({ open, onClose, onSubmit, supplierData, refreshData }) => {
  let isEditMode = !!supplierData;
  const [fields, setFields] = React.useState<DialogField[]>([]);
  const [formData, setFormData] = React.useState<Partial<Supplier>>(supplierData || {});

  // the field image
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [imageFile, setImageFile] = React.useState<File | null>(null);
  const [imagePreview, setImagePreview] = React.useState<string | null>(null);
  const [imageError, setImageError] = React.useState<string | null>(null);
  const [isPictureDeleted, setIsPictureDeleted] = React.useState(false); // Flag for picture deletion

  //field management
  const [isFieldManagementOpen, setFieldManagementOpen] = React.useState(false);

  // custom field 
  const [customFieldsData, setCustomFieldsData] = React.useState<Record<string, any>>({});

  React.useEffect(() => {
    fetchFields();
  }, []);

  const fetchFields = async () => {
    try {
      const response = await axios.get('/api/supplier-dialog-fields');
      setFields(response.data);
    } catch (error) {
      console.error('Failed to fetch dialog fields:', error);
    }
  };

  const addFieldToDatabase = async (field: DialogField): Promise<DialogField> => {  
    try {
      const response = await axios.post('/api/supplier-dialog-fields', field);
      return response.data;
    } catch (error) {
      console.error('Failed to add dialog field:', error);
      return field;
    }
  }

  const updateFieldInDatabase = async (field: DialogField): Promise<DialogField> => {
    try {
      const response = await axios.put(`/api/supplier-dialog-fields/${field.id}`, field);
      return response.data;
    } catch (error) {
      console.error('Error updating field:', error);
      throw error;
    }
  };

  const deleteFieldFromDatabase = async (id: number): Promise<void> => {
    try {
      await axios.delete(`/api/supplier-dialog-fields/${id}`);
    } catch (error) {
      console.error('Error deleting field:', error);
      throw error;
    }
  };

  const { handleCustomFieldsUpdate } = useCustomFieldsUpdate(); // Use the custom

  const handleFieldManagementSubmit = (updatedFields: DialogField[]) => {
    handleCustomFieldsUpdate(
      fields,                         // Original fields before update
      formData.customFields || {},     // Current custom fields data
      supplierData?.customFields,      // Customer custom fields
      updatedFields,                   // Updated fields
      setCustomFieldsData,             // Update customFieldsData
      setFormData,                     // Update formData
      setFields,                       // Update fields
      setFieldManagementOpen           // Close dialog
    );

    refreshData();  // <-- Trigger refresh after field management is done
  };
  //end custom field

  //for delete the image
  const handleDeleteImage = async () => {
    if (formData.id) {
      try {
        // Send request to backend to delete the image
        setIsPictureDeleted(true);
        setImageFile(null);
        setImagePreview(null);
        setFormData({ ...formData, pictureUrl: undefined });
      } catch (error) {
        console.error('Failed to delete image:', error);
      }
    }
  };

  const { handleDownloadImage } = useImageDownload(); // Use the custom hook

  const handleSubmit = async () => {
    if (validateForm()) {
      const customFields: Record<string, any> = {};

      fields.forEach((field) => {
        if (field.canDelete) {
          // Merge custom fields with form data
          customFields[field.name] = formData[field.name as keyof typeof formData] || customFieldsData[field.name];
        }
      });

      if (isPictureDeleted && formData.id) {
        try {
          await axios.delete(`/api/delete-supplier-picture/${formData.id}`);
        } catch (error) {
          console.error('Failed to delete image:', error);
        }
      }

      onSubmit({
        ...formData,
        customFields,  // Include custom fields in the submission
      }, imageFile || undefined);
    }
  };
  
  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};
    fields.forEach(field => {
      const fieldValue = formData[field.name as keyof Supplier];

      // For other fields, handle as usual
      if (field.required && (!fieldValue || typeof fieldValue === 'string' && !fieldValue.trim())) {
        newErrors[field.name] = `${field.label} cần có giá trị`;
      }
      
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  React.useEffect(() => {
    if (open){
      if (supplierData) {
        setFormData({
          ...supplierData,
        });

        // Populate custom fields data from supplierData.customFields if it exists
        if (supplierData.customFields) {
          try{
            // If customFields is a string, parse it. Otherwise, use it as it is.
            const parsedCustomFields = typeof supplierData.customFields === 'string' ? JSON.parse(supplierData.customFields) : supplierData.customFields; 
            setCustomFieldsData(parsedCustomFields);
          } catch(error){
            console.log('Error parsing custom fields:', error);
          }

        }
  
        setIsPictureDeleted(false); // Reset the flag when the dialog opens
        setImageFile(null); 

        if (supplierData.pictureUrl) {
          setImagePreview(supplierData.pictureUrl);
        } else {
          setImagePreview(null);  
        }
      } else {
        setFormData({
          name: '',
          email: '',
          phoneNumber: '',
          address: '',
          city: '',
          categoryFuelSupplier: false,
          categoryServiceProvider: false,
          categoryVehicleDealer: false,
          categoryPartSupplier: false,
          categoryFinancialServiceProvider: false,
          categoryInsuranceCompany: false,
        });
        setImageFile(null);
        setImagePreview(null);
        setCustomFieldsData({});  
      }
    }
  }, [open, supplierData]);

  const handleCustomFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Check if the input is a checkbox or a regular text input
    setCustomFieldsData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,  // If checkbox, store as boolean; else, store the value
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Handle checkbox separately to update the state with boolean values
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const formFields: FormField[] = fields.map(field => ({
    label: field.label, // Directly use the label
    name: field.name,
    type: field.name === 'categoryFuelSupplier' || field.name === 'categoryServiceProvider' || field.name === 'categoryVehicleDealer' || field.name === 'categoryPartSupplier' || field.name === 'categoryFinancialServiceProvider' || field.name === 'categoryInsuranceCompany'
      ? 'checkbox'
      : field.type, // Checkboxes for specific fields, otherwise use the field's type
    value: formData[field.name as keyof Supplier] || customFieldsData[field.name] || '',  // Custom field values included
    required: field.required, // Required flag as per field settings
    handleChange: field.canDelete
      ? handleCustomFieldChange  // Custom field change handler for deletable fields
      : handleChange, // General change handler
    error: errors[field.name], // Include error handling
  }));

  return (
    <CustomDialog open={open} title="Thêm/Chỉnh sửa thông tin nhà cung cấp" onClose={onClose} manageFields={() => setFieldManagementOpen(true)}
    onSubmit={handleSubmit} submitLabel="Lưu" dialogWidth='800px' dialogMaxWidth='95%'>
        <ImageUpload
        imageFile={imageFile}
        imagePreview={imagePreview}
        setImageFile={setImageFile}
        setImagePreview={setImagePreview}
        onDeleteImage={handleDeleteImage}
        onDownloadImage={() => handleDownloadImage(formData.pictureUrl)}  // Pass a function without arguments
        setImageError={setImageError}
        imageError={imageError}
      />
      <FormFields fields={formFields} />

    <FieldManagementDialog
        open={isFieldManagementOpen}
        onClose={() => setFieldManagementOpen(false)}
        fields={fields}
        onFieldSubmit={handleFieldManagementSubmit}
        addFieldToDatabase={addFieldToDatabase}
        updateFieldInDatabase={updateFieldInDatabase}
        deleteFieldFromDatabase={deleteFieldFromDatabase}
      />

    </CustomDialog>
  );
};
