import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { GenericFilters } from '@/components/dashboard/filter/genericFilter';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1'; 
import axios from 'axios';
import { formatDate } from '@/hooks/format-date';
import dayjs, { Dayjs } from 'dayjs';
import { TiresDialog } from './tiresDialog';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { statusMap, statusOrder, reverseStatusOrder } from '@/hooks/mapping';
import { getContrastTextColor } from '@/hooks/contrast-text-color';

interface TireSet {
  name: string;
}

interface TireSize {
  name: string;
}

interface LatestInspection {
  status: string;
  reason: string;
  currentTreadDepth: number;  
}

interface TireModel {
  name: string;
}

interface TireManufacturer { 
  name: string
}

interface Supplier {
  name: string
}

interface Vehicle {
  vehicleNumber: string;
  name: string;
  primaryMeterType: string;
  primaryMeter: number;
  secondaryMeterType: string; 
  secondaryMeter: number; 
}

interface TirePosition {
  name: string;
}

interface Warehouse {
  name: string
}

enum TreadDepthUnit {
  mm = 'mm',
  in132 = 'in132'
}

export interface Tire {
  id: string;
  tireNumber: string;
  isSet: boolean;
  setId: number;
  set: TireSet;
  manufacturerId: number; 
  manufacturer: TireManufacturer;
  tireModelId: number;
  tireModel: TireModel;
  tireSizeId: number;
  tireSize: TireSize;
  description: string;
  purchaseDate: Date | string;
  purchaseDescription: string;
  vendorId: number;
  vendor: Supplier;
  cost: number;
  taxFlg: boolean;
  taxRate: number;
  totalCost: number;
  vehicleId: number;
  vehicle: Vehicle;
  mountedOnVehicleFlg : boolean;
  tirePositionId: number;
  tirePosition: TirePosition; 
  storageLocationId: string;  
  storageLocation: Warehouse;
  treadDepthUnit: TreadDepthUnit;
  warningWhenWornFlg: boolean;
  minimumTreadDepth: number;
  intervalMeter: number;  
  intervalTime: number; 
  createdAt: Date;
  updatedAt: Date;
  latestInspection: LatestInspection;

  customFields: Record<string, string>;
}

function applyPagination(rows: Tire[], page: number, rowsPerPage: number): Tire[] {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export default function TiresPage(): React.JSX.Element {  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5); // Adjust number of rows per page as necessary
  const [data, setData] = React.useState<Tire[]>([]); // Inspection data state
  const [filteredData, setFilteredData] = React.useState<Tire[]>([]); // Filtered data state
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedTire, setSelectedTire] = React.useState<Tire | null>(null);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/tires'); 
      const sortedData = response.data.sort((a: Tire, b: Tire) => a.tireNumber.localeCompare(b.tireNumber));
      setData(sortedData);  
      setFilteredData(sortedData);
    } catch (error) {
      console.error('Error fetching data: ', error);  
    }
  }

  React.useEffect(() => {   
    fetchData();
  }, []);

  const paginatedInspections = applyPagination(filteredData, page, rowsPerPage);

  const handleFilterChange = (column: string, searchTerm: string | boolean | Dayjs | null) => { 
    if (column === 'statusOrder') {
      const sorted = [...filteredData].sort((a, b) => {
        const orderMap = searchTerm === 'asc' ? statusOrder : reverseStatusOrder;
        const statusA = a.latestInspection.status as keyof typeof orderMap; // Type assertion
        const statusB = b.latestInspection.status as keyof typeof orderMap; // Type assertion
  
        // Compare status order first
        const statusComparison = orderMap[statusA] - orderMap[statusB];
        if (statusComparison !== 0) {
          return statusComparison;
        }
  
        // If statuses are equal, compare primaryMeter relative to primaryMeterThreshold
        const thresholdA = a.minimumTreadDepth ?? 0;
        const thresholdB = b.minimumTreadDepth ?? 0;
  
        const distanceA = Math.abs((a.vehicle.primaryMeter ?? 0) - thresholdA);
        const distanceB = Math.abs((b.vehicle.primaryMeter ?? 0) - thresholdB);
  
        return searchTerm === 'asc' ? distanceA - distanceB : distanceB - distanceA;
      });
  
      setFilteredData(sorted);
      return;
    }

    const filtered = data.filter((tire) => {
      let value;

      switch (column) {
        case 'status': 
          value = tire.latestInspection.status;
          break;
        case 'manufacturerName':  
          value = tire.manufacturer.name;
          break;
        case 'tireModelName':
          value = tire.tireModel.name;
          break;  
        case 'tireSizeName':
          value = tire.tireSize.name;
          break;
      }

      if (searchTerm === null || searchTerm === '') {
        return true;
      }
  
      if (typeof value === 'string' && typeof searchTerm === 'string') {
        return value.toLowerCase().includes(searchTerm.toLowerCase());
      }
  
      if (typeof value === 'boolean' && typeof searchTerm === 'boolean') {
        return value === searchTerm;
      }

      if (column === 'date' && dayjs.isDayjs(searchTerm)) {
        const date = dayjs(value as string).startOf('day');
        const filterDate = searchTerm.startOf('day');
        return date.isSame(filterDate);
      };
  
      return false;
    });

    setFilteredData(filtered);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => { 
    setRowsPerPage(newRowsPerPage); 
    setPage(0); 
  }

  const handleOpenDialog = () => {
    setSelectedTire(null); 
    setOpenDialog(true);
  }

  const handleEditTire = (id: string) => {
    setSelectedTire(null);  

    const inspectionToEdit = data.find((inspection) => inspection.id === id);
    if (inspectionToEdit) {
      setSelectedTire(inspectionToEdit); // Populate the dialog with the selected inspection
      setOpenDialog(true); // Open dialog for editing
    }
  };

  const handleDeleteTire = async (id: string) => {
    const confirmed = window.confirm('Bạn có chắc xóa thẻ đã chọn?');
    if (confirmed) {
      try {
        await axios.delete(`/api/tires/${JSON.stringify(id)}`);
        setData((prevData) => prevData.filter((tire) => tire.id !== id));
        setFilteredData((prevData) => prevData.filter((tire) => tire.id !== id));
      } catch (error) {
        console.error('Error deleting tire:', error);
      }
    }
  };

  const handleDeleteTireSelected = async (selectedIds: string[]) => {
    const confirmed = window.confirm('Bạn có chắc là xóa những thẻ đã chọn?'); 
    if (confirmed) {
      try{
          await axios.delete(`/api/tires/${JSON.stringify(selectedIds)}`);
          alert('Xóa thành công');
          setData((prevData) => prevData.filter((tire) => !selectedIds.includes(tire.id)));
          setFilteredData((prevData) => prevData.filter((tire) => !selectedIds.includes(tire.id)));  
      } catch (error){
        console.log('Error deleting tire:', error);
      }
    }
  }

  const handleAddOrEditTire = async (tire: Partial<Tire>) => { 
    try {
      const formData = new FormData();

      Object.keys(tire).forEach((key) => {
      const value = tire[key as keyof Tire];
        if (value !== undefined) {
          // Ensure that all values appended to FormData are strings or Blob objects
          formData.append(key, value as string | Blob);
        }
      });

      if (tire.customFields) {
        formData.set('customFields', JSON.stringify(tire.customFields));
      }

      if (selectedTire) {
        const response = await axios.put(`/api/tires/${selectedTire.id}`, formData, {
          headers: { 
            'Content-Type': 'multipart/form-data'
          }  
        });

        const updatedTire = response.data;  

        setData((prevData) => 
          prevData.map((tire) => (tire.id === updatedTire.id ? updatedTire : tire)) 
        );
        setFilteredData((prevData) =>
          prevData.map((tire) => (tire.id === updatedTire.id ? updatedTire : tire)) 
        )
      } else {
        const response = await axios.post('/api/tires', formData, {
          headers: { 
            'Content-Type': 'multipart/form-data'
          }
        });

        const newTire = response.data; 

        setData((prevData) => [newTire, ...prevData]);
        setFilteredData((prevData) => [newTire, ...prevData]);
      }
    
      fetchData();
      setOpenDialog(false);
    } catch (error) {
      alert('Có lỗi xảy ra, vui lòng thử lại sau');
      console.error('Error adding/editing tire:', error);
    }
  };

  const filterColumns = [
    { label: 'Mã số bánh xe', value: 'tireNumber' },
    { label: 'Tên nhà cung cấp', value: 'supplierName'},
    { label: 'Tên mẫu bánh xe', value: 'tireModelName'},  
    { label: 'Kích thước bánh xe', value: 'tireSizeName'},
    { label: 'Ngày mua ', value: 'purchaseDate', type: 'date' as 'date'},
    { 
      value: 'status', 
      label: "Trạng thái kiểm tra", 
      type: 'dropdown' as const,
      options: [
        { label: "Chưa đến hạn", value: "NotDue" },
        { label: "Sắp đến hạn", value: "DueSoon" },
        { label: "Đã đến hạn", value: "Due" },  
        { label: "Quá hạn", value: "Overdue" }
      ],
    },
    {
      value: 'statusOrder',
      label: "Sắp xếp trạng thái",
      type: 'dropdown' as const,
      options: [
        { label: "Chưa đến hạn -> Quá hạn", value: "asc" },
        { label: "Quá hạn -> Chưa đến hạn", value: "desc" },
      ],
    },

  ];

  const treadDepthUnitMap : { [key in Tire['treadDepthUnit']]: string } = {
    mm: 'mm',
    in132: '1/32 in'
  };

  type StatusKey = keyof typeof statusMap; // Type for keys of statusMap
  
  const columns: TableColumn<Tire>[] = [
    { label: "Mã số bánh xe", field: (row) => row.tireNumber },
    { label: "Tên nhà sản xuất", field: (row) => row.manufacturer?.name || "" },
    { label: "Tên mẫu bánh xe", field: (row) => row.tireModel?.name || "" },
    { label: "Kích thước bánh xe", field: (row) => row.tireSize?.name || "" },
    {
      label: "Trạng thái lốp xe",
      field: "latestInspection",
      customRender: (row) => {
        const currentTreadDepth = row.latestInspection?.currentTreadDepth;
        const minimumTreadDepth = row.minimumTreadDepth;
  
        if (currentTreadDepth === null || currentTreadDepth === undefined) {
          return (
            <Typography color="textSecondary" sx={{ display: 'flex', alignItems: 'center', fontStyle: "italic" }}>
              <HelpOutlineIcon sx={{ color: 'orange', mr: 1 }} />
              Chưa kiểm tra
            </Typography>
          );
        }
  
        if (minimumTreadDepth === null || minimumTreadDepth === undefined) {
          return (
            <Typography color="textSecondary" sx={{ display: 'flex', alignItems: 'center', fontStyle: "italic" }}>
              <HelpOutlineIcon sx={{ color: 'orange', mr: 1 }} />
              Chưa đặt giới hạn
            </Typography>
          );
        }
  
        return (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: currentTreadDepth < minimumTreadDepth ? "red" : "green",
            }}
          >
            {currentTreadDepth < minimumTreadDepth ? (
              <>
                <CancelIcon sx={{ color: 'red', mr: 1 }} />
                {currentTreadDepth} {treadDepthUnitMap[row.treadDepthUnit]}
              </>
            ) : (
              <>
                <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
                {currentTreadDepth} {treadDepthUnitMap[row.treadDepthUnit]}
              </>
            )}
          </Typography>
        );
      },
    },
    {
      label: "Trạng thái kiểm tra",
      field: (row) => {
        const status = (row.latestInspection && row.latestInspection.status as StatusKey) || 'NotDue';
        const statusInfo = statusMap[status]; 
        const textColor = getContrastTextColor(statusInfo.color); 
        return (
          <Chip
            label={statusInfo.label}
            style={{
              backgroundColor: statusInfo.color,
              color: textColor,
              fontWeight: 'bold',
              borderRadius: '5px',
              padding: '5px 10px',
            }}
          />
        );
      }
    },
    { label: "Ngày mua", field: (row) => formatDate(row.purchaseDate) },
    { label: "Ngày tạo", field: (row) => formatDate(row.createdAt) },
    { label: "Ngày cập nhật", field: (row) => formatDate(row.updatedAt) },
  ];
  

  const handleCloseDialog = () => {
    setSelectedTire(null);  
    setOpenDialog(false);
  }

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h4">Quản lý Lốp xe</Typography>
        </Stack>
        <div>
          <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={handleOpenDialog}>
            Thêm
          </Button>
        </div>
      </Stack>

      <GenericFilters 
        columns={filterColumns} onFilterChange={handleFilterChange} placeholder="Tìm thẻ"
      />

      <GenericTable
        count={filteredData.length}
        page={page}
        rows={applyPagination(filteredData, page, rowsPerPage)} // Use transformed data
        columns={columns}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onEdit={handleEditTire  }
        onDelete={handleDeleteTire  }
        onDeleteSelected={handleDeleteTireSelected} 
      />


      <TiresDialog 
        open={openDialog} 
        onClose={handleCloseDialog} 
        tiresData={selectedTire ?? undefined} 
        onSubmit={handleAddOrEditTire}   
        refreshData={fetchData}  
      />


    </Stack>
  );
};

