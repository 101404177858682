import * as React from 'react';
import { Typography } from '@mui/material';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { RenewalReminder, RenewalReminderName } from './RenewalPage'; 
import axios from 'axios';
import { FieldManagementDialog, DialogField } from '@/components/dashboard/fieldManagement/FieldManagement';
import  useCustomFieldsUpdate from '@/hooks/field-managementSubmit'; // Import the hook
import { UseFetchVechicle, UseFetchSupplier, UseFetchAccount, UseFetchRenewalReminderName } from '@/hooks/fetch-relationship-field'; 
import { formatDate } from '@/hooks/format-date';
import { unitToDays } from '@/hooks/mapping';
import FormThings  from './formWithCustomSelectRenewalDialog'; 

interface RenewalDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (renewalReminderData: Partial<RenewalReminder>) => void;
  renewalReminderData?: Partial<RenewalReminder>;
  refreshData: () => void; // <-- pass fetchData here
}

export const RenewalReminderDialog: React.FC<RenewalDialogProps> = ({ open, onClose, onSubmit, renewalReminderData, refreshData }) => {
  let isEditMode = !!renewalReminderData  ;

  const [fields, setFields] = React.useState<DialogField[]>([]);
  const [formData, setFormData] = React.useState<Partial<RenewalReminder>>(renewalReminderData || {});
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
 
  const [isFieldManagementOpen, setFieldManagementOpen] = React.useState(false);

  const [customFieldsData, setCustomFieldsData] = React.useState<Record<string, any>>({});

  const { vehicles } = UseFetchVechicle();
  const { suppliers } = UseFetchSupplier();
  const { assignees, customers } = UseFetchAccount();
  const { vehicleNames, assigneeNames, supplierNames, customerNames, addRenewalReminderName, editRenewalReminderName, deleteRenewalReminderName } = UseFetchRenewalReminderName();  

  const [parentId, setParentId] = React.useState<number | null>(null);  

  // set reminder name id
  const [reminderNameId, setReminderNameId] = React.useState<string | null>(null);  

  React.useEffect(() => {
    fetchFields();
  }, []);

  const fetchFields = async () => {
    try {
      const response = await axios.get('/api/renewal-reminder-dialog-fields');
      setFields(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  const addFieldToDatabase = async (field: DialogField): Promise<DialogField> => {  
    try {
      const response = await axios.post('/api/renewal-reminder-dialog-fields', field);
      return response.data;
    } catch (error) {
      console.error('Failed to add dialog field:', error);
      return field;
    }
  }

  const updateFieldInDatabase = async (field: DialogField): Promise<DialogField> => {
    try {
      const response = await axios.put(`/api/renewal-reminder-dialog-fields/${field.id}`, field);
      return response.data;
    } catch (error) {
      console.error('Error updating field:', error);
      throw error;
    }
  };

  const deleteFieldFromDatabase = async (id: number): Promise<void> => {
    try {
      await axios.delete(`/api/renewal-reminder-dialog-fields/${id}`);
    } catch (error) {
      console.error('Error deleting field:', error);
      throw error;
    }
  };

  const { handleCustomFieldsUpdate } = useCustomFieldsUpdate(); // Use the custom

  const handleFieldManagementSubmit = (updatedFields: DialogField[]) => {
    handleCustomFieldsUpdate(
      fields,                         
      formData.customFields || {},     
      renewalReminderData?.customFields,      
      updatedFields,                   
      setCustomFieldsData,             
      setFormData,                     
      setFields,                       
      setFieldManagementOpen           
    );
    refreshData();  // <-- Trigger refresh after field management is done
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const customFields: Record<string, any> = {}; 

      fields.forEach((fields)=> {
        if (fields.canDelete) {
          customFields[fields.name] = customFieldsData[fields.name];
        } 
      })

      onSubmit({
        ...formData,
        customFields,
        parentId: parentId || undefined,
        reminderNameId: reminderNameId || undefined,  

      })
    }
  };


  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};

    // check to require the formData.parentType to be selected
    if (!formData.parentType) { 
      newErrors.parentType = 'Vui lòng chọn mục tiêu gia hạn';
    }

    if (!parentId) {
      newErrors.parentId = 'Vui lòng chọn mục tiêu';
    }

    if (!reminderNameId || reminderNameId == null || reminderNameId === '0') {  
      newErrors.reminderNameId = 'Vui lòng chọn mục nhắc nhở';
    }

    if (!formData.nextDueDate) {  
      newErrors.nextDueDate = 'Vui lòng chọn ngày đén hạn'; 
    }

    if (!formData.preReminderDate) {
      newErrors.preReminderDate = 'Vui lòng chọn ngày nhắc nhở';
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) { 
      const errorMessages = Object.values(newErrors)
      .map((message) => `+ ${message  }`)
      .join('\n');  
      alert(`Các mục bị lỗi:\n${errorMessages}`);
    };

    return Object.keys(newErrors).length === 0;
  };  

  React.useEffect(() => {
    if (open) {
      if (isEditMode && renewalReminderData) {
        setFormData({...renewalReminderData,
          nextDueDate: formatDate(renewalReminderData.nextDueDate),
        });

        setParentId(renewalReminderData.parentId || null);  

        if (renewalReminderData.customFields) {
          try {
            const parsedCustomFields = typeof renewalReminderData.customFields === 'string' ? JSON.parse(renewalReminderData.customFields) : renewalReminderData.customFields;
            setCustomFieldsData(parsedCustomFields);
          } catch (error) {
            console.log("Error parsing custom fields:", error); 
          }
        }

        setReminderNameId(renewalReminderData.reminderNameId || null);
      } else {
        setFormData({});  
        setCustomFieldsData({});  
        setParentId(null);  
      }
    } 
  }, [open, isEditMode]);

  const handleCustomFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Check if the input is a checkbox or a regular text input
    setCustomFieldsData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,  // If checkbox, store as boolean; else, store the value
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,  // If checkbox, store as boolean; else, store the value
    }));
  };  

  const formFields: FormField[] = fields.map((field) => {
    return {
      label: field.label,
      name: field.name,
      type: ['parentType'].includes(field.name) ? 'select' : field.type,
      options: ['parentType'].includes(field.name) ? [
        { label: 'Xe', value: 'Vehicle' },
        { label: 'Nhà cung cấp', value: 'Supplier' },
        { label: 'Khách hàng', value: 'Customer' },
        { label: 'Nhân viên', value: 'Assignee' },  
      ] : undefined,
      value: formData[field.name as keyof RenewalReminder] || customFieldsData[field.name] || '',
      required: field.required,
      handleChange: field.canDelete ? handleCustomFieldChange : handleChange,
      error: errors[field.name],
    }
    
  });

  return (
    <CustomDialog open={open} title="Thêm/Chỉnh sửa thông tin thẻ nhắc" onClose={onClose} manageFields={() => setFieldManagementOpen(true)}
    onSubmit={handleSubmit} submitLabel="Lưu" dialogWidth='800px' dialogMaxWidth='95%'
    >

      <div className='row'>
        <FormThings
          formFields={formFields}
          vehicles={vehicles}
          customers={customers}
          suppliers={suppliers}
          assignees={assignees}
          parentId={parentId}
          parentType={formData.parentType}  
          setParentId={setParentId}   
          setReminderNameId={setReminderNameId} 
          reminderNameId={reminderNameId}    
          addRenewalReminderName={addRenewalReminderName} 
          editRenewalReminderName={editRenewalReminderName}
          deleteRenewalReminderName={deleteRenewalReminderName}  
          vehicleNames={vehicleNames} 
          assigneeNames={assigneeNames}
          supplierNames={supplierNames}
          customerNames={customerNames}
        />
      </div>

        <FieldManagementDialog
          open={isFieldManagementOpen}
          onClose={() => setFieldManagementOpen(false)}
          fields={fields}
          onFieldSubmit={handleFieldManagementSubmit}
          addFieldToDatabase={addFieldToDatabase}
          updateFieldInDatabase={updateFieldInDatabase}
          deleteFieldFromDatabase={deleteFieldFromDatabase}
        />
    </CustomDialog>
  )


}

















