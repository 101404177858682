import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { CustomSelectWithAdd } from '@/components/dashboard/customSelectWithAdd/customSelectWithAdd';
import { Typography } from '@mui/material';
import { Warehouse } from '@phosphor-icons/react';

const FormThings = ({
    formFields,
    vehicles,
    vehicleId,
    setVehicleId,

    tireSets,
    tireSetId,
    setTireSetId,
    addTireSet,
    editTireSet,    
    deleteTireSet,

    manufacturers,
    manufacturerId,
    setManufacturerId,
    addManufacturer,
    editManufacturer,
    deleteManufacturer,

    tireModels,
    tireModelId,
    setTireModelId,
    addTireModel,
    editTireModel,
    deleteTireModel,

    tireSizes,
    tireSizeId,
    setTireSizeId,
    addTireSize,
    editTireSize,
    deleteTireSize,

    tirePositions,
    tirePositionId,
    setTirePositionId,
    addTirePosition,
    editTirePosition,
    deleteTirePosition,

    vendors,
    vendorId,
    setVendorId,

    warehouses,
    warehouseId,    
    setWarehouseId,
    addWarehouse,
    editWarehouse,
    deleteWarehouse,

    isSetData,
    mountedOnVehicleFlgData,
    } : { 
    formFields: FormField[];
    vehicles: any[];
    vehicleId: number | null;
    setVehicleId: (id: number) => void;

    tireSets: any[];    
    tireSetId: number | null;
    setTireSetId: (id: number) => void;
    addTireSet: (item: string) => void;
    editTireSet: (id: number, newName: string) => void; 
    deleteTireSet: (id: number) => void;

    manufacturers: any[];   
    manufacturerId: number | null;
    setManufacturerId: (id: number) => void;
    addManufacturer: (item: string) => void;    
    editManufacturer: (id: number, newName: string) => void;    
    deleteManufacturer: (id: number) => void;

    tireModels: any[];
    tireModelId: number | null; 
    setTireModelId: (id: number) => void;
    addTireModel: (item: string) => void;   
    editTireModel: (id: number, newName: string) => void;   
    deleteTireModel: (id: number) => void;  

    tireSizes: any[];   
    tireSizeId: number | null;  
    setTireSizeId: (id: number) => void;
    addTireSize: (item: string) => void;    
    editTireSize: (id: number, newName: string) => void;    
    deleteTireSize: (id: number) => void;   

    tirePositions: any[];   
    tirePositionId: number | null;
    setTirePositionId: (id: number) => void;
    addTirePosition: (item: string) => void;    
    editTirePosition: (id: number, newName: string) => void;    
    deleteTirePosition: (id: number) => void;

    vendors: any[]; 
    vendorId: number | null;
    setVendorId: (id: number) => void;

    warehouses: any[];  
    warehouseId: number | null;
    setWarehouseId: (id: number) => void;  
    addWarehouse: (item: string) => void;
    editWarehouse: (id: number, newName: string) => void;   
    deleteWarehouse: (id: number) => void;  
    
    isSetData: boolean; 
    mountedOnVehicleFlgData: boolean;
    }) => {

        const selectedVehicle = vehicles.find((vehicle) => vehicle.id === vehicleId);

        return (
            <form>  
                {formFields.map((field, index) => (
                    <div key={index}>

                        <FormFields fields={[field]} />

                        {index === 1 && isSetData === true && (
                            <CustomSelectWithAdd
                                label='Số lượng lốp trong 1 bộ *'
                                items={tireSets}
                                value={tireSetId || 0}
                                onChange={setTireSetId}
                                addItem={addTireSet}
                                editItem={editTireSet}
                                deleteItem={deleteTireSet}
                            />
                        )}

                        {index === 1 && (
                            <CustomSelectWithAdd
                                label='Nhà sản xuất'
                                items={manufacturers}
                                value={manufacturerId || 0}
                                onChange={setManufacturerId}
                                addItem={addManufacturer}
                                editItem={editManufacturer}
                                deleteItem={deleteManufacturer}
                            />  
                        )}

                        {index === 1 && (
                            <CustomSelectWithAdd
                                label='Mẫu lốp'
                                items={tireModels}
                                value={tireModelId || 0}
                                onChange={setTireModelId}
                                addItem={addTireModel}  
                                editItem={editTireModel}
                                deleteItem={deleteTireModel}
                            />  
                        )}

                        {index === 1 && (   
                            <CustomSelectWithAdd
                                label='Kích thước lốp'
                                items={tireSizes}
                                value={tireSizeId || 0}
                                onChange={setTireSizeId}
                                addItem={addTireSize}
                                editItem={editTireSize}
                                deleteItem={deleteTireSize}
                            />      
                        )}

                        {index === 4 && (
                            <CustomSelectWithAdd
                               label="Nhà cung cấp"
                                items={vendors}
                                value={vendorId || 0}
                                onChange={setVendorId}
                            />
                        )}

                        {field.name === 'totalCost' && (
                            <CustomSelectWithAdd
                                label='Xe'
                                items={vehicles}
                                value={vehicleId || 0}
                                onChange={setVehicleId} 
                            />
                        )}

                        {field.name === 'totalCost' && (   
                            <CustomSelectWithAdd
                                label='Kho'
                                items={warehouses}
                                value={warehouseId || 0}
                                onChange={setWarehouseId}
                                addItem={addWarehouse}
                                editItem={editWarehouse}
                                deleteItem={deleteWarehouse}
                            />
                        )}

                        {(  field.name ==='mountedOnVehicleFlg' &&  mountedOnVehicleFlgData) && (
                            <CustomSelectWithAdd
                                label='Vị trí lắp'
                                items={tirePositions}
                                value={tirePositionId || 0}
                                onChange={setTirePositionId}
                                addItem={addTirePosition}
                                editItem={editTirePosition}
                                deleteItem={deleteTirePosition}
                            />
                        )}


                        {/* Helper text for the selected vehicle */}
                        {field.name === 'intervalMeter' && (
                            <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
                                Đơn vị đo khoảng cách:{" "}
                                {selectedVehicle ? selectedVehicle.primaryMeterType : "kilomet (mặc định)"}
                            </Typography>
                        )}

                        



                    </div>
                ))}

            </form>
        )
}


export default FormThings;
