import { createSlice } from '@reduxjs/toolkit';

interface VehicleState {
  list: string[];
}

const initialState: VehicleState = {
  list: [],
};

export const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    addVehicle: (state, action) => {
      state.list.push(action.payload);
    },
  },
});

export const { addVehicle } = vehicleSlice.actions;
export default vehicleSlice.reducer;
