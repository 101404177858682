import * as React from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import { TabPanel } from '@/components/dashboard/tabPanel/tabPanel'; // Import the tab panel component
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { MaintainanceServiceEntry } from './MaintainanceServiceEntries'; // Import your MaintainanceServiceEntry type
import axios from 'axios';
import { FieldManagementDialog, DialogField } from '@/components/dashboard/fieldManagement/FieldManagement';
import  useCustomFieldsUpdate from '@/hooks/field-managementSubmit'; // Import the hook
import { UseFetchAccount, UseFetchVechicle, UseFetchSupplier, useFetchServiceEntriesStatus, UseFetchWorkOrder, UseFetchBillableStatus, UseFetchServiceTask } from '@/hooks/fetch-relationship-field'; 
import FormThings from './formWithCustomSelectServiceEntries'; // Import the form component
import PartEntryComponent from '@/components/subPlace/PartEntry'; // Import the sub part page

interface MaintainanceServiceEntryDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (maintainanceServiceEntryData: Partial<MaintainanceServiceEntry>, imageFile?: File) => void;
  maintainanceServiceEntryData?: Partial<MaintainanceServiceEntry>;
  refreshData: () => void; // <-- pass fetchData here
  fromIssueFlg?: boolean; // Flag to indicate if opened from issue
  workOrderIdProps?: string;
}

export const MaintainanceServiceEntryDialog: React.FC<MaintainanceServiceEntryDialogProps> = ({ open, onClose, onSubmit, maintainanceServiceEntryData, refreshData, fromIssueFlg = false, workOrderIdProps }) => {
  let isEditMode = !!maintainanceServiceEntryData && !fromIssueFlg; // Set isEditMode only if data is present and not from issue
  const [fields, setFields] = React.useState<DialogField[]>([]);
  const [formData, setFormData] = React.useState<Partial<MaintainanceServiceEntry>>(maintainanceServiceEntryData || {});
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [serviceEntryNumberLoading, setServiceEntryNumberLoading] = React.useState(false);  

  //field management
  const [isFieldManagementOpen, setFieldManagementOpen] = React.useState(false);

  // custom field 
  const [customFieldsData, setCustomFieldsData] = React.useState<Record<string, any>>({});

  const { technicians, customers } = UseFetchAccount();
  const {  vehicles } = UseFetchVechicle();
  const { suppliers } = UseFetchSupplier();
  const { serviceEntriesStatus } = useFetchServiceEntriesStatus();
  const { workOrders } = UseFetchWorkOrder();
  const { billableStatus } = UseFetchBillableStatus();
  const { serviceTasks,  addServiceTask, editServiceTask, deleteServiceTask } = UseFetchServiceTask();

  const [customerId, setCustomerId] = React.useState<number | null>(null);
  const [inChargeId, setInChargeId] = React.useState<number | null>(null);
  const [vehicleId, setVehicleId] = React.useState<number | null>(null);
  const [statusId, setStatusId] = React.useState<number | null>(null);
  const [completedDate, setCompletedDate] = React.useState<Date | null>(null);
  const [workOrderId, setWorkOrderId] = React.useState<number | null>(null);
  const [billableStatusId, setBillableStatusId] = React.useState<number | null>(null);
  const [serviceTaskId, setServiceTaskId] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (open){
      
      if (isEditMode && maintainanceServiceEntryData) {

        setFormData({
          ...maintainanceServiceEntryData,
          statusId: maintainanceServiceEntryData.statusId || undefined,
        });

        if (maintainanceServiceEntryData.id ) {
          setServiceEntryId(maintainanceServiceEntryData.id); 
        }

        // Populate custom fields data from maintainanceServiceEntryData.customFields if it exists
        if (maintainanceServiceEntryData.customFields) {
          try{
            // If customFields is a string, parse it. Otherwise, use it as it is.
            const parsedCustomFields = typeof maintainanceServiceEntryData.customFields === 'string' ? JSON.parse(maintainanceServiceEntryData.customFields) : maintainanceServiceEntryData.customFields; 
            setCustomFieldsData(parsedCustomFields);
          } catch(error){
            console.log('Error parsing custom fields:', error);
          }
        }

        setInChargeId(maintainanceServiceEntryData.inChargeId || null);
        setVehicleId(maintainanceServiceEntryData.vehicleId || null);
        setStatusId(maintainanceServiceEntryData.statusId || null);
        setWorkOrderId(maintainanceServiceEntryData.workOrderId || null);
        setBillableStatusId(maintainanceServiceEntryData.billableStatusId || null);
        setCustomerId(maintainanceServiceEntryData.customerId || null);
        setServiceTaskId(maintainanceServiceEntryData.serviceTaskId || null);
        setCompletedDate(maintainanceServiceEntryData.completedDate ? new Date(maintainanceServiceEntryData.completedDate) : null); 

  
      } else {
        setFormData({
        });
        setServiceEntryId(null);  
        setInChargeId(null);
        setVehicleId(null);
        setStatusId(null);
        setWorkOrderId(null);
        setBillableStatusId(null);
        setCustomerId(null);
        setServiceTaskId(null);
        setCustomFieldsData({});  
        if (!isEditMode) {
          fetchNextVehicleNumber();
          setVehicleId(maintainanceServiceEntryData && (maintainanceServiceEntryData as MaintainanceServiceEntry).vehicleId ? (maintainanceServiceEntryData as MaintainanceServiceEntry).vehicleId : null);
        }
      }
    }
  }, [open, isEditMode]);

  React.useEffect(() => {
    if (open && workOrderIdProps) {
      const parsedWorkOrderId = parseInt(workOrderIdProps, 10);
      if (!isNaN(parsedWorkOrderId)) {
        setWorkOrderId(parsedWorkOrderId);
      }
    }
  }, [open, workOrderIdProps]);

  // change thí too
  const fetchNextVehicleNumber = async () => {
    try {
      setServiceEntryNumberLoading(true);
      const response = await axios.get('/api/maintainance-service-entry/next-service-entry-number');
      const nextServiceEntryNumber = response.data.nextServiceEntryNumber;
      setFormData(prevFormData => ({
        ...prevFormData,
        serviceEntryNumber: nextServiceEntryNumber,
      }));
    } catch (error) {
      console.error('Failed to fetch next vehicle number:', error);
    } finally {
      setServiceEntryNumberLoading(false);
    }
  };

  React.useEffect(() => {
    fetchFields();
  }, []);

  const fetchFields = async () => {
    try {
      const response = await axios.get('/api/service-entries-dialog-fields');
      setFields(response.data);
    } catch (error) {
      console.error('Failed to fetch dialog fields:', error);
    }
  };

  const addFieldToDatabase = async (field: DialogField): Promise<DialogField> => {  
    try {
      const response = await axios.post('/api/service-entries-dialog-fields', field);
      return response.data;
    } catch (error) {
      console.error('Failed to add dialog field:', error);
      return field;
    }
  }

  const updateFieldInDatabase = async (field: DialogField): Promise<DialogField> => {
    try {
      const response = await axios.put(`/api/service-entries-dialog-fields/${field.id}`, field);
      return response.data;
    } catch (error) {
      console.error('Error updating field:', error);
      throw error;
    }
  };

  const deleteFieldFromDatabase = async (id: number): Promise<void> => {
    try {
      await axios.delete(`/api/service-entries-dialog-fields/${id}`);
    } catch (error) {
      console.error('Error deleting field:', error);
      throw error;
    }
  };

  const { handleCustomFieldsUpdate } = useCustomFieldsUpdate(); // Use the custom

  const handleFieldManagementSubmit = (updatedFields: DialogField[]) => {
    handleCustomFieldsUpdate(
      fields,                         
      formData.customFields || {},     
      maintainanceServiceEntryData?.customFields,      
      updatedFields,                   
      setCustomFieldsData,             
      setFormData,                     
      setFields,                       
      setFieldManagementOpen           
    );

    refreshData();  // <-- Trigger refresh after field management is done
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        const selectedStatus = serviceEntriesStatus.find((status) => status.id === statusId);
  
        // Confirmation step for completed status
        if (selectedStatus?.function === 'COMPLETED') {
          const confirmAction = window.confirm(
            'Các phụ tùng sử dụng trong nhiệm vụ sẽ bị trừ vào kho và mục này sẽ không được chỉnh nữa. Bạn có chắc chắn muốn xác nhận?'
          );
          if (!confirmAction) return; // If user cancels, stop the submission
        }
  
        const customFields: Record<string, any> = {};
        fields.forEach((field) => {
          if (field.canDelete) {
            customFields[field.name] = formData[field.name as keyof typeof formData] || customFieldsData[field.name];
          }
        });
  
        const submitData: Partial<MaintainanceServiceEntry> = {
          ...formData,
          customFields,
          vehicleId: vehicleId ?? undefined,
          inChargeId: inChargeId ?? undefined,
          statusId: statusId ?? undefined,
          workOrderId: workOrderId ?? undefined,
          billableStatusId: billableStatusId ?? undefined,
          customerId: customerId ?? undefined,
          serviceTaskId: serviceTaskId ?? undefined,
          completedDate: completedDate ?? undefined,
        };
  
        const formDataSubmit = new FormData();
  
        Object.keys(submitData).forEach((key) => {
          const value = submitData[key as keyof MaintainanceServiceEntry];
          if (value !== undefined && value !== null) {
            formDataSubmit.append(key, value.toString());
          }
        });
  
        if (submitData.customFields) {
          formDataSubmit.set('customFields', JSON.stringify(submitData.customFields));
        }
  
        let response;
        if (isEditMode) {
          // Editing existing maintainanceServiceEntry
          response = await axios.put(`/api/maintainance-service-entry/${submitData.id}`, formDataSubmit, {
            headers: {
              'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
            },
          });
        } else {
          // Adding new maintainanceServiceEntry
          response = await axios.post('/api/maintainance-service-entry/', formDataSubmit, {
            headers: {
              'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
            },
          });
        }
  
        const result = response.data;
        onSubmit(result);
      } catch (error) {
        console.error('Submission failed:', error);
        alert('Đã xảy ra lỗi khi cập nhập dữ liệu. Vui lòng thử lại sau.');
      }
    }
  };
  
  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};
  
    // Validate required fields
    fields.forEach((field) => {
      const fieldValue = formData[field.name as keyof MaintainanceServiceEntry];
      if (field.required && (!fieldValue || (typeof fieldValue === 'string' && !fieldValue.trim()))) {
        newErrors[field.name] = `${field.label} cần có giá trị`;
      }
    });
  
    // Additional custom validations
    if (!vehicleId) {
      newErrors['vehicleId'] = 'Vui lòng chọn phương tiện';
    }
  
    if (!inChargeId) {
      newErrors['inChargeId'] = 'Vui lòng chọn bên phụ trách';
    }
  
    const selectedStatus = serviceEntriesStatus.find((status) => status.id === statusId);
    if (selectedStatus?.function === 'COMPLETED' && !completedDate) {
      newErrors['completedDate'] = 'Vui lòng chọn ngày hoàn thành';
    }
  
    const selectedVehicle = vehicles.find((vehicle) => vehicle.id === vehicleId);
    if (selectedVehicle && formData.primaryMeter && formData.primaryMeter < selectedVehicle.primaryMeter) {
      newErrors['primaryMeter'] = 'Odo chính lúc thực hiện không được bé hơn ODO hiện tại của xe';
    }
  
    // If there are any errors, display them in a single alert box
    if (Object.keys(newErrors).length > 0) {
      alert(Object.values(newErrors).join('\n'));
      setErrors(newErrors);
      return false;
    }
  
    setErrors({});
    return true;
  };
  
  const [serviceEntryId, setServiceEntryId] = React.useState<string | null>(null);


  React.useEffect(() => {
    if (!open) {
      setErrors({});
      setFormData({});
      setCustomFieldsData({});
      setServiceEntryId(null);
      setServiceEntryNumberLoading(false);
    }
  })
  
  
  React.useEffect(() => {
    if (formData.id) {
      setServiceEntryId(formData.id);
    }
  }, [formData.id]);  // Ensures this runs whenever formData.id changes
  

  const handleCustomFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Check if the input is a checkbox or a regular text input
    setCustomFieldsData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,  // If checkbox, store as boolean; else, store the value
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Handle checkbox separately to update the state with boolean values
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const formFields: FormField[] = fields.map(field => {
    if (field.name === 'serviceEntryNumber') {
      return {
        label: 'Mã số nhiệm vụ',
        name: 'serviceEntryNumber',
        type: 'text',
        value: formData.serviceEntryNumber || '',
        required: true,
        error: errors['serviceEntryNumber'],

      };
    } else {
      return {
        label: field.label,
        name: field.name,
        type: field.type === 'inChargeType' ? 'select' : field.type,
        options: field.name === 'inChargeType'
          ? [
              { label: 'Thợ sửa chữa', value: 'TECHNICIAN' },
              { label: 'Xưởng ngoài', value: 'VENDOR' },
            ]
          : undefined,
        value: formData[field.name as keyof MaintainanceServiceEntry] || customFieldsData[field.name] || '',
        required: field.required,
        handleChange: field.canDelete ? handleCustomFieldChange : handleChange,
        error: errors[field.name],
      };
    }
  });
  
  // the sub part page
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {  
    setActiveTab(newValue);
  };

  return (
    <CustomDialog open={open} title="Thêm/Chỉnh sửa thông tin nhiệm vụ" onClose={onClose} manageFields={() => setFieldManagementOpen(true)}
    onSubmit={handleSubmit} submitLabel="Lưu" dialogWidth={isEditMode ? '1600px' : '800px'} dialogMaxWidth='90%'
    >

      <div className="row">
        <div className={ isEditMode ? "col-md-6" : "col-md-12"}>
          <FormThings
            formFields={formFields}
            status={serviceEntriesStatus}
            statusId={statusId} 
            setStatusId={setStatusId}
            completedDate={completedDate}
            setCompletedDate={setCompletedDate}
            vehicles={vehicles}
            inChargeId={inChargeId}
            setInChargeId={setInChargeId}
            vehicleId={vehicleId}
            setVehicleId={setVehicleId}
            customers={customers} 
            customerId={customerId}
            setCustomerId={setCustomerId}
            technicians={technicians}
            suppliers={suppliers}
            inChargeType={formData.inChargeType} // Pass the inChargeType from the form data
            workOrders={workOrders}
            workOrderId={workOrderId} 
            setWorkOrderId={setWorkOrderId}
            billableStatus={billableStatus} 
            billableStatusId={billableStatusId}
            setBillableStatusId={setBillableStatusId}
            serviceTasks={serviceTasks}
            serviceTaskId={serviceTaskId}
            setServiceTaskId={setServiceTaskId}
            addServiceTask={addServiceTask} 
            editServiceTask={editServiceTask}
            deleteServiceTask={deleteServiceTask}
            isEditMode={isEditMode} 
          />
        </div>
       
        { isEditMode && formData.id && (
          <div className="col-md-6">
            <Typography variant="h5" gutterBottom>Các dữ liệu liên quan</Typography>

            {/* Tabs for history and additional information */}
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={activeTab} onChange={handleTabChange} aria-label="Service entries related data" variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile  // MUI v5+ only
                >
                <Tab label="Bộ phận được sử dụng" />
              </Tabs>
            </Box>

            <TabPanel value={activeTab} index={0}>
              {serviceEntryId ? (
                <PartEntryComponent serviceEntryId={serviceEntryId} />
              ) : (
                <Typography>Chưa có dữ liệu...</Typography> // Prevents rendering with an incorrect ID
              )}
            </TabPanel>


          </div>
        ) }
      </div>

     
   

      <FieldManagementDialog
        open={isFieldManagementOpen}
        onClose={() => setFieldManagementOpen(false)}
        fields={fields}
        onFieldSubmit={handleFieldManagementSubmit}
        addFieldToDatabase={addFieldToDatabase}
        updateFieldInDatabase={updateFieldInDatabase}
        deleteFieldFromDatabase={deleteFieldFromDatabase}
      />

    </CustomDialog>
  );
};
