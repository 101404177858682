import { useState } from 'react';
import { DialogField } from '@/components/dashboard/fieldManagement/FieldManagement';

const useCustomFieldsUpdate = () => {
  const handleCustomFieldsUpdate = (
    originalFields: DialogField[],
    currentCustomFieldsData: Record<string, any>,
    customerCustomFields: Record<string, any> | undefined,
    updatedFields: DialogField[],
    setCustomFieldsData: (fields: Record<string, any>) => void,
    setFormData: (formData: Partial<any>) => void,
    setFields: (fields: DialogField[]) => void,
    setFieldManagementOpen: (open: boolean) => void
  ) => {
    let updatedCustomFieldsData = { ...currentCustomFieldsData }; // Clone current custom fields data
    let updatedCustomerCustomFields = { ...customerCustomFields }; // Clone existing customer custom fields

    updatedFields.forEach((newField) => {
      const oldField = originalFields.find((field) => field.id === newField.id);

      if (oldField && oldField.name !== newField.name) {
        // If the field name is updated, reflect that in the customFields
        if (currentCustomFieldsData[oldField.name]) {
          updatedCustomFieldsData[newField.name] = currentCustomFieldsData[oldField.name];
          delete updatedCustomFieldsData[oldField.name];

          // Update customerData customFields
          if (updatedCustomerCustomFields[oldField.name]) {
            updatedCustomerCustomFields[newField.name] = updatedCustomerCustomFields[oldField.name];
            delete updatedCustomerCustomFields[oldField.name];
          }
        }
      }

      // Handle deleted fields
      if (oldField?.name && !updatedFields.find((field) => field.name === oldField?.name)) {
        delete updatedCustomFieldsData[oldField?.name];
        delete updatedCustomerCustomFields[oldField?.name];
      }
    });

    // Update the customFieldsData and formData states
    setCustomFieldsData(updatedCustomFieldsData);

    // Update formData (including any other customer data, not just custom fields)
    setFormData((prev: any) => ({
      ...prev,
      customFields: updatedCustomerCustomFields, // Update customer custom fields
    }));

    setFields(updatedFields);
    setFieldManagementOpen(false); // Close field management dialog
  };

  return { handleCustomFieldsUpdate };
};

export default useCustomFieldsUpdate;
