import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';  
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1'; 
import axios from 'axios';
import { formatDate } from '@/hooks/format-date';
import { FuelInputDialog } from './FuelInputTabDialog';

export interface FuelInputTabProps {
  fuelTankId: string; 
}

export interface FuelInput {
  id: string;
  date: Date | string;
  quantity: number;
  description: string;
}

function applyPagination(rows: FuelInput[], page: number, rowsPerPage: number): FuelInput[] {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export default function FuelInputTab({fuelTankId}: FuelInputTabProps): React.JSX.Element {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5); // Adjust number of rows per page as necessary
  const [data, setData] = React.useState<FuelInput[]>([]); 
  const [filteredData, setFilteredData] = React.useState<FuelInput[]>([]); // Filtered data state
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedFuelInput, setSelectedFuelInput] = React.useState<FuelInput | null>(null);
  
  const fetchData = async () => { 
    try {
      // get the data from the api /api/fuel-tanks/fuel-inputs/:fuelTankId
      const response = await axios.get(`/api/fuel-tanks/fuel-inputs/${fuelTankId}`);  
      const sortedData = response.data.sort((a: FuelInput, b: FuelInput) => {a.id.localeCompare(b.id)});
      setData(sortedData);
      setFilteredData(sortedData);  

    } catch (error) {
      console.error(error); 
    }
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => { 
    setRowsPerPage(newRowsPerPage); 
    setPage(0);
  };

  const handleOpenDialog = () => {
    setSelectedFuelInput(null); // Reset selected inspection when adding a new one
    setOpenDialog(true);
  };

  const handleEditFuelInput = (id: string) => {
    const fuelInputToEdit = data.find((fuelInput) => fuelInput.id === id);  
    if (fuelInputToEdit) {
      setSelectedFuelInput(fuelInputToEdit);
      setOpenDialog(true);
    } 
  };

  const handleDeleteFuelInput = async (id: string) => {
    const confirmed = window.confirm('Bạn có chắc muốn xóa thẻ này?');
    if (confirmed) {
      try {
        await axios.delete(`/api/fuel-tanks/fuel-inputs/${JSON.stringify(id)}`);
        setData((prevData) => prevData.filter((fuelInput) => fuelInput.id !== id));
        setFilteredData((prevData) => prevData.filter((fuelInput) => fuelInput.id !== id));
      } catch (error) {
        console.error('Error deleting fuel input:', error);
      }
    }
  }

  const handleDeleteFuelInputSelected = async (selectedIds: string[]) => {  
    const confirmed = window.confirm('Bạn có chắc muốn xóa các thẻ đã chọn?');
    if (confirmed) {
      try{
          await axios.delete(`/api/fuel-tanks/fuel-inputs/${JSON.stringify(selectedIds)}`);
          alert('Xóa thành công');
          setData((prevData) => prevData.filter((fuelInput) => !selectedIds.includes(fuelInput.id)));
          setFilteredData((prevData) => prevData.filter((fuelInput) => !selectedIds.includes(fuelInput.id)));  
      } catch (error){
        console.log('Error deleting fuel tank:', error);
      }
    }
  };

  const handleAddOrEditFuelInput = async (fuelTankData: Partial<FuelInput>) => {  
    try {
      const formData = new FormData();

      Object.keys(fuelTankData).forEach((key) => {
        const value = fuelTankData[key as keyof FuelInput];
        if (value !== undefined) { // Skip inspectionItemsStatus for now
          formData.append(key, value as string | Blob);
        }
      });

      if (selectedFuelInput) {
        const response = await axios.put(`/api/fuel-tanks/fuel-inputs/${selectedFuelInput.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },  
        });

        const updatedFuelInput = response.data ;  

        setData((prevData) => prevData.map((fuelInput) => (fuelInput.id === updatedFuelInput.id ? updatedFuelInput : fuelInput)));  
        setFilteredData((prevData) => prevData.map((fuelInput) => (fuelInput.id === updatedFuelInput.id ? updatedFuelInput : fuelInput)));
      } else {
        const response = await axios.post(`/api/fuel-tanks/fuel-inputs/${fuelTankId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const newFuelInput = response.data;  
        setData((prevData) => [newFuelInput, ...prevData]);  
        setFilteredData((prevData) => [newFuelInput, ...prevData]); 
      } 
      setOpenDialog(false);
    
    } catch (error) {
      alert('Có lỗi xảy ra, vui lòng thử lại sau');
      console.error('Error adding/editing fuel tank:', error);
    }
  };

  const columns: TableColumn<FuelInput>[] = [
    { label: "Ngày", field: (row) => formatDate(row.date) }, 
    { label: "Số lượng", field: "quantity" },
    { label: "Mô tả", field: "description" },
  ];    

  const handleDialogClose = () => {
    setOpenDialog(false);

    setSelectedFuelInput(null);    
  }

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h4">Lịch sử nạp</Typography>
        </Stack>
        <div>
          <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={handleOpenDialog}>
            Thêm
          </Button>
        </div>
      </Stack>      

      <GenericTable
        count={filteredData.length}
        page={page}
        rows={applyPagination(filteredData, page, rowsPerPage)} // Use transformed data
        columns={columns}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onEdit={handleEditFuelInput}
        onDelete={handleDeleteFuelInput}
        onDeleteSelected={handleDeleteFuelInputSelected} 
      />

      <FuelInputDialog open={openDialog} onClose={handleDialogClose} onSubmit={handleAddOrEditFuelInput} fuelInputData={selectedFuelInput ?? undefined} 
        refreshData={fetchData}
      />

    </Stack>
  )

}
