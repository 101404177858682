import * as React from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import { TabPanel } from '@/components/dashboard/tabPanel/tabPanel'; // Import the tab panel component
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { MaintainanceWorkOrder } from './MaintainanceWorkOrder'; // Import your MaintainanceWorkOrder type
import axios from 'axios';
import { FieldManagementDialog, DialogField } from '@/components/dashboard/fieldManagement/FieldManagement';
import  useCustomFieldsUpdate from '@/hooks/field-managementSubmit'; // Import the hook
import { UseFetchAccount, UseFetchVechicle, UseFetchSupplier, UseFetchWorkOrderStatus } from '@/hooks/fetch-relationship-field'; 
import FormThings from './formWithCustomSelectWorkOrder';
import { formatDate } from '@/hooks/format-date';
import MaintainanceServiceEntrysPage from './MaintainanceServiceEntries'; // Import the service entries component  

interface MaintainanceWorkOrderDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (maintainanceWorkOrderData: Partial<MaintainanceWorkOrder>, imageFile?: File) => void;
  maintainanceWorkOrderData?: Partial<MaintainanceWorkOrder>;
  refreshData: () => void; // <-- pass fetchData here
}

export const MaintainanceWorkOrderDialog: React.FC<MaintainanceWorkOrderDialogProps> = ({ open, onClose, onSubmit, maintainanceWorkOrderData, refreshData }) => {
  let isEditMode = !!maintainanceWorkOrderData;
  const [fields, setFields] = React.useState<DialogField[]>([]);
  const [formData, setFormData] = React.useState<Partial<MaintainanceWorkOrder>>(maintainanceWorkOrderData || {});
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [workOrderNumberLoading, setWorkOrderNumberLoading] = React.useState(false);  

  //field management
  const [isFieldManagementOpen, setFieldManagementOpen] = React.useState(false);

  // custom field 
  const [customFieldsData, setCustomFieldsData] = React.useState<Record<string, any>>({});

  const { technicians } = UseFetchAccount();
  const {  vehicles } = UseFetchVechicle();
  const { suppliers } = UseFetchSupplier();
  const { workOrderStatus } = UseFetchWorkOrderStatus();
  
  const [inChargeId, setInChargeId] = React.useState<number | null>(null);
  const [vehicleId, setVehicleId] = React.useState<number | null>(null);
  const [statusId, setStatusId] = React.useState<number | null>(null);

  // for the service entries
  const [serviceEntries, setServiceEntries] = React.useState<any[]>([]); 
  const [availableServiceEntries, setAvailableServiceEntries] = React.useState<any[]>([]);

  const [workOrderId, setWorkOrderId] = React.useState<string | null>(null);  

  React.useEffect(() => {
    if (formData.id) {
      setWorkOrderId(formData.id);  
    }
  }, [formData.id]);

  // for the vehicle number
  React.useEffect(() => {
    if (open && !maintainanceWorkOrderData) {
      fetchNextVehicleNumber();
    }
  }, [open, maintainanceWorkOrderData]);
  
  const fetchNextVehicleNumber = async () => {
    try {
      setWorkOrderNumberLoading(true);
      const response = await axios.get('/api/maintainance-work-orders/next-work-order-number');
      const nextWorkOrderNumber = response.data.nextWorkOrderNumber;
      setFormData(prevFormData => ({
        ...prevFormData,
        workOrderNumber: nextWorkOrderNumber,
      }));
    } catch (error) {
      console.error('Failed to fetch next vehicle number:', error);
    } finally {
      setWorkOrderNumberLoading(false);
    }
  };

  // for the fields and data
  React.useEffect(() => {
    fetchFields();
  }, []);

  const fetchFields = async () => {
    try {
      const response = await axios.get('/api/work-order-dialog-fields');
      setFields(response.data);
    } catch (error) {
      console.error('Failed to fetch dialog fields:', error);
    }
  };

  const addFieldToDatabase = async (field: DialogField): Promise<DialogField> => {  
    try {
      console.log('Adding field:', field);
      const response = await axios.post('/api/work-order-dialog-fields', field);
      return response.data;
    } catch (error) {
      console.error('Failed to add dialog field:', error);
      return field;
    }
  }

  const updateFieldInDatabase = async (field: DialogField): Promise<DialogField> => {
    try {
      console.log('Updating field:', field);
      const response = await axios.put(`/api/work-order-dialog-fields/${field.id}`, field);
      return response.data;
    } catch (error) {
      console.error('Error updating field:', error);
      throw error;
    }
  };

  const deleteFieldFromDatabase = async (id: number): Promise<void> => {
    try {
      await axios.delete(`/api/work-order-dialog-fields/${id}`);
    } catch (error) {
      console.error('Error deleting field:', error);
      throw error;
    }
  };

  const { handleCustomFieldsUpdate } = useCustomFieldsUpdate(); // Use the custom

  const handleFieldManagementSubmit = (updatedFields: DialogField[]) => {
    handleCustomFieldsUpdate(
      fields,                         
      formData.customFields || {},     
      maintainanceWorkOrderData?.customFields,      
      updatedFields,                   
      setCustomFieldsData,             
      setFormData,                     
      setFields,                       
      setFieldManagementOpen           
    );

    refreshData();  // <-- Trigger refresh after field management is done
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      if (!vehicleId) {
        alert('Vui lòng chọn phương tiện');
        return;
      }

      if (!inChargeId) {
        alert('Vui lòng chọn người phụ trách');
        return
      }

      const { approvedDate, activeDate, completedDate, closedDate } = formData;

      // Date validation logic
      if (approvedDate && activeDate && new Date(activeDate) <= new Date(approvedDate)) {
        alert('Ngày hoạt động phải lớn hơn ngày phê duyệt.');
        return;
      }
  
      if (activeDate && completedDate && new Date(completedDate) <= new Date(activeDate)) {
        alert('Ngày hoàn thành phải lớn hơn ngày kích hoạt.');
        return;
      }
  
      if (completedDate && closedDate && new Date(closedDate) <= new Date(completedDate)) {
        alert('Ngày đóng phải lớn hơn ngày hoàn thành.');
        return;
      }
  
      // Approved date should be the earliest of all the dates if present
      if (approvedDate) {
        if (
          (activeDate && new Date(approvedDate) >= new Date(activeDate)) ||
          (completedDate && new Date(approvedDate) >= new Date(completedDate)) ||
          (closedDate && new Date(approvedDate) >= new Date(closedDate))
        ) {
          alert('Ngày phê duyệt phải nhỏ hơn tất cả các ngày khác.');
          return;
        }
      }

      const customFields: Record<string, any> = {};

      fields.forEach((field) => {
        if (field.canDelete) {
          // Merge custom fields with form data
          customFields[field.name] = formData[field.name as keyof typeof formData] || customFieldsData[field.name];
        }
      });

      // add the validate to check these things: (approvedDate must be lesser than others days / the activeDate must be greater than approved date / the completedDate must be greater than the active day
      // the closedDay must be greater than the completed day)       

      onSubmit({
        ...formData,
        customFields,  
        vehicleId: vehicleId ?? undefined, // Include vehicleId if selected
        inChargeId: inChargeId ?? undefined, // Include inChargeId if selected
        statusId: statusId ?? undefined, // Include workOrderStatusId if selected
      });
    }
  };
  
  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};
    fields.forEach(field => {
      const fieldValue = formData[field.name as keyof MaintainanceWorkOrder];

      if (field.required && (!fieldValue || typeof fieldValue === 'string' && !fieldValue.trim())) {
        newErrors[field.name] = `${field.label} cần có giá trị`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  React.useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const errorMessage = Object.values(errors).join('\n');
      alert(`Có lỗi xảy ra:\n${errorMessage}`);
    }
  }, [errors]);


  const fetchServiceEntries = async (id: string) => {
    const response = await axios.get(`/api/maintainance-work-order/${id}/service-entries`);
    const serviceEntries = response.data;
    setServiceEntries(serviceEntries);
  }

  const fetchAvailableServiceEntries = async () => {  
    const response = await axios.get('/api/maintainance-service-entry');
    const availableServiceEntries = response.data;
    setAvailableServiceEntries(availableServiceEntries);
  };

  React.useEffect(() => {
    if (open) {
      if (maintainanceWorkOrderData) {
        setFormData({
          ...maintainanceWorkOrderData,
          statusId: maintainanceWorkOrderData.statusId || undefined,
          activeDate: formatDate(maintainanceWorkOrderData.activeDate),
          approvedDate: formatDate(maintainanceWorkOrderData.approvedDate),
          completedDate: formatDate(maintainanceWorkOrderData.completedDate),
          closedDate: formatDate(maintainanceWorkOrderData.closedDate),
          dueDate: formatDate(maintainanceWorkOrderData.dueDate), 

        });
  
        // Parse custom fields if present
        if (maintainanceWorkOrderData.customFields) {
          try {
            const parsedCustomFields =
              typeof maintainanceWorkOrderData.customFields === 'string'
                ? JSON.parse(maintainanceWorkOrderData.customFields)
                : maintainanceWorkOrderData.customFields;
            setCustomFieldsData(parsedCustomFields);
          } catch (error) {
            console.log('Error parsing custom fields:', error);
          }
        }
  
        setInChargeId(maintainanceWorkOrderData.inChargeId || null);
        setVehicleId(maintainanceWorkOrderData.vehicleId || null);
        setStatusId(maintainanceWorkOrderData.statusId || null);
  
        // Fetch service entries only if `maintainanceWorkOrderData.id` is present
        if (maintainanceWorkOrderData.id) {
          fetchServiceEntries(maintainanceWorkOrderData.id);
        }
      } else {
        // Reset fields for new work order
        setFormData({});
        setInChargeId(null);
        setVehicleId(null);
        setStatusId(null);
        setCustomFieldsData({});
  
        // Fetch next vehicle number for new work orders
        fetchNextVehicleNumber();
      }
  
      // Fetch available service entries
      fetchAvailableServiceEntries();
    }
  }, [open, maintainanceWorkOrderData]);




  const handleCustomFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Check if the input is a checkbox or a regular text input
    setCustomFieldsData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,  // If checkbox, store as boolean; else, store the value
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Handle checkbox separately to update the state with boolean values
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const formFields: FormField[] = fields.map(field => {
    if (field.name === 'workOrderNumber') {
      return {
        label: 'Mã số công việc',
        name: 'workOrderNumber',
        type: 'text',
        value: formData.workOrderNumber || '',
        required: true,
        handleChange: handleChange, // Make read-only by not assigning handleChange if needed
        error: errors['workOrderNumber'],
      };
    } else  {
      return {
        label: field.label,
        name: field.name,
        type: field.type === 'inChargeType' ? 'select' : field.type,
        options: field.name === 'inChargeType'
          ? [
              { label: 'Thợ sửa chữa', value: 'TECHNICIAN' },
              { label: 'Xưởng ngoài', value: 'VENDOR' },
            ]
          : undefined,
        value: formData[field.name as keyof MaintainanceWorkOrder] || customFieldsData[field.name] || '',
        required: field.required,
        handleChange: field.canDelete ? handleCustomFieldChange : handleChange,
        error: errors[field.name],
      };
    }
  });

  const handleDialogClose = () => { 

    onClose();
    setErrors({});
    setCustomFieldsData({});
    setServiceEntries([]);
    setAvailableServiceEntries([]);
    setInChargeId(null);
    setVehicleId(null);
    setStatusId(null);
  };

  const [activeTab, setActiveTab] = React.useState(0);  

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };  
  
  return (
    <CustomDialog open={open} title="Thêm/Chỉnh sửa thông tin việc cần làm" onClose={handleDialogClose} manageFields={() => setFieldManagementOpen(true)}
    onSubmit={handleSubmit} submitLabel="Lưu" dialogWidth={isEditMode ? '1600px' : '800px'} 
    dialogMaxWidth='95%'>

      <div className="row">
        <div className={isEditMode ? "col-md-6" : "col-md-12"}>
          <FormThings
            formFields={formFields}
            status={workOrderStatus}
            statusId={statusId} 
            setStatusId={setStatusId}
            vehicles={vehicles}
            inChargeId={inChargeId}
            setInChargeId={setInChargeId}
            vehicleId={vehicleId}
            setVehicleId={setVehicleId}
            technicians={technicians}
            suppliers={suppliers}
            inChargeType={formData.inChargeType} // Pass the inChargeType from the form data
          />
        </div>

        {isEditMode && formData.id && (
          <div className='col-md-6'>
            <Typography variant="h5" gutterBottom>Các dữ liệu liên quan</Typography>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={activeTab} onChange={handleTabChange} aria-label="Work order related data" variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile  // MUI v5+ only
                >
                <Tab label="Danh sách nhiệm vụ liên quan" />
              </Tabs>

            </Box>

            <TabPanel value={activeTab} index={0}>  
              {workOrderId ? (
                <>
                  <MaintainanceServiceEntrysPage
                    workOrderId={workOrderId} 
                  />
                </>
              ) : (
                <Typography>Chưa có dữ liệu...</Typography> // Prevents rendering with an incorrect ID
              )}

            </TabPanel>
            
          </div>
          )}
      </div>


    <FieldManagementDialog
        open={isFieldManagementOpen}
        onClose={() => setFieldManagementOpen(false)}
        fields={fields}
        onFieldSubmit={handleFieldManagementSubmit}
        addFieldToDatabase={addFieldToDatabase}
        updateFieldInDatabase={updateFieldInDatabase}
        deleteFieldFromDatabase={deleteFieldFromDatabase}

      />

    </CustomDialog>
  );
};
