import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form'; 
import styles from './LoginForm.module.css'; 

interface LoginFormInputs {
  username: string;
  password: string;
}

const LoginForm: React.FC = () => {
  const { register, handleSubmit, formState: { errors } } = useForm<LoginFormInputs>();
  const [error, setError] = React.useState('');
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<LoginFormInputs> = async (data) => {
    setError('');
    try{
      const response = await axios.post('/api/login', data);
      localStorage.setItem('token', response.data.token);
      navigate('/dashboard');
    } catch{
      setError('Invalid login credentials'); 
    }
  }


  return (
    <div className={`${styles.container} flex justify-center items-center min-h-screen`}>
    <div className={styles.screen}>
      <div className={styles.screen__content}>
        <form className={styles.login} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.login__field}>
            <i className={`${styles.login__icon} fas fa-user`}></i>
            <input
              type="text"
              className={styles.login__input}
              placeholder="Tên tài khoản"
              {...register('username', { required: 'Cần có tên tài khoản' })}
            />
            {errors.username && <p className={styles.error}>{errors.username.message}</p>}
          </div>
          <div className={styles.login__field}>
            <i className={`${styles.login__icon} fas fa-lock`}></i>
            <input
              type="password"
              className={styles.login__input}
              placeholder="Mật khẩu"
              {...register('password', { required: 'Cần phải có mật khẩu' })}
            />
            {errors.password && <p className={styles.error}>{errors.password.message}</p>}
          </div>
          {error && <p className={styles.error}>{error}</p>}
          <button className={`${styles.button} ${styles.login__submit}`} type="submit">
            <span className={styles.button__text}>Đăng nhập</span>
            <i className={`${styles.button__icon} fas fa-chevron-right`}></i>
          </button>
        </form>
       
      </div>
      <div className={styles.screen__background}>
        <span className={`${styles.screen__background__shape} ${styles.screen__background__shape4}`}></span>
        <span className={`${styles.screen__background__shape} ${styles.screen__background__shape3}`}></span>		
        <span className={`${styles.screen__background__shape} ${styles.screen__background__shape2}`}></span>
        <span className={`${styles.screen__background__shape} ${styles.screen__background__shape1}`}></span>
      </div>
    </div>
  </div>
  );
};

export default LoginForm;
