import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { Chip, Button } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Download as DownloadIcon } from '@phosphor-icons/react';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { Upload as UploadIcon } from '@phosphor-icons/react';
import { GenericFilters } from '@/components/dashboard/filter/genericFilter';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1';
import axios, { AxiosResponse } from 'axios';
import { DynamicDialog } from '@/components/subPlace/statusDialog';
import { timeIntervalUnit }  from '@/hooks/mapping'; // Import the function
import { InspectionItemsDialog } from '@/components/inspection/InspectionItemsDialog'; // Import the component
import { InspectionCategory, PriorityStatus } from '@/types/dialog'; // Import the component
import { InspectionFormDialog } from '@/components/inspection/InspectionFormDialog'; // Import the component    
import { InspectionIssuesProgramDialog } from '@/components/inspection/InspectionIssuesProgramDialog'; // Import the component
import { fi } from 'date-fns/locale';
import dayjs, { Dayjs } from 'dayjs';

export interface IssuePriorityStatus {
    id: string;
    name: string;
    description: string;
    color: string;
    createdAt: Date;
    udpatedAt: Date;    
}

export interface InspectionItems {
    id: string;
    categoryId: number;
    name: string;
    description: string;
    requireCommentOnFail: boolean;
    createdAt: Date;

    category?: InspectionCategory;  
}

export interface InspectionForm {
    id: string;
    name: string;
    description: string;
    inspectionItemsId: number[];
}

export interface InspectionPrograms {
    id: string;
    name: string;
    description: string;
    primaryMeterType?: string;
    secondaryMeter_flg: boolean;
    secondaryMeterType?: string;
    createdAt: Date;
    updateAt: Date;
    inspectionSchedules?: InspectionSchedule[];
}

export interface InspectionSchedule {
    id: string;
    inspectionProgramId: number;
    inspectionFormIds: number[];
    primaryMeterInterval?: number;
    secondaryMeterInterval?: number;
    timeInterval?: number;
    timeIntervalUnit?: string;
    createdAt: Date;
    updateAt: Date;
}

function applyPagination<T>(rows: T[], page: number, rowsPerPage: number): T[] {
    return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export default function InspectionsIssuesSetupPage(): React.JSX.Element {
    const [inspectionItems, setInspectionItems] = React.useState<InspectionItems[]>([]);    
    const [inspectionForm, setInspectionForm] = React.useState<InspectionForm[]>([]);
    const [inspectionPrograms, setInspectionPrograms] = React.useState<InspectionPrograms[]>([]);
    const [priorityStatus, setPriorityStatus] = React.useState<IssuePriorityStatus[]>([]);

    const [filteredData, setFilteredData] = React.useState({
        inspectionItems: [] as InspectionItems[],
        inspectionForm: [] as InspectionForm[],
        inspectionPrograms: [] as InspectionPrograms[],
        priorityStatus: [] as IssuePriorityStatus[]
      });
      
        
    type TableKey = "inspectionItems" | "inspectionForm" | "priorityStatus" | "inspectionPrograms";

    const paginationStatesInitial: Record<TableKey, { page: number; rowsPerPage: number }> = {   
        inspectionItems: { page: 0, rowsPerPage: 5 },
        inspectionForm: { page: 0, rowsPerPage: 5 },
        priorityStatus: { page: 0, rowsPerPage: 5 },
        inspectionPrograms: { page: 0, rowsPerPage: 5 }
    };

    const [paginationStates, setPaginationStates] = React.useState(paginationStatesInitial);

    const tableLabels = [
        { key: "inspectionItems", label: "Các mục kiểm tra" },
        { key: "inspectionForm", label: "Mẫu kiểm tra" },
        { key: "priorityStatus", label: "Trạng thái ưu tiên của vấn đề" }, 
        { key: "inspectionPrograms", label: "Chương trình kiểm tra" }
    ];

    const [visibleTables, setVisibleTables] = React.useState({
        inspectionItems: true,
        inspectionForm: true,
        priorityStatus: true,
        inspectionPrograms: true
    });

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const [inspectionItemsRes, inspectionFormRes, priorityStatusRes , inspectionProgramsRes] = await Promise.all([
                    axios.get('/api/inspection-items'),
                    axios.get('/api/inspection-forms'),
                    axios.get('/api/priority-statuses'),
                    axios.get('/api/inspection-programs')
                ]);

                setInspectionItems(inspectionItemsRes.data);
                setFilteredData((prev) => ({ ...prev, inspectionItems: inspectionItemsRes.data }));

                setInspectionForm(inspectionFormRes.data);
                setFilteredData((prev) => ({ ...prev, inspectionForm: inspectionFormRes.data }));
                
                setPriorityStatus(priorityStatusRes.data);  
                setFilteredData((prev) => ({ ...prev, priorityStatus: priorityStatusRes.data }));

                setInspectionPrograms(inspectionProgramsRes.data);
                setFilteredData((prev) => ({ ...prev, inspectionPrograms: inspectionProgramsRes.data }));
            } catch (error) {
                console.error('error fetching the data from the server', error);
            }
        };
        fetchData();
    }, []);

    const handleFilterChange = (table: string, column: string, searchTerm: string | boolean | Dayjs | null) => {
        const tableConfig = tablesConfig.find((t) => t.key === table);
      
        if (!tableConfig) {
          console.warn(`Table configuration for "${table}" not found.`);
          return;
        }
      
        const { data, setFilteredData } = tableConfig;
      
        if (!searchTerm && searchTerm !== false) {
          // Reset to original data if searchTerm is null or empty
          setFilteredData(data);
          return;
        }
      
        const filtered = data.filter((row) => {
          const value = row[column as keyof typeof row];
      
          if (typeof value === 'string' && typeof searchTerm === 'string') {
            // Handle string values
            return value.toLowerCase().includes(searchTerm.toLowerCase());
          }
      
          if (typeof value === 'boolean' && typeof searchTerm === 'boolean') {
            // Handle boolean values
            return value === searchTerm;
          }
      
          return false;
        });
      
        setFilteredData(filtered);
      };
      
      
    const handlePageChange = (tableKey: keyof typeof paginationStates, newPage: number) => {
        setPaginationStates(prev => ({
            ...prev,
            [tableKey]: { ...prev[tableKey], page: newPage }
        }));  
    };

    const handleRowsPerPageChange = (tableKey: keyof typeof paginationStates, rowsPerPage: number) => {
        setPaginationStates(prev => ({  
            ...prev,
            [tableKey]: { ...prev[tableKey], rowsPerPage }
        }));
    };

    const handleToggleTable = (tableKey: keyof typeof visibleTables) => {
        setVisibleTables(prev => ({
            ...prev,
            [tableKey]: !prev[tableKey]
        }));

    };

    const tablesConfig: Array<{
        key: TableKey;
        title: string;
        data: any[];
        filteredData: any[];    
        setFilteredData: (data: any[]) => void;
        visible: boolean;
        filterColumns: { value: string; label: string }[];  
        tableColumns: TableColumn<any>[];    
    }> = [
        {
            key: "inspectionItems",
            title: "Mục kiểm tra",
            data: inspectionItems,
            filteredData: filteredData.inspectionItems,
            setFilteredData: (data: InspectionItems[]) => setFilteredData( prev => ({ ...prev, inspectionItems: data })),
            visible: visibleTables.inspectionItems,
            filterColumns: [
                { value: "name", label: "Tên" },
                { value: "description", label: "Mô tả" },
                // search by category name
                { value: "category", label: "Danh mục" }
            ],
            tableColumns: [
                { label: "Tên", field: 'name' },
                { label: "Ghi chú", field: 'description' },
                { 
                    label: "Cần phải note khi có vấn đề", 
                    field: 'requireCommentOnFail',
                    customRender: (row) => row.requireCommentOnFail == true ? "Có" : "Không"
                },
                {
                    label: 'Danh mục',
                    field: 'category',
                    customRender: (row) => row.category?.name || "N/A"   
                },
                
                { label: "Ngày tạo", field: 'createdAt', isDate: true }
            ] as TableColumn<InspectionItems>[]
        },
        {
            key: "inspectionForm",
            title: "Mẫu kiểm tra",
            data: inspectionForm,
            filteredData: inspectionForm,
            setFilteredData: setInspectionForm,
            visible: visibleTables.inspectionForm,
            filterColumns: [
                { value: "name", label: "Tên" },
                { value: "description", label: "Ghi Chú" },
            ],
            tableColumns: [
                { label: "Tên", field: 'name' },
                { label: "Ghi chú", field: 'description' },
                { label: "Ngày tạo", field: 'createdAt', isDate: true }
            ] as TableColumn<InspectionForm>[]
        },
        {
            key: "priorityStatus",
            title: "Trạng thái ưu tiên của vấn đề",
            data: priorityStatus,
            filteredData: filteredData.priorityStatus,
            setFilteredData: (data: IssuePriorityStatus[]) => setFilteredData(prev => ({ ...prev, priorityStatus: data })),
            visible: visibleTables.priorityStatus,
            filterColumns: [
                { value: "name", label: "Tên" },
                { value: "description", label: "Ghi chú" },
            ],
            tableColumns: [
                { label: 'Tên', field: 'name' },
                { label: 'Mô tả', field: 'description' },
                {
                    label: 'Màu',
                    field: (row) => (
                        <Chip
                            style={{
                                backgroundColor: row.color || '#ccc', // Use the color from the row
                                color: '#fff',                         // White text for contrast
                                borderRadius: '5px',
                                padding: '5px 10px',
                                fontWeight: 'bold',
                            }}
                        />
                    ),
                },

            ] as TableColumn<PriorityStatus>[]
        },
        {
            key: "inspectionPrograms",
            title: "Gói kiểm tra",
            data: inspectionPrograms,
            filteredData: inspectionPrograms,
            setFilteredData: setInspectionPrograms,
            visible: visibleTables.inspectionPrograms,
            filterColumns: [
                { value: "name", label: "Tên" },
                { value: "description", label: "Ghi chú" },
            ],
            tableColumns: [
                { label: "Tên", field: 'name' },
                { label: "Ghi chú", field: 'description' },
                { label: 'Khoảng đo chính', field: 'primaryMeterType', customRender: (row) => timeIntervalUnit(row.primaryMeterType || '') },
                { label: 'Khoảng đo phụ', field: 'secondaryMeterType', customRender: (row) => timeIntervalUnit(row.secondaryMeterType || '') },
            ] as TableColumn<InspectionPrograms>[]
        },
    ];

    const visibleTablesCount = tablesConfig.filter((table) => table.visible).length;

    const dialogConfig = {
        priorityStatus: {
            title: 'Thêm/sửa trạng thái ưu tiên của vấn đề',
            fields: [
                { name: 'name', label: 'Tên', type: 'text' },
                { name: 'description', label: 'Mô tả', type: 'text' },
                { name: 'color', label: 'Màu',  type: 'color' },
            ]
        }
    };

    const [openDialog, setOpenDialog] = React.useState(false); 
    const [selectedTable, setSelectedTable] = React.useState<keyof typeof  dialogConfig | 'inspectionForm' | 'inspectionItems' | 'inspectionPrograms' | null>(null);
    const [selectedData, setSelectedData] = React.useState<any>(null);

    const handleEditDialog = (tableKey: keyof typeof dialogConfig | 'inspectionForm' | 'inspectionItems' | 'inspectionPrograms', rowData: any) => {
        setSelectedTable(tableKey);
        setSelectedData(rowData);
        setOpenDialog(true);
    };

    const handleOpenDialog = (tableKey: keyof typeof dialogConfig | 'inspectionForm' | 'inspectionItems' | 'inspectionPrograms') => {  
        setSelectedTable(tableKey);
        setSelectedData(null);
        setOpenDialog(true);
    };

    const mappingForTabl = {
        inspectionItems: 'inspection-items',
        inspectionForm: 'inspection-forms',
        priorityStatus: 'priority-statuses',
        inspectionPrograms: 'inspection-programs'
    }

    const handleSetData = (tableKey: keyof typeof dialogConfig , data: any) => {
        if (tableKey === 'priorityStatus') {
            setPriorityStatus(data);
        }
    }

    const handleAddOrEditInspectionItems = async (data: any) => {
        try {
            let response: AxiosResponse<InspectionItems>;
            if (selectedData) {
                response = await axios.put(`/api/inspection-items/${selectedData.id}`, data);
                
                const updatedItem = response.data;
    
                // Update the state to reflect the updated item
                setFilteredData(prev => ({
                    ...prev,
                    inspectionItems: prev.inspectionItems.map((item) => (item.id === updatedItem.id ? updatedItem : item)),
                }));
                setInspectionItems(prev => prev.map(item => (item.id === updatedItem.id ? updatedItem : item)));
            } else {
                response = await axios.post('/api/inspection-items', data);
    
                const newItem = response.data;
    
                // Add the new item to the state
                setFilteredData(prev => ({
                    ...prev,
                    inspectionItems: [newItem, ...prev.inspectionItems],
                }));
    
                setInspectionItems(prev => [newItem, ...prev]);
            }
            
            setSelectedData(null);
            setOpenDialog(false);
         
        } catch (error: any) {
            console.error('Error adding/editing inspection:', error);
            if (error.response && error.response.status === 409) {
                alert('Tên mục kiểm tra đã tồn tại, vui lòng chọn tên khác');
              } else {
                alert('Có lỗi xảy ra, vui lòng thử lại sau');
            }  
        }
    };
    
    const handleAddOrEditInspectionForm = async (udpatedData: any) => { 
        try {

            let response: AxiosResponse<InspectionForm>;    

            if (selectedData) {
                response = await axios.put(`/api/inspection-forms/${selectedData.id}`, udpatedData);
                
                const updatedItem = response.data;
    
                // Update the state to reflect the updated item
                setFilteredData(prev => ({
                    ...prev,
                    inspectionForm: prev.inspectionForm.map((item) => (item.id === updatedItem.id ? updatedItem : item)),
                }));
                setInspectionForm(prev => prev.map(item => (item.id === updatedItem.id ? updatedItem : item)));
            } else {
                response = await axios.post('/api/inspection-forms', udpatedData);
    
                const newItem = response.data;
    
                // Add the new item to the state
                setFilteredData(prev => ({
                    ...prev,
                    inspectionForm: [newItem, ...prev.inspectionForm],
                }));
    
                setInspectionForm(prev => [newItem, ...prev]);
            }

            setOpenDialog(false);
            setSelectedData(null);
        } catch (error: any) {
            console.error('Error adding/editing inspection:', error);
            if (error.response && error.response.status === 409) {
                alert('Tên Mẫu kiểm tra đã tồn tại, vui lòng chọn tên khác');
              } else {
                alert('Có lỗi xảy ra, vui lòng thử lại sau');
            }  
        }

    };

    const handleAddOrEditStatuses = async (updatedData: any) => {
        try {
            const formData = new FormData();

            if (selectedTable === 'priorityStatus') {   
                formData.append('name', updatedData.name);
                formData.append('description', updatedData.description);
                formData.append('color', updatedData.color);
            } else {
                return alert('Bảng không hợp lệ');
            }

            const apiRoute = `/api/${mappingForTabl[selectedTable]}`; 

            // check if it is an edit or add
            if (selectedData) {
                // Edit
                const response = await axios.put(`${apiRoute}/${selectedData.id}`, formData);
                const updatedData = response.data;   
                const updatedDataIndex = filteredData[selectedTable].findIndex((item) => item.id === updatedData.id);
                const updatedDataArray = [...filteredData[selectedTable]];
                updatedDataArray[updatedDataIndex] = updatedData;
                setFilteredData(prev => ({ ...prev, [selectedTable]: updatedDataArray }));
                handleSetData(selectedTable, updatedDataArray);
                setSelectedData(null);
            } else {
                // Add
                const response = await axios.post(apiRoute, formData);
                const newData = response.data;
                setFilteredData(prev => ({ ...prev, [selectedTable]: [...prev[selectedTable], newData] }));
                handleSetData(selectedTable, [...filteredData[selectedTable], newData]);
                setSelectedData(null);  
            }
            setOpenDialog(false);
        } catch (error: any) {
            console.error('Lỗi thêm/cập nhập nhà cung cấp:', error); 
            if (error.response && error.response.status === 409) {
                alert('Tên trạng thái đã tồn tại, vui lòng chọn tên khác');
              } else {
                alert('Lỗi thêm/cập nhập trạng thái. Hãy kiểm tra các ô lại.');
            }  
        }

    };

    const handleAddOrEditInspectionPrograms = async (updatedData: any, existingInspectionIssuesPrograms: InspectionSchedule[]) => {
        try {
            let response: AxiosResponse<InspectionPrograms>;

            if (updatedData.id) {
                response = await axios.post('/api/inspection-programs',  {
                    id: updatedData.id,
                    name: updatedData.name,
                    description: updatedData.description,   
                    primaryMeterType: updatedData.primaryMeterType,
                    secondaryMeterType: updatedData.secondaryMeterType,
                    secondaryMeter_flg: updatedData.secondaryMeter_flg,
                });

                const inpsectionProgramId = response.data.id;   

                const existingIds  = existingInspectionIssuesPrograms.map((schedule) => schedule.id);

                if (updatedData.inspectionSchedules && updatedData.inspectionSchedules.length > 0) {
                    for (const schedule of updatedData.inspectionSchedules) {
                        if (existingIds.includes(schedule.id)) {
                            await axios.put(`/api/inspection-programs/${inpsectionProgramId}/inspection-schedules/${schedule.id}`, {
                                inspectionFormIds: schedule.inspectionFormIds,
                                primaryMeterInterval: schedule.primaryMeterInterval,
                                secondaryMeterInterval: schedule.secondaryMeterInterval,
                                timeInterval: schedule.timeInterval,
                                timeIntervalUnit: schedule.timeIntervalUnit,
                            });
                        } else {
                            await axios.post(`/api/inspection-programs/${inpsectionProgramId}/inspection-schedules`, {
                                inspectionFormIds: schedule.inspectionFormIds,
                                primaryMeterInterval: schedule.primaryMeterInterval,
                                secondaryMeterInterval: schedule.secondaryMeterInterval,
                                timeInterval: schedule.timeInterval,
                                timeIntervalUnit: schedule.timeIntervalUnit,
                            });
                        }
                    }

                    // Identify schedules to delete
                    // const updatedIds: string[] = updatedData.insepctionSchedules.map((schedule: InspectionSchedule) => schedule.id);
                    const updatedIds: string[] = updatedData.inspectionSchedules.map((schedule: any) => schedule.id);
                    const schedulesToDelete = existingIds.filter((id) => !updatedIds.includes(id));
            
                    if (schedulesToDelete.length > 0) {
                        for (const scheduleId of schedulesToDelete) {
                        await axios.delete(`/api/inspection-programs/${inpsectionProgramId}/inspection-schedules/${scheduleId}`);
                        }
                    }
                } else {
                    for (const schedule of existingInspectionIssuesPrograms) {
                        await axios.delete(`/api/inspection-programs/${inpsectionProgramId}/inspection-schedules/${schedule.id}`);
                    }
                }
            } else {
                response = await axios.post('/api/inspection-programs', {
                    name: updatedData.name,
                    description: updatedData.description,
                    primaryMeterType: updatedData.primaryMeterType,
                    secondaryMeterType: updatedData.secondaryMeterType,
                    secondaryMeter_flg: updatedData.secondaryMeter_flg,
                });

                const inpsectionProgramId = response.data.id;

                if (updatedData.inspectionSchedules && updatedData.inspectionSchedules.length > 0) {
                    for (const schedule of updatedData.inspectionSchedules) {
                        await axios.post(`/api/inspection-programs/${inpsectionProgramId}/inspection-schedules`, {
                            inspectionFormIds: schedule.inspectionFormIds,
                            primaryMeterInterval: schedule.primaryMeterInterval,
                            secondaryMeterInterval: schedule.secondaryMeterInterval,
                            timeInterval: schedule.timeInterval,
                            timeIntervalUnit: schedule.timeIntervalUnit,
                        });
                    }
                }
            }

            window.location.reload();

            // Update the state and data in the table
            const updatedPrograms = updatedData.id
                ? inspectionPrograms.map((program) =>
                    program.id === response.data.id ? response.data : program
                )
                : [...inspectionPrograms, response.data];
          

            setInspectionPrograms(updatedPrograms);
            setFilteredData((prev) => ({ ...prev, inspectionPrograms: updatedPrograms }));  



            setSelectedData(null);
            setOpenDialog(false);
        } catch (error) {
            alert('Có lỗi xảy ra, vui lòng thử lại sau');
            console.error('Error adding/editing inspection:', error);
        }
    } 

    const handleDeleteDefault = async (tableKey: keyof typeof dialogConfig, id: string) => {
        const confirmed = window.confirm('Bạn có chắc chắn muốn xoá mục này?'); 

        if (confirmed) {
            try {
                const apiRoute = `/api/${mappingForTabl[tableKey]}`;

                await axios.delete(`${apiRoute}/${id}`); // Send delete request to the API
                
                setFilteredData((prev) => ({    
                    ...prev,
                    [tableKey]: (prev[tableKey] as any[]).filter((item) => item.id !== id),
                }));

                // Update the specific state as well (optional if maintaining separate state variables)
                handleSetData(tableKey, (filteredData[tableKey] as any[]).filter((item) => item.id !== id));
                
                alert('Xóa thành công! Tải lại trang để cập nhật dữ liệu mới.');

                window.location.reload();
            } catch (error) {
                alert('Lỗi khi xoá mục. Hãy thử lại sau.');
                console.error('Lỗi khi xoá mục:', error);
            }
        }
    };

    const handleDeleteDefaultSelected = async (tableKey: keyof typeof dialogConfig,  selectedIds: string[]) => {
        const confirmed = window.confirm('Bạn có chắc chắn muốn xoá các mục đã chọn?');

        if (confirmed) {
            try {
                const apiRoute = `/api/${mappingForTabl[tableKey]}/${JSON.stringify(selectedIds)}`;   

                await axios.delete(`/api/${mappingForTabl[tableKey]}/${JSON.stringify(selectedIds)}`);

                alert('Xóa thành công!');
                setFilteredData((prev) => ({
                    ...prev,
                    [tableKey]: (prev[tableKey] as any[]).filter((item) => !selectedIds.includes(item.id)),
                }));

                handleSetData(tableKey, (filteredData[tableKey] as any[]).filter((item) => !selectedIds.includes(item.id)));
            } catch (error) {
                alert('Lỗi khi xoá mục. Hãy thử lại sau.');
                console.error('Lỗi khi xoá mục:', error);
            }
        }
    }

    return (
        <Stack spacing={4}>
          <Typography variant="h4">Thiết lập các dữ liệu phụ</Typography>

          <div className="table-visibility-controls">
            <Typography variant="h5">Các bảng</Typography>
            {tableLabels.map(({ key, label }) => (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    checked={visibleTables[key as keyof typeof visibleTables]}
                    onChange={() => handleToggleTable(key as keyof typeof visibleTables)}
                  />
                }
                label={label} // Use custom label
              />
            ))}
          </div>

          <div className="row">
            {tablesConfig.map(({ key, title, data, filteredData, setFilteredData, visible, filterColumns, tableColumns }, index) =>
                visible ? (
                <div
                    key={title}
                    className={`mb-5 ${
                    visibleTablesCount === 2 ? 'col-md-12' : index === tablesConfig.length - 1 ? 'col-md-12' : 'col-md-6'
                    }`} // Use col-md-12 if only two tables are visible
                >
                    <Stack spacing={3}>
                        <Stack direction="row" spacing={3}>
                            <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                            <Typography variant="h5">{title}</Typography>
                            </Stack>
                            <div>
                            <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={() => handleOpenDialog(key)}>
                                Thêm
                            </Button>
                            </div>
                        </Stack>
                        <GenericFilters
                        columns={filterColumns}
                        onFilterChange={(col, term) => handleFilterChange(key, col, term )}
                        />
                        <GenericTable
                        count={filteredData.length}
                        page={paginationStates[key].page} // Use the specific table's pagination state
                        rows={applyPagination(filteredData, paginationStates[key].page, paginationStates[key].rowsPerPage)}
                        columns={tableColumns as unknown as TableColumn<{ id: string }>[]}
                        rowsPerPage={paginationStates[key].rowsPerPage} // Specific table's rowsPerPage
                        onPageChange={(newPage) => handlePageChange(key, newPage)} // Pass `key` to identify table
                        onRowsPerPageChange={(newRows) => handleRowsPerPageChange(key, newRows)} // Pass `key` for rows per page change
                        onEdit={(id) => {
                            const rowData = data.find((item) => item.id === id); // Find the row data by id
                            handleEditDialog(key as keyof typeof dialogConfig, rowData);
                        }}
                        // Set onDelete explicitly to undefined for statuses
                        onDelete={ (id) => handleDeleteDefault(key as keyof typeof dialogConfig, id)
                        }
                        onDeleteSelected={(ids) => handleDeleteDefaultSelected(key as keyof typeof dialogConfig, ids)} // Bulk delete
                        />
                    </Stack>
                </div>
                ) : null
            )}
          </div>

        {selectedTable === 'inspectionItems' && (
            <InspectionItemsDialog
                open={openDialog}
                data={selectedData}
                title="Thêm/sửa mục kiểm tra"
                onClose={() => setOpenDialog(false)}
                onSubmit={handleAddOrEditInspectionItems}
            />
        )};

         {selectedTable === 'inspectionForm' && (
            <InspectionFormDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                data={selectedData}
                onSubmit={handleAddOrEditInspectionForm}
                availableInspectionItems={inspectionItems}  
            />
        )};

        {selectedTable === 'inspectionPrograms' && (
            <InspectionIssuesProgramDialog  
                open={openDialog}
                data={selectedData}
                onClose={() => setOpenDialog(false)}
                onSubmit={(updatedData, existingInspectionIssuesPrograms) => { handleAddOrEditInspectionPrograms(updatedData, existingInspectionIssuesPrograms)}}
                availableInspectionForms={inspectionForm}
            />
        )}; 

        { selectedTable && selectedTable in dialogConfig && (
             <DynamicDialog
             open={openDialog}
             title={dialogConfig[selectedTable as keyof typeof dialogConfig].title}
             fields={dialogConfig[selectedTable as keyof typeof dialogConfig].fields}
             data={selectedData}
             onClose={() => setOpenDialog(false)}
             onSubmit={handleAddOrEditStatuses}
         />

        )}



        </Stack>
    );

};


