import React, { useState } from 'react';
import { TextField, Typography } from '@mui/material';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { CustomSelectWithAdd, CustomSelectWithAddProps } from '@/components/dashboard/customSelectWithAdd/customSelectWithAdd';

const FormThings = ({
  formFields,
  vehicles,
  vehicleId,
  setVehicleId,
  inspectionForms,
  inspectionFormId,
  setInspectionFormId,

} : {
  formFields: FormField[];
  vehicles: any[];
  vehicleId: number | null;
  setVehicleId: (id: number) => void;
  inspectionForms: any[];
  inspectionFormId: number | null;
  setInspectionFormId: (id: number) => void;
}) => {

  const selectedVehicle = vehicles.find((vehicle) => vehicle.id === vehicleId);

  return (
    <form>
      {formFields.map((field, index) => (
        <div key={index}>
          {/* Render form fields */}
          {index === 0 && (
            <CustomSelectWithAdd
              label="Phương tiện *"
              items={vehicles}
              value={vehicleId || 0}
              onChange={setVehicleId}
              manageItem="/dashboard/vehicles"
            />
          )}

          {/* Helper text for the selected vehicle */}
          {index === 0 && selectedVehicle && (
            <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
              Odo chính của xe: {selectedVehicle.primaryMeter} {selectedVehicle.primaryMeterType}
            </Typography>
          )}

          {index === 0 && (
            <CustomSelectWithAdd
              label="Chọn đơn báo cáo  *"
              items={inspectionForms}
              value={inspectionFormId || 0}
              onChange={setInspectionFormId}
              description_flg={true}
            />
          )}

          <FormFields fields={[field]} />
       
        </div>
      ))}

    </form>

  );
}

export default FormThings;
