import * as React from 'react';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import {  Part } from './PartsPage'; // Import your customertype
import { ImageUpload } from '@/components/dashboard/imageUpload/ImageUpLoad';
import { CustomSelectWithAdd } from '@/components/dashboard/customSelectWithAdd/customSelectWithAdd';
import axios from 'axios';
import { FieldManagementDialog, DialogField } from '@/components/dashboard/fieldManagement/FieldManagement';
import useImageDownload from '@/hooks/image-download'; // Import the hook
import  useCustomFieldsUpdate from '@/hooks/field-managementSubmit'; // Import the hook
import { UseFetchManufacturer, UseFetchPartMeasurementUnit } from '@/hooks/fetch-relationship-field'; // Import the hooks
import { formatDate } from '@/hooks/format-date';

interface PartDialogProps {
  open: boolean;
  onClose: () => void;  
  onSubmit: (partData: Partial<Part>, imageFile?: File) => void;
  partData?: Partial<Part>;
  refreshData: () => void;
}

export const PartDialog: React.FC<PartDialogProps> = ({ open, onClose, onSubmit, partData, refreshData }) => {
  const isEditMode = !!partData;
  const [fields, setFields] = React.useState<DialogField[]>([]);
  const [formData, setFormData] = React.useState<Partial<Part>>(partData || {});

  // the field image
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [imageFile, setImageFile] = React.useState<File | null>(null);
  const [passwordInput, setPasswordInput] = React.useState(''); 
  const [imagePreview, setImagePreview] = React.useState<string | null>(null);
  const [imageError, setImageError] = React.useState<string | null>(null);
  const [isPictureDeleted, setIsPictureDeleted] = React.useState(false); // Flag for picture deletion

  //field management
  const [isFieldManagementOpen, setFieldManagementOpen] = React.useState(false);

  // custom field 
  const [customFieldsData, setCustomFieldsData] = React.useState<Record<string, any>>({});

  const { manufacturers, addManufacturer, editManufacturer, deleteManufacturer } = UseFetchManufacturer();
  const { partMeasurementUnits } = UseFetchPartMeasurementUnit();

  const [ manufacturerId, setManufacturerId ] = React.useState<number | null>(null);
  const [ measurementUnitId, setMeasurementUnitId ] = React.useState<number | null>(null);

  const [partNumberLoading, setPartNumberLoading] = React.useState(false);  

  const fetchNextPartNumber = async () => {
    try {
      setPartNumberLoading(true);
      const response = await axios.get('/api/parts/next-part-number');
      const nextPartNumber = response.data.nextPartNumber;
      setFormData((prev) => ({ ...prev, partNumber: nextPartNumber })); 

    } catch (error) {
      console.error('Error fetching next part number:', error);
    } finally {
      setPartNumberLoading(false);  
    }
  };

  React.useEffect(() => {
    fetchFields();
  }, []);

  const fetchFields = async () => {
    try {
      const response = await axios.get('/api/parts/part-dialog-fields'); 
      setFields(response.data);  
    } catch (error) {
      console.error('Error fetching fields:', error);
    }
  };

  const addFieldToDatabase = async (field: DialogField): Promise<DialogField> => {  
    try {
      const response = await axios.post('/api/parts/part-dialog-fields', field);
      return response.data;
    } catch (error) {
      console.error('Failed to add dialog field:', error);
      return field;
    }
  }

  const updateFieldInDatabase = async (field: DialogField): Promise<DialogField> => {
    try {
      const response = await axios.put(`/api/parts/part-dialog-fields/${field.id}`, field);
      return response.data;
    } catch (error) {
      console.error('Error updating field:', error);
      throw error;
    }
  };

  const deleteFieldFromDatabase = async (id: number): Promise<void> => {
    try {
      await axios.delete(`/api/parts/part-dialog-fields/${id}`);
    } catch (error) {
      console.error('Error deleting field:', error);
      throw error;
    }
  };

  const { handleCustomFieldsUpdate } = useCustomFieldsUpdate();

  const handleFieldManagementSubmit = (updatedFields: DialogField[]) => {
    handleCustomFieldsUpdate(
      fields,                         // Original fields before update
      formData.customFields || {},     // Current custom fields data
      partData?.customFields,      // Customer custom fields
      updatedFields,                   // Updated fields
      setCustomFieldsData,             // Update customFieldsData
      setFormData,                     // Update formData
      setFields,                       // Update fields
      setFieldManagementOpen           // Close dialog
    );

    refreshData();  // <-- Trigger refresh after field management is done
  };

  const handleDeleteImage = async () => {
    if (formData.id) {
      try {
        setIsPictureDeleted(true);
        setImageFile(null);
        setImagePreview(null);
        setFormData({ ...formData, pictureUrl: undefined });
      } catch (error) {
        console.error('Failed to delete image:', error);
      }
    }
  };

  // for download image
  const { handleDownloadImage } = useImageDownload(); // Use the custom hook
  

  const handleSubmit = async () => {
    if (validateForm()) {
      const customFields: Record<string, any> = {};

      fields.forEach((field) => {
        if (field.canDelete) {
          // Merge custom fields with form data
          customFields[field.name] = formData[field.name as keyof typeof formData] || customFieldsData[field.name];
        }
      });      

      if (isPictureDeleted && formData.id) {
        try {
          await axios.delete(`/api/delete-part-picture/${formData.id}`);
        } catch (error) {
          console.error('Failed to delete picture:', error);
        }
      }

      onSubmit({ ...formData, customFields, manufacturerId: manufacturerId ?? undefined, measurementUnitId: measurementUnitId ?? undefined }, imageFile || undefined);

    }
  };

  const validateForm = (): boolean => {
    const newErrors: {  [key: string]: string} = {};

    fields.forEach(field => {
      const fieldValue = formData[field.name as keyof Part];

      if (field.required && (!fieldValue || typeof fieldValue === 'string' && !fieldValue.trim())) {
        newErrors[field.name] = `${field.label} cần có giá trị`;
      }
    });


    setErrors(newErrors);
    // If there are errors, show them in an alert
    if (Object.keys(newErrors).length > 0) {
      const errorMessages = Object.values(newErrors)
        .map((message) => `+ ${message}`) // Add "+ " before each error message
        .join('\n'); // Combine all error messages

      alert(`Các mục bị lỗi:\n${errorMessages}`); // Show alert with errors
    }

    return Object.keys(newErrors).length === 0;
  }

  React.useEffect(() => {
    if (open) {
      if (partData) {
        setFormData({
          ...partData,
          manufacturerId: partData.manufacturerId ?? undefined,
          measurementUnitId: partData.measurementUnitId ?? undefined,
        })

        if (partData.customFields) {
          try{
            // If customFields is a string, parse it. Otherwise, use it as it is.
            const parsedCustomFields = typeof partData.customFields === 'string' ? JSON.parse(partData.customFields) : partData.customFields; 
            setCustomFieldsData(parsedCustomFields);
          } catch(error){
            console.log('Error parsing custom fields:', error);
          }
        }

        setManufacturerId(partData.manufacturerId ?? null); 
        setMeasurementUnitId(partData.measurementUnitId ?? null);

        setIsPictureDeleted(false); 
        setImageFile(null);

        if (partData.pictureUrl) {
          setImagePreview(partData.pictureUrl);
        }

      } else {

        setFormData({});

        setManufacturerId(null);  
        setMeasurementUnitId(null);

        setIsPictureDeleted(false);
        setImageFile(null); 
        setImagePreview(null);  

        setCustomFieldsData({});  

        if (!isEditMode) {
          fetchNextPartNumber();
        }
      }

    }

  }, [open, partData]);

  const handleCustomFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Check if the input is a checkbox or a regular text input
    setCustomFieldsData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,  // If checkbox, store as boolean; else, store the value
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
    // Update the state
    const updatedFormData = {
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    };

    setFormData(updatedFormData); 
  };

  const formFields: FormField[] = fields.map((field) => { 
    if (field.name === 'partNumber') {
      return {
        label: 'Mã số thẻ',
        name: 'partNumber',
        type: 'text',
        value: formData.partNumber || '',
        required: true,
        error: errors['partNumber'],
      };
    } else if ((field.name === 'durationWarranty' || field.name === 'warrantyDescription' ) && !formData.warrantyFlg ) {
      return null;

    } else {
      return {
        label: field.label, 
        name: field.name,
        type: field.type,
        value: formData[field.name as keyof Part] || customFieldsData[field.name] || '',
        required: field.required, 
        handleChange: field.canDelete ? handleCustomFieldChange : handleChange,
        error: errors[field.name],
        multiline: field.type === 'text',

      }

    }
  })
  .filter(Boolean) as FormField[]; // Filter out null values and cast to FormField[]
  
  
  return (
    <CustomDialog open={open} manageFields={() => setFieldManagementOpen(true)} 
    onClose={onClose} title={isEditMode ? 'Chỉnh sửa thẻ' : 'Thêm thẻ'} 
    onSubmit={handleSubmit} submitLabel='Lưu'>

      <ImageUpload
        imageFile={imageFile}
        imagePreview={imagePreview}
        setImageFile={setImageFile}
        setImagePreview={setImagePreview}
        onDeleteImage={handleDeleteImage}
        onDownloadImage={() => handleDownloadImage(formData.pictureUrl)}  // Pass a function without arguments
        setImageError={setImageError}
        imageError={imageError}
      />

      {/* <FormFields fields={formFields} /> */}

      {formFields.map((field, index) => (
        <div key={index}>

          { index === 0 && (
            <CustomSelectWithAdd
              label='Nhà sản xuất'
              items={manufacturers}
              value={manufacturerId || 0}
              onChange={setManufacturerId}
              addItem={addManufacturer}
              editItem={editManufacturer}
              deleteItem={deleteManufacturer}
            />
          )}

          <FormFields fields={[field]} />

          { field.name === 'description' && ( 
            <CustomSelectWithAdd
              label='Đơn vị của linh kiện'
              items={partMeasurementUnits}
              value={measurementUnitId || 0}
              onChange={setMeasurementUnitId}
              manageItem="/dashboard/inventory-management"
            />
          )}
        </div>
      ))}
      
  
      <FieldManagementDialog
        open={isFieldManagementOpen}
        onClose={() => setFieldManagementOpen(false)}
        fields={fields}
        onFieldSubmit={handleFieldManagementSubmit}
        addFieldToDatabase={addFieldToDatabase}
        updateFieldInDatabase={updateFieldInDatabase}
        deleteFieldFromDatabase={deleteFieldFromDatabase}
      />

    </CustomDialog>

  )
}




