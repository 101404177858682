import { Typography } from '@mui/material';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { CustomSelectWithAdd, CustomSelectWithAddProps } from '@/components/dashboard/customSelectWithAdd/customSelectWithAdd';

const FormThings = ({
  formFields,
  fuelTypes,
  fuelTypeId,
  setFuelTypeId,
  addFuelType,
  editFuelType,
  deleteFuelType,
} : {
  formFields: FormField[];
  fuelTypes: any[]; 
  fuelTypeId: number | null;
  setFuelTypeId: (id: number) => void;  
  addFuelType: (item: string) => void;
  editFuelType: (id: number, newName: string) => void;
  deleteFuelType: (id: number) => void;
}) => {
  return (
    <form>
      {formFields.map((field, index) => (
        <div key={index}>
          {/* Render form fields */}
          <FormFields fields={[field]} />

          {index === 0 && (
              <CustomSelectWithAdd
                label='Loại nhiên liệu *'
                items={fuelTypes}
                value={fuelTypeId || 0}
                onChange={setFuelTypeId }
                addItem={addFuelType}
                editItem={editFuelType}
                deleteItem={deleteFuelType}
              />
            )}

        </div>
      ))}
    </form>
  );
};

export default FormThings;
