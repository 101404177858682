export const getInChargeType = (inChargeTypeValue: string ): string => {
    const inChargeType: { [key: string]: string } = {
        'TECHNICIAN': 'Thợ ở xưởng',
        'VENDOR': 'Xưởng ngoài',
    };

    return inChargeType[inChargeTypeValue] || 'Không xác định';
};


export const timeIntervalUnit = (unit: string): string => {
    const timeIntervalUnit: { [key: string]: string } = {
        'hour': 'Giờ',
        'kilometer': 'Kilometer',
        'mile': 'Dặm',  
        'day': 'Ngày',
        'week': 'Tuần',
        'month': 'Tháng',
        'year': 'Năm',
    };

    return timeIntervalUnit[unit] || 'Không xác định';
};

export const unitToDays = (value: number, unit: string): number => {
  switch (unit) {
    case 'day':
      return value;
    case 'week':
      return value * 7;
    case 'month':
      return value * 30;
    case 'year':
      return value * 365;
    default:
      return value; // Fallback to the same value if the unit is unrecognized
  }
};

// mapping for the work order
export const functionMapping = (functionValue: string): string => {
    const mappings: Record<string, string> = {
      // WorkOrderFunction mappings
      DRAFT: 'Dự thảo',
      PENDING: 'Đang xét duyệt',
      APPROVED: 'Đã xét duyệt',
      ACTIVE: 'Đang làm',
      CLOSED: 'Đã đóng',
    NEW: 'Mới tạo',
      IN_PROGRESS: 'Đang làm',
      COMPLETED: 'Đã hoàn thành',

    };
  
    return mappings[functionValue] || ''; // Default to 'Không xác định' for unmapped values
  };
  

// universal status 
export const statusMap = {
  NotDue: { label: 'Chưa đến hạn', color: '#4caf50' }, // Green
  DueSoon: { label: 'Sắp đến hạn', color: '#ff9800' }, // Orange
  Due: { label: 'Đến hạn', color: '#2196f3' }, // Blue  
  Overdue: { label: 'Quá hạn', color: '#f44336' }, // Red
} as const; // Use 'as const' to make this a readonly object with literal types

export const statusOrder = {
  NotDue: 1,
  DueSoon: 2,
  Due: 3,
  Overdue: 4,
};

export const reverseStatusOrder = {
  NotDue: 4,
  Due: 3,
  DueSoon: 2,
  Overdue: 1,
};

export const parentTypeMap = {
  Vehicle: "Xe",
  Assignee: "Nhân viên",
  Customer: "Khách hàng", 
  Supplier: "Nhà cung cấp", 
}

export const statusInspectionMap = {
  Done: { label: 'Đã hoàn thành', color: '#4caf50' }, // Green
  Unfinished: { label: 'Chưa hoàn thành/Chưa đạt', color: '#f44336' }, // Orange
} as const; // Use 'as const' to make this a readonly object with literal types

