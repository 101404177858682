import * as React from 'react';
import { Typography } from '@mui/material';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { ServiceReminder } from './ReminderPage'; 
import axios from 'axios';
import { FieldManagementDialog, DialogField } from '@/components/dashboard/fieldManagement/FieldManagement';
import  useCustomFieldsUpdate from '@/hooks/field-managementSubmit'; // Import the hook
import { UseFetchVechicle, UseFetchServiceTask } from '@/hooks/fetch-relationship-field'; 
import FormThings from './formWithCustomSelectReminderDialog';
import { formatDate } from '@/hooks/format-date';
import { unitToDays } from '@/hooks/mapping';
import ReminderServiceEntries from '@/components/subPlace/ReminderServiceEntriesComponent';  

interface ReminderDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (serviceReminderData: Partial<ServiceReminder>) => void;
  serviceReminderData?: Partial<ServiceReminder>;
  refreshData: () => void; // <-- pass fetchData here
}
 
export const ReminderDialog: React.FC<ReminderDialogProps> = ({ open, onClose, onSubmit, serviceReminderData, refreshData }) => {
  let isEditMode = !!serviceReminderData;
  const [fields, setFields] = React.useState<DialogField[]>([]);
  const [formData, setFormData] = React.useState<Partial<ServiceReminder>>(serviceReminderData || {});
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
 
  //field management
  const [isFieldManagementOpen, setFieldManagementOpen] = React.useState(false);

  // custom field 
  const [customFieldsData, setCustomFieldsData] = React.useState<Record<string, any>>({});

  const {  vehicles } = UseFetchVechicle();
  const { serviceTasks,  addServiceTask, editServiceTask, deleteServiceTask } = UseFetchServiceTask();

  const [vehicleId, setVehicleId] = React.useState<number | null>(null);
  const [serviceTaskId, setServiceTaskId] = React.useState<number | null>(null);

  const [serviceEntriesHistory, setServiceEntriesHistory] = React.useState<any[]>([]);  

  // for the fields and data
  React.useEffect(() => {
    fetchFields();
  }, []);

  React.useEffect(() => {
    if (vehicleId && serviceTaskId) {
      fetchServiceEntriesHistory(vehicleId, serviceTaskId); 
    }
  }, [vehicleId, serviceTaskId]);

  const fetchFields = async () => {
    try {
      const response = await axios.get('/api/service-reminder-dialog-fields');
      setFields(response.data);
    } catch (error) {
      console.error('Failed to fetch dialog fields:', error);
    }
  };

  const fetchServiceEntriesHistory = async (vehicleId: number, serviceTaskId: number) => {
    try {
      const response = await axios.get('/api/service-entries-history', {
        params: { vehicleId, serviceTaskId },
      });
      setServiceEntriesHistory(response.data);
    } catch (error) {
      console.error('Failed to fetch service entries history:', error);
    }
  };

  const addFieldToDatabase = async (field: DialogField): Promise<DialogField> => {  
    try {
      const response = await axios.post('/api/service-reminder-dialog-fields', field);
      return response.data;
    } catch (error) {
      console.error('Failed to add dialog field:', error);
      return field;
    }
  }

  const updateFieldInDatabase = async (field: DialogField): Promise<DialogField> => {
    try {
      const response = await axios.put(`/api/service-reminder-dialog-fields/${field.id}`, field);
      return response.data;
    } catch (error) {
      console.error('Error updating field:', error);
      throw error;
    }
  };

  const deleteFieldFromDatabase = async (id: number): Promise<void> => {
    try {
      await axios.delete(`/api/service-reminder-dialog-fields/${id}`);
    } catch (error) {
      console.error('Error deleting field:', error);
      throw error;
    }
  };

  const { handleCustomFieldsUpdate } = useCustomFieldsUpdate(); // Use the custom

  const handleFieldManagementSubmit = (updatedFields: DialogField[]) => {
    handleCustomFieldsUpdate(
      fields,                         
      formData.customFields || {},     
      serviceReminderData?.customFields,      
      updatedFields,                   
      setCustomFieldsData,             
      setFormData,                     
      setFields,                       
      setFieldManagementOpen           
    );
    refreshData();  // <-- Trigger refresh after field management is done
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const customFields: Record<string, any> = {};

      fields.forEach((field) => {
        if (field.canDelete) {
          // Merge custom fields with form data
          customFields[field.name] = formData[field.name as keyof typeof formData] || customFieldsData[field.name];
        }
      });

      onSubmit({
        ...formData,
        customFields,
        vehicleId: vehicleId ?? undefined,
        serviceTaskId: serviceTaskId ?? undefined,

      })
    }
  };

  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};
  
    // Add validation errors for vehicle and service task
    if (!vehicleId) {
      newErrors.vehicleId = 'Vui lòng chọn phương tiện';
    }
  
    if (!serviceTaskId) {
      newErrors.serviceTaskId = 'Vui lòng chọn công việc';
    }
  
    // Validate time interval and units
    if (formData.timeInterval && !formData.timeIntervalUnit) {
      newErrors.timeIntervalUnit =
        'Vui lòng chọn đơn vị của khoảng thời gian nếu có giá trị khoảng thời gian.';
    }
  
    if (!formData.timeInterval && formData.timeIntervalUnit) {
      newErrors.timeInterval =
        'Vui lòng cung cấp giá trị của khoảng thời gian nếu có đơn vị thời gian.';
    }
  
    // Validate time interval threshold and units
    if (formData.timeIntervalThreshold && !formData.timeIntervalThresholdUnit) {
      newErrors.timeIntervalThresholdUnit =
        'Vui lòng chọn đơn vị của khoảng thời gian nhắc nhở nếu có giá trị khoảng thời gian nhắc nhở.';
    }
  
    if (!formData.timeIntervalThreshold && formData.timeIntervalThresholdUnit) {
      newErrors.timeIntervalThreshold =
        'Vui lòng cung cấp giá trị của khoảng thời gian nhắc nhở nếu có đơn vị thời gian nhắc nhở.';
    }
  
    // Convert time intervals to days for comparison
    if (
      formData.timeInterval &&
      formData.timeIntervalThreshold &&
      formData.timeIntervalUnit &&
      formData.timeIntervalThresholdUnit
    ) {
      const timeIntervalInDays = unitToDays(
        formData.timeInterval,
        formData.timeIntervalUnit
      );
      const timeIntervalThresholdInDays = unitToDays(
        formData.timeIntervalThreshold,
        formData.timeIntervalThresholdUnit
      );
  
      if (timeIntervalThresholdInDays >= timeIntervalInDays) {
        newErrors.timeIntervalThreshold =
          'Khoảng thời gian nhắc nhở không thể lớn hơn hoặc bằng khoảng thời gian nhắc nhở chính.';
      }
    }

    // Validate manual next due date
    if (formData.manualNextDueDate_flg) {
      if (vehicleId && vehicles.length > 0) {
        const selectedVehicle = vehicles.find((v) => v.id === vehicleId);
        if (selectedVehicle) {
          if (
            formData.nextDuePrimaryMeter && formData.nextDuePrimaryMeter <= selectedVehicle.primaryMeter
          ) {
            newErrors.nextDueDatePrimaryMeter =
              'Khoảng đo chính tiếp theo cần lớn hơn khoảng đo chính hiện tại của phương tiện.';
          }
        } else {
          newErrors.vehicleId = 'Dữ liệu phương tiện không hợp lệ.';
        }
      } else {
        newErrors.vehicleId = 'Vui lòng chọn phương tiện để kiểm tra.';
      }
    }

    if (formData.nextDueDate) {
      const currentDate = new Date();
      const nextDueDate = new Date(formData.nextDueDate);
      if (nextDueDate <= currentDate) {
        newErrors.nextDueDate =
          'Ngày đến hạn tiếp theo phải nằm trong tương lai.';
      }
    }
  
    // Set the errors
    setErrors(newErrors);
  
    // If there are errors, show them in an alert
    if (Object.keys(newErrors).length > 0) {
      const errorMessages = Object.values(newErrors)
        .map((message) => `+ ${message}`) // Add "+ " before each error message
        .join('\n'); // Combine all error messages
  
      alert(`Các mục bị lỗi:\n${errorMessages}`); // Show alert with errors
    }
  
    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };
  
  React.useEffect(() => {
    if (open) {
      if (isEditMode && serviceReminderData) {
        setFormData({...serviceReminderData,
          nextDueDate: formatDate(serviceReminderData.nextDueDate)
        });

        if (serviceReminderData.customFields) {
          try {
            const parsedCustomFields = typeof serviceReminderData.customFields === 'string' ? JSON.parse(serviceReminderData.customFields) : serviceReminderData.customFields;
            setCustomFieldsData(parsedCustomFields);
          } catch (error) {
            console.log("Error parsing custom fields:", error); 
          }
        }

        setVehicleId(serviceReminderData.vehicleId || null);
        setServiceTaskId(serviceReminderData.serviceTaskId || null);

      } else {
        setFormData({});
        setCustomFieldsData({});
        setVehicleId(null);
        setServiceTaskId(null);
      }
    }
  }, [open, isEditMode]);

  const handleCustomFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Check if the input is a checkbox or a regular text input
    setCustomFieldsData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,  // If checkbox, store as boolean; else, store the value
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Handle checkbox separately to update the state with boolean values
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });

  };

  const formFields: FormField[] = fields
  .filter((field) => {
    // Exclude the fields `nextDuePrimaryMeter` and `nextDueDate` if `manualNextDueDate_flg` is false
    if (!formData.manualNextDueDate_flg && ['nextDuePrimaryMeter', 'nextDueDate'].includes(field.name)) {
      return false;
    }
    return true;
  })
  .map((field) => {
    return {
      label: field.label,
      name: field.name,
      type: ['timeIntervalUnit', 'timeIntervalThresholdUnit'].includes(field.name) ? 'select' : field.type,
      options: ['timeIntervalUnit', 'timeIntervalThresholdUnit'].includes(field.name)
        ? [
            { label: 'Ngày', value: 'day' },
            { label: 'Tuần', value: 'week' },
            { label: 'Tháng', value: 'month' },
            { label: 'Năm', value: 'year' },
          ]
        : undefined,
      value: formData[field.name as keyof ServiceReminder] || customFieldsData[field.name] || '',
      required: field.required,
      handleChange: field.canDelete ? handleCustomFieldChange : handleChange,
      error: errors[field.name],
    };
  });


  const handleCloseDialog = () => { 
    onClose();
    setErrors({});
    setFormData({});
    setCustomFieldsData({});
    setVehicleId(null);
    setServiceTaskId(null); 
    setServiceEntriesHistory([]); 
  };
  

  return (
    <CustomDialog open={open} title="Thêm/Chỉnh sửa thông tin thẻ nhắc" onClose={handleCloseDialog} manageFields={() => setFieldManagementOpen(true)}
    onSubmit={handleSubmit} submitLabel="Lưu" dialogWidth='800px' dialogMaxWidth='95%'
    >
  
    <div className='row'>
      <div className={isEditMode ? "col-md-6" : "col-md-12"}>
        <FormThings 
          formFields={formFields}
          vehicles={vehicles} 
          vehicleId={vehicleId} 
          setVehicleId={setVehicleId}
          serviceTasks={serviceTasks}
          serviceTaskId={serviceTaskId}
          setServiceTaskId={setServiceTaskId}
          addServiceTask={addServiceTask} 
          editServiceTask={editServiceTask}
          deleteServiceTask={deleteServiceTask}
        />
      </div> 

      {isEditMode && (
        <div className='col-md-6'>
          <Typography variant="h5" gutterBottom>Lịch sử các nhiệm vụ của xe</Typography>

          <ReminderServiceEntries entries={serviceEntriesHistory} vehicleId={vehicleId} vehicles={vehicles} />
        </div>
      )}

    </div>

    
    <FieldManagementDialog
      open={isFieldManagementOpen}
      onClose={() => setFieldManagementOpen(false)}
      fields={fields}
      onFieldSubmit={handleFieldManagementSubmit}
      addFieldToDatabase={addFieldToDatabase}
      updateFieldInDatabase={updateFieldInDatabase}
      deleteFieldFromDatabase={deleteFieldFromDatabase}
    />

    </CustomDialog>


  )


};








