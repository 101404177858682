import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Download as DownloadIcon } from '@phosphor-icons/react';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { Upload as UploadIcon } from '@phosphor-icons/react';
import { GenericFilters } from '@/components/dashboard/filter/genericFilter';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1';
import axios from 'axios';
import { SupplierDialog } from './supplierDialog';
import { formatDate } from '@/hooks/format-date';
import dayjs, { Dayjs } from 'dayjs';

// **NOTE: in this code just prefered the supplier to the accoutns instead (tempalte used)

export interface Supplier {
  id: string;
  name: string;
  accountNumber: string;
  number: string;
  email: string;
  phoneNumber: string;
  address: string;
  city: string;
  categoryFuelSupplier: boolean;
  categoryServiceProvider: boolean;
  categoryVehicleDealer: boolean;
  categoryPartSupplier: boolean;
  categoryFinancialServiceProvider: boolean;
  categoryInsuranceCompany: boolean;
  createdAt: Date;
  pictureUrl: string;
  customFields: Record<string, string>;
}

function applyPagination(rows: Supplier[], page: number, rowsPerPage: number): Supplier[] {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export default function SuppliersPage(): React.JSX.Element {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5); // Adjust number of rows per page as necessary
  const [data, setData] = React.useState<Supplier[]>([]); // Supplier data state
  const [filteredData, setFilteredData] = React.useState<Supplier[]>([]); // Filtered data state
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedSupplier, setSelectedSupplier] = React.useState<Supplier | null>(null);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/suppliers');
      const sortedData = response.data.sort((a: Supplier, b: Supplier) => { a.name.localeCompare(b.name) });
      setData(sortedData);
      setFilteredData(sortedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const paginatedSuppliers = applyPagination(filteredData, page, rowsPerPage);

  const handleFilterChange = (column: string, searchTerm: string | boolean | Dayjs | null) => {
    const filtered = data.filter((supplier) => {
      const value = supplier[column as keyof Supplier]; // Access the column value dynamically
  
      if (searchTerm === null) {
        // If no searchTerm, include all items (e.g., dropdown initial state)
        return true;
      }
  
      if (typeof value === 'string') {
        return value.toLowerCase().includes((searchTerm as string).toLowerCase()); // Case-insensitive search for strings
      }
  
      if (typeof value === 'number') {
        return value === Number(searchTerm); // Match numbers exactly
      }
  
      if (typeof value === 'boolean') {  
        return value === Boolean(searchTerm); // Match boolean values exactly
      }

      return false;  
    });
    
    setFilteredData(filtered);
  };
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleOpenDialog = () => {
    setSelectedSupplier(null); // Reset selected supplier when adding a new one
    setOpenDialog(true);
  };

  const handleEditSupplier = (id: string) => {
    const supplierToEdit = data.find((supplier) => supplier.id === id);
    if (supplierToEdit) {
      setSelectedSupplier(supplierToEdit); // Populate the dialog with the selected supplier
      setOpenDialog(true); // Open dialog for editing
    }
  };

  const handleDeleteSupplier = async (id: string) => {
    const confirmed = window.confirm('Bạn có chắc là xóa nhà cung cấp này?');
    if (confirmed) {
      try {
        await axios.delete(`/api/suppliers/${JSON.stringify(id)}`);
        setData((prevData) => prevData.filter((supplier) => supplier.id !== id));
        setFilteredData((prevData) => prevData.filter((supplier) => supplier.id !== id));
      } catch (error) {
        console.error('Error deleting supplier:', error);
      }
    }
  };

  const handleDeleteSupplierSelected = async (selectedIds: string[]) => {
    const confirmed = window.confirm('Bạn có chắc là xóa những nhà cung cấp đã chọn?');
    if (confirmed) {
      try {
        await axios.delete(`/api/suppliers/${JSON.stringify(selectedIds)}`);
        alert('Xóa thành công');
        setData((prevData) => prevData.filter((supplier) => !selectedIds.includes(supplier.id)));
        setFilteredData((prevData) => prevData.filter((supplier) => !selectedIds.includes(supplier.id)));
      } catch (error) {
        console.log('Error deleting supplier:', error);
      }
    }
  }

  const handleAddOrEditSupplier = async (supplierData: Partial<Supplier>, imageFile?: File) => {
    try {
      // Create a FormData object to handle both file and supplier data
      const formData = new FormData();
  
      // Append the supplier data fields to the FormData object
      Object.keys(supplierData).forEach((key) => {
        const value = supplierData[key as keyof Supplier];
        if (value !== undefined) {
          // Ensure that all values appended to FormData are strings or Blob objects
          formData.append(key, value as string | Blob);
        }
      });
        
      // Handle customFields: Convert customFields to a JSON string before appending
      if (supplierData.customFields) {
        formData.set('customFields', JSON.stringify(supplierData.customFields));
      }
  
      // Append the image file, if it exists
      if (imageFile) {
        formData.append('picture', imageFile); // 'picture' is the field name for the image file
      }
  
      if (selectedSupplier) {
        // Editing existing supplier
        const response = await axios.put(`/api/suppliers/${selectedSupplier.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
          },
        });
        const updatedSupplier = response.data;
  
        setData((prevData) =>
          prevData.map((supplier) => (supplier.id === updatedSupplier.id ? updatedSupplier : supplier))
        );
        setFilteredData((prevData) =>
          prevData.map((supplier) => (supplier.id === updatedSupplier.id ? updatedSupplier : supplier))
        );
      } else {
        // Adding new supplier
        const response = await axios.post('/api/suppliers', formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
          },
        });
        const newSupplier = response.data;
        setData((prevData) => [...prevData, newSupplier]);
        setFilteredData((prevData) => [...prevData, newSupplier]);
      }
      fetchData(); // Fetch data after the operation
      setOpenDialog(false); // Close the dialog after the operation
    } catch (error: any) {
      console.error('Lỗi thêm/cập nhập nhà cung cấp:', error); 
      if (error.response && error.response.status === 409) { 
        const errorFlag = error.response.data.errorFlag;
        switch (errorFlag) {
          case 'SUPPLIERNAME_EXISTS': 
            alert('Tên nhà cung cấp đã tồn tại. Hãy chọn tên khác.');
            break;
          default: 
            alert('Lỗi thêm/cập nhập nhà cung cấp. Hãy kiểm tra các ô lại.');
        }
      } else {
        alert('Lỗi thêm/cập nhập nhà cung cấp. Hãy kiểm tra các ô lại.');
      }
    }
  };
  
  //filter columns
  const filterColumns = [
    { value: 'name', label: 'Tên nhà cung cấp' },
    { value: 'phoneNumber', label: 'Số Điện thoại' },
    { value: 'email', label: 'Email' },
  ];

  //table columns
  const columns: TableColumn<Supplier>[] = [
    { label: 'Ảnh', field: 'pictureUrl', isImage: true },
    { label: 'Tên nhà cung cấp', field: 'name' },
    { label: 'Email', field: 'email' },
    { label: 'Địa chỉ', field: 'address' },
    { label: 'Số Điện thoại', field: 'phoneNumber' },
    { label: 'Thời gian được tạo', field: 'createdAt', customRender: (rowData) => formatDate(rowData.createdAt), isDate: true },
  ];

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h4">Nhà cung cấp</Typography>
        </Stack>
        <div>
          <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={handleOpenDialog}>
            Thêm
          </Button>
        </div>
      </Stack>
      <GenericFilters columns={filterColumns} onFilterChange={handleFilterChange} placeholder="Tìm nhà cung cấp" />
      <GenericTable
        count={filteredData.length}
        page={page}
        rows={paginatedSuppliers}
        columns={columns}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onEdit={handleEditSupplier}
        onDelete={handleDeleteSupplier}
        onDeleteSelected={handleDeleteSupplierSelected}
      />
      {/* Add supplier dialog */}
      <SupplierDialog open={openDialog} onClose={() => setOpenDialog(false)} onSubmit={handleAddOrEditSupplier} supplierData={selectedSupplier ?? undefined} 
        refreshData={fetchData} // <-- pass fetchData here
      />
    </Stack>
  );
}

// This ensures that this file is treated as a module and resolves the TS1208 error
export { };
