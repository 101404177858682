import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { Download as DownloadIcon } from '@phosphor-icons/react';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { Upload as UploadIcon } from '@phosphor-icons/react';
import { GenericFilters } from '@/components/dashboard/filter/genericFilter';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1'; 
import axios from 'axios';
import { timeIntervalUnit, statusOrder, reverseStatusOrder } from '@/hooks/mapping';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SpeedIcon from '@mui/icons-material/Speed';
import { formatDate } from '@/hooks/format-date';
import { FuelEntryDialog } from './FuelEntryDialog';
import dayjs, { Dayjs } from 'dayjs';

interface Vehicle {
    vehicleNumber: string;
    name: string;
    primaryMeterType: string;
    primaryMeter: number;
    secondaryMeterType: string; 
    secondaryMeter: number; 
}

interface Driver {
  username: string;
  firstName: string;
  lastName: string;
}

interface FuelType {
  name: string;
}

export interface FuelEntry {
    id: string;
    fuelEntryNumber: string;
    vehicleId: number;
    vehicle: Vehicle;
    date: Date | string;
    primaryMeter: number;
    driverId: number;   
    driver: Driver;
    fuelFromType: string;
    fuelFromId: number;
    fuelTypeId: number;
    fuelType: FuelType; 
    taxFlg: boolean;
    taxRate: number;  
    quantity: number;
    unitCost: number;
    totalCost: number;
    createdAt: Date;
    updateAt: Date;

    customFields: Record<string, any>;
}

interface FuelEntryPageProps {
  fuelTankId?: string; // Optional prop to filter data by fuelTankId
}

function applyPagination(rows: FuelEntry[], page: number, rowsPerPage: number): FuelEntry[] {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export default function FuelEntryPage({ fuelTankId }: FuelEntryPageProps): React.JSX.Element {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5); // Adjust number of rows per page as necessary
  const [data, setData] = React.useState<FuelEntry[]>([]); // Inspection data state
  const [filteredData, setFilteredData] = React.useState<FuelEntry[]>([]); // Filtered data state
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedFuelEntry, setSelectedFuelEntry] = React.useState<FuelEntry | null>(null);

  const fetchData = async () => {
    try {
      const params = fuelTankId ? { fuelTankId } : {}; // Include fuelTankId if provided
      const response = await axios.get('/api/fuel-entries', { params });
      const sortedData = response.data.sort((a: FuelEntry, b: FuelEntry) =>
        a.fuelEntryNumber.localeCompare(b.fuelEntryNumber)
      );
      setData(sortedData);
      setFilteredData(sortedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => { 
    setRowsPerPage(newRowsPerPage); 
    setPage(0);
  };

  const handleOpenDialog = () => {
    setSelectedFuelEntry(null); // Reset selected inspection when adding a new one
    setOpenDialog(true);
  };

  const handleEditFuelEntry = (id: string) => {
    const fuelEntryToEdit = data.find((fuelEntry) => fuelEntry.id === id);  
    if (fuelEntryToEdit) {
      setSelectedFuelEntry(fuelEntryToEdit);
      setOpenDialog(true);
    } 
  };

  const handleDeleteFuelEntry = async (id: string) => {
    const confirmed = window.confirm('Bạn có chắc muốn xóa thẻ này?');
    if (confirmed) {
      try {
        await axios.delete(`/api/fuel-entries/${JSON.stringify(id)}`);
        setData((prevData) => prevData.filter((fuelEntry) => fuelEntry.id !== id));
        setFilteredData((prevData) => prevData.filter((fuelEntry) => fuelEntry.id !== id));
      } catch (error) {
        console.error('Error deleting fuel entry:', error);
      }
    }
  }

  const handleDeleteFuelEntrySelected = async (selectedIds: string[]) => {  
    const confirmed = window.confirm('Bạn có chắc muốn xóa các thẻ đã chọn?');
    if (confirmed) {
      try{
          await axios.delete(`/api/fuel-entries/${JSON.stringify(selectedIds)}`);
          alert('Xóa thành công');
          setData((prevData) => prevData.filter((fuelEntry) => !selectedIds.includes(fuelEntry.id)));
          setFilteredData((prevData) => prevData.filter((fuelEntry) => !selectedIds.includes(fuelEntry.id)));  
      } catch (error){
        console.log('Error deleting fuel entry:', error);
      }
    }
  };

  const handleAddOrEditFuelEntry = async (fuelEntryData: Partial<FuelEntry>) => {  
    try {
      const formData = new FormData();

      Object.keys(fuelEntryData).forEach((key) => {
        const value = fuelEntryData[key as keyof FuelEntry];
        if (value !== undefined) {
          // Ensure that all values appended to FormData are strings or Blob objects
          formData.append(key, value as string | Blob);
        }
      });

      // Handle customFields: Convert customFields to a JSON string before appending
      if (fuelEntryData.customFields) {
        formData.set('customFields', JSON.stringify(fuelEntryData.customFields));
      }

      if (selectedFuelEntry) {
        // Editing existing maintainanceWorkOrder
        const response = await axios.put(`/api/fuel-entries/${selectedFuelEntry.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
          },
        });
        const updatedFuelEntry = response.data;
  
        setData((prevData) =>
          prevData.map((fuelEntry) => (fuelEntry.id === updatedFuelEntry.id ? updatedFuelEntry : fuelEntry))
        );
        setFilteredData((prevData) =>
          prevData.map((fuelEntry) => (fuelEntry.id === updatedFuelEntry.id ? updatedFuelEntry : fuelEntry))
        );
      } else {
        // Adding new maintainanceWorkOrder
        const response = await axios.post('/api/fuel-entries', formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
          },
        });
        const newFuelEntry = response.data;
        setData((prevData) => [newFuelEntry, ...prevData ]);
        setFilteredData((prevData) => [newFuelEntry, ...prevData ]);
      }
      fetchData(); // Fetch data after the operation
      setOpenDialog(false); // Close the dialog after the operation
    
    } catch (error) {
      alert('Có lỗi xảy ra, vui lòng thử lại sau');
      console.error('Error adding/editing fuel entry:', error);
    }
  };

  const handleFilterChange = (column: string, searchTerm: string | boolean | null | Dayjs ) => {
    const filtered = data.filter((fuelEntry) => {
			let value = fuelEntry[column as keyof FuelEntry]; // Access the column value dynamically

      if (searchTerm === null) {
        // If no searchTerm, include all items (e.g., dropdown initial state)
        return true;
      }
      
  
      // Map column name to the actual data field
      switch (column) {
        case 'fuelEntryNumber':
          value = fuelEntry.fuelEntryNumber;
          break;
        case 'vehicle':
          value = fuelEntry.vehicle?.name;
          break;
        case 'driver':
          value = fuelEntry.driver?.username;
          break;
        case ' fuelType':
          value = fuelEntry.fuelType?.name; 
          break;
      }
      
  
      if (typeof value === 'string' && typeof searchTerm === 'string') {
        return value.toLowerCase().includes(searchTerm.toLowerCase()); // Case-insensitive search for strings
        }
      
        if (typeof value === 'number' && typeof searchTerm === 'number') {
        return value === searchTerm; // Match numbers exactly
        }
      
        if (typeof value === 'boolean') {
          return value === Boolean(searchTerm); // Match boolean values exactly
        }
      
      if (column === 'date' && dayjs.isDayjs(searchTerm)) {
        // Compare dates for `ngay_kham`
        const date = dayjs(value as string).startOf('day'); // Normalize to date only
        const filterDate = searchTerm.startOf('day'); // Normalize search date
        return date.isSame(filterDate);
      }
  
      return false; // Default return false if value type does not match the expected types
    });
    
    setFilteredData(filtered);
  };

  const filterColumns = [
		{ value: 'fuelEntryNumber', label: 'Số thẻ' },
		{ value: 'vehicle', label: 'Tên xe' },
		{ value: 'date', label: 'Ngày', type: 'date' as 'date' }, // Add ngay_kham as a date filter	
    { value: 'driver', label: 'Tên tk tài xế' },
		{ value: 'fuelFromType', label: 'Nguồn nhiên liệu',  options: [{ label: 'Kho xăng', value: "FuelTank" }, 
      { label: 'Nhà cung cấp', value: "Vendor" }, 
      { label: 'Khác', value: 'None'}
    ], type: 'dropdown' as const },
    { value: 'fuelType', label: 'Loại xăng' },
		{ value: 'createdAt', label: 'Ngày tạo', type: 'date' as 'date' }, // Add ngay_kham as a date filter
	]

  const fuelFromTypeMap: { [key in FuelEntry['fuelFromType']]: string } = {
    FuelTank: 'Kho xăng',
    Vendor: 'Nhà cung cấp',
    None: 'Khác', 
  }

  const columns: TableColumn<FuelEntry>[] = [
    { label: "Mã số thẻ", field: (row) => row.fuelEntryNumber },
    { label: "Tên xe", field: (row) => row.vehicle?.name || "" },
    { label: "Ngày", field: (row) => formatDate(row.date) },
    { label: "Số ODO chính", field: (row) => row.primaryMeter ?  `${row.primaryMeter} (${row.vehicle?.primaryMeterType || ""})` : "" },
    { 
      label: "Tài xế", 
      field: (row) => row.driver 
        ? `${row.driver.firstName} ${row.driver.lastName} (${row.driver.username})` 
        : "" 
    },
    { label: "Nguồn nhiên liệu", field: (row) => fuelFromTypeMap[row.fuelFromType] || "" },  
    // { label: "Tên nguồn xăng", field: (row) => row.fuelFromId || "" },  
    { label: "Loại nhiên liệu", field: (row) => row.fuelType?.name || "" },
    { label: "Số lượng", field: (row) => row.quantity || "" },
    { label: "Đơn giá", field: (row) => row.unitCost || "" },
    { label: "Tổng chi phí", field: (row) => row.totalCost || "" },
  ];
  

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h4">Quản lý hóa đơn xăng</Typography>
        </Stack>
        <div>
          <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={handleOpenDialog}>
            Thêm
          </Button>
        </div>
      </Stack>

      <GenericFilters 
        columns={filterColumns} onFilterChange={handleFilterChange} placeholder="Tìm thẻ"
      />

       <GenericTable
        count={filteredData.length}
        page={page}
        rows={applyPagination(filteredData, page, rowsPerPage)} // Use transformed data
        columns={columns}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onEdit={handleEditFuelEntry  }
        onDelete={handleDeleteFuelEntry  }
        onDeleteSelected={handleDeleteFuelEntrySelected} 
      />
 
      <FuelEntryDialog 
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSubmit={handleAddOrEditFuelEntry}
        fuelEntryData={selectedFuelEntry ?? undefined}
        refreshData={fetchData}
      />
    </Stack>
  )

}