import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';  
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { GenericFilters } from '@/components/dashboard/filter/genericFilter';
import { TableColumn } from '@/components/dashboard/table/columnType1'; 
import axios from 'axios';
import { formatDate } from '@/hooks/format-date';
import dayjs, { Dayjs } from 'dayjs';
import { PartEntryDialog } from './PartEntryDialog';

export interface PartEntryTabProps {
  serviceEntryId: string;
}

interface Part {
  name: string; 
}

interface InventoryLocation {
  name: string;
} 

export interface PartEntry {
  id: string;
  partId: number;
  part: Part; 
  inventoryLocationId: number;
  inventoryLocation: InventoryLocation; 
  quantity: number; 
  comment: string;
  warrantyFlg: boolean;
  expiredWarrantyDate: Date | string;
  warrantyReference: string;
  unitCost: number;
  taxFlg: boolean;
  taxRate: number;
  totalCost: number;
  createdAt: string;
  updateAt: string;
}

function applyPagination(rows: PartEntry[], page: number, rowsPerPage: number): PartEntry[] {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export default function PartEntryComponent({serviceEntryId}: PartEntryTabProps ): React.JSX.Element {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [data, setData] = React.useState<PartEntry[]>([]);
  const [filteredData, setFilteredData] = React.useState<PartEntry[]>([]);  
  const [openDialog, setOpenDialog] = React.useState(false);  
  const [selectedPartEntry, setSelectedPartEntry] = React.useState<PartEntry | null>(null); 

  const fetchData = async () => {
    try { 
      const response = await axios.get(`/api/part-entries/${serviceEntryId}`);  
      const sortedData = response.data.sort((a: PartEntry, b: PartEntry) => a.id.localeCompare(b.id));  
      setData(sortedData);  
      setFilteredData(sortedData);
    } catch (error) {
      console.error(error); 
    }
  }

  React.useEffect(() => {
    fetchData();  
  }, []);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => { 
    setRowsPerPage(newRowsPerPage); 
    setPage(0);
  };

  const handleOpenDialog = () => {
    setSelectedPartEntry(null); // Reset selected inspection when adding a new one
    setOpenDialog(true);
  }

  const handleEditPartEntry = (id: string) => {
    const partEntryToEdit = data.find((partEntry) => partEntry.id === id);
    if (partEntryToEdit) {
      setSelectedPartEntry(partEntryToEdit);  
      setOpenDialog(true);
    }
  }

  const handleDeletePartEntry = async (id: string) => {
    const confirmed = window.confirm('Bạn có chắc muốn xóa thẻ này?');
    if (confirmed) {
      try {
        await axios.delete(`/api/part-entries/${JSON.stringify(id)}`);
        setData(data.filter((partEntry) => partEntry.id !== id));
        setFilteredData((prevData) => prevData.filter((partEntry) => partEntry.id !== id)); 
      } catch (error) {
        console.error('Error deleting part entry:', error);  
      }
    }  
  }

  const handleDeletePartEntrySelected = async (selectedIds: string[]) => {  
    const confirmed = window.confirm('Bạn có chắc muốn xóa các thẻ đã chọn?');  
    if (confirmed) {
      try {
        await axios.delete(`/api/part-entries/${JSON.stringify(selectedIds)}`);  
        alert('Xóa thành công!');
        setData((prevData) => prevData.filter((partEntry) => !selectedIds.includes(partEntry.id)));
        setFilteredData((prevData) => prevData.filter((partEntry) => !selectedIds.includes(partEntry.id))); 
      } catch (error) {
        console.log('Error deleteing part entry:', error);  
      }
    }
  };

  const handleAddOrEditPartEntry = (partEntryData: Partial<PartEntry>) => { 
    try {
      if (selectedPartEntry) {
        setData((prevData) => prevData.map((partEntry) => (partEntry.id === partEntryData.id ? partEntryData as PartEntry : partEntry)));
        setFilteredData((prevData) => prevData.map((partEntry) => (partEntry.id === partEntryData.id ? partEntryData as PartEntry : partEntry)));
      } else {
        setData((prevData) => [...prevData, partEntryData as PartEntry]);
        setFilteredData((prevData) => [...prevData, partEntryData as PartEntry]);
      } 
      fetchData();  
      setOpenDialog(false);
    } catch (error) {
      alert('Có lỗi xảy ra, vui lòng thử lại sau');
      console.error('Error adding/editing fuel tank:', error);
    }
  }   

  const handleFilterChange = (column: string, searchTerm: string | boolean | null | Dayjs ) => {
    const filtered = data.filter((partEntry) => {
      let value = partEntry[column as keyof PartEntry]; // Access the column value dynamically

      if (searchTerm === null) {
        // If no searchTerm, include all items (e.g., dropdown initial state)
        return true;
      }

      if (typeof value === 'string' && typeof searchTerm === 'string') {
        return value.toLowerCase().includes(searchTerm.toLowerCase()); // Case-insensitive search for strings
        }
      
        if (typeof value === 'number' && typeof searchTerm === 'number') {
        return value === searchTerm; // Match numbers exactly
        }
      
        if (typeof value === 'boolean') {
        return value === searchTerm; // Match boolean values
        }
      
      if (column === 'date' && dayjs.isDayjs(searchTerm)) {
        // Compare dates for `ngay_kham`
        const date = dayjs(value as string).startOf('day'); // Normalize to date only
        const filterDate = searchTerm.startOf('day'); // Normalize search date
        return date.isSame(filterDate);
      }
  
      return false; // Default return false if value type does not match the expected types
    });
    
    setFilteredData(filtered);
  };

  const filterColumns = [
    { value: 'partName', label: 'Tên linh kiện' },
    { value: 'inventoryLocaitonName', label: 'Tên loại nhiên liệu' },
    { value: 'warranty_flg', label: 'Có bảo hành?' }, 
    { value: 'tax_flg', label: 'Có áp dụng thuế?' },
  ];

  const columns: TableColumn<PartEntry>[] = [
    { label: 'Tên linh kiện', field: (row) => row.part?.name || "" },
    { label: 'Kho', field: (row) => row.inventoryLocation?.name || "" },
    { label: 'Bảo hành', field: (row) => row.warrantyFlg ? 'Có✅' : 'Không❌' }, 
    { label: 'Ngày hết hạn bảo hành', field: (row) => row.expiredWarrantyDate ? formatDate(row.expiredWarrantyDate) : "" },
    { label: 'Có áp dụng thuế', field: (row) => row.taxFlg ? 'Có✅' : 'Không❌' },
    { label: 'Phần trăm thuế', field: (row) => row.taxRate ? `${row.taxRate}%` : "" },
    { label: 'Tổng chi phí', field: (row) => row.totalCost ? `${row.totalCost.toLocaleString()} VND` : "" },
    { label: "Ngày tạo", field: (row) => formatDate(row.createdAt)},
    { label: "Ngày cập nhật", field: (row) => formatDate(row.updateAt)},
  ];

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h5">Quản lý các mục linh kiện được dùng</Typography> 
        </Stack>
        <div>
          <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={handleOpenDialog}>
            Thêm
          </Button>
        </div>
      </Stack>
      <GenericFilters columns={filterColumns} onFilterChange={handleFilterChange} placeholder="Tìm thẻ "/>
      <GenericTable
        count={filteredData.length}
        page={page}
        rows={applyPagination(filteredData, page, rowsPerPage)} // Use transformed data
        columns={columns}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onEdit={handleEditPartEntry}
        onDelete={handleDeletePartEntry}
        onDeleteSelected={handleDeletePartEntrySelected} 
      />

      <PartEntryDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSubmit={handleAddOrEditPartEntry}
        partEntryData={selectedPartEntry ?? undefined}
        refreshData={fetchData}
        serviceEntryId={serviceEntryId}
      />
    
    </Stack>
  )
}