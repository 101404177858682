import * as React from 'react';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { Vehicle } from './Vehicle'; // Import your Vehicle type
import { ImageUpload } from '@/components/dashboard/imageUpload/ImageUpLoad';
import { CustomSelectWithAdd } from '@/components/dashboard/customSelectWithAdd/customSelectWithAdd';
import axios from 'axios';
import { FieldManagementDialog, DialogField } from '@/components/dashboard/fieldManagement/FieldManagement';
import useImageDownload from '@/hooks/image-download'; // Import the hook
import  useCustomFieldsUpdate from '@/hooks/field-managementSubmit'; // Import the hook
import { UseFetchDepartments, UseFetchGroups, UseFetchMake, UseFetchModel ,UseFetchVehicleType, UseFetchFuelType, UseFetchLicensePlateType, UseFetchTrailerAttachedTo } from '@/hooks/fetch-relationship-field'; // Import the hooks
import FormThings from "./formWithCustomSelectsVehicle"
import { format } from 'path';
import { formatDate } from '@/hooks/format-date';

interface VehicleDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (vehicleData: Partial<Vehicle>, imageFile?: File) => void;
  vehicleData?: Partial<Vehicle>;
  refreshData: () => void; // <-- pass fetchData here
}


export const VehicleDialog: React.FC<VehicleDialogProps> = ({ open, onClose, onSubmit, vehicleData, refreshData }) => {
  let isEditMode = !!vehicleData;
  const [fields, setFields] = React.useState<DialogField[]>([]);
  const [formData, setFormData] = React.useState<Partial<Vehicle>>(vehicleData || {});

  // the field image
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [imageFile, setImageFile] = React.useState<File | null>(null);
  const [imagePreview, setImagePreview] = React.useState<string | null>(null);
  const [imageError, setImageError] = React.useState<string | null>(null);
  const [isPictureDeleted, setIsPictureDeleted] = React.useState(false); // Flag for picture deletion

  //field management
  const [isFieldManagementOpen, setFieldManagementOpen] = React.useState(false);

  // custom field 
  const [customFieldsData, setCustomFieldsData] = React.useState<Record<string, any>>({});

  const { departments, addDepartment, editDepartment, deleteDepartment } = UseFetchDepartments();
  const { groups, addGroup, editGroup, deleteGroup } = UseFetchGroups();
  const { makes, addMake, editMake, deleteMake } = UseFetchMake();
  const { models, addModel, editModel, deleteModel } = UseFetchModel();
  const { vehicleTypes, addVehicleType, editVehicleType, deleteVehicleType } = UseFetchVehicleType();
  const { fuelTypes, addFuelType, editFuelType, deleteFuelType } = UseFetchFuelType();
  const { licensePlateTypes, addLicensePlateType, editLicensePlateType, deleteLicensePlateType } = UseFetchLicensePlateType();
  const { trailerAttachedTo } = UseFetchTrailerAttachedTo();
  
  // department, and group id state 
  const [departmentId, setDepartmentId] = React.useState<number | null>(null);
  const [groupId, setGroupId] = React.useState<number | null>(null);
  const [makeId, setMakeId] = React.useState<number | null>(null);
  const [modelId, setModelId] = React.useState<number | null>(null);  
  const [vehicleTypeId, setVehicleTypeId] = React.useState<number | null>(null);
  const [fuelTypeId, setFuelTypeId] = React.useState<number | null>(null);
  const [licensePlateTypeId, setLicensePlateTypeId] = React.useState<number | null>(null);
  const [trailerAttachedToId, setTrailerAttachedToId] = React.useState<number | null>();
  const [vehicleNumberLoading, setVehicleNumberLoading] = React.useState(false);  
  React.useEffect(() => {
    if (open && !vehicleData){
      fetchNextVehicleNumber();
    }

  }, [open, vehicleData]);
  

  const fetchNextVehicleNumber = async () => {
    try {
      setVehicleNumberLoading(true);
      const response = await axios.get('/api/vehicles/next-vehicle-number');
      const nextVehicleNumber = response.data.nextVehicleNumber;
      setFormData(prevFormData => ({
        ...prevFormData,
        vehicleNumber: nextVehicleNumber
      }));
    } catch (error) {
      console.error('Failed to fetch next vehicle number:', error);
    } finally {
      setVehicleNumberLoading(false);
    }
  };

  //for hiding the trailer field
  const [isTrailer, setIsTrailer] = React.useState(false);

  React.useEffect(() => {
    fetchFields();
  }, []);

  const fetchFields = async () => {
    try {
      const response = await axios.get('/api/vehicle-dialog-fields');
      setFields(response.data);
    } catch (error) {
      console.error('Failed to fetch dialog fields:', error);
    }
  };

  const addFieldToDatabase = async (field: DialogField): Promise<DialogField> => {  
    try {
      const response = await axios.post('/api/vehicle-dialog-fields', field);
      return response.data;
    } catch (error) {
      console.error('Failed to add dialog field:', error);
      return field;
    }
  }

  const updateFieldInDatabase = async (field: DialogField): Promise<DialogField> => {
    try {
      const response = await axios.put(`/api/vehicle-dialog-fields/${field.id}`, field);
      return response.data;
    } catch (error) {
      console.error('Error updating field:', error);
      throw error;
    }
  };

  const deleteFieldFromDatabase = async (id: number): Promise<void> => {
    try {
      await axios.delete(`/api/vehicle-dialog-fields/${id}`);
    } catch (error) {
      console.error('Error deleting field:', error);
      throw error;
    }
  };

  const { handleCustomFieldsUpdate } = useCustomFieldsUpdate(); // Use the custom

  const handleFieldManagementSubmit = (updatedFields: DialogField[]) => {
    handleCustomFieldsUpdate(
      fields,                         // Original fields before update
      formData.customFields || {},     // Current custom fields data
      vehicleData?.customFields,      // Customer custom fields
      updatedFields,                   // Updated fields
      setCustomFieldsData,             // Update customFieldsData
      setFormData,                     // Update formData
      setFields,                       // Update fields
      setFieldManagementOpen           // Close dialog
    );

    refreshData();  // <-- Trigger refresh after field management is done
  };

  //end custom field

  //for delete the image
  const handleDeleteImage = async () => {
    if (formData.id) {
      try {
        // Send request to backend to delete the image
        setIsPictureDeleted(true);
        setImageFile(null);
        setImagePreview(null);
        setFormData({ ...formData, pictureUrl: undefined });
      } catch (error) {
        console.error('Failed to delete image:', error);
      }
    }
  };

  const { handleDownloadImage } = useImageDownload(); // Use the custom hook

  const handleSubmit = async () => {
    if (validateForm()) {
      const customFields: Record<string, any> = {};

      fields.forEach((field) => {
        if (field.canDelete) {
          // Merge custom fields with form data
          customFields[field.name] = formData[field.name as keyof typeof formData] || customFieldsData[field.name];
        }
      });

      if (isPictureDeleted && formData.id) {
        try {
          await axios.delete(`/api/delete-vehicle-picture/${formData.id}`);
        } catch (error) {
          console.error('Failed to delete image:', error);
        }
      }

      // validate the form
      // require to have primary meter and primaryMEterType
      if (!formData.primaryMeter || !formData.primaryMeterType) { 
        alert("Cần phải có số đo ODO chính và đơn vị");
        return;
      };

      // IF primary metter and secondary meter have data but the type is empty, return invalid alert box
      if (formData.primaryMeter && !formData.primaryMeterType) {
        alert("Đơn vị Đo ODO chính không thể trống khi có dữ liệu ODO chính");
        return;
      }

      if (formData.secondaryMeter && !formData.secondaryMeterType) {
        alert("Đơn vị Đo ODO phụ không thể trống khi có dữ liệu ODO phụ");
        return;
      }

      // if primary metter and secondary meter id not < 0 , return invalid alert box
      if (formData.primaryMeter && formData.primaryMeter < 0) {
        alert("Số ODO chính không thể nhỏ hơn 0");
        return;
      }

      if (formData.secondaryMeter && formData.secondaryMeter < 0) {
        alert("Số ODO phụ không thể nhỏ hơn 0");
        return;
      }

      // check the vehicleNumber
      if (!formData.vehicleNumber) {
        alert("Số xe không thể trống");
        return;
      }

      onSubmit({
        ...formData,
        customFields,  // Include custom fields in the submission
        departmentId: departmentId ?? undefined,
        groupId: groupId ?? undefined,       
        makeId: makeId ?? undefined,
        modelId: modelId ?? undefined,
        vehicleTypeId: vehicleTypeId ?? undefined,
        fuelTypeId: fuelTypeId ?? undefined,
        licensePlateTypeId: licensePlateTypeId ?? undefined,
        trailerAttachedToId: trailerAttachedToId ?? undefined, 
      }, imageFile || undefined);
    }
  };
  
  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};
    fields.forEach(field => {
      const fieldValue = formData[field.name as keyof Vehicle];

      // For other fields, handle as usual
      if (field.required && (!fieldValue || typeof fieldValue === 'string' && !fieldValue.trim())) {
        newErrors[field.name] = `${field.label} cần có giá trị`;
      }
      
    });
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      const errorMessages = Object.values(newErrors)
        .map((message) => `+ ${message}`) // Add "+ " before each error message
        .join('\n'); // Combine all error messages

      alert(`Các mục bị lỗi:\n${errorMessages}`); // Show alert with errors
    }
    return Object.keys(newErrors).length === 0;
  };

  const clearFormData = () => {
    setFormData({
      id: '',
      name: '',
      vin: '',
      vehicleNumber: '',
      licensePlateNumber: '',
      makeId: 0,
      modelId: 0,
      trim: '',
      year: 0,
      vehicleTypeId: 0,
      departmentId: 0,
      groupId: 0,
      fuelTypeId: 0,
      licensePlateTypeId: 0,
      primaryMeterType: '',
      primaryMeter: 0,
      secondaryMeterType: '',
      secondaryMeter: 0,
      isTrailer: false,
      trailerAttachedToId: 0,
      pictureUrl: '',
      customFields: {},
      renewalDate: new Date(),
    });
    
    setDepartmentId(null);
    setGroupId(null);
    setMakeId(null);
    setModelId(null);
    setVehicleTypeId(null);
    setFuelTypeId(null);
    setLicensePlateTypeId(null);
    setTrailerAttachedToId(null);
    
    setImageFile(null);
    setImagePreview(null);
    setCustomFieldsData({});
    setIsTrailer(false);
    setIsPictureDeleted(false);
  };

  React.useEffect(() => {
    if (open){
      if (vehicleData) {
        setFormData({
          ...vehicleData,
          departmentId: vehicleData.departmentId ?? undefined,
          groupId: vehicleData.groupId ?? undefined,
          makeId: vehicleData.makeId ?? undefined,
          modelId: vehicleData.modelId ?? undefined,
          vehicleTypeId: vehicleData.vehicleTypeId ?? undefined,
          fuelTypeId: vehicleData.fuelTypeId ?? undefined,
          licensePlateTypeId: vehicleData.licensePlateTypeId ?? undefined,
          trailerAttachedToId: vehicleData.trailerAttachedToId ?? undefined,
          renewalDate: vehicleData.renewalDate ? formatDate(vehicleData.renewalDate) : undefined,
        });

        // Populate custom fields data from vehicleData.customFields if it exists
        if (vehicleData.customFields) {
          try{
            // If customFields is a string, parse it. Otherwise, use it as it is.
            const parsedCustomFields = typeof vehicleData.customFields === 'string' ? JSON.parse(vehicleData.customFields) : vehicleData.customFields; 
            setCustomFieldsData(parsedCustomFields);
          } catch(error){
            console.log('Error parsing custom fields:', error);
          }
        }

        setDepartmentId(vehicleData.departmentId ?? null);
        setGroupId(vehicleData.groupId ?? null);
        setMakeId(vehicleData.makeId ?? null);
        setModelId(vehicleData.modelId ?? null);
        setVehicleTypeId(vehicleData.vehicleTypeId ?? null);
        setFuelTypeId(vehicleData.fuelTypeId ?? null);
        setLicensePlateTypeId(vehicleData.licensePlateTypeId ?? null);
        setTrailerAttachedToId(vehicleData.trailerAttachedToId ?? null);
  
        setIsPictureDeleted(false); // Reset the flag when the dialog opens

        // for the check is trailer
        setIsTrailer(vehicleData.isTrailer || false); // Initialize isTrailer state based on existing data


        if (vehicleData.pictureUrl) {
          setImagePreview(vehicleData.pictureUrl);
        } else {
          setImagePreview(null);  
        }
      } else {
        clearFormData();
      }
    }
  }, [open, vehicleData]);

  const handleCustomFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Check if the input is a checkbox or a regular text input
    setCustomFieldsData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,  // If checkbox, store as boolean; else, store the value
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    if (name === 'isTrailer') {
      setIsTrailer(checked); // Update isTrailer state when checkbox changes
  
      // Clear trailerAttachedToId when isTrailer is checked
      if (checked) {
        setTrailerAttachedToId(null);
      }
    }
  
    // Handle checkbox separately to update the state with boolean values
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };
  
 const formFields: FormField[] = fields.reduce<FormField[]>((acc, field) => {
  if (field.name === 'vehicleNumber') {
    // Make vehicleNumber read-only and always visible
    acc.push({
      label: 'Mã số xe',
      name: 'vehicleNumber',
      type: 'text',
      value: formData.vehicleNumber || '',
      required: true,
      error: errors['vehicleNumber'],
    });
  } else {
    // Existing form field setup
    // Conditionally exclude the trailerAttachedToId field if isTrailer is checked
    if (field.name === 'trailerAttachedToId' && isTrailer) {
      return acc; // Skip adding this field to the accumulator
    }

    acc.push({
      label: field.label,
      name: field.name,
      type: field.name === 'primaryMeter' || field.name === 'secondaryMeter' ? 'number'
           : field.name === 'primaryMeterType' || field.name === 'secondaryMeterType' ? 'select'
           : field.type || 'text', // Default type to 'text' if undefined
      options: field.name === 'primaryMeterType' || field.name === 'secondaryMeterType'
               ? [
                   { label: 'Kilometer', value: 'kilometer' },
                   { label: 'Meter', value: 'meter' },
                   { label: 'Dặm', value: 'miles' },
                 ]
               : undefined,
      value: field.name === 'primaryMeter' || field.name === 'secondaryMeter'
             ? formData[field.name as keyof Vehicle] ?? 0
             : field.name === 'primaryMeterType' || field.name === 'secondaryMeterType'
             ? formData[field.name as keyof Vehicle] ?? ''
             : formData[field.name as keyof Vehicle] || customFieldsData[field.name] || '',
      required: field.required,
      handleChange: field.canDelete ? handleCustomFieldChange : handleChange,
      error: errors[field.name],
      // multiple false for fields (primarymetertype and secondarymetertype)
      // multiple: field.name === 'primaryMeterType' || field.name === 'secondaryMeterType' ? false : true,

    });
  }

  return acc;
}, []); // Start with an empty array


    
  return (
    <CustomDialog open={open} title="Thêm/Chỉnh sửa thông tin xe" onClose={onClose} manageFields={() => setFieldManagementOpen(true)}
    onSubmit={handleSubmit} submitLabel="Lưu" dialogWidth='800px' dialogMaxWidth='95%'>
        <ImageUpload
        imageFile={imageFile}
        imagePreview={imagePreview}
        setImageFile={setImageFile}
        setImagePreview={setImagePreview}
        onDeleteImage={handleDeleteImage}
        onDownloadImage={() => handleDownloadImage(formData.pictureUrl)}  // Pass a function without arguments
        setImageError={setImageError}
        imageError={imageError}
      />
      <FormThings
        formFields={formFields}
        departments={departments}
        groups={groups}
        makes={makes}
        models={models}
        vehicleTypes={vehicleTypes}
        fuelTypes={fuelTypes}
        licensePlateTypes={licensePlateTypes}
        trailerAttachedTo={trailerAttachedTo}
        // starting from here to pass things and create thinsg in the formThings
        departmentId={departmentId}
        setDepartmentId={setDepartmentId}
        groupId={groupId}
        setGroupId={setGroupId}
        isTrailer={isTrailer} // Pass isTrailer to FormThings
        addDepartment={addDepartment}
        editDepartment={editDepartment}
        deleteDepartment={deleteDepartment}
        addGroup={addGroup}
        editGroup={editGroup}
        deleteGroup={deleteGroup}
        makeId={makeId}
        setMakeId={setMakeId}
        addMake={addMake}
        editMake={editMake}
        deleteMake={deleteMake}
        modelId={modelId}
        setModelId={setModelId}
        addModel={addModel}
        editModel={editModel}
        deleteModel={deleteModel}
        vehicleTypeId={vehicleTypeId}
        setVehicleTypeId={setVehicleTypeId}
        addVehicleType={addVehicleType}
        editVehicleType={editVehicleType}
        deleteVehicleType={deleteVehicleType}
        fuelTypeId={fuelTypeId}
        setFuelTypeId={setFuelTypeId}
        addFuelType={addFuelType}
        editFuelType={editFuelType}
        deleteFuelType={deleteFuelType}
        licensePlateTypeId={licensePlateTypeId}
        setLicensePlateTypeId={setLicensePlateTypeId}
        addLicensePlateType={addLicensePlateType}
        editLicensePlateType={editLicensePlateType}
        deleteLicensePlateType={deleteLicensePlateType}
        trailerAttachedToId={trailerAttachedToId}
        setTrailerAttachedToId={setTrailerAttachedToId}
      />
      <FieldManagementDialog
        open={isFieldManagementOpen}
        onClose={() => setFieldManagementOpen(false)}
        fields={fields}
        onFieldSubmit={handleFieldManagementSubmit}
        addFieldToDatabase={addFieldToDatabase}
        updateFieldInDatabase={updateFieldInDatabase}
        deleteFieldFromDatabase={deleteFieldFromDatabase}
      />

    </CustomDialog>
  );
};
