import * as React from 'react';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { Customer } from './User'; // Import your customertype
import { ImageUpload } from '@/components/dashboard/imageUpload/ImageUpLoad';
import { CustomSelectWithAdd } from '@/components/dashboard/customSelectWithAdd/customSelectWithAdd';
import axios from 'axios';
import { FieldManagementDialog, DialogField } from '@/components/dashboard/fieldManagement/FieldManagement';
import useImageDownload from '@/hooks/image-download'; // Import the hook
import  useCustomFieldsUpdate from '@/hooks/field-managementSubmit'; // Import the hook
import { UseFetchDepartments, UseFetchJobTitles, UseFetchGroups } from '@/hooks/fetch-relationship-field'; // Import the hooks
import FormThings from "./formWithCustomSelectsUser"
import { formatDate } from '@/hooks/format-date';

interface UserDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (customerData: Partial<Customer>, imageFile?: File) => void;
  customerData?: Partial<Customer>;
  refreshData: () => void; // <-- pass fetchData here
}


export const UserDialog: React.FC<UserDialogProps> = ({ open, onClose, onSubmit, customerData, refreshData }) => {
  let isEditMode = !!customerData;
  const [fields, setFields] = React.useState<DialogField[]>([]);
  const [formData, setFormData] = React.useState<Partial<Customer>>(customerData || {});
  
  // the field image
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [imageFile, setImageFile] = React.useState<File | null>(null);
  const [passwordInput, setPasswordInput] = React.useState(''); 
  const [imagePreview, setImagePreview] = React.useState<string | null>(null);
  const [imageError, setImageError] = React.useState<string | null>(null);
  const [isPictureDeleted, setIsPictureDeleted] = React.useState(false); // Flag for picture deletion

  //field management
  const [isFieldManagementOpen, setFieldManagementOpen] = React.useState(false);

  // custom field 
  const [customFieldsData, setCustomFieldsData] = React.useState<Record<string, any>>({});

  const { departments, addDepartment, editDepartment, deleteDepartment } = UseFetchDepartments();
  const { jobTitles, addJobTitle, editJobTitle, deleteJobTitle } = UseFetchJobTitles();
  const { groups, addGroup, editGroup, deleteGroup } = UseFetchGroups();

  // department, jobTitle, and group id state 
  const [departmentId, setDepartmentId] = React.useState<number | null>(null);
  const [jobTitleId, setJobTitleId] = React.useState<number | null>(null);
  const [groupId, setGroupId] = React.useState<number | null>(null);

  React.useEffect(() => {
    fetchFields();
  }, []);

  const fetchFields = async () => {
    try {
      const response = await axios.get('/api/user-dialog-fields');
      setFields(response.data);
    } catch (error) {
      console.error('Failed to fetch dialog fields:', error);
    }
  };

  const addFieldToDatabase = async (field: DialogField): Promise<DialogField> => {  
    try {
      const response = await axios.post('/api/user-dialog-fields', field);
      return response.data;
    } catch (error) {
      console.error('Failed to add dialog field:', error);
      return field;
    }
  }

  const updateFieldInDatabase = async (field: DialogField): Promise<DialogField> => {
    try {
      const response = await axios.put(`/api/user-dialog-fields/${field.id}`, field);
      return response.data;
    } catch (error) {
      console.error('Error updating field:', error);
      throw error;
    }
  };

  const deleteFieldFromDatabase = async (id: number): Promise<void> => {
    try {
      await axios.delete(`/api/user-dialog-fields/${id}`);
    } catch (error) {
      console.error('Error deleting field:', error);
      throw error;
    }
  };

  const { handleCustomFieldsUpdate } = useCustomFieldsUpdate();

  const handleFieldManagementSubmit = (updatedFields: DialogField[]) => {
    handleCustomFieldsUpdate(
      fields,                         // Original fields before update
      formData.customFields || {},     // Current custom fields data
      customerData?.customFields,      // Customer custom fields
      updatedFields,                   // Updated fields
      setCustomFieldsData,             // Update customFieldsData
      setFormData,                     // Update formData
      setFields,                       // Update fields
      setFieldManagementOpen           // Close dialog
    );

    refreshData();  // <-- Trigger refresh after field management is done
  };
  
  //end custom field

  //for delete the image
  const handleDeleteImage = async () => {
    if (formData.id) {
      try {
        setIsPictureDeleted(true);
        setImageFile(null);
        setImagePreview(null);
        setFormData({ ...formData, pictureUrl: undefined });
      } catch (error) {
        console.error('Failed to delete image:', error);
      }
    }
  };

  // for download image
  const { handleDownloadImage } = useImageDownload(); // Use the custom hook

  const handleSubmit = async () => {
    if (validateForm()) {
      const customFields: Record<string, any> = {};

      fields.forEach((field) => {
        if (field.canDelete) {
          // Merge custom fields with form data
          customFields[field.name] = formData[field.name as keyof typeof formData] || customFieldsData[field.name];
        }
      });

      if (isPictureDeleted && formData.id) {
        try {
          await axios.delete(`/api/delete-account-picture/${formData.id}`);
        } catch (error) {
          console.error('Failed to delete picture:', error);
        }
      }


      onSubmit({
        ...formData,
        customFields,  // Include custom fields in the submission
        password: passwordInput,
        departmentId: departmentId ?? undefined,
        jobTitleId: jobTitleId ?? undefined,
        groupId: groupId ?? undefined,
      }, imageFile || undefined);
    }
  };

  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};
    fields.forEach(field => {
      const fieldValue = formData[field.name as keyof Customer];

      if (field.name === 'password') {
        if (field.required && (!passwordInput || passwordInput.trim() === '') && !isEditMode) {
          newErrors[field.name] = `${field.label} cần có`; // Error if password is required but not provided
        }
      } else {
        // For other fields, handle as usual
        if (field.required && (!fieldValue || typeof fieldValue === 'string' && !fieldValue.trim())) {
          newErrors[field.name] = `${field.label} cần có giá trị`;
        }
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  React.useEffect(() => {
    if (open){
      if (customerData) {

        setFormData({
          ...customerData,
          departmentId: customerData.departmentId ?? undefined,
          jobTitleId: customerData.jobTitleId ?? undefined,
          groupId: customerData.groupId ?? undefined,
          hiredOn: formatDate(customerData.hiredOn),
        });

        // Populate custom fields data from customerData.customFields if it exists
        if (customerData.customFields) {
          try{
            // If customFields is a string, parse it. Otherwise, use it as it is.
            const parsedCustomFields = typeof customerData.customFields === 'string' ? JSON.parse(customerData.customFields) : customerData.customFields; 
            setCustomFieldsData(parsedCustomFields);
          } catch(error){
            console.log('Error parsing custom fields:', error);
          }
        }
        
        setDepartmentId(customerData.departmentId ?? null);
        setJobTitleId(customerData.jobTitleId ?? null);
        setGroupId(customerData.groupId ?? null);
        
        setIsPictureDeleted(false); // Reset the flag when the dialog opens
        setImageFile(null); 

        if (customerData.pictureUrl) {
          setImagePreview(customerData.pictureUrl);
        } else {
          setImagePreview(null);  
        }

      } else {
        setFormData({
          username: '',
          password: '',
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          address: '',
          departmentId: 0,
          jobTitleId: 0,
          groupId: 0,
          categoryDriver: false,
          categoryTechnician: false,
          hiredOn: '',
        });
        setPasswordInput('');
        setImageFile(null);
        setImagePreview(null);
        setCustomFieldsData({});  
        setDepartmentId(null);  
        setJobTitleId(null);  
        setGroupId(null);
      }
    }
  }, [open, customerData]);

  const handleCustomFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Check if the input is a checkbox or a regular text input
    setCustomFieldsData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,  // If checkbox, store as boolean; else, store the value
    }));
  };

  //create a function to empty the password input field even the website autofills the password
  React.useEffect(() => {
    const handleAutoFill = () => {
      const password = document.getElementById('password');
      if (password) {
        password.addEventListener('input', () => {
          setPasswordInput('');
        });
      }
    };
    handleAutoFill();
  }, []);


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
   
    // Handle checkbox separately to update the state with boolean values
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => { 
    setPasswordInput(e.target.value);
  }

  const formFields: FormField[] = fields.map(field => ({
    label: field.name === 'password'
      ? (isEditMode ? 'Mật khẩu (Nhập để thay đổi)' : 'Mật khẩu (Tạo mới)')
      : field.label,
    name: field.name,
    type: field.name === 'password'
      ? 'password'
      : field.name === 'categoryDriver' || field.name === 'categoryTechnician'
      ? 'checkbox'
      : field.type,
    value: field.name === 'password'
      ? passwordInput
      : formData[field.name as keyof Customer] || customFieldsData[field.name] || '',  // Custom field values included
    required: field.name === 'password'
      ? !isEditMode
      : field.required, // Password is required only in create mode
    handleChange: field.name === 'password'
      ? handlePasswordChange
      : field.canDelete
      ? handleCustomFieldChange  // Custom field change handler
      : handleChange,
    error: errors[field.name],
  }));

  return (
    <CustomDialog open={open} title={isEditMode ? "Chỉnh sửa tài khoản" : "Thêm mới tài khoản"} onClose={onClose} manageFields={() => setFieldManagementOpen(true)}
    onSubmit={handleSubmit} submitLabel="Lưu" dialogWidth='800px' dialogMaxWidth='95%'> 
      <ImageUpload
        imageFile={imageFile}
        imagePreview={imagePreview}
        setImageFile={setImageFile}
        setImagePreview={setImagePreview}
        onDeleteImage={handleDeleteImage}
        onDownloadImage={() => handleDownloadImage(formData.pictureUrl)}  // Pass a function without arguments
        setImageError={setImageError}
        imageError={imageError}
      />
  
      <FormThings
      formFields={formFields}
      departments={departments}
      jobTitles={jobTitles}
      groups={groups}
      departmentId={departmentId}
      setDepartmentId={setDepartmentId}
      jobTitleId={jobTitleId}
      setJobTitleId={setJobTitleId}
      groupId={groupId}
      setGroupId={setGroupId}
      addDepartment={addDepartment}
      editDepartment={editDepartment}
      deleteDepartment={deleteDepartment}
      addJobTitle={addJobTitle}
      editJobTitle={editJobTitle}
      deleteJobTitle={deleteJobTitle}
      addGroup={addGroup}
      editGroup={editGroup}
      deleteGroup={deleteGroup}
    />

    <FieldManagementDialog
      open={isFieldManagementOpen}
      onClose={() => setFieldManagementOpen(false)}
      fields={fields}
      onFieldSubmit={handleFieldManagementSubmit}
      addFieldToDatabase={addFieldToDatabase}
      updateFieldInDatabase={updateFieldInDatabase}
      deleteFieldFromDatabase={deleteFieldFromDatabase}
    />

    </CustomDialog>
  );
};
