import { useCallback } from 'react';

const useImageDownload = () => {
  const handleDownloadImage = useCallback((pictureUrl: string | undefined) => {
    if (pictureUrl) {
      try {
        // Construct the full URL using the pictureUrl and the server's static route
        const serverUrl = `${pictureUrl}`;
  
        // Open the image in a new tab
        window.open(serverUrl, '_blank');
      } catch (error) {
        console.error('Error opening image:', error);
      }
    } else {
      console.error('No picture URL available.');
    }
  }, []);

  return { handleDownloadImage };
};

export default useImageDownload;
