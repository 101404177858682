import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { ServiceTasks  } from '@/components/subPlace/subEntriesTypeMaintainance'; // Import the component
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields } from '@/components/dashboard/formField/FormFields';
import { PartEntriesComponent } from '@/components/subPlace/PartEntriesComponents'; // Import the component
import { PartEntries, Part } from '@/components/subPlace/subEntriesTypeMaintainance'; // Import the component
import axios from 'axios';

interface ServiceTasksDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  data?: Partial<ServiceTasks>;
}

export const ServiceTasksDialog: React.FC<ServiceTasksDialogProps> = ({ open, data, onClose, onSubmit }) => {
  const [taskData, setTaskData] = React.useState(data || {});
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [partEntries, setPartEntries] = useState<PartEntries[]>(data?.partEntries || []);
  const [availableParts, setAvailableParts] = useState<Part[]>([]);

  useEffect(() => {
    if (data) {
      setTaskData(data);
      fetchPartEntries(data.id); // Fetch existing service entries if `data` is provided
    } else {
      setTaskData({});
      setPartEntries([]);
    }

    // Fetch available parts for selection
    async function fetchParts() {
      // Replace with actual API call
      const response = await axios.get('/api/parts/min');
      setAvailableParts(response.data);
    }
    fetchParts();
  }, [data]);

  async function fetchPartEntries(taskId?: string) {
    if (taskId) {
      const response = await axios.get(`/api/service-tasks/${taskId}/part-entries`);
      setPartEntries(response.data);
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTaskData({ ...taskData, [e.target.name]: e.target.value });
  };

  const handlePartEntriesChange = (updatedEntries: PartEntries[]) => {
    setPartEntries(updatedEntries);
  };

  const handleSubmit = () => {
    const newErrors: { [key: string]: string } = {};
    
    if (!taskData.name) {
      newErrors.name = 'Vui lòng nhập tên công việc.';
    }
  
    // Find entries missing either partId or quantity
    const incompleteEntries = partEntries.filter(entry => !entry.partId || !entry.quantity);
  
    if (incompleteEntries.length > 0) {
      alert('Some service entries are missing part ID or quantity. These items will not be submitted.');
    }
  
    // Filter out incomplete entries
    const filteredPartEntries = partEntries.filter(entry => entry.partId && entry.quantity);
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      alert('Vui lòng nhập tên');
      return;
    }
  
    setErrors({});
    
    // Proceed with submission, using only complete entries
    onSubmit({
      ...taskData,
      partEntries: filteredPartEntries,
    });
  };
  

  const formFields = [
    { name: 'name', label: 'Tên công việc', type: 'text', value: taskData.name || '', handleChange: handleChange, required: true },
    { name: 'description', label: 'Mô tả', type: 'text', value: taskData.description || '', handleChange: handleChange, required: true },
  ];

  return ( 
    <>
      <CustomDialog open={open} title="Thêm/Chỉnh sửa công việc" onClose={onClose} onSubmit={handleSubmit} dialogWidth='1600px' dialogMaxWidth='95%'>
        <div className='row'>
          <div className='col-md-6'>
            <FormFields fields={formFields} />
          </div>
          <div className='col-md-6'>
            <PartEntriesComponent
              entries={partEntries}
              availableParts={availableParts}
              onEntriesChange={handlePartEntriesChange}
              entryFields={{ partIdField: 'partId', quantityField: 'quantity', measureField: 'measure' }}  

            />
          </div>
        </div>
      </CustomDialog>
    </>
  );
   
};

