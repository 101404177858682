import React from 'react';
import { Select, MenuItem, Typography, Stack, FormControl, InputLabel, Chip, Box, TextField } from '@mui/material';
import { UseFetchInspectionCategory } from '@/hooks/fetch-relationship-field';

interface InspectionFormCheckProps {
  form: {
    inspectionItemId: number;
    inspectionCategoryId?: number;
    inspectionItemName: string;
    inspectionRequireCommentOnFail?: boolean; // Optional
    inspectionDescription?: string; // Optional
    status: string;
    inspectionComment?: string; // Optional comment field for failed items
  }[];
  onFormChange: (updatedForm: {
    inspectionItemId: number;
    inspectionCategoryId?: number;
    inspectionItemName: string;
    inspectionRequireCommentOnFail?: boolean;
    inspectionDescription?: string;
    status: string;
    inspectionComment?: string;
  }[]) => void;
}

export const InspectionFormCheckComponent: React.FC<InspectionFormCheckProps> = ({ form, onFormChange }) => {
  const { inspectionCategories } = UseFetchInspectionCategory();
  const [groupedItems, setGroupedItems] = React.useState<{ [categoryId: number]: typeof form }>({});

  React.useEffect(() => {
    groupItemsByCategory();
  }, [form, inspectionCategories]);

  const groupItemsByCategory = () => {
    const grouped: { [categoryId: number]: typeof form } = {};
    form.forEach((item) => {
      const categoryId = item.inspectionCategoryId || -1; // Default to -1 if no category
      if (!grouped[categoryId]) {
        grouped[categoryId] = [];
      }
      grouped[categoryId].push({ ...item });
    });
    setGroupedItems(grouped);
  };

  const handleStatusChange = (inspectionItemId: number, newStatus: string) => {
    const updatedForm = form.map((item) =>
      item.inspectionItemId === inspectionItemId ? { ...item, status: newStatus } : item
    );
    onFormChange([...updatedForm]);
  };

  const handleCommentChange = (inspectionItemId: number, newComment: string) => {
    const updatedForm = form.map((item) =>
      item.inspectionItemId === inspectionItemId ? { ...item, inspectionComment: newComment } : item
    );
    onFormChange([...updatedForm]);
  };

  return (
    <Stack spacing={3}>
      {Object.keys(groupedItems).length > 0 ? (
        Object.entries(groupedItems).map(([categoryId, items]) => {
          const category = inspectionCategories.find((cat) => cat.id === parseInt(categoryId, 10));
          return (
            <Box key={categoryId} sx={{ border: '1px solid #ccc', borderRadius: '8px', padding: '16px', backgroundColor: "#f9f9f9" }}>
              <Typography variant="h6" gutterBottom>
                {category ? category.name : 'Không có danh mục'}
              </Typography>
              {items.map((item) => (
                <Box key={item.inspectionItemId} sx={{ marginBottom: '16px' }}>
                  <Typography variant="body1">{item.inspectionItemName}</Typography>

                  {/* Render description only if it exists */}
                  {item.inspectionDescription && (
                    <Typography variant="body2" color="textSecondary">
                      {item.inspectionDescription}
                    </Typography>
                  )}

                  {/* Show Chip only if inspectionRequireCommentOnFail is true */}
                  {item.inspectionRequireCommentOnFail && (
                    <Chip label="Yêu cầu ghi chú nếu không đạt" color="warning" variant="outlined" />
                  )}

                  <FormControl fullWidth className="mt-3">
                    <InputLabel>{`Trạng thái`}</InputLabel>
                    <Select
                      value={item.status}
                      onChange={(e) => handleStatusChange(item.inspectionItemId, e.target.value as string)}
                      label={`Trạng thái`}
                    >
                      <MenuItem value="Not_Checked">Chưa kiểm tra</MenuItem>
                      <MenuItem value="Fail">Không đạt</MenuItem>
                      <MenuItem value="Success">Đạt</MenuItem>
                    </Select>
                  </FormControl>

                  {/* Render Comment Box if the item status is 'Fail' and requires a comment */}
                  {item.status === 'Fail' && item.inspectionRequireCommentOnFail && (
                    <TextField
                      fullWidth
                      label="Ghi chú"
                      variant="outlined"
                      value={item.inspectionComment || ''}
                      onChange={(e) => handleCommentChange(item.inspectionItemId, e.target.value)}
                      multiline
                      rows={3}
                      sx={{ mt: 2 }}
                    />
                  )}
                </Box>
              ))}
            </Box>
          );
        })
      ) : (
        <Typography>Không có mục kiểm tra nào</Typography>
      )}
    </Stack>
  );
};
