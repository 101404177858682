import React from 'react';
import styles from './Logo.module.css';

interface LogoProps {
    onToggleSidebar: () => void;
  }

  const Logo: React.FC<LogoProps> = ({ onToggleSidebar }) => {  
    return (
        <div className='d-flex align-items-center justify-content-between'>
            <a href='/dashboard' className={`d-flex align-items-center ${styles.logo}`}>
                {/* logo some where here */}
                <span className='d-none d-lg-block'>Trang quản lý</span>
            </a>
            <i className='bi bi-list toggle-sidebar-btn' data-toggle-btn="true" onClick={onToggleSidebar}></i> {/* Use prop to toggle sidebar */}
        </div>
    );
}

export default Logo;
