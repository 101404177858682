import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { CustomSelectWithAdd } from '@/components/dashboard/customSelectWithAdd/customSelectWithAdd';
import { Typography } from '@mui/material';

const FormThings = ({
    formFields,
    vehicles,
    vehicleId,
    setVehicleId,
    inspectionBy,
    inspectionById,
    setInspectionById,
    inspectionForms,
    inspectionFormId,
    setInspectionFormId,
  }: {
    formFields: FormField[];
    vehicles: any[];
    vehicleId: number | null; 
    setVehicleId: (id: number) => void; 
    inspectionBy: any[];
    inspectionById: number | null;
    setInspectionById: (id: number) => void;
    inspectionForms: any[]; 
    inspectionFormId: number | null;  
    setInspectionFormId: (id: number) => void;
  }) => {
    const selectedVehicle = vehicles.find((vehicle) => vehicle.id === vehicleId);

    return (
      <form>
        {formFields.map((field, index) => (
          <div key={index}>
            {/* Render form fields */}
            <FormFields fields={[field]} />

            {index === 0 && (
              <CustomSelectWithAdd
                label="Phương tiện *"
                items={vehicles}
                value={vehicleId || 0}
                onChange={setVehicleId}
                manageItem="/dashboard/vehicles"
              />
            )}

          {index === 0 && selectedVehicle && (
            <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
              Odo chính của xe: {selectedVehicle.primaryMeter} {selectedVehicle.primaryMeterType}
            </Typography>
          )}


            {index === 0 && (
              <CustomSelectWithAdd
                label="Tên đơn kiểm tra *"
                items={inspectionForms} 
                value={inspectionFormId || 0}
                onChange={setInspectionFormId}
                manageItem="/dashboard/inspections-issues-management"
              />
            )}      

            {index === 0 && (
              <CustomSelectWithAdd
                label="Kiểm tra bởi *"
                items={inspectionBy}
                value={inspectionById || 0}
                onChange={setInspectionById}
                manageItem="/dashboard/employees"
              />
            )}
             
          </div>
        ))}
      </form>
    );
};
  
  export default FormThings;