import * as React from 'react';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { formatDate } from '@/hooks/format-date';
import { PartEntry } from './PartEntry';
import { CustomSelectWithAdd } from '@/components/dashboard/customSelectWithAdd/customSelectWithAdd';
import { UseFetchPart, UseFetchInventoryLocationForPart } from '@/hooks/fetch-relationship-field'; 
import axios from 'axios';

interface PartEntryDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (partEntryData: Partial<PartEntry>) => void;
  partEntryData?: Partial<PartEntry>;
  refreshData: () => void; 
  serviceEntryId: string;    
}

export const PartEntryDialog: React.FC<PartEntryDialogProps> = ({open, onClose, onSubmit, partEntryData, refreshData, serviceEntryId}) => {
  let isEditMode = !!partEntryData;
  const [formData, setFormData] = React.useState<Partial<PartEntry>>(partEntryData || {});
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});  

  const [partId, setPartId] = React.useState<number | null>(null);
  const [inventoryLocationWarehouseId, setInventoryLocationWarehouseId] = React.useState<number | null>(null);

  const { parts } = UseFetchPart(); 
  const { inventoryLocationsPart } = UseFetchInventoryLocationForPart(partId);

  React.useEffect(() => {
    if (partId) {
      const selectedPart = parts.find((part) => part.id === partId);  
      if (selectedPart && formData.unitCost === undefined) {
        setFormData({
          ...formData,
          unitCost: selectedPart.unitCost,
        });
      } 
    }
  }, [partId, parts]);

  React.useEffect(() => {
    if (open) {
      if (partEntryData) {
        setFormData({
          ...partEntryData,
          expiredWarrantyDate: formatDate(partEntryData?.expiredWarrantyDate),
        });
  
        setPartId(partEntryData.partId || null);
  
        setTimeout(() => {
          setInventoryLocationWarehouseId(partEntryData.inventoryLocationId || null);
        }, 100);
      } else {
        setFormData({});
        setPartId(null);
        setInventoryLocationWarehouseId(null);  
      }
    }
  }, [open, partEntryData]);

  // 🛠 NEW useEffect to fetch inventory locations when partId changes
  React.useEffect(() => {
    if (partId) {
      const fetchInventoryLocations = async () => {
        try {
          const { inventoryLocationsPart } = UseFetchInventoryLocationForPart(partId);
          if (inventoryLocationsPart.length > 0) {
            setInventoryLocationWarehouseId(inventoryLocationsPart[0].id); // Set first location by default
          }
        } catch (error) {
          console.error('Error fetching inventory locations:', error);
        }
      };

      fetchInventoryLocations();
    } else {
      setInventoryLocationWarehouseId(null); // Reset if no part is selected
    }
  }, [partId]);

  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};
    const errorMessages: string[] = []; // To collect all error messages
  
    // Require partId and unitCost
    if (partId === null || partId === undefined || partId === 0) {
      const error = '+ Linh kiện không được để trống';
      newErrors.partId = error; 
      errorMessages.push(error);  
    }
  
    if (!formData.unitCost) {
      const error = '+ Đơn giá không được để trống';
      newErrors.unitCost = error; 
      errorMessages.push(error);  
    }
  
    setErrors(newErrors);
  
    if (errorMessages.length > 0) {
      alert(`Lỗi, thiếu những data dưới đây:\n\n${errorMessages.join('\n')}`);
    } 
  
    return errorMessages.length === 0;
  };
  
  

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        const partEntryData = {
          ...formData,
          partId: partId,
          inventoryLocationWarehouseId: inventoryLocationWarehouseId,
          serviceEntryId: serviceEntryId,
        };

        const formDataSubmit = new FormData();
        Object.keys(partEntryData).forEach((key) => {
          const value = partEntryData[key as keyof PartEntry];
          formDataSubmit.append(key, value as string | Blob);
        });

        let reponse;

        if (isEditMode) {
          reponse = await axios.put(`/api/part-entries/${formData.id}`, formDataSubmit, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          });
        } else {
          reponse = await axios.post(`/api/part-entries/${serviceEntryId}`, formDataSubmit, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }); 
        }

        const updatedPartEntry = reponse.data;  
        onSubmit(updatedPartEntry);

      } catch (error: any) {
        console.error(error);
        alert('Có lỗi xảy ra. Vui lòng thử lại sau.');
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {  
    const { name, type, value, checked } = e.target;

    const updatedFormData = {
      ...formData,
      [name]: type === 'checkbox' ? checked : value,  
    };

    // calculate the total cost based on the unit cost, quantity, and tax rate if there is taxFlg and taxRate
    if (name === 'unitCost' || name === 'quantity' || name === 'taxRate' || name === 'taxFlg') { 
      const unitCost = parseFloat(String(updatedFormData.unitCost || 0));
      const quantity = parseFloat(String(updatedFormData.quantity || 0));
      const taxRate = updatedFormData.taxFlg ? parseFloat(String(updatedFormData.taxRate || 0)) : 0;
      const taxMultiplier = 1 + taxRate / 100;  

      if (quantity > 0) {
        updatedFormData.totalCost = parseFloat((unitCost * quantity * taxMultiplier).toFixed(2));
      } else {
        updatedFormData.totalCost = 0;
      }
    };

    setFormData(updatedFormData);
  };



  const formFields: FormField[] = [
    {
      label: "Số lượng dùng",
      name: "quantity",
      type: "number",
      value: formData.quantity || "", 
      handleChange: handleChange,
      error: errors.date,
    },
    {
      label: 'Ghi chú',
      name: 'comment',
      type: 'text',
      value: formData.comment || '',  
      handleChange: handleChange,
      error: errors.comment,
    }, 
    {
      label: 'Có bảo hành?',
      name: 'warrantyFlg',
      type: 'checkbox', 
      value: formData.warrantyFlg || false,
      handleChange: handleChange,
      error: errors.warranty_flg,
    },
    {
      label: 'Ngày hết hạn bảo hành',
      name: 'expiredWarrantyDate',
      type: 'date',
      value: formData.expiredWarrantyDate || '',
      handleChange: handleChange,
      error: errors.expiredWarrantyDate,
    },
    {
      label: 'Ghi chú cho bảo hành',
      name: 'warrantyReference',
      type: 'text',
      value: formData.warrantyReference || '',
      handleChange: handleChange,
      error: errors.warrantyReference,
    },
    {
      label: 'Đơn giá',
      name: 'unitCost',
      type: 'number',
      value: formData.unitCost || '',
      handleChange: handleChange,
      error: errors.unitCost,
    },
    {
      label: 'Có áp dụng thuế?',
      name: 'taxFlg',
      type: 'checkbox',
      value: formData.taxFlg || false,
      handleChange: handleChange,
      error: errors.tax_flg,
    },
    {
      label: 'Phần trăm thuế áp dụng (%)',
      name: 'taxRate',
      type: 'number',
      value: formData.taxRate || '',
      handleChange: handleChange,
      error: errors.taxRate
    },
    {
      label: 'Tổng chi phí',
      name: 'totalCost',
      type: 'number',
      value: formData.totalCost || '',
      error: errors.totalCost,
    }
  ];

  return (
    <CustomDialog 
      open={open} 
      title={isEditMode ? "Chỉnh sửa thông tin nhập linh kiện" : "Thêm nhập linh kiện"} 
      onClose={onClose} 
      onSubmit={handleSubmit} 
      submitLabel="Lưu" 
      dialogWidth="800px" 
      dialogMaxWidth="95%"
    > 
  
      {formFields
        .filter(field => 
          (field.name !== "taxRate" || formData.taxFlg ) &&
          (field.name !== "expiredWarrantyDate" || formData.warrantyFlg) && // Show expiredWarrantyDate only if warranty_flg is true
          (field.name !== "warrantyReference" || formData.warrantyFlg) // Show warrantyReference only if warranty_flg is true
        ) 
        .map((field, index) => (
          <div key={index}>
            {index === 0 && (
              <CustomSelectWithAdd 
                label="Linh kiện *"
                items={parts}
                value={partId || 0}
                onChange={setPartId}
                manageItem="/dashboard/part"
              />
            )}
  
            {index === 0 && (
              <CustomSelectWithAdd 
                label="Lấy từ kho"
                items={inventoryLocationsPart}
                value={inventoryLocationWarehouseId || 0}
                onChange={setInventoryLocationWarehouseId}
                manageItem="/dashboard/inventory-location"
              />
            )}
  
            <FormFields fields={[field]} />
          </div>
      ))}
    </CustomDialog>
  );
  
};