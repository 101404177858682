import React, { useEffect, useState } from 'react';
import { InspectionItems } from '@/components/inspection/InspectionIssuesSetup'; // Import the component
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { UseFetchInspectionCategory } from '@/hooks/fetch-relationship-field';
import axios from 'axios';
import { CustomSelectWithAdd } from '@/components/dashboard/customSelectWithAdd/customSelectWithAdd'; // Import the component
import { set } from 'date-fns';

interface InspectionItemsDialogProps {
  open: boolean;
  title: string;
  onClose: () => void;
  onSubmit: (data: any) => void;
  data?: Partial<InspectionItems>;
}

export const InspectionItemsDialog: React.FC<InspectionItemsDialogProps> = ({ open, title, data, onClose, onSubmit }) => {
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [inspectionData, setInspectionData] = React.useState(data || {});
  const { inspectionCategories, addInspectionCategory, editInspectionCategory, deleteInspectionCategory } = UseFetchInspectionCategory();

  const [categoryId, setCategoryId] = React.useState<number | null>(null);

  const handleSubmit = async () => {
    if (validateForm()) {
      setErrors({});
      
      onSubmit({
        ...inspectionData,
        categoryId: categoryId,
      });
    }
  };

  const validateForm = (): boolean => {
    const errors: { [key: string]: string } = {};
    // required the name field

    if (!inspectionData.name) {
      errors.name = 'Vui lòng nhập tên mục kiểm tra.';
    }

    setErrors(errors);
    // alert with the error name
    if (Object.keys(errors).length > 0) {
      alert(errors.name);
    }
    return Object.keys(errors).length === 0;  
  };

  React.useEffect(() => {
    if (open) {
      if (data) {

        setInspectionData(data);

        setCategoryId(data.categoryId ?? null); 
        
      } else {
        setInspectionData({});
        setCategoryId(null);
      }
    }

  }, [open, data]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {  
    const { name, type, value, checked } = e.target;

    // Handle checkbox separately to update the state with boolean values
    setInspectionData({
      ...inspectionData,
      [name]: type === 'checkbox' ? checked : value,
    });

  };

  const formFields: FormField[] = [
    { 
      name: 'name',
      label: 'Tên mục kiểm tra',
      type: 'text',
      value: inspectionData.name,
      handleChange: handleChange,
      error: errors.name,
    },
    {
      name: 'description',
      label: 'Ghi chú',
      type: 'text',
      value: inspectionData.description,
      handleChange: handleChange,
      error: errors.description,
    },
    {
      name: 'requireCommentOnFail',
      label: 'Yêu cầu bình luận khi không đạt', 
      type: 'checkbox', 
      value: inspectionData.requireCommentOnFail,
      handleChange: handleChange,
      error: errors.requireCommentOnFail,
    }
  
  ];


  return (
    <CustomDialog open={open} title={title} onClose={onClose} onSubmit={handleSubmit} submitLabel='Lưu'>
          <FormFields fields={formFields} /> {/* Pass mapped formFields here */}

          <CustomSelectWithAdd 
            label="Danh mục"
            items={inspectionCategories}
            value={ categoryId || 0}
            onChange={setCategoryId}
            addItem={addInspectionCategory}
            editItem={editInspectionCategory}
            deleteItem={deleteInspectionCategory}
          />
    </CustomDialog>  

  );

};

