import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import { isValidImage } from '@/hooks/image-check';
import  './imageUpload.css';

interface ImageUploadProps {
  imageFile: File | null;
  imagePreview: string | null;
  setImageFile: (file: File | null) => void;
  setImagePreview: (url: string | null) => void;
  setImageError: (error: string | null) => void;
  imageError: string | null;
  onDeleteImage?: () => void; // Optional prop to handle image deletion
  onDownloadImage: () => void; // Prop to handle image download
  width?: number; // Optional width
  height?: number; // Optional height
  aspectRatio?: string; // Optional aspect ratio
  maxWidth?: string; // Optional max width
  label?: string; // Optional title 
}


export const ImageUpload: React.FC<ImageUploadProps> = ({
  imageFile,
  imagePreview,
  setImageFile,
  setImagePreview,
  setImageError,
  imageError,
  onDeleteImage,
  onDownloadImage,
  width = 420, // Default width
  height = 315, // Default height
  aspectRatio = '4 / 3', // Default aspect ratio
  maxWidth = '450px', // Default max width
  label = 'Ảnh' // Default title
}) => {
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      
      // Check file name for non-English characters
      const fileName = file.name;
      const validFileNamePattern = /^[a-zA-Z0-9_.-]+$/; // Allows only English letters, numbers, underscores (_), hyphens (-), and dots (.)

      if (!validFileNamePattern.test(fileName)) {
        setImageError('Tên file chỉ nên chứa các ký tự tiếng Anh, số, dấu gạch dưới (_), dấu gạch ngang (-), hoặc dấu chấm (.)');
        return;
      }

      // Validate image file properties
      const { valid, error } = isValidImage(file);
      if (!valid) {
        setImageError(error || 'Invalid image');
        return;
      }

      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
      setImageError(null);
    }
  };

  const handleDeleteImage = () => {
    setImageFile(null);
    setImagePreview(null);
    setImageError(null);
    if (onDeleteImage){
      onDeleteImage();
    }
  };

  const handleDownloadImage = () => {
    onDownloadImage();  
  };

  return (
    <Box className="mb-3">
      <Typography variant="body1" sx={{ mb: 1 }}><strong>{label}</strong></Typography>

      {imagePreview ? (
        <div>
          <img
            src={imagePreview}
            alt="Preview"
            width={width}
            height={height}
            style={{
              objectFit: 'cover',
              aspectRatio, // Dynamic aspect ratio
              maxWidth, // Dynamic max width
              marginBottom: '1rem',
            }}
          />

          {/* Download Image Button */}

          <div className='d-flex flex-row gap-1 mb-1'>
            <Button onClick={handleDownloadImage} color="primary" variant="outlined" >
              Tải ảnh về
            </Button>

            {onDeleteImage && 
              <Button onClick={handleDeleteImage} color="error" variant="outlined">
                Xóa ảnh
              </Button>
            }
         
          </div>
           
        </div>
      ) : (
        <Typography variant="body2" color="textSecondary">Chưa có ảnh</Typography>
      )}
      <Button variant="contained" component="label">
        Tải ảnh lên 
        <input type="file" accept="image/*" onChange={handleImageChange} hidden />
      </Button>
      {imageError && (
        <Typography variant="body2" color="error" sx={{ marginTop: '0.5rem' }}>
          {imageError}
        </Typography>
      )}
    </Box>
  );
};
