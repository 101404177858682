import React, { useState } from 'react';
import { Typography, Chip, Box, Pagination } from '@mui/material';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { formatDate } from '@/hooks/format-date'; // Import your date formatting function
import { getContrastTextColor } from '@/hooks/contrast-text-color';
import { Vehicle, Assignee } from "@/types/dialog";
import { statusInspectionMap } from '@/hooks/mapping';

interface ReminderInspectionEntriesProps {
  inspections: {
    inspectionNumber: string;
    inspectionForm: {name: string; description: string};
    inspectionBy: {username: string; firstName: string; lastName: string};
    primaryMeter: number;
    inspectionDate: string;
    vehicle: { vehicleNumber: string; name: string };   
    status?: keyof typeof statusInspectionMap; // Restrict to keys of statusMap
  }[];

  vehicleId: number | null; 
  vehicles: Vehicle[];  
}

const ReminderInspection: React.FC< ReminderInspectionEntriesProps> = ({ inspections, vehicleId, vehicles }) => {
  const [currentPage, setCurrentPage] = useState(1); // State to track the current page 
  const entriesPerPage = 5; // Limit entries per page

  const totalPages = Math.ceil(inspections.length / entriesPerPage);  

  const currentInspections = inspections.slice( 
    (currentPage - 1) * entriesPerPage, 
    currentPage * entriesPerPage  
  );

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const selectedVehicle: Vehicle | undefined = vehicles.find((vehicle: Vehicle) => vehicle.id === vehicleId); 

  return (
    <Box mt={2}>
      {inspections.length === 0 ? (
              <Typography>Không có nhiệm vụ nào được tìm thấy.</Typography>
            ) : (
              <>
                {currentInspections.map((inspection, index) => (
                  <Link
                    to={`/dashboard/inspections?inspectionNumber=${inspection.inspectionNumber}`}
                    key={index}
                    style={{ textDecoration: 'none', color: 'inherit' }} // Remove default link styles
                  >
                    <Box
                      sx={{
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        padding: '16px',
                        backgroundColor: "#f9f9f9",
                        cursor: 'pointer', // Add pointer cursor
                        transition: 'box-shadow 0.3s ease', // Add hover effect
                        '&:hover': { boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' },
                      }}
                      className="mb-2"
                    >
                      <Typography variant="h6" gutterBottom>
                        <strong> Mã kiểm tra: {inspection.inspectionNumber}</strong>
                      </Typography>

                      <Typography gutterBottom>
                        <strong>Phương tiện: </strong> {inspection.vehicle?.name} - {inspection.vehicle?.vehicleNumber}
                      </Typography>

                      <Typography gutterBottom>
                        <strong>Thông tin đơn: </strong> Tên đơn: {inspection.inspectionForm?.name} - Mô tả: {inspection.inspectionForm?.description}
                      </Typography>

                      <Typography gutterBottom>
                        <strong>Người phụ trách: </strong> {inspection.inspectionBy?.firstName} {inspection.inspectionBy?.lastName} (Tên tài khoản: {inspection.inspectionBy?.username})
                      </Typography>

                      {inspection.primaryMeter && selectedVehicle && (
                        <Typography gutterBottom>
                          <strong>Odo chính lúc thực hiện: </strong>{inspection.primaryMeter}  {selectedVehicle.primaryMeterType}
                        </Typography> 
                      )}

                      <Typography gutterBottom>
                        <strong>Ngày kiểm tra: </strong>{formatDate(inspection.inspectionDate)}  
                      </Typography>

                      <Typography gutterBottom>
                        <Box display="flex" alignItems="center" mb={1}>
                          <Typography><strong>Trạng thái:</strong></Typography>
                          {inspection.status && statusInspectionMap[inspection.status] ? (
                            <Chip
                              label={statusInspectionMap[inspection.status].label}
                              style={{
                                backgroundColor: statusInspectionMap[inspection.status].color,
                                color: '#fff',
                                fontWeight: 'bold',
                                borderRadius: '5px',
                                padding: '5px 10px',
                                marginLeft: '10px',
                              }}
                            />
                          ) : (
                            <Chip
                              label="Không xác định"
                              style={{
                                backgroundColor: '#9e9e9e', // Default gray color
                                color: '#fff',
                                fontWeight: 'bold',
                                borderRadius: '5px',
                                padding: '5px 10px',
                                marginLeft: '10px',
                              }}
                            />
                          )}
                        </Box>
                      </Typography>
                    </Box>
                  </Link>
                ))}
      
                {/* Pagination Component */}
                <Box display="flex" justifyContent="center" mt={2}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Box>
              </>
            )}
  
    </Box>
  );
};

export default ReminderInspection;
