import * as React from 'react';
import { Typography } from '@mui/material';
import { CustomDialog } from '@/components/dashboard/dialog/Dialog';
import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { InspectionReminder } from './InspectionReminderPage'; 
import axios from 'axios';
import { FieldManagementDialog, DialogField } from '@/components/dashboard/fieldManagement/FieldManagement';
import  useCustomFieldsUpdate from '@/hooks/field-managementSubmit'; // Import the hook
import { UseFetchVechicle, UseFetchInspectionForm  } from '@/hooks/fetch-relationship-field'; 
import FormThings from './formWithCustomSelectInspectionReminder';
import { formatDate } from '@/hooks/format-date';
import { unitToDays } from '@/hooks/mapping';
import ReminderInspection from '@/components/subPlace/InspectionReminderComponenent';

interface InspectionReminderDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (serviceReminderData: Partial<InspectionReminder>) => void;
  inspectionReminderData?: Partial<InspectionReminder>;
  refreshData: () => void; // <-- pass fetchData here
}

export const InspectionReminderDialog: React.FC<InspectionReminderDialogProps> = ({open, onClose, onSubmit, inspectionReminderData, refreshData}) => {
  let isEditMode = !!inspectionReminderData;
  const [fields, setFields] = React.useState<DialogField[]>([]);
  const [formData, setFormData] = React.useState<Partial<InspectionReminder>>(inspectionReminderData || {});
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
 
  const [isFieldManagementOpen, setFieldManagementOpen] = React.useState(false);
  
  const [customFieldsData, setCustomFieldsData] = React.useState<Record<string, any>>({});
  
  const { vehicles } = UseFetchVechicle();
  const { inspectionForms } = UseFetchInspectionForm(); 

  const [vehicleId, setVehicleId] = React.useState<number | null>(null);
  const [inspectionFormId, setInspectionFormId] = React.useState<number | null>(null);

  const [inspectionFormHistory, setInspectionFormHistory] = React.useState<any[]>([]);  

  React.useEffect(() => {
    fetchFields();
  }, []);

  // doing the history later
  React.useEffect(() => {
    if (vehicleId && inspectionFormId) {
      fetchInspectionHistory(vehicleId, inspectionFormId); 
    }
  }, [vehicleId, inspectionFormId]);

  const fetchFields = async () => {
    try {
      const response = await axios.get('/api/inspection-reminder-dialog-fields');
      setFields(response.data);
    } catch (error) {
      console.error('Failed to fetch dialog fields:', error);
    }
  };

  // history will do later
  const fetchInspectionHistory = async (vehicleId: number, inspectionFormId: number) => {
    try {
      const response = await axios.get('/api/inspections-history', {
        params: { vehicleId, inspectionFormId },
      });
      setInspectionFormHistory(response.data);
    } catch (error) {
      console.error('Failed to fetch service entries history:', error);
    }
  };

  const addFieldToDatabase = async (field: DialogField): Promise<DialogField> => {  
    try {
      const response = await axios.post('/api/inspection-reminder-dialog-fields', field);
      return response.data;
    } catch (error) {
      console.error('Failed to add dialog field:', error);
      return field;
    }
  }

  const updateFieldInDatabase = async (field: DialogField): Promise<DialogField> => {
    try {
      const response = await axios.put(`/api/inspection-reminder-dialog-fields/${field.id}`, field);
      return response.data;
    } catch (error) {
      console.error('Error updating field:', error);
      throw error;
    }
  };

  const deleteFieldFromDatabase = async (id: number): Promise<void> => {
    try {
      await axios.delete(`/api/inspection-reminder-dialog-fields/${id}`);
    } catch (error) {
      console.error('Error deleting field:', error);
      throw error;
    }
  };

  const { handleCustomFieldsUpdate } = useCustomFieldsUpdate(); // Use the custom

  const handleFieldManagementSubmit = async (updatedFields: DialogField[]) => {  
    handleCustomFieldsUpdate(
      fields,                         
      formData.customFields || {},     
      inspectionReminderData?.customFields,      
      updatedFields,                   
      setCustomFieldsData,             
      setFormData,                     
      setFields,                       
      setFieldManagementOpen           
    );
    refreshData();  // <-- Trigger refresh after field management is done
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const customFields: Record<string, any> = {};

      fields.forEach((field) => {
        if (field.canDelete) {
          customFields[field.name] = formData[field.name as keyof typeof formData] || customFieldsData[field.name];
        }
      });

      onSubmit({
        ...formData,
        customFields,
        vehicleId: vehicleId ?? undefined,
        inspectionFormId: inspectionFormId ?? undefined,
      });
    }
  };

  const validateForm = ():  boolean => {
    const newErrors: { [key: string]: string } = {};

    if (!vehicleId) {
      newErrors.vehicleId = 'Vui lòng chọn phương tiện'; 
    }

    if (!inspectionFormId) {
      newErrors.sinspectionFormId = 'Vui lòng chọn đơn báo cáo'; 
    } 

     // Validate time interval and units
    if (formData.timeInterval && !formData.timeIntervalUnit) {
      newErrors.timeIntervalUnit =
        'Vui lòng chọn đơn vị của khoảng thời gian nếu có giá trị khoảng thời gian.';
    }
  
    if (!formData.timeInterval && formData.timeIntervalUnit) {
      newErrors.timeInterval =
        'Vui lòng cung cấp giá trị của khoảng thời gian nếu có đơn vị thời gian.';
    }
  
    // Validate time interval threshold and units
    if (formData.timeIntervalThreshold && !formData.timeIntervalThresholdUnit) {
      newErrors.timeIntervalThresholdUnit =
        'Vui lòng chọn đơn vị của khoảng thời gian nhắc nhở nếu có giá trị khoảng thời gian nhắc nhở.';
    }
  
    if (!formData.timeIntervalThreshold && formData.timeIntervalThresholdUnit) {
      newErrors.timeIntervalThreshold =
        'Vui lòng cung cấp giá trị của khoảng thời gian nhắc nhở nếu có đơn vị thời gian nhắc nhở.';
    }
  
    // Convert time intervals to days for comparison
    if (
      formData.timeInterval &&
      formData.timeIntervalThreshold &&
      formData.timeIntervalUnit &&
      formData.timeIntervalThresholdUnit
    ) {
      const timeIntervalInDays = unitToDays(
        formData.timeInterval,
        formData.timeIntervalUnit
      );
      const timeIntervalThresholdInDays = unitToDays(
        formData.timeIntervalThreshold,
        formData.timeIntervalThresholdUnit
      );
  
      if (timeIntervalThresholdInDays >= timeIntervalInDays) {
        newErrors.timeIntervalThreshold =
          'Khoảng thời gian nhắc nhở không thể lớn hơn hoặc bằng khoảng thời gian nhắc nhở chính.';
      }
    }

    // Validate manual next due date
    if (formData.manualNextDueDate_flg) {
      if (vehicleId && vehicles.length > 0) {
        const selectedVehicle = vehicles.find((v) => v.id === vehicleId);
        if (selectedVehicle) {
          if (
            formData.nextDuePrimaryMeter && formData.nextDuePrimaryMeter <= selectedVehicle.primaryMeter
          ) {
            newErrors.nextDueDatePrimaryMeter =
              'Khoảng đo chính tiếp theo để nhắc cần lớn hơn khoảng đo chính hiện tại của phương tiện.';
          }
        } else {
          newErrors.vehicleId = 'Dữ liệu phương tiện không hợp lệ.';
        }
      } else {
        newErrors.vehicleId = 'Vui lòng chọn phương tiện để kiểm tra.';
      }
    }

    if (formData.nextDueDate) {
      const currentDate = new Date();
      const nextDueDate = new Date(formData.nextDueDate);
      if (nextDueDate <= currentDate) {
        newErrors.nextDueDate =
          'Ngày đến hạn tiếp theo phải nằm trong tương lai.';
      }
    }

    // Set the errors
    setErrors(newErrors);
    
    // If there are errors, show them in an alert
    if (Object.keys(newErrors).length > 0) {
      const errorMessages = Object.values(newErrors)
        .map((message) => `+ ${message}`) // Add "+ " before each error message
        .join('\n'); // Combine all error messages
  
      alert(`Các mục bị lỗi:\n${errorMessages}`); // Show alert with errors
    }
  
    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  React.useEffect(() => {
    if (open) {
      if (isEditMode && inspectionReminderData) {
        setFormData({...inspectionReminderData,
          nextDueDate: formatDate(inspectionReminderData.nextDueDate),  
        });

        if (inspectionReminderData.customFields) {
          try {
            const parsedCustomFields = typeof inspectionReminderData.customFields === 'string' ? JSON.parse(inspectionReminderData.customFields) : inspectionReminderData.customFields;
            setCustomFieldsData(parsedCustomFields);
          } catch (error) {
            console.log('Failed to parse custom fields:', error);
          }
        }

        setVehicleId(inspectionReminderData.vehicleId || null); 
        setInspectionFormId(inspectionReminderData.inspectionFormId || null); 
      } else {
        setFormData({});
        setCustomFieldsData({});
        setVehicleId(null); 
        setInspectionFormId(null);
      }
    }
  }, [open, isEditMode]);

  const handleCustomFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Check if the input is a checkbox or a regular text input
    setCustomFieldsData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,  // If checkbox, store as boolean; else, store the value
    }));
  };
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;
  
    // Handle checkbox separately to update the state with boolean values
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const formFields: FormField[] = fields
  .filter((field) => {
    // Exclude fields based on `manualNextDueDate_flg`
    if (!formData.manualNextDueDate_flg && ['nextDuePrimaryMeter', 'nextDueDate'].includes(field.name)) {
      return false;
    }
    return true;
  })
  .map((field) => {
    return {
      label: field.label,
      name: field.name,
      type: ['timeIntervalUnit', 'timeIntervalThresholdUnit'].includes(field.name) ? 'select' : field.type,
      options: ['timeIntervalUnit', 'timeIntervalThresholdUnit'].includes(field.name)
        ? [
            { label: 'Ngày', value: 'day' },
            { label: 'Tuần', value: 'week' },
            { label: 'Tháng', value: 'month' },
            { label: 'Năm', value: 'year' },
          ]
        : undefined,
      value: formData[field.name as keyof InspectionReminder] || customFieldsData[field.name] || '',
      required: field.required,
      handleChange: field.canDelete ? handleCustomFieldChange : handleChange,
      error: errors[field.name],
    };
  });


  const handleCloseDialog = () => { 
    onClose();
    setErrors({});
    setFormData({});
    setCustomFieldsData({});
    setVehicleId(null); 
    setInspectionFormId(null);
    setInspectionFormHistory([]);

  };
      
  return (      
    <CustomDialog open={open} title="Thêm/Chỉnh sửa thông tin thẻ nhắc" onClose={handleCloseDialog} manageFields={() => setFieldManagementOpen(true)}
    onSubmit={handleSubmit} submitLabel="Lưu" dialogWidth='800px' dialogMaxWidth='95%'
    >

    <div className='row'>
      <div className={isEditMode ? "col-md-6" : "col-md-12"}>
        <FormThings 
          formFields={formFields}
          vehicles={vehicles} 
          vehicleId={vehicleId} 
          setVehicleId={setVehicleId}
          // serviceTasks={serviceTasks}
          // serviceTaskId={serviceTaskId}
          // setServiceTaskId={setServiceTaskId}
          inspectionForms={inspectionForms} 
          inspectionFormId={inspectionFormId}
          setInspectionFormId={setInspectionFormId} 
        
        />
      </div> 

      { isEditMode && (
        <div className='col-md-6'>
          <Typography variant="h5" gutterBottom>Lịch sử các lần kiểm tra của xe</Typography>
          <ReminderInspection inspections={inspectionFormHistory} vehicleId={vehicleId} vehicles={vehicles}/>  
        </div>
      )}
    </div>

    
    <FieldManagementDialog
      open={isFieldManagementOpen}
      onClose={() => setFieldManagementOpen(false)}
      fields={fields}
      onFieldSubmit={handleFieldManagementSubmit}
      addFieldToDatabase={addFieldToDatabase}
      updateFieldInDatabase={updateFieldInDatabase}
      deleteFieldFromDatabase={deleteFieldFromDatabase}
    />

    </CustomDialog>

  )
    
}

 

