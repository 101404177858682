import { FormFields, FormField } from '@/components/dashboard/formField/FormFields';
import { CustomSelectWithAdd , CustomSelectWithAddProps } from '@/components/dashboard/customSelectWithAdd/customSelectWithAdd';

const FormThings = ({
    formFields,
    departments,
    groups,
    makes,
    models,
    vehicleTypes,
    fuelTypes,
    licensePlateTypes,
    trailerAttachedTo,
    departmentId,
    setDepartmentId,
    isTrailer,
    addDepartment,
    editDepartment,
    deleteDepartment,
    groupId,
    setGroupId,
    addGroup,
    editGroup,
    deleteGroup,
    makeId,
    setMakeId,
    addMake,
    editMake,
    deleteMake,
    modelId,
    setModelId,
    addModel,
    editModel,
    deleteModel,
    vehicleTypeId,
    setVehicleTypeId,
    addVehicleType,
    editVehicleType,
    deleteVehicleType,
    fuelTypeId,
    setFuelTypeId,
    addFuelType,
    editFuelType,
    deleteFuelType,
    licensePlateTypeId,
    setLicensePlateTypeId,
    addLicensePlateType,
    editLicensePlateType,
    deleteLicensePlateType,
    trailerAttachedToId,
    setTrailerAttachedToId,
  
} : {

    formFields: FormField[];
    departments: any[];
    groups: any[];
    makes: any[];
    models: any[];
    vehicleTypes: any[];
    fuelTypes: any[];
    licensePlateTypes: any[];
    trailerAttachedTo: any[];
    departmentId: number | null;
    addDepartment:(item: string) => void;
    setDepartmentId: (id: number) => void;
    isTrailer: boolean; // Add isTrailer prop
    editDepartment: (id: number, newName: string) => void;
    deleteDepartment: (id: number) => void;
    groupId: number | null;
    setGroupId: (id: number) => void;
    addGroup: (item: string) => void;
    editGroup: (id: number, newName: string) => void;
    deleteGroup: (id: number) => void;
    makeId: number | null;
    setMakeId: (id: number) => void;
    addMake: (item: string) => void;
    editMake: (id: number, newName: string) => void;
    deleteMake: (id: number) => void;
    modelId: number | null;
    setModelId: (id: number) => void;
    addModel: (item: string) => void;
    editModel: (id: number, newName: string) => void;
    deleteModel: (id: number) => void;
    vehicleTypeId: number | null;
    setVehicleTypeId: (id: number) => void;
    addVehicleType: (item: string) => void;
    editVehicleType: (id: number, newName: string) => void;
    deleteVehicleType: (id: number) => void;
    fuelTypeId: number | null;
    setFuelTypeId: (id: number) => void;
    addFuelType: (item: string) => void;
    editFuelType: (id: number, newName: string) => void;
    deleteFuelType: (id: number) => void;
    licensePlateTypeId: number | null;
    setLicensePlateTypeId: (id: number) => void;
    addLicensePlateType: (item: string) => void;
    editLicensePlateType: (id: number, newName: string) => void;
    deleteLicensePlateType: (id: number) => void;
    trailerAttachedToId: number | null | undefined;
    setTrailerAttachedToId: (id: number) => void;
}) => {
    return (
        <form>
            {formFields.map((field, index) => (
                <div key={index}>
                    {/* Render form fields */}
                    <FormFields fields={[field]} />

                    {/* Inject custom select components at specific positions */}
                    {index === 2 && (
                        <CustomSelectWithAdd
                            label='Hãng Xe'
                            items={makes}
                            value={makeId || 0}
                            onChange={setMakeId}
                            addItem={addMake}
                            editItem={editMake}
                            deleteItem={deleteMake}
                        />
                    )}

                    {index === 2 && (
                        <CustomSelectWithAdd
                            label='Dòng Xe'
                            items={models}
                            value={modelId || 0}
                            onChange={setModelId}
                            addItem={addModel}
                            editItem={editModel}
                            deleteItem={deleteModel}
                        />
                    )}

                    {index === 5 && (
                        <CustomSelectWithAdd
                            label='Loại Xe'
                            items={vehicleTypes}
                            value={vehicleTypeId || 0}
                            onChange={setVehicleTypeId}
                            addItem={addVehicleType}
                            editItem={editVehicleType}
                            deleteItem={deleteVehicleType}
                        />
                    )}

                    
                    {index === 5 && (
                        <CustomSelectWithAdd
                            label='Bộ Phận'
                            items={departments}
                            value={departmentId || 0}
                            onChange={setDepartmentId}
                            addItem={addDepartment}
                            editItem={editDepartment}
                            deleteItem={deleteDepartment}
                        />
                    )}
                    {index === 5 && (
                        <CustomSelectWithAdd
                            label='Nhóm'
                            items={groups}
                            value={groupId || 0}
                            onChange={setGroupId}
                            addItem={addGroup}
                            editItem={editGroup}
                            deleteItem={deleteGroup}
                        />
                    )}

                    {index === 5 && (
                        <CustomSelectWithAdd
                            label='Loại Nhiên Liệu'
                            items={fuelTypes}
                            value={fuelTypeId || 0}
                            onChange={setFuelTypeId}
                            addItem={addFuelType}
                            editItem={editFuelType}
                            deleteItem={deleteFuelType}
                        />
                    )}

                    {index === 6 && (
                        <CustomSelectWithAdd
                            label='Loại Biển Số'
                            items={licensePlateTypes}
                            value={licensePlateTypeId || 0}
                            onChange={setLicensePlateTypeId}
                            addItem={addLicensePlateType}
                            editItem={editLicensePlateType}
                            deleteItem={deleteLicensePlateType}
                        />
                    )}

                    {index === 12 && !isTrailer && (
                        <CustomSelectWithAdd
                            label='Xe Kèm Theo'
                            items={trailerAttachedTo}
                            value={trailerAttachedToId || 0}
                            onChange={setTrailerAttachedToId}
                        />
                    )}

                </div>
            ))}
        </form>
    );
};

export default FormThings;