import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Download as DownloadIcon } from '@phosphor-icons/react';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { Upload as UploadIcon } from '@phosphor-icons/react';
// import { CustomersFilters } from './customers-filters';
import { GenericFilters } from '@/components/dashboard/filter/genericFilter';
import { GenericTable } from '@/components/dashboard/table/genericTable';
import { TableColumn } from '@/components/dashboard/table/columnType1'; 
import axios from 'axios';
import { UserDialog } from './userDialog';
import { formatDate } from '@/hooks/format-date';
import dayjs, { Dayjs } from 'dayjs';

// **NOTE: in this code just prefered the customer to the accoutns instead (tempalte used)

export interface Customer {
  id: string;
  password: string;
  username: string;
  firstName: string;   // Add firstName
  lastName: string;    // Add lastName
  email: string;
  phoneNumber: string;
  address: string;
  departmentId: number;  // Add department
  jobTitleId: number;    // Add jobTitle
  groupId: number;
  categoryDriver: boolean;
  categoryTechnician: boolean;
  hiredOn: Date | string;
  createdAt: Date;
  pictureUrl: string;
  customFields: Record<string, string>; 
}

function applyPagination(rows: Customer[], page: number, rowsPerPage: number): Customer[] {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

export default function UsersPage(): React.JSX.Element {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5); // Adjust number of rows per page as necessary
  const [data, setData] = React.useState<Customer[]>([]); // Customer data state
  const [filteredData, setFilteredData] = React.useState<Customer[]>([]); // Filtered data state
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedCustomer, setSelectedCustomer] = React.useState<Customer | null>(null);

  const fetchData = async () => {
    try{
      const response = await axios.get('/api/accounts_data');
      const sortedData = response.data.sort((a: Customer, b: Customer) => {a.username.localeCompare(b.username)});
      setData(sortedData);
      setFilteredData(sortedData);
    } catch (error){
      console.error('Error fetching data:', error);
    }
  };

  // /api/accounts_data
  React.useEffect(() => {   
    fetchData();
  }, []);


  const paginatedCustomers = applyPagination(filteredData, page, rowsPerPage);
  
  const handleFilterChange = (column: string, searchTerm: string | boolean | Dayjs | null) => {
    const filtered = data.filter((customer) => {
      const value = customer[column as keyof Customer]; // Access the column value dynamically
  
      if (searchTerm === null) {
        // If no searchTerm, include all items (e.g., dropdown initial state)
        return true;
      }
  
      if (typeof value === 'string') {
        return value.toLowerCase().includes((searchTerm as string).toLowerCase()); // Case-insensitive search for strings
      }
  
      if (typeof value === 'number') {
        return value === Number(searchTerm); // Match numbers exactly
      }
  
      if (typeof value === 'boolean') {  
        return value === Boolean(searchTerm); // Match boolean values exactly
      }

      return false;  
    });
    
    setFilteredData(filtered);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => { 
    setRowsPerPage(newRowsPerPage); 
    setPage(0);
  };

  const handleOpenDialog = () => {
    setSelectedCustomer(null); // Reset selected customer when adding a new one
    setOpenDialog(true);
  };

  const handleEditCustomer = (id: string) => {
    const customerToEdit = data.find((customer) => customer.id === id);
    if (customerToEdit) {
      setSelectedCustomer(customerToEdit); // Populate the dialog with the selected customer
      setOpenDialog(true); // Open dialog for editing
    }
  };

  const handleDeleteCustomer = async (id: string) => {
    const confirmed = window.confirm('Bạn có chắc là xóa người dùng này?');
    if (confirmed) {
      try {
        await axios.delete(`/api/delete-account/${JSON.stringify(id)}`);
        setData((prevData) => prevData.filter((customer) => customer.id !== id));
        setFilteredData((prevData) => prevData.filter((customer) => customer.id !== id));
      } catch (error) {
        console.error('Error deleting customer:', error);
      }
    }
  };
  
  const handleDeleteCustomerSelected = async (selectedIds: string[]) => {
    const confirmed = window.confirm('Bạn có chắc là xóa những người dùng đã chọn?'); 
    if (confirmed) {
      try{
          await axios.delete(`/api/delete-account/${JSON.stringify(selectedIds)}`);
          alert('Xóa thành công');
          setData((prevData) => prevData.filter((customer) => !selectedIds.includes(customer.id)));
          setFilteredData((prevData) => prevData.filter((customer) => !selectedIds.includes(customer.id)));  
      } catch (error){
        console.log('Error deleting customer:', error);
      }
    }
  }

  const handleAddOrEditCustomer = async (customerData: Partial<Customer>, imageFile?: File) => {
    try {
      // Create a FormData object to handle both file and customer data
      const formData = new FormData();
  
      // Append the customer data fields to the FormData object
      Object.keys(customerData).forEach((key) => {
        const value = customerData[key as keyof Customer];
        if (value !== undefined) {
          formData.append(key, value as string | Blob);
        }
      });
  
      // Handle customFields: Convert customFields to a JSON string before appending
      if (customerData.customFields) {
        formData.set('customFields', JSON.stringify(customerData.customFields));
      }
  
      // Append the image file, if it exists
      if (imageFile) {
        formData.append('picture', imageFile); // 'picture' is the field name for the image file
      }
  
      if (selectedCustomer) {
          // Editing existing customer
        const response = await axios.put(`/api/edit-account/${selectedCustomer.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
          },
        });
        const updatedCustomer = response.data;
  
        setData((prevData) =>
          prevData.map((customer) => (customer.id === updatedCustomer.id ? updatedCustomer : customer))
        );
        setFilteredData((prevData) =>
          prevData.map((customer) => (customer.id === updatedCustomer.id ? updatedCustomer : customer))
        );
      } else {
        // Validate the form data if the password is empty
        if (!customerData.password) {
          alert('Password is required');
          return;
        }
  
        // Adding new customer
        const response = await axios.post('/api/add-account', formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure the correct header is set for file uploads
          },
        });
        const newCustomer = response.data;
        setData((prevData) => [...prevData, newCustomer]);
        setFilteredData((prevData) => [...prevData, newCustomer]);
      }
    
      setOpenDialog(false); // Close the dialog after the operation

    } catch (error: any) {
      console.error('Lỗi thêm/cập nhật người dùng, vui lòng thử lại sau');
      if (error.response && error.response.status === 409) {
        const errorFlag = error.response.data.errorFlag;
        switch (errorFlag) {
          case 'USERNAME_EXISTS':
            alert('Tên tài khoản đã tồn tại. Hãy chọn tên tài khoản khác.');
            break;
          case 'EMAIL_EXISTS':
            alert('Email đã tồn tại. Hãy chọn email khác.');
            break;
          case 'BOTH_USERNAME_AND_EMAIL_EXIST':
            alert('Tên tài khoản và email đã tồn tại. Hãy chọn tên tài khoản và email khác.');
            break;
          default:
            alert('Lỗi thêm/cập nhật người dùng. Vui lòng thử lại sau.');
          }
      } else {
        alert('Lỗi thêm/cập nhật người dùng. Vui lòng thử lại sau.');
      }
    }
  };

  //filter columns
  const filterColumns = [
    { value: 'username', label: 'Tên' },
    { value: 'phoneNumber', label: 'Số Điện thoại' },
    { value: 'email', label: 'Email' }, 
  ];

  //table columns
  const columns: TableColumn<Customer>[] = [
    { label: 'Ảnh', field: 'pictureUrl', isImage: true },
    { label: 'Tên tài khoản', field: 'username' },
    { label: 'Email', field: 'email' },
    { label: 'Địa chỉ', field: 'address' },
    { label: 'Số Điện thoại', field: 'phoneNumber' },
    { label: 'Thời gian được tạo', field: 'createdAt', customRender: (rowData) => formatDate(rowData.createdAt), isDate: true },
  ];

   return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h4">Nhân viên công ty</Typography>
        </Stack>
        <div>
          <Button startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={handleOpenDialog}>
            Thêm
          </Button>
        </div>
      </Stack>
      <GenericFilters columns={filterColumns} onFilterChange={handleFilterChange} placeholder="Tìm nhân viên" />
      <GenericTable
        count={filteredData.length}
        page={page}
        rows={paginatedCustomers}
        columns={columns}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onEdit={handleEditCustomer}
        onDelete={handleDeleteCustomer}
        onDeleteSelected={handleDeleteCustomerSelected}
      />
      {/* Add customer dialog */}
      <UserDialog open={openDialog} onClose={() => setOpenDialog(false)} onSubmit={handleAddOrEditCustomer} customerData={selectedCustomer ?? undefined}   
      refreshData={fetchData} // <-- pass fetchData here
 />
    </Stack>
  );
}

// This ensures that this file is treated as a module and resolves the TS1208 error
export {};
