import * as React from 'react';
import {
  TextField,
  Stack,
  Typography,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  FormControl, Chip, Link, Button, Box,  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { vi } from 'date-fns/locale';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { customVi } from '@/assets/data/calendarCustom';
import {format, parse, parseISO} from 'date-fns';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import mammoth from 'mammoth';
import axios from 'axios';


export interface FormField {
  label: string;
  name: string;
  type?: string;
  value: any;
  error?: string;
  required?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  options?: { label: string; value: string; color?: string }[];
  multiple?: boolean;
  isFile?: boolean;
  multiline?: boolean;
}

interface FormFieldsProps {
  fields: FormField[];
}

export const FormFields: React.FC<FormFieldsProps> = ({ fields }) => {
  const PDF_WORKER_URL = `https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`;
  const [blobUrl, setBlobUrl] = React.useState<string | null>(null);
  const [wordHtml, setWordHtml] = React.useState<string | null>(null);

  const fetchWordAsHtml = async (url: string) => {
    try {
      // Properly encode the URL to handle special characters
      const encodedUrl = encodeURI(url);
      const response = await axios.get(encodedUrl, { responseType: 'arraybuffer' });
      const arrayBuffer = response.data;
      const result = await mammoth.convertToHtml({ arrayBuffer });
      setWordHtml(result.value);
    } catch (error) {
      console.error('Error fetching Word document:', error);
    }
  };
  
  // Fetch PDF as Blob and create a URL for it
  const fetchPdfAsBlob = async (url: string) => {
    try {
      // Properly encode the URL to handle special characters
      const encodedUrl = encodeURI(url);
      const response = await axios.get(encodedUrl, { responseType: 'blob' });
      const blob = response.data;
      const urlBlob = URL.createObjectURL(blob);
      setBlobUrl(urlBlob);
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };
  
  React.useEffect(() => {
    // Find the file field if it exists and fetch it accordingly
    const fileField = fields.find((field) => field.value && field.isFile);
    if (fileField) {
      const encodedFileName = encodeURIComponent(fileField.value.split('/').pop() || '');
      const fileUrl = `/api/pdf-preview/${encodedFileName}`;
  
      if (fileField.value.endsWith('.pdf')) {
        fetchPdfAsBlob(fileUrl);
      } else if (fileField.value.endsWith('.docx')) {
        fetchWordAsHtml(encodeURI(fileField.value));
      }
    }
  
    // Clean up Blob URL when component unmounts
    return () => {
      if (blobUrl) URL.revokeObjectURL(blobUrl);
    };
  }, [fields]);

  // for file download
  const downloadFile = async (filename: any) => {
    try {
      const response = await axios.get(`/api/pdf-download/${encodeURIComponent(filename)}`, {
        responseType: 'blob', // Important to handle binary files
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename); // Set filename for the download
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.error('Error downloading the file:', error);
      alert('Error downloading the file. Please try again later.');
    }
  };
  

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={customVi}>
      <Stack spacing={2} className="mt-2">
        {fields.map((field, index) => (
          <div key={index}>
            {field.type === 'checkbox' ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(field.value)}
                    
                    onChange={(e) => {
                      if (field.handleChange){
                        field.handleChange(e);
                      }
                    }}
                    name={field.name}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body1" component="span">
                    <strong>{field.label}</strong> <span style={{ color: "#FF0000" }}>{field.required ? ' *' : ''}</span>
                  </Typography>
                }
              />
            ) : field.type === 'select' && field.options ? (
              // Render a select dropdown if the field type is 'select' and options are provided
              <>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>{field.label}</strong> <span style={{ color: "#FF0000" }}>{field.required ? ' *' : ''}</span>
                </Typography>
                <FormControl fullWidth error={!!field.error} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Select
                    name={field.name}
                    value={field.value}
                    onChange={(e) => {
                      const value = field.multiple
                        ? (e.target.value as string[]) // If multiple, treat the value as an array
                        : (e.target.value as string); // If not, treat the value as a single string

                      if (field.handleChange) {
                        field.handleChange({
                          target: { name: field.name, value },
                        } as React.ChangeEvent<HTMLInputElement>);
                      }
                    }}
                    required={field.required}
                    fullWidth
                    displayEmpty
                    multiple={field.multiple} // Supports multiple selection if true
                    renderValue={(selected) => {
                      if (Array.isArray(selected)) {
                        return selected.map((value) => {
                          const selectedOption = field.options?.find((option) => option.value === value);
                          return selectedOption ? (
                            <Chip
                              key={value}
                              label={selectedOption.label}
                              style={{
                                backgroundColor: selectedOption.color || 'transparent',
                                color: selectedOption.color ? '#fff' : 'inherit',
                                marginRight: '4px',
                              }}
                              size="small"
                            />
                          ) : null;
                        });
                      } else {
                        const selectedOption = field.options?.find((option) => option.value === selected);
                        return selectedOption ? (
                          <Chip
                            label={selectedOption.label}
                            style={{
                              backgroundColor: selectedOption.color || 'transparent',
                              color: selectedOption.color ? '#fff' : 'inherit',
                            }}
                            size="small"
                          />
                        ) : '';
                      }
                    }}
                  >
                    {field.options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {/* Show checkbox only for multiple select */}
                        {field.multiple && (
                          <Checkbox
                            checked={
                              Array.isArray(field.value) &&
                              field.value.includes(option.value)
                            }
                          />
                        )}
                        {option.color && (
                          <Chip
                            style={{
                              backgroundColor: option.color,
                              color: '#fff',
                              marginRight: '8px',
                              height: '16px',
                              width: '16px',
                              borderRadius: '16px',
                            }}
                            size="small"
                          />
                        )}
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>

                  {/* Add clear button if there is a value */}
                  {field.value && (
                    <IconButton
                      onClick={() => {
                        if (field.handleChange) {
                          field.handleChange({
                            target: { name: field.name, value: '' }, // Set value to an empty string
                          } as React.ChangeEvent<HTMLInputElement>);
                        }
                      }}
                      sx={{ ml: 1 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </FormControl>

                {field.error && (
                  <Typography variant="caption" color="error">
                    {field.error}
                  </Typography>
                )}
              </>
            ) : field.type === 'date' ? (
              <>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>{field.label}</strong> <span style={{ color: "#FF0000" }}>{field.required ? ' *' : ''}</span>
              </Typography>
              <DatePicker
                value={field.value ? parse(field.value, 'dd/MM/yyyy', new Date()) : null} // Parse dd/MM/yyyy to Date
                onChange={(newValue) => {
                  const formattedDate = newValue ? format(newValue, 'dd/MM/yyyy') : '';
                  if (field.handleChange){
                    field.handleChange({
                      target: { name: field.name, value: formattedDate }, // Format back to dd/MM/yyyy
                    } as React.ChangeEvent<HTMLInputElement>);
                  }
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    required: field.required,
                    error: !!field.error,
                    helperText: field.error,
                    placeholder: 'dd/mm/yyyy',
                  },
                }}
              />
            </>
            ) : field.type === 'color' ? (
              <>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>{field.label}</strong> <span style={{ color: "#FF0000" }}>{field.required ? ' *' : ''}</span>
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  height: '40px',
                  backgroundColor: field.value,
                  borderRadius: '4px',
                  overflow: 'hidden',
                  border: '1px solid #ddd', // Adjust border color as needed
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <input
                  type="color"
                  name={field.name}
                  value={field.value}
                  onChange={(e) => {
                    if (field.handleChange) {
                      field.handleChange(e as React.ChangeEvent<HTMLInputElement>);
                    }
                  }}
                  style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    outline: 'none',
                    padding: 0,
                    cursor: 'pointer',
                    backgroundColor: 'transparent',
                  }}
                />
              </Box>
            </>
            ) : field.isFile && field.value ? (
              <>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>{field.label}</strong> <span style={{ color: "#FF0000" }}>{field.required ? ' *' : ''}</span>
                </Typography>
              
                {/* Render a PDF preview if it's a PDF file */}
                {field.value.endsWith('.pdf') ? (
                    <>
                        <Button
                          variant="contained"
                          style={{ backgroundColor: '#00CFFF', color: '#000' }}
                          className="mb-3"
                          onClick={() => downloadFile(field.value.split('/').pop())} // Using the Axios download function

                        >
                          Tải về
                        </Button>      
                    {field.value.endsWith('.pdf') && blobUrl ? (
                  <div style={{ height: '400px', overflow: 'auto', border: '1px solid #ddd', borderRadius: '4px' }}>
                    <Worker workerUrl={PDF_WORKER_URL}>
                      <Viewer fileUrl={blobUrl} />
                    </Worker>
                  </div>
                    ) : field.value.endsWith('.docx') && wordHtml ? (
                      <div
                        style={{
                          height: '400px',
                          overflow: 'auto',
                          border: '1px solid #ddd',
                          borderRadius: '4px',
                          padding: '10px',
                          backgroundColor: '#f9f9f9',
                        }}
                        dangerouslySetInnerHTML={{ __html: wordHtml }}
                      />
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        Không preview được file do không phải PDF hoặc DOCX. Hoặc là do extension của browser máy của bạn.
                      </Typography>
                    )}
                  </>
                ) : (
                  // Non-PDF files can still be directly downloadable
                    <Button
                      variant="contained"
                      style={{ backgroundColor: '#00CFFF', color: '#000' }} // Light blue background with black text
                      className="mb-3"
                      onClick={() => downloadFile(field.value.split('/').pop())} // Using the Axios download function
                    >
                      Tải về
                    </Button>
                )}
              </>
            ) : field.type === 'text' ? (
              <>
                <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>{field.label}</strong> <span style={{ color: "#FF0000" }}>{field.required ? ' *' : ''}</span>
                </Typography>
                <TextField
                    name={field.name}
                    value={field.value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                      if (field.handleChange) {
                          field.handleChange(e as React.ChangeEvent<HTMLInputElement>);
                      }
                  }}
                    fullWidth
                    required={field.required}
                    error={!!field.error}
                    helperText={field.error}
                    placeholder={field.label}
                    autoComplete="off"   
                    multiline={field.multiline} // Enable multiline if field.multiline is true

                />
              </>
            ) : field.type === 'number' || field.type === 'float' ? (
              <>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>{field.label}</strong> <span style={{ color: "#FF0000" }}>{field.required ? ' *' : ''}</span>
                </Typography>
                <TextField
                  name={field.name}
                  type="number"
                  value={field.value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    if (field.handleChange) {
                        field.handleChange(e as React.ChangeEvent<HTMLInputElement>);
                    }
                }}                 
                  fullWidth
                  required={field.required}
                  error={!!field.error}
                  helperText={field.error}
                  placeholder={field.label}
                  InputLabelProps={{
                    shrink: false,
                  }}
                  autoComplete="off"
                  multiline={field.multiline} // Enable multiline if field.multiline is true  
                />
              </>
            ) : ( 
            <>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>{field.label}</strong> <span style={{ color: "#FF0000" }}>{field.required ? ' *' : ''}</span>
                  </Typography>
                  <TextField
                    name={field.name}
                    type={field.type || 'text'}
                    value={field.value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                      if (field.handleChange) {
                          field.handleChange(e as React.ChangeEvent<HTMLInputElement>);
                      }
                  }}                 
                    fullWidth
                    required={field.required}
                    error={!!field.error}
                    helperText={field.error}
                    placeholder={field.label}
                    InputLabelProps={{
                      shrink: false,
                    }}
                    autoComplete="off"
                    multiline={field.multiline} // Enable multiline if field.multiline is true  
                  />
                </>
              )}
          </div>
        ))}
      </Stack>
      </LocalizationProvider>


    </>
    
  );
};
