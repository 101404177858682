export const getContrastTextColor = (bgColor: string): string => {
    // Remove the hash if present
    const color = bgColor.startsWith('#') ? bgColor.slice(1) : bgColor;
  
    // Convert to RGB values
    const r = parseInt(color.slice(0, 2), 16);
    const g = parseInt(color.slice(2, 4), 16);
    const b = parseInt(color.slice(4, 6), 16);
  
    // Calculate brightness according to WCAG standards
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  
    // Return white text for dark background and black for light background
    return brightness > 128 ? '#000' : '#fff';
  };
  